import React, { Component } from 'react';

export default class UserEditHelp {

    static name() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Jméno (jméno + příjmení), které zadáte, bude přiřazeno k Vašemu účtu a bude se zobrazovat v Plánovači u jednotlivých chodů.</p>
                <p>Současně toto jméno bude uvedeno u každé potraviny, kterou označíte jako nežádoucí (nevhodnou) – aplikace tak bude upozorňovat na používání těchto potravin, pokud se v jídelníčku objeví.</p>
            </div>
        );
    }

    static photo() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Profilová fotka se bude zobrazovat na úvodní straně po přihlášení do aplikace. Můžete ji v Nastavení svého účtu kdykoli vyměnit za jinou.</p>
            </div>
        );
    }

    static inputData() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Vyplnění požadovaných údajů je potřebné pro správné hodnocení způsobu a kvality stravování v aplikaci. Nezbytné jsou především datum narození, výška a váha.</p>
                <p>Neznáte-li obvod pasu a boků, můžete je doplnit později.</p>
            </div>
        );
    }

    static bmr() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Bazální metabolismu je množství energie, které tělo potřebuje pro udržení základních životních funkcí v klidovém stavu. 
                Její hodnota je nezbytná pro výpočet energetické bilance a tedy sledování kvality způsobu stravování.</p>
                <p>Pokud ji neznáte, nevyplňujte, aplikace ji vypočítá sama z předchozích vstupních parametrů, které jste v nastavení již zadali.</p>
            </div>
        );
    }

    static energeticRegime() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Zvolte režim, který bude odpovídat Vašemu cíli. Na jeho základě bude aplikace sledovat energetickou bilanci, upozorňovat na případné chyby ve stravování a současně i doporučovat možnosti vedoucí ke zjednání nápravy. Svoji volbu můžete kdykoli měnit na základě vlastních preferencí.</p>
                <p>Režim <b>„Chci hubnout“</b> - cílem je udržovat energetickou bilanci v záporných hodnotách tak, aby dlouhodobě vedla k redukci tělesné hmotnosti. Aplikace upozorní na situace, kdy denní bilance klesne pod hodnotu BMR (bazální metabolismus), nebo naopak přesáhne hodnotu u dětí BMR + 150 kCal, u žen BMR + 200 kCal a u mužů BMR + 300 kCal.</p>
                <p>Režim <b>„Vyrovnaný“</b> - cílem je udržovat energetickou bilanci na vyrovnané úrovni, aplikace sleduje rozmezí 50 kCal od rovnovážného stavu. Pokud se bilance vychýlí mimo toto rozmezí, vždy na nerovnováhu upozorní a doporučí tipy vedoucí k nápravě situace.</p>
                <p>Režim <b>„Chci přibrat“</b> - cílem je udržovat energetickou bilanci v kladných hodnotách tak, aby vedla k nárůstu tělesné hmotnosti. Aplikace upozorní na situace, kdy denní bilance klesne pod hodnotu vyrovnaného energetického příjmu + 50 kCal, a poskytne tipy pro zjednání nápravy situace.</p>
            </div>
        );
    }

    static nutritionRegime() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Volba konkrétního režimu umožní aplikaci vést Vaše stravování požadovaným směrem – vždy upozorní na případnou chybu v koncepci jídelníčku, která neodpovídá pravidlům zvoleného režimu.</p>
                <p>Režim <b>„Smíšený“</b> – tento způsob stravování nevylučuje žádné potraviny, zahrnuje všechny živočišné i rostlinné potraviny.</p>
                <p>Režim <b>„Lakto-ovo vegetarián“</b> – tento způsob stravování vylučuje z jídelníčku maso, ryby, mořské plody a produkty z nich. Objeví-li se jakákoli taková potravina v jídelníčku, aplikace na toto upozorní.</p>
                <p>Režim <b>„Ovo vegetarián“</b> - tento způsob stravování vylučuje z jídelníčku maso, ryby, mořské plody a produkty z nich a živočišné mléčné výrobky (mléka, jogurty, sýry, tvarohy apod.). Objeví-li se jakákoli taková potravina v jídelníčku, aplikace na toto upozorní.</p>
                <p>Režim <b>„Lakto vegetarián“</b> - tento způsob stravování vylučuje z jídelníčku maso, ryby, mořské plody, vejce a produkty z nich. Objeví-li se jakákoli taková potravina v jídelníčku, aplikace na toto upozorní.</p> 
                <p>Režim <b>„Vegan“</b> - tento způsob stravování vylučuje z jídelníčku veškeré živočišné produkty (maso, ryby, mořské plody, živočišné mléčné výrobky, vejce a výrobky z nich včetně včelích produktů). Objeví-li se jakákoli taková potravina v jídelníčku, aplikace na toto upozorní.</p>
                <p>Režim <b>„Bez lepku“</b> - tento způsob stravování vylučuje z jídelníčku veškeré zdroje lepku (rostlinná bílkovina nacházející se v lepkových obilovinách – pšenici, žitu, ječmeni a ovsu). Objeví-li se jakákoli taková potravina v jídelníčku, aplikace na toto upozorní. Výjimku tvoří potraviny vyrobené z lepkových obilovin, které obsahují méně než 20 mg lepku v 1 kg potraviny a jsou na základě platných legislativních předpisů označovány jako bezlepkové. V případě, že Vaše individualita nemůže konzumovat ani tyto potraviny, je třeba je označit jako nežádoucí zvlášť.</p>
                <p>Režim <b>„Laktózová intolerance“</b> - tento způsob stravování vylučuje z jídelníčku veškeré zdroje laktózy (mléčného cukru přítomného v živočišných mléčných výrobcích). Objeví-li se jakákoli taková potravina v jídelníčku, aplikace na toto upozorní. Výjimku tvoří kategorie bezlaktózových mléčných produktů, u kterých byl obsah mléčného cukru enzymaticky snížen pod hodnotu 10 mg na 100 gramů nebo mililitrů potraviny. V případě, že Vaše individualita nemůže konzumovat ani tyto potraviny, je třeba je označit jako nežádoucí zvlášť.</p>
                <p>Režim <b>„Dna“</b> - tento způsob stravování kontroluje v jídelníčku obsah purinů (látky, které v přebytku podporují vznik a rozvoj dny). Objeví-li se v jednom receptu potravina s obsahem purinů nad 150 mg, či podíl purinů v celodenním jídelníčku přesáhne 400 mg, aplikace na toto upozorní. </p>
            </div>
        );
    }

    static energyOutput() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p><b>Nepatrná</b><br />Sedavé zaměstnání, bez fyzické aktivity</p>
                <p><b>Velmi malá</b><br />Sedavé zaměstnání, minimální  volnočasová fyzická aktivita</p>
                <p><b>Malá</b><br />Sedavé zaměstnání, pravidelná volnočasová aktivita s nízkou intenzitou (chůze)</p>
                <p><b>Malá sportovní</b><br />Sedavé zaměstnání, pravidelná volnočasová aktivita se střední intenzitou (jízda na kole, plavání, běh, pilates...)</p>
                <p><b>Střední</b><br />Sedavé zaměstnání + trénink vyšší intenzity 3x- 4x týdně; nebo fyzicky náročná práce</p>
                <p><b>Střední sportovní</b><br />Sedavé zaměstnání + trénink vyšší intenzity 4x- 5x týdně; nebo fyzicky náročná práce</p>
                <p><b>Vysoká</b><br />Sedavé zaměstnání + trénink vyšší intenzity 5-6x týdně; nebo fyzicky náročná práce + trénink vyšší intenzity 2x týdně</p>
                <p><b>Vysoká sportoní</b><br />Sedavé zaměstnání + trénink vyšší intenzity 5-6x týdně; nebo fyzicky náročná práce + trénink vyšší intenzity 3x týdně</p>
                <p><b>Značně velká</b><br />Fyzicky náročná práce + trénink vyšší intenzity 4x – 5x týdně</p>
            </div>
        );
    }

    static color() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Barvou, kterou zvolíte u svého profilu, bude označeno Vaše jméno v Plánovači u každého chodu.</p>
            </div>
        );
    }

    static newPassword() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Zde si můžete nastavit nové přihlašovací heslo.</p>
                <p>Pokud nechcete měnit Vaše stávající heslo, nechte pole prázdná.</p>
            </div>
        );
    }

    static pin() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Toto čtyřmístné číslo použijete u služby "ZOFapp Profi" pro propojení Vašeho účtu se svým poradcem.</p>
            </div>
        );
    }

}
