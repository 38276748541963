import { isFuture } from "date-fns";

let _localeData =  {
    "Více": {
        'sk': 'Viac'
    },
    "Zpět" :{
        'sk': 'Späť'
    },
    "Úkol ke dni": {
        'sk': "Úloha ku dňu"
    },
    "Úkol k lekci": {
        'sk': "Úloha k lekcii"
    },
    "Jídelníček": {
        'sk': "Jedálniček"
    },
    "Cílový profil": {
        'sk': "Cielový profil"
    },
    "Uložit": {
        'sk': "Uložiť"
    },
    "Uložit a zavřít": {
        'sk': "Uložiť a zavrieť"
    },
    "Uložit úkol": {
        'sk': "Uložiť úlohu"
    },
    "Uložit nový úkol": {
        'sk': "Uložiť novú úlohu"
    },
    "Uložit nový recept": {
        'sk': "Uložiť nový recept"
    },
    "Potvrdit přepsání": {
        'sk': "Potvrdiť prepísanie"
    },
    "Název nového úkolu": {
        'sk': "Názov novej úlohy"
    },
    "Název receptu": {
        'sk': "Názov receptu"
    },
    "Popis receptu": {
        'sk': "Popis receptu"
    },
    "Obrázek receptu": {
        'sk': "Obrázok receptu"
    },
    "Uložit jako... (zadejte název)": {
        'sk': "Uložiť ako... (zadajte názov)"
    },
    "Uložit recept": {
        'sk': "Uložiť recept"
    },
    "Název ukládaného receptu": {
        'sk': "Názov ukladaného receptu"
    },
    "Přepsat recept": {
        'sk': "Prepísať recept"
    },
    "Zavřít": {
        'sk': "Zavrieť"
    },
    "Přepsat": {
        'sk': "Prepísať"
    },
    "Načíst úkol": {
        'sk': "Načítať úlohu"
    },
    "Načíst": {
        'sk': "Načítať"
    },
    "Přejmenovat": {
        'sk': "Premenovať"
    },
    "Poznámka": {
        'sk': "Poznámka"
    },
    "Dlouhodobé vyhodnocení": {
        'sk': "Dlhodobé vyhodnotenie"
    },
    "Přehled": {
        'sk': "Prehľad"
    },
    "Hodinový": {
        'sk': "Hodinový"
    },
    "Kompaktní": {
        'sk': "Kompaktný"
    },
    "Vyhodnocení": {
        'sk': "Vyhodnotenie"
    },
    "Přesunout výše": {
        'sk': "Presunúť vyššie"
    },
    "Přesunout níže": {
        'sk': "Presunúť nižšie"
    },
    "Popis přípravy": {
        'sk': "Popis prípravy"
    },
    "Přidat talíř": {
        'sk': "Pridať tanier"
    },
    "Smazat": {
        'sk': "Zmazať"
    },
    "Smazat talíř": {
        'sk': "Zmazať tanier"
    },
    "Základní údaje": {
        'sk': "Základné údaje"
    },
    "Potvrdit smazání": {
        'sk': "Potvrdiť zmazanie"
    },
    "Detail receptu": {
        'sk': "Detail receptu"
    },
    "Recept byl vložen": {
        'sk': "Recept bol vložený"
    },
    "Nemazat": {
        'sk': "Nemazať"
    },
    "Energie": {
        'sk': "Energia"
    },
    "Nutriční hodnoty": {
        'sk': "Nutričné hodnoty"
    },
    "Energetické poměry makroživin": {
        'sk': "Energetické pomery makroživín"
    },
    "Pestrost": {
        'sk': "Pestrosť"
    },
    "K uloženému úkolu": {
        'sk': "K uloženej úlohe"
    },
    "Uložit poznámku": {
        'sk': "Uložiť poznámku"
    },
    "Uložit změny": {
        'sk': "Uložiť zmeny"
    },
    "Ukládám": {
        'sk': "Ukladám"
    },
    "Sloupcový": {
        'sk': "Stĺpcový"
    },
    "Čárový": {
        'sk': "Čiarový"
    },
    "Délka": {
        'sk': "Dĺžka"
    },
    "Otevřít": {
        'sk': "Otvoriť"
    },
    "Otevřít recept": {
        'sk': "Otvoriť recept"
    },
    "Upravit": {
        'sk': "Upraviť"
    },
    "Štítky": {
        'sk': "Štítky"
    },
    "Sdílení/export": {
        'sk': "Zdielanie/export"
    },
    "Zobrazit vyhodocení": {
        'sk': "Zobraziť vyhodnotenie"
    },
    "Zobrazit zadání": {
        'sk': "Zobraziť zadanie"
    },
    "Zadání úkolu": {
        'sk': "Zadanie úlohy"
    },
    "Tvorba jídelníčku": {
        'sk': "Tvorba jedálnička"
    },
    "Vzorový jídelníček": {
        'sk': "Vzorový jedálniček"
    },
    "Uložit do mých receptů": {
        'sk': "Uložiť do mojich receptov"
    },
    "Přidat": {
        'sk': "Pridať"
    },
    "Přidat potravinu": {
        'sk': "Pridať potravinu"
    },
    "Přidat recept": {
        'sk': "Pridať recept"
    },
    "Přidat do receptu": {
        'sk': "Pridať do receptu"
    },
    "Řazení": {
        'sk': "Radenie"
    },
    "Duplikát byl vytvořen": {
        'sk': "Duplikát bol vytvorený"
    },
    "Kalorie": {
        'sk': "Kalórie"
    },
    "Bílkoviny": {
        'sk': "Bielkoviny"
    },
    "Sacharidy": {
        'sk': "Sacharidy"
    },
    "Tuky": {
        'sk': "Tuky"
    },
    "Vláknina": {
        'sk': "Vláknina"
    },
    "Další": {
        'sk': 'Ďalšie'
    },
    "Název": {
        'sk': "Názov"
    },
    "Alergeny": {
        'sk': "Alergény"
    },
    "Vymazat chod": {
        'sk': "Vymazať jedlo"
    },
    "Opravdu vymazat chod?": {
        'sk': "Skutočně vymazať jedlo?"
    },
    "Ano, vymazat chod": {
        'sk': "Áno, vymazať jedlo"
    },
    "Trénink": {
        'sk': "Tréning"
    },
    "Ingredience": {
        'sk': "Ingrediencie"
    },
    "Aerobní": {
        'sk': "Aerobný"
    },
    "Anaerobní": {
        'sk': "Anaerobný"
    },
    "Chody / chuť": {
        'sk': "Jedlá / chuť"
    },    
    "minut": {
        'sk': "minút"
    },
    "přebývá": {
        'sk': "prebytok"
    },
    "zbývá": {
        'sk': "zostáva"
    },
    "Postup přípravy": {
        'sk': "Postup prípravy"
    },
    "Postup přípravy receptu": {
        'sk': "Postup prípravy receptu"
    },
    "Chody/potraviny obsahující": {
        'sk': "Jedlá/potraviny obsahujúce"
    },
    "použity nepovolené potraviny": {
        'sk': "použité nepovolené potraviny"
    },
    "Potravina vložena.": {
        'sk': "Potravina vložená."
    },
    "Hledat v receptech...": {
        'sk': "Hľadať v receptoch..."
    },
    "filtr": {
        'sk': "filter"
    },
    "Kategorie": {
        'sk': "Kategórie"
    },
    "Stravovací režim": {
        'sk': "Stravovací režim"
    },
    "Stravovací režimy": {
        'sk': "Stravovacie režimy"
    },
    "Barva profilu": {
        'sk': "Farba profilu"
    },
    "Chuť": {
        'sk': "Chuť"
    },
    "Zobrazení": {
        'sk': "Zobrazenie"
    },
    "porce": {
        'sk': "porcia"
    },
    "populární": {
        'sk': "populárne"
    },
    "nejnovější": {
        'sk': "najnovšie"
    },
    "Neplatný formát obrázku (nahrávejte pouze JPG nebo PNG soubory)": {
        'sk': "Neplatný formát obrázku (nahrávajte iba JPG alebo PNG súbory)"
    },
    "Hmotnostní poměry živin": {
        'sk': "Hmotnostné pomery živín"
    },
    "Energetické poměry makroživin": {
        'sk': "Energetické pomery makroživín"
    },
    "Energetický příjem": {
        'sk': "Energetický príjem"
    },
    "Komentář k vzorovému jídelníčku": {
        'sk': "Komentár k vzorovému jedálničku"
    },
    "Informace o profilu": {
        'sk': "Informácie o profile"
    },
    "Výška": {
        'sk': "Výška"
    },
    "Váha": {
        'sk': "Váha"
    },
    "Obvod pasu": {
        'sk': "Obvod pása"
    },
    "Obvod boků": {
        'sk': "Obvod bokov"
    },
    "BMR": {
        'sk': "BMR"
    },
    "Výdej energie": {
        'sk': "Výdaj energie"
    },
    "Vytvořte svůj profil": {
        'sk': "Vytvorte svoj profil"
    },
    "Před použitím aplikace ZOF je třeba nejprve vytvořit svůj profil. Vyplňte prosím následující formulář": {
        'sk': "Pred použitím aplikácie ZOF je potrebné najskôr vytvoriť svoj profil. Vyplňte prosím nasledujúci formulár",
    },
    "Položky označené hvězdičkou jsou povinné. Ostatní můžete vyplnit až později.": {
        'sk': "Položky označené hviezdičkou sú povinné. Ostatné môžete vyplniť až neskôr."
    },
    "Jméno profilu": {
        'sk': "Meno profilu"
    },
    "Jméno": {
        'sk': "Meno"
    },
    "Oslovení": {
        'sk': "Oslovenie"
    },
    "Specifikace": {
        'sk': "Špecifikácia"
    },
    "Profilová fotka": {
        'sk': "Profilová fotka"
    },
    "Foto": {
        'sk': "Foto"
    },
    "Stávající foto": {
        'sk': "Existujúce foto"
    },
    "Vstupní údaje": {
        'sk': "Vstupné údaje"
    },
    "Datum narození": {
        'sk': "Dátum narodenia"
    },
    "Bazální metabolismus": {
        'sk': "Bazálny metabolizmus"
    },
    "Pokud hodnotu neznáte, nevyplňujte, aplikace ji vypočítá sama.": {
        'sk': "Ak hodnotu nepoznáte, nevypĺňajte, aplikácia ju vypočíta sama."
    },
    "Vytvořit profil": {
        'sk': "Vytvoriť profil"
    },
    "Doplňte názvy ke všem receptům!": {
        'sk': "Doplňte názvy ku všetkým receptom!"
    },
    "Doplňte popisy přípravy ke všem receptům!": {
        'sk': "Doplňte popisy prípravy ku všetkým receptom!"
    },
    "Vyberte chod": {
        'sk': "Vyberte chod"
    },
    "Po vyplnění jídelníčku nezapomeňte úkol uložit tlačítkem Uložit úkol!": {
        'sk': "Po vyplnení jedálnička nezabudnite úlohu uložiť tlačidlom Uložiť úlohu!"
    },
    "Na základě vyplněných základních údajů může aplikace vyhodnocovat Vaše recepty a jídelníčky, generovat jídelníčky na míru, sledovat energetickou bilanci.": {
        'sk': "Na základe vyplnených základných údajov môže aplikácia vyhodnocovať Vaše recepty a jedálničky, generovať jedálničky na mieru, sledovať energetickú bilanciu."
    },
    "Energetický režim": {
        'sk': "Energetický režim"
    },
    "E-mail, změna hesla": {
        'sk': "E-mail, zmena hesla"
    },
    "Přihlašovací e-mail": {
        'sk': "Prihlasovací e-mail"
    },
    "Nové heslo": {
        'sk': "Nové heslo"
    },
    "Nové heslo znovu": {
        'sk': "Nové heslo znova"
    },
    "Váš ZOF PIN": {
        'sk': "Váš ZOF PIN"
    },
    "Pokud chcete ponechat stávající heslo, nechte pole prázdná.": {
        'sk': "Ak chcete ponechať existujúce heslo, nechajte pole prázdne."
    },
    "Uložit profil": {
        'sk': "Uložiť profil"
    },
    "Nastavení": {
        'sk': "Nastavenie"
    },
    "Odhlásit se": {
        'sk': "Odhlásiť sa"
    },
    "sloupcový": {
        'sk': "stĺpcový"
    },
    "čárový": {
        'sk': "čiarový"
    },
    "Zobrazit vyhodnocení": {
        'sk': "Zobraziť vyhodnotenie"
    },
};

export default class Locale {

    constructor() {
    }

    static get(text) {
        let lang = localStorage.getItem('zofapp_lang');
        if(!lang) lang = 'cs';
        if(lang == 'cs') return text;
        return _localeData[text][lang];
    }

    static setLocale(loc) {
        this.locale = loc;
    }

}

export function __(text) {
    return Locale.get(text);
}
