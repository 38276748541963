import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Loading from 'components/Loading/index';
import api from 'api';
import Util from 'utility';

import UserEdit from './UserEdit/index';

class User extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            profiles: false,
            loading: true
        }

        this.setPrimaryProfile = this.setPrimaryProfile.bind(this);
    }

    setPrimaryProfile(profile) {
        let profiles = this.state.profiles;
        profiles.map(prof => {
            if(prof.id == profile.id) {
                prof.is_primary = 1;
            } else {
                prof.is_primary = 0;
            }
            return prof;
        });

        this.setState({
            profiles: profiles
        });
    }

    componentDidMount() {
        Util.setPageTitle('Uživatel');
        Util.resetCookbookFilter();
        Util.resetFoodsFilter();
    }

/*
    <Route path="/profiles" exact={true} component={ProfileList} />
    <Route path="/profiles/create" exact={true} component={ProfileCreate} />
    <Route path="/profiles/undesirable/:id" component={ProfileUndesirableFoods} />
    <Route path="/profiles/favorite/:id" component={ProfileFavoriteFoods} />
    <Route path="/profiles/foodselect/:id" component={ProfileFoodSelection} />
    <Route path="/profiles/notes/:id" component={ProfileNotes} />
    <Route path="/profiles/delete/:id" component={ProfileDelete} />
*/

    render() {
        return (
            <Switch>
                <Route path="/user/edit/:id" component={UserEdit} />
            </Switch>
        );     
    }
}

export default User;
