import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loading from 'components/Loading/index';
import Util from 'utility';
import api from 'api';

import { 
    closeModal,
    showMessage,
    setFoodGroupDescription,
    loadGlobals,
    loginUser,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
        setFoodGroupDescription: data => dispatch(setFoodGroupDescription(data)),
        loadGlobals: data => dispatch(loadGlobals(data)),
        loginUser: data => dispatch(loginUser(data)),
    };
};


class PanelGroupDetail extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            group: {},
            clients: {},
            addMemberUserId: 0,
            loading: true,
        };    
        this.onChange = this.onChange.bind(this);
        this.removeGroupMember = this.removeGroupMember.bind(this);
        this.addMemberToGroup = this.addMemberToGroup.bind(this);
    }

    componentDidMount() {
        api('clients/load-group/' + this.props.id).then((res) =>{
            if(typeof res.data.error === 'string') {
                this.props.showMessage({
                    show: true,
                    type: 'error',
                    message: 'Tým nenalezen.'
                });               
                this.props.closeModal();
            } else {
                this.setState({
                    loading: false,
                    group: res.data.group,
                    clients: res.data.clients,
                });
            }
        });
    }

    onChange(e) {
        let n = e.target.name;
        let v = e.target.value;
        if(n == 'add_client_id') {
            this.setState({
                addMemberUserId: v
            });
        }
    }

    removeGroupMember(id, e) {
        this.setState({
            loading: true
        }, () => {
            api('clients/remove-from-group', { post: true, data: { group_id: this.state.group.id, client_id: id }}).then((res) =>{
                this.props.loadClients();
                this.setState({
                    loading: false,
                    group: res.data
                });
            });
        });    
    }

    addMemberToGroup(e) {
        api('clients/add-to-group', { post: true, data: { group_id: this.state.group.id, client_id: this.state.addMemberUserId }}).then((res) =>{
            this.props.loadClients();
            this.setState({
                loading: false,
                group: res.data
            });
        });
    }

    render() {
        if(this.state.loading === true) return <Loading />;
        const { group } = this.state;

        let members = [], memberIds = [];
        Object.keys(group.members).map(m => {
            const member = group.members[m];
            memberIds.push(member.id);
            members.push(
                <div className="member">
                    <p>{member.name}</p>
                    <aside>
                        <a onClick={(e) => this.removeGroupMember(member.id, e)}><i className="fa fa-times"></i></a>
                    </aside>
                </div>
            );
        })

        let clientsList = [];
        clientsList.push(<option value={0}>- Vyberte uživatele -</option>);
        Object.keys(this.state.clients).map(c => {
            const client = this.state.clients[c];
            if(memberIds.includes(client.id)) return;
            clientsList.push(<option value={client.id}>{client.name}</option>);
        });



        return (
            <React.Fragment>
            <div className="group-detail">
                <p><b>{group.title}</b></p>
                <p>
                    Přidat: <select onChange={this.onChange} name="add_client_id">{clientsList}</select>
                    <button onClick={this.addMemberToGroup}>Přidat</button>
                </p>
                <div className="members-list">
                    {members}
                </div>
            </div>
            </React.Fragment>            
        );            
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PanelGroupDetail));
