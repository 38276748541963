import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Link, withRouter } from 'react-router-dom';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class Welcome extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: {}
        };    
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ textAlign: 'center', padding: '2em 0', maxWidth: '480px', margin: '1em auto' }}>
                    <div style={{background:'#fff',padding:'2em',borderRadius:'4px'}}>
                    <h2 style={{ margin:'0 0 .5em 0', color: '#4eb32d' }}>Profil vytvořen</h2>
                    <p>Registrací do aplikace jste získali 15 receptů zdarma (najdete je v Kuchařce v sekci <Link to="/recipe/me">Moje recepty</Link>).</p>
                    <p>Kam dále?</p>
                    <p>V aplikaci si můžete v databázi potravin označit:</p>
                    <p style={{ textAlign: 'left', margin: '1em 0'}}><b>1. Oblíbené potraviny</b><br /><br />Takto označené potraviny se při tvorbě receptu nabízí přednostně ve speciálním seznamu. Vyhnete se tak opakovanému vyhledávání těchto potravin, máte všechny na jednom místě.</p>
                    <p style={{ textAlign: 'left', margin: '2em 0 3em 0' }}><b>2. Nežádoucí potraviny</b><br /><br />Potraviny označené jako nežádoucí budou při tvorbě receptu viditelně označeny. Toto je velmi důležité především pro jedince trpící potravinovými intolerancemi či alergiemi, případně zájemce o alternativní způsoby stravování. Lépe se tak těmto potravinám v jídelníčku vyhnete, případně je snadno nahradíte jinými.</p>
                    <p style={{ margin: '2em 0' }}>
                        <a className="button-primary" onClick={() => this.props.history.push('/food')}>Označit oblíbené či nevhodné potraviny</a>
                    </p>
                    <p style={{ margin: '2em 0' }}>
                        <Link to="/recipe/me" className="button-primary">Prohlédnout 15 receptů zdarma</Link>
                    </p>
                    <p style={{ margin: '2em 0' }}>
                        <a className="button-primary" onClick={() => this.props.history.push('/')}>Přejít na úvodní stranu</a>
                    </p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
