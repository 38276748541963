import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { debounce } from "throttle-debounce";   
import api from 'api';

import {
    showMessage
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        showMessage: data => dispatch(showMessage(data)),
    };
};

class RecipeSaveToMyRecipes extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const hash = this.props.match.params.hash;
        api("recipes/save-to-my-recipes/" + hash, { post: true, data: {hash: hash }}).then((res) => {
            this.props.showMessage({
                show: true,
                type: 'success',
                message: 'Recept byl uložen do Vašich receptů.'
            });               
            this.props.history.push('/recipe/me');
        });
    }

    render() {
        return [];
    }  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecipeSaveToMyRecipes));
