import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { debounce } from "throttle-debounce";   
import Util from 'utility';
import api from 'api';

import PanelMenuDetail from './PanelMenuDetail';

import { 
    openModal, 
    closeModal
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
    };
};

class MenuList extends Component {
    constructor(props) {
        super(props);

        let initialFilter = {
            q: '', 
            cookbook: [],
            category: [], 
            regime: [], 
            taste: [],
            sortBy: '',
            sortOrder: '',
            lastSortIsCustom: false,
            customSortAttribute: 'fiber',
        };

        this.state = {
            filter: initialFilter,
            total: 0,
            page: 0,
            perPage: 30,
            menus: {},
            selectedMenuIds: [],
        }
        this.updateFilter = this.updateFilter.bind(this);
        this.updateSort = this.updateSort.bind(this);
        this.changePage = this.changePage.bind(this);
        this.loadMenus = this.loadMenus.bind(this);
        this.loadMenusDebounced = debounce(250, this.loadMenus);
        this.openMenuDetail = this.openMenuDetail.bind(this);
        this.beginReload = this.beginReload.bind(this);
        this.endReload = this.endReload.bind(this);
        this.showMenus = this.showMenus.bind(this);        
        this.selectMenu = this.selectMenu.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.selectNone = this.selectNone.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
    }

    componentDidMount() {
        this.loadMenus();
        if(typeof this.props.match.params.id === "string") {
            this.openMenuDetail(this.props.match.params.id * 1, false);
        }
    }

    selectMenu(e, menu) {
        let sm = this.state.selectedMenuIds;
        sm = sm.filter(id => id !== menu.id);
        if(e.target.checked) sm.push(menu.id);
        this.setState({
            selectedMenuIds: sm
        });
    }


    deleteSelected() {
        const profileId = Util.getFirstProfileId();
        let that = this;
        api('menus/mass-delete', { post: true, data: { ids: this.state.selectedMenuIds.join(',') }}).then((res) =>{
            that.loadMenus();
        });
    }

    selectAll() {
        let ids = [];
        this.state.menus.map(f => {
            ids.push(f.id);
        });
        this.setState({
            selectedMenuIds: ids
        });
    }

    selectNone() {
        this.setState({
            selectedMenuIds: []
        });
    }


    showMenus() {
        this.props.history.push('/');
        this.props.history.push('/menu');
    }

    updateSort(e) {
        let filter = this.state.filter;
        let newSortBy = e.currentTarget.getAttribute('data-sort');
        if(newSortBy == 'custom') {
            if(e.currentTarget.getAttribute('data-change-order-only') == '1') {
                newSortBy = filter.sortBy;
                filter.sortOrder = filter.sortOrder == 'asc' ? 'desc' : 'asc';
            } else {
                newSortBy = e.target.value;
                filter.customSortAttribute = newSortBy;
                filter.sortBy = newSortBy;
                filter.sortOrder = 'desc';
            }
            filter.lastSortIsCustom = true;
        } else {
            filter.lastSortIsCustom = false;
            if(newSortBy == filter.sortBy) {
                switch(filter.sortOrder) {
                    case 'asc':
                        filter.sortOrder = 'desc';
                        break;
                    case 'desc':
                        filter.sortOrder = '';
                        filter.sortBy = '';
                        break;
                }
            } else {
                filter.sortBy = newSortBy;
                filter.sortOrder = 'asc';
            }
        }
        this.setState({
            filter: filter
        }, () => {
            this.loadMenusDebounced();
        });
    }

    loadMenus() {
        api('menus/list', { post: true, data: { filter: this.state.filter }}).then((res) =>{
            this.setState({
                menus: res.data.menus
            });
        });
    }
   
    changePage(e, page) {
        this.setState({
            page: page,
            changingPageOnly: true
        }, () => {
            this.loadRecipes();
        });
    }

    updateFilter(e) {
        let filter = this.state.filter;
        const v = e.target.value;
        switch(e.target.name) {
            case 'q':
                filter.q = e.target.value;
                break;
            case 'c':
                if(filter.category.includes(v)) {
                    filter.category = filter.category.filter(item => item !== v);                    
                } else {
                    filter.category.push(v);
                }
                break;
        }
        this.setState({
            filter: filter
        }, () => {
            this.loadMenusDebounced();
        });
    }

    openMenuDetail(id, e) {
        if(e) e.preventDefault();
        // if (e.target != e.currentTarget) return;
        this.props.closeModal();
        this.props.openModal({
            'title': 'Detail jídelníčku', 
            'class': 'dialog-big',
            'content': (
                <PanelMenuDetail id={id} showMenus={this.showMenus} beginReload={this.beginReload} endReload={this.endReload}  />
            )
        });
    }

    beginReload() {
        this.setState({
            loading: true
        });
    }

    endReload() {
        this.setState({
            loading: false
        });
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;
        const { filter, menus } = state;

        let sortSymbols = {};
        if(filter.sortBy != '') {
            if(filter.sortOrder == 'asc') {
                sortSymbols[filter.sortBy] = <i className="fa fa-angle-up"></i>;
            } else {
                sortSymbols[filter.sortBy] = <i className="fa fa-angle-down"></i>;
            }
        }

        let sortSymbolCustom = false;
        if(filter.lastSortIsCustom) {
            if(filter.sortOrder == 'asc') {
                sortSymbolCustom = <a style={{paddingLeft:'.5em'}} onClick={this.updateSort} data-sort="custom" data-change-order-only="1"><i className="fa fa-angle-up"></i></a>;
            } else {
                sortSymbolCustom = <a style={{paddingLeft:'.5em'}} onClick={this.updateSort} data-sort="custom" data-change-order-only="1"><i className="fa fa-angle-down"></i></a>;
            }
        }

        let sortIgnore = [
            'weight', 'weight_total', 'amount', 'drink_amount', 'energy_expenditure_kcal', 'energy_expenditure_kj',
            'energy_balance_kcaj', 'energy_balance_kj', 'energy_output_kcal', 'energy_output_kj',
            'proteins', 'carbohydrates', 'fats', 'fats_omega3_dha_epa', 'energy_kcal', 'energy_kj', 'energy_balance_kcal', 'energy_balance_kj',
            'proteins_ratio', 'fats_saturated_unsaturated_ratio', 'fats_monounsaturated_polyunsaturated_ratio', 'fats_omega3_omega6_ratio',
            'fiber_ratio', 'calcium_magnesium_ratio', 'potassium_sodium_ratio',
            'energy', 'digestibility', 'acidobasicity', 'thermic_effect', 'biotin',
        ];
        let customSortOptions = [];
        for(let attr in globals.attributes) {
            if(sortIgnore.includes(attr)) continue;
            customSortOptions.push(
                <option value={attr}>{globals.attributes[attr]}</option>
            );
        }

        let menuListHeader = (
            <div className="recipes-header">
                <div className="recipe-item">
                    <div className="title">
                        <h3>Název</h3>
                    </div>
                    <div className="recipe-nutrition-data">
                        <div>Energie</div>
                        <div><a onClick={this.updateSort} data-sort="proteins">Bílkoviny {sortSymbols['proteins'] || false}</a></div>
                        <div><a onClick={this.updateSort} data-sort="carbohydrates">Sacharidy {sortSymbols['carbohydrates'] || false}</a></div>
                        <div><a onClick={this.updateSort} data-sort="fats">Tuky {sortSymbols['fats'] || false}</a></div>
                        <div>
                            <select name="custom_sort" onChange={this.updateSort} data-sort="custom" style={{fontSize:'11px',width:'6em'}} value={filter.customSortAttribute}>
                                {customSortOptions}
                            </select>
                            {sortSymbolCustom}
                        </div>
                    </div>
                </div>
            </div>
        );




        let menuList = [];
        if(menus.length > 0) {
            Object.keys(menus).map(key => {
                const menu = menus[key];

                let nutrData = [];
                if(menu.nutritionData != '') {
                    nutrData.push(<div>
                                    {Math.ceil(menu.nutritionData['energy_kcal'])} kcal
                                    <br />
                                    {Math.ceil(menu.nutritionData['energy_kj'])} kJ
                                 </div>);
                    nutrData.push(<div>{parseFloat(menu.nutritionData['proteins'])} {globals.attribute_units['proteins']}</div>);
                    nutrData.push(<div>{parseFloat(menu.nutritionData['carbohydrates'])} {globals.attribute_units['carbohydrates']}</div>);
                    nutrData.push(<div>{parseFloat(menu.nutritionData['fats'])} {globals.attribute_units['fats']}</div>);
                    nutrData.push(<div>{parseFloat(menu.nutritionData[filter.customSortAttribute])} {globals.attribute_units[filter.customSortAttribute]}</div>);
                }

                let menuCheckbox = (
                    <div className="check">
                        <input type="checkbox" checked={state.selectedMenuIds.includes(menu.id)} onClick={(e) => this.selectMenu(e, menu)} />
                    </div>
                );

                menuList.push(
                    <div key={menu.id} className="recipe-item">
                        {menuCheckbox}
                        <div className="title">
                            <h3 onClick={(e) => this.openMenuDetail(menu.id, e)}>{menu.title}</h3>
                        </div>
                        <div className="recipe-nutrition-data">
                            {nutrData}
                        </div>
                    </div>
                );
            });
        }


        if(filter.viewMode == 'gallery') {
            menuList = [
                <div className="recipe-item-gallery-wrap">
                    {menuList}
                </div>
            ];
            menuListHeader = false;
        }


        let selectedMenuOptions = false;
        if(state.selectedMenuIds.length > 0) {
            selectedMenuOptions = (
                <>
                Nastavit označené jako: 
                <a style={{marginLeft:'.5em',display:'inline-block',padding:'.3em .7em',background:'#888',color:'#fff',borderRadius:'3px'}} onClick={this.deleteSelected}>Vymazat</a>
                </>
            );
        }

        let selectedMenusSelectionOptions = (
            <p>Vybrat: 
                <a style={{marginLeft:'.5em',display:'inline-block',padding:'.3em .7em',background:'#888',color:'#fff',borderRadius:'3px'}} onClick={this.selectAll}>Vše</a>
                <a style={{marginLeft:'.5em',display:'inline-block',padding:'.3em .7em',background:'#888',color:'#fff',borderRadius:'3px'}} onClick={this.selectNone}>Nic</a>
                {'\u00A0'}
                {selectedMenuOptions}
            </p>
        );


        if(typeof this.props.isEmbedded !== 'undefined') {
            selectedMenusSelectionOptions = false;
        }


        let content = [
            <div className="page page-recipes">
                <div className="page-header">
                    <h1>Jídelníčky</h1>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="filters" style={{background:'#fff'}}>
                            <div className="box">
                                <div className="search-input">
                                    <i className="fa fa-search"></i>
                                    <input type="text" name="q" placeholder="Hledat..." value={filter.q} onChange={this.updateFilter} />
                                </div>
                            </div>
                        </div>
                        <div className="panel list">
                            <div className="box">
                                {menuListHeader}
                                {menuList}
                                {selectedMenusSelectionOptions}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        ];

        return content;
    }  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuList));
