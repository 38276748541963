import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import Loading from 'components/Loading/index';
import api from 'api';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class DialogDayNotes extends Component { 

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            date: this.props.date,
            note: '',
        };
        this.yyyymmdd = this.yyyymmdd.bind(this);
        this.saveNote = this.saveNote.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        const ymd = this.yyyymmdd(this.state.date);
        api('planner/load-day-notes/' + ymd).then(result => {
            this.setState({
                loading: false,
                note: result.data.body
            });
        });
    }

    yyyymmdd(date) {
        let yyyy = '' + date.getFullYear();
        let mm = ('0' + (date.getMonth() + 1));  // prepend 0 // +1 is because Jan is 0
        mm = mm.substr(mm.length - 2);                  // take last 2 chars
        let dd = ('0' + date.getDate());         // prepend 0
        dd = dd.substr(dd.length - 2);                  // take last 2 chars
        return yyyy + "-" + mm + "-" + dd;
    }    

    saveNote() {
        const ymd = this.yyyymmdd(this.state.date);
        api('planner/save-day-notes', {post: true, data: {date:ymd, note:this.state.note}}).then(result => {
            this.props.onSaved();
        });
    }

    onChange(e) {
        this.state[e.target.name] = e.target.value;
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = data;

        if(state.loading) return <Loading />;

        return (
            <React.Fragment>
            <table className="generic-table">
                <tbody>
                    <tr>
                        <td><textarea placeholder="Vaše poznámky k tomuto dni..." name="note" style={{width:'100%',height:'12em'}} defaultValue={state.note} onChange={this.onChange}></textarea></td>
                    </tr>
                    <tr>
                        <td><button className="btn-primary" onClick={this.saveNote}>Uložit poznámky</button></td>
                    </tr>
                </tbody>
            </table>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogDayNotes));
