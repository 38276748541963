import React, { Component } from "react";
import { connect } from 'react-redux';
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, getMonth, addDays, addMonths, subMonths, isEqual, isToday } from "date-fns";
import localeCs from 'date-fns/locale/cs';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class SimpleCalendar extends React.Component {
    constructor(props) {
        super(props);

        let initialDate = this.props.initialDate;
        if(!initialDate) {
            initialDate = new Date();            
        }

        this.state = {
            currentDate: initialDate,
            selectedDate: initialDate,
            copySourceDate: false,
        };

        this.nextMonth = this.nextMonth.bind(this);
        this.prevMonth = this.prevMonth.bind(this);
        this.handleCellClick = this.handleCellClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(typeof this.props.initialDate !== 'undefined') {

            const incomingDate = format(this.props.initialDate, 'yyyy-MM-dd');
            const currentDate = format(this.state.currentDate, 'yyyy-MM-dd');

            if(incomingDate !== currentDate) {
                this.setState({
                    currentDate: this.props.initialDate,
                    selectedDate: this.props.initialDate
                });
            }
        }
    }

    xxxxxxcomponentWillReceiveProps(props) {
        if(typeof props.initialDate !== 'undefined') {
            this.setState({
                currentDate: props.initialDate,
                selectedDate: props.initialDate
            });
        }
    }

    renderHeader() {
        const dateFormat = "LLLL yyyy";
        return (
            <div className="header row flex-middle">
                <div className="col col-start">
                    <div className="icon" onClick={this.prevMonth}>
                        <i className="fa fa-caret-left"></i>
                    </div>
                </div>
                <div className="col col-center">
                    <span>{format(this.state.currentDate, dateFormat, {locale: localeCs})}</span>
                </div>
                <div className="col col-end" onClick={this.nextMonth}>
                    <div className="icon"><i className="fa fa-caret-right"></i></div>
                </div>
            </div>
        );
    }

    renderDays() {
        const days = [];
        let startDate = startOfWeek(this.state.currentDate, {weekStartsOn: 1});

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {format(addDays(startDate, i), 'EEEEEE', {locale: localeCs})}
                </div>
            );
        }

        return <div className="days row">{days}</div>;
    }

    renderCells() {
        const { currentDate } = this.state;
        const { data } = this.props;
        let monthStart = startOfMonth(currentDate);
        let monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart, {weekStartsOn: 1});
        const endDate = endOfWeek(monthEnd);
        const currentDateNumber = getMonth(currentDate);
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        let ymdDate = "";

        const selectedDayFmt = format(this.state.selectedDate, 'yyyy-MM-dd');

        while (day <= endDate) {

            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, "d");
                ymdDate = format(day, "yyyy-MM-dd");
                const cloneDay = day;
                let dayMonthNumber = getMonth(day);

                let dayClass = `col cell`;
                if(isToday(day)) {
                    dayClass = `col today cell`;
                }

                if(selectedDayFmt == ymdDate) {
                    dayClass = `col active cell`;
                }
                if(dayMonthNumber != currentDateNumber) {
                    dayClass = `col cell other-month`;
                }

                let minimap = [];

                if(data.profiClientId == 0) {
                    // My calendar (non ZOF Profi, or "my own" planner)
                    if(typeof this.props.monthData[ymdDate] != undefined) {
                        for(let hour in this.props.monthData[ymdDate]) {
                            let mmProfiles = [];
                            for(let profileId in this.props.monthData[ymdDate][hour]) {
                                if(typeof this.props.data.globals.profiles[profileId] != 'undefined') {
                                    mmProfiles.push(<div key={profileId} style={{ width: '3px', height: '3px', opacity: '.5', backgroundColor: '#' + this.props.data.globals.profiles[profileId].profile_color }}></div>);
                                }
                            }
                            let top = Math.floor((hour / 24) * 100) + '%';
                            minimap.push(<div key={hour} className="minimap-hour" style={{ top: top }}>{mmProfiles}</div>);
                        }
                    }
                } else {
                    // ZOF Profi: client's planner
                    if(typeof this.props.monthData[ymdDate] != undefined) {
                        for(let hour in this.props.monthData[ymdDate]) {
                            let mmProfiles = [];
                            for(let profileId in this.props.monthData[ymdDate][hour]) {
                                mmProfiles.push(<div key={profileId} style={{ width: '3px', height: '3px', opacity: '.5', backgroundColor: '#888' }}></div>);
                            }
                            let top = Math.floor((hour / 24) * 100) + '%';
                            minimap.push(<div key={hour} className="minimap-hour" style={{ top: top }}>{mmProfiles}</div>);
                        }
                    }

                }
                
                days.push(
                    <div className={dayClass} key={day}>
                        <a className="number" onClick={(e) => this.handleCellClick(e, cloneDay)}>
                            {formattedDate}
                            {minimap}
                        </a>
                    </div>
                );

                day = addDays(day, 1);
            }
            rows.push(
                <div className="calendar-row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    }

    nextMonth() {
        let newDate = addMonths(this.state.currentDate, 1);
        localStorage.setItem('planner_month', newDate);
        this.setState({
            currentDate: newDate
        }, () => {
            this.props.onDateChange(newDate);   
            // this.props.onChangeMonth(this.state.currentDate);
        });
    };

    prevMonth() {
        let newDate = subMonths(this.state.currentDate, 1);
        localStorage.setItem('planner_month', newDate);
        this.setState({
            currentDate: newDate
        }, () => {
            this.props.onDateChange(newDate);   
            // this.props.onChangeMonth(this.state.currentDate);
        });
    };

    handleCellClick(e, date) {     
        this.props.onDateChange(date);   
    }

    render() {
        let calendarBody;

        calendarBody = (
            <div className="simple-calendar-wrap">
                <div className="simple-calendar">
                    {this.renderHeader()}
                    {this.renderDays()}
                    {this.renderCells()}
                </div>
            </div>
        );

        return calendarBody;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleCalendar);



