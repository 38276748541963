import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";

import Login from "components/Login";
import Logout from "components/Logout";
import Main from "components/Main";

import { closeModal } from "../../actions/index";

const mapStateToProps = (state) => {
    return { data: state };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: (data) => dispatch(closeModal(data)),
    };
};

class App extends Component {
    componentDidUpdate(prevProps) {
        // Close any modals when route changed
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.closeModal();
        }
    }
    render() {
        return [
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <Route path="/" component={Main} />
            </Switch>
        ];
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
