import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from 'components/Loading';
import shortid from 'shortid';
import api from 'api';
import Util from 'utility';

import FoodSearch from 'components/FoodSearch';

import {
    addFood
 } from 'actions/index';

 const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        addFood: data => dispatch(addFood(data)),
    };
};

class PanelAddFood extends Component { 
    constructor(props) {
        super(props);

        let initialFilter = {
            keyword: '',
            categoryId : -1,
            profileId: 0,
            sort: '',
            lastAction: '',
        };
        let savedFilter = localStorage.getItem('zof_filter_foods');
        if(typeof savedFilter == 'string') {
            savedFilter = JSON.parse(savedFilter);
            if(typeof savedFilter.keyword == 'string') initialFilter.keyword = savedFilter.keyword;
            if(typeof savedFilter.categoryId == 'number') initialFilter.categoryId = savedFilter.categoryId;
            if(typeof savedFilter.profileId == 'number') initialFilter.profileId = savedFilter.profileId;
            if(typeof savedFilter.sort == 'string') initialFilter.sort = savedFilter.sort;
            if(typeof savedFilter.lastAction == 'string') initialFilter.lastAction = savedFilter.lastAction;
        }

        this.state = {
            foods: [],
            filter: initialFilter,

            foodLoading: false,
            currentFood: false,

            refCategorySelect: false,

            selectedModalFood: {},            
            selectedFoodId: 0,
            modalX: 0,
            modalY: 0,
        };

        this.showFoodDetail = this.showFoodDetail.bind(this);
        this.closeFoodDetail = this.closeFoodDetail.bind(this);
        this.addToRecipe = this.addToRecipe.bind(this);
    }

    showFoodDetail(id, e) {
        this.setState({
            foodLoading: true
        }, () => {
            api('foods/detail/' + id).then(result => {
                this.setState({
                    foodLoading: false,
                    currentFood: result.data
                });
            });
        });
    }

    closeFoodDetail() {
        this.setState({
            foodLoading: false,
            currentFood: false
        });
    }

    addToRecipe(foodId, groupId) {
        let img_src;
        if(this.props.data.globals.myFoodIds.includes(foodId)) {
            img_src = 'https://www.zofapp.cz/data/zof/item/' + foodId + '.svg?r=' + Math.random();
        } else {
            img_src = 'https://www.zofapp.cz/data/zof/item/' + foodId + '.svg';
        }

        if(typeof groupId === 'undefined') groupId = '';
        let food = {
            id: shortid(),
            food_id: foodId,
            group_id: groupId,
            image: img_src,
            title: this.props.data.globals.itemData[foodId]['title'],
            weight: this.props.data.globals.itemData[foodId]['weight'],
            is_drink: 0
        };
        this.props.addFood(food);
        this.setState({
            selectedFoodId: 0,
        });
        Util.doLoadEvaluation();
        this.props.onFoodAdded();
    }

    render() {
        const { state } = this;
        const { filter } = state;
        const { globals } = this.props.data;

        let content;
        
        if(state.foodLoading) {
            content = <Loading />;
        } else {

            const food = state.currentFood;
            if(food) {
                let addToRecipeOptions = [];
                let mealHash = this.props.data.activeMealHash;
                if(typeof this.props.data.foodGroups[mealHash] != "undefined") {
                    for(let g in this.props.data.foodGroups[mealHash]) {
                        let group = this.props.data.foodGroups[mealHash][g];
                        if(group.title == '') group.title = 'Nepojmenovaný talíř';
                        addToRecipeOptions.push(
                            <button onClick={() => this.addToRecipe(food.id, g)}><i className="fa fa-plus"></i> <p><span>Přidat do:</span><br />{group.title}</p></button>
                        );
                    }
                }


                food.proteins = parseFloat(food.proteins);
                food.carbohydrates = parseFloat(food.carbohydrates);
                food.fats = parseFloat(food.fats);
                food.fiber = parseFloat(food.fiber);
        
                let kcal = food.proteins * 4.18 + food.carbohydrates * 4.18 + food.fats * 9 + food.fiber * 2;
                let kj = kcal * 4.184;

                let undesirableInfo = false;
                if(typeof this.props.data.appModeData !== 'undefined') {
                    if(typeof this.props.data.appModeData.undesirableFoods !== 'undefined') {
                        if(this.props.data.appModeData.undesirableFoods.includes(food.id)) {
                            for(let profileId in this.props.data.globals.undesirableFoods) {
                                profileId = profileId * 1;
                                undesirableInfo = <p className="item-info-undesirable"><span>Nevhodná potravina</span></p>;
                            }
                        }
                    }
                }


        
                content = (
                    <div className="zof-food-detail">
                        <button onClick={this.closeFoodDetail}><i className="fa fa-angle-left"></i></button>
                        <div class="food-detail-heading" style={{ paddingTop: 0 }}>
                            <img src={`https://www.zofapp.cz/data/zof/item/${food.id}.svg`} alt="" />
                            <h1>{food.title}</h1>
                        </div>
                        {undesirableInfo}
                        <div dangerouslySetInnerHTML={{__html: food.description}} />
                    </div>
                );
            } else {
                content = <FoodSearch onClick={this.showFoodDetail} isEmbedded={true} isDrinkRegime={this.props.isDrinkRegime} groupId={this.props.groupId} />;
            }
        }

        return (
            <React.Fragment>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div className="zof-items-selection">
                {content}
            </div>
            </div>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelAddFood);
