import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import Loading from 'components/Loading/index';
import api from 'api';
import { Locale, __ } from 'locale';

import { 
    showMessage, 
    openModal,
    closeModal,
    setLectureTaskData,
    setLectureTaskCompleteSaved
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        showMessage: data => dispatch(showMessage(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        setLectureTaskData: data => dispatch(setLectureTaskData(data)),
        setLectureTaskCompleteSaved: data => dispatch(setLectureTaskCompleteSaved(data)),
    };
};

class DialogLoadLectureTask extends Component { 

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            comment: '',
            tasks: {},
            renameTaskId: 0,
            renameText: '',
            taskComment: false,
            loading: true,
        };
        this.loadTasks = this.loadTasks.bind(this);
        this.openTask = this.openTask.bind(this);
        this.renameTask = this.renameTask.bind(this);
        this.doRenameTask = this.doRenameTask.bind(this);
        this.cancelTargetTask = this.cancelTargetTask.bind(this);
        this.deleteTask = this.deleteTask.bind(this);
        this.taskComment = this.taskComment.bind(this);
        this.updateTaskComment = this.updateTaskComment.bind(this);
        this.saveTaskComment = this.saveTaskComment.bind(this);
    }

    componentDidMount() {
        this.loadTasks();
    }

    loadTasks() {
        api('lecturetask/list/' + this.props.data.appModeData.lectureId).then(result => {
            this.setState({
                tasks: result.data.tasks,
                loading: false
            });
        });            
    }

    openTask(id, e) {
        api("lecturetask/load/" + id, { post: true, data:{lectureId:this.props.data.appModeData.lectureId} }).then((res) => {
            let lastLoadedTitle = false;
            this.state.tasks.map(t => {
                if(t.id === id) {
                    lastLoadedTitle = t.title;
                }
            });
            if(lastLoadedTitle) {
                let ltData = this.props.data.lectureTaskData;
                ltData.lastLoadedTitle = lastLoadedTitle;
                this.props.setLectureTaskData(ltData);

                if(res.data.task_meta.is_complete === 1) {
                    this.props.setLectureTaskCompleteSaved(true);
                }
            }
            this.props.onLoaded();
        });        
    }

    renameTask(id, e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;

        let text = '';
        this.state.tasks.map(task => {
            if(task.id === id) {
                text = task.title;
            }
        });

        this.setState({
            renameTaskId: id,
            renameText: text
        });
    }

    taskComment(id, e) {
        let task = false;
        this.state.tasks.map(t => {
            if(id === t.id) {
                task = t;
            }
        });

        if(task) {
            this.setState({
                taskComment: task
            });
        }
    }

    updateTaskComment(e) {
        let t = this.state.taskComment;
        t.comment = e.target.value;
        this.setState({
            taskComment: t
        });
    }

    saveTaskComment() {
        api("lecturetask/update-comment", { post: true, data: {id:this.state.taskComment.id, text:this.state.taskComment.comment} }).then((res) => {
            this.loadTasks();
            this.setState({
                taskComment: false
            });
        });
    }

    doRenameTask(e) {
        api("lecturetask/rename", { post: true, data: {id:this.state.renameTaskId, text:this.state.renameText} }).then((res) => {
            this.loadTasks();
            this.cancelTargetTask();
        });
    }

    deleteTask(id, e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;
        this.setState({
            deleteTaskId: id,
        });
    }

    doDeleteTask() {
        api("lecturetask/delete", { post: true, data: {id:this.state.deleteTaskId} }).then((res) => {
            this.loadTasks();
            this.cancelTargetTask();
        });
    }

    cancelTargetTask() {
        this.setState({
            renameTaskId: 0,
            renameText: 0,
            deleteTaskId: 0
        });
    }
    
    render() {
        const { state } = this;

        if(state.loading) return <Loading />;

        if(state.taskComment) {
            return (
                <div className="lecturetask-note">
                    <h2><a onClick={() => this.setState({taskComment: false})}><i className="fa fa-angle-left"></i></a> Poznámka</h2>
                    <p>{__('K uloženému úkolu')} „{state.taskComment.title}“</p>
                    <textarea onChange={this.updateTaskComment} value={state.taskComment.comment}></textarea>
                    <button type="button" onClick={() => this.saveTaskComment()}>{__('Uložit poznámku')}</button>
                </div>
            );
        }

        let taskList = [];
        state.tasks.map(task => {

            let comment = false;
            if(task.comment != '') {
                comment = <p className="comment">Poznámka: {task.comment}</p>;
            }
            
            let taskAlreadyInList = false
            if(task.id === state.renameTaskId) {
                taskList.push(
                    <div className="lecturetask-item">
                        <article>
                            <p>
                                <input type="text" style={{fontWeight:'bold',width:'100%',padding:'4px 5px'}} onChange={(e) => this.setState({renameText: e.target.value})} value={state.renameText} />
                            </p>
                            <p class="info">
                                {task.course_title} / {task.lecture_title}
                            </p>
                            {comment}
                        </article>
                        <aside>
                            <a onClick={(e) => this.doRenameTask()}>{__('Uložit')}</a>
                            <a onClick={(e) => this.cancelTargetTask(task.id, e)} className="delete">{__('Zpět')}</a>
                        </aside>
                    </div>
                );
                taskAlreadyInList = true;
            } 

            if(task.id === state.deleteTaskId) {
                taskList.push(
                    <div className="lecturetask-item">
                        <article>
                            <h2 style={{color:'#f00'}}>{task.title}</h2>
                            <p class="info">
                                {task.course_title} / {task.lecture_title}
                            </p>
                            {comment}
                        </article>
                        <aside>
                            <a style={{background:'#f00'}} onClick={(e) => this.doDeleteTask()}>Potvrdit smazání</a>
                            <a style={{background:'#999'}} onClick={(e) => this.cancelTargetTask(task.id, e)} className="delete">Zpět</a>
                        </aside>
                    </div>
                );
                taskAlreadyInList = true;
            } 

            if(!taskAlreadyInList) {
                taskList.push(
                    <div className="lecturetask-item">
                        <article>
                            <h2>{task.title}</h2>
                            <p class="info">
                                {task.course_title} / {task.lecture_title}
                            </p>
                            {comment}
                        </article>
                        <aside>
                            <a onClick={(e) => this.openTask(task.id, e)}>{__('Načíst')}</a>
                            <a onClick={(e) => this.taskComment(task.id, e)} className="rename">{__('Poznámka')}</a>
                            <a onClick={(e) => this.renameTask(task.id, e)} className="rename">{__('Přejmenovat')}</a>
                            <a onClick={(e) => this.deleteTask(task.id, e)} className="delete">{__('Smazat')}</a>
                        </aside>
                    </div>
                );
            }
        });
        
        return (
            <React.Fragment>
                {taskList}
            </React.Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogLoadLectureTask));
