import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import Util from 'utility';

import { 
    showMessage, 
    openModal,
    closeModal,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        showMessage: data => dispatch(showMessage(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
    };
};

class DialogLectureTaskSampleText extends Component { 

    constructor(props) {
        super(props);
    }

    render() {
        const { data } = this.props;
        const { globals } = data;

        return (
            <div className="lecturetask-profile-dialog">
                <div style={{overflowY:'auto',border:'1px solid #ccc',padding:'4px',fontSize:'.9em'}} dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogLectureTaskSampleText));
