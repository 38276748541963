import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading/index';
import Saving from 'components/Saving/index';
import api from 'api';
import Util from 'utility';

import { 
    loadGlobals
} from '../../actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        loadGlobals: data => dispatch(loadGlobals(data)),
    };
};

class Profile extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            saving: false,
            initialProfileUrl: '',
            account: {},
            originalAvatarFilename: '',
            originalCoverFilename: '',
            errors: [],
            saved: false,
            changeLoginData: false,
        };    
        this.onChange = this.onChange.bind(this);
        this.saveAccount = this.saveAccount.bind(this);
    }

    componentDidMount() {
        Util.setPageTitle('Účet');
        api('account/get').then(result => {
            result = result.data;
            this.setState({
                account: result,
                initialProfileUrl: result.profile_url,
                originalAvatarFilename: result.avatar,
                originalCoverFilename: result.cover,
                loading: false
            });
        });
    }

    onChange(e) {
        const n = e.target.name;
        let v = e.target.value;

        if(n == 'delete_avatar') {
            v = e.target.checked ? 1 : 0;
        }
        if(n == 'avatar') {
            v = e.target.files[0];
        }

        if(n == 'delete_cover') {
            v = e.target.checked ? 1 : 0;
        }

        if(n == 'zof_public_profile_enabled') {
            v = e.target.checked ? 1 : 0;
        }


        if(n == 'cover') {
            v = e.target.files[0];
        }

        if(n == 'new_photo') {
            v = e.target.files[0];
        }
        let account = this.state.account;
        account[n] = v;

        this.setState({
            account: account
        }); 
    }

    saveAccount() {
        const { account } = this.state;
        this.setState({
            saving: true,
            errors: []
        }, () => {

            let fd = new FormData;
            for(let i in account) {
                fd.append(i, account[i]);
            }

            api('account/update', {post: true, data: fd}).then(result => {
                result = result.data;

                if(result.status == 0) {
                    this.setState({
                        saving: false,
                        errors: result.errors
                    });
                } else {
                    api('load/startup-data').then((result) => {
                        this.props.loadGlobals(result.data);
                        let account = this.state.account;
                        account.delete_avatar = 0;
                        setTimeout(() => {
                            this.setState({
                                account: account,
                                saving: false,
                                saved: true
                            });
                        }, 300);
                    });
                }
            });
        });
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;
        const { account } = state;

        if(state.loading) return <Loading />;

        if(account.profile_url == null) account.profile_url = '';

        let saving = false;
        if(state.saving) {
            saving = <Saving />;
        }

        let errors = false;
        if(state.errors.length > 0) {
            errors = <div className="errors">{state.errors}</div>;
        }

        // Form data

        let loginData = <p style={{margin:'2em 0 2em 1em'}}><a style={{ color: '#4eb32d', textDecoration: 'underline' }} onClick={() => this.setState({changeLoginData: true})}>Změnit přihlašovací údaje</a></p>
        if(this.state.changeLoginData) {
            loginData = (
                <div className="row">
                    <div className="col">
                    <h3>Přihlašovací údaje</h3>
                        <div className="input-row">
                            <span>E-mail:</span>
                            <div className="inputs">
                                <input type="text" name="username" onChange={this.onChange} value={account.username} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Nové heslo:</span>
                            <div className="inputs">
                                <input type="password" name="password" autocomplete="new-password" value={account.password} onChange={this.onChange} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Nové heslo znovu:</span>
                            <div className="inputs">
                                <input type="password" name="password2" autocomplete="new-password" value={account.password2} onChange={this.onChange} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        let currentProfileUrl = `https://www.zofapp.cz/u/id/${account.id}`;

        let publicProfileInput = [
            <>
                <div className="inputs" style={{flexWrap:'wrap'}}>
                    <div style={{ display: 'flex', flex: '1', alignItems: 'center' }}>
                        <span style={{ width: 'auto', flexBasis: 'auto', paddingRight: '.5em' }}>zofapp.cz/u/</span><input type="text" name="profile_url" onChange={this.onChange} value={account.profile_url} maxLength="40" />
                    </div>
                    <p style={{ flexBasis:'100%',margin: '.5em 0', fontSize: '11px', color: '#444' }}>Pokud si nezadáte vlastní adresu profilu, bude dostupný na adrese <b>{currentProfileUrl}</b>.<br />Upozornění: po zadání adresy již nebude možné ji změnit.</p>
                </div>
            </>
        ];
        if(state.initialProfileUrl !== null && state.initialProfileUrl != '') {
            publicProfileInput = 
                <>
                    <div className="inputs">
                        <div style={{ display: 'flex', flex: '1', alignItems: 'center' }}>
                            <span style={{ width: 'auto', flexBasis: 'auto', paddingRight: '.5em' }}>zofapp.cz/u/</span><input style={{ background: '#f1f1f1', color: '#777', fontWeight: 'bold' }} readOnly={true} type="text" name="profile_url" value={state.initialProfileUrl} maxLength="40" />
                        </div>
                        <p style={{ flexBasis: '100%', margin: '.5em 0', fontSize: '11px', color: '#444' }}>Chcete změnit adresu Vašeho profilu? <a target="_blank" href="https://www.zofapp.cz/kontakt">Napište nám</a>.</p>
                    </div>
                </>;
        }



        let publicProfileLink = false;
        if(state.initialProfileUrl !== null && state.initialProfileUrl != '') {
            publicProfileLink = <a target="_blank" href={`https://www.zofapp.cz/u/${state.initialProfileUrl}`}>Přejít na profil <span><i className="fa fa-link"></i></span></a>;
        }

        const random = Math.random() * 10000000;

        let content = (
            <React.Fragment>
            <div className="panel panel-padded mt-1 mb-1">
                {errors}
                <div className="row">
                    <div className="col">
                        <h3>Adresa veřejného profilu</h3>
                        <div className="input-row">
                            <span>Adresa:</span>
                            {publicProfileInput}
                        </div>                        
                        <div className="input-row">
                            <span></span>
                            <div className="inputs" style={{flexWrap:'wrap'}}>
                                <label style={{fontWeight:'bold',padding:'.5em 0',display:'flex',alignItems:'center'}}>
                                    <input style={{marginRight:'4px'}} type="checkbox" name="zof_public_profile_enabled" onChange={this.onChange} checked={account.zof_public_profile_enabled == 1} />
                                    profil je veřejně přístupný
                                </label>
                            </div>
                        </div>
                        <h3>Osobní údaje</h3>
                        <div className="input-row">
                            <span>Jméno:</span>
                            <div className="inputs">
                                <input type="text" name="name" onChange={this.onChange} value={account.name} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Datum narození:</span>
                            <div className="inputs">
                                <input type="text" name="birthday" onChange={this.onChange} value={account.birthday} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Ulice:</span>
                            <div className="inputs">
                                <input type="text" name="street" onChange={this.onChange} value={account.street}/>
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Město:</span>
                            <div className="inputs">
                            <input type="text" name="city" onChange={this.onChange} value={account.city}/>
                            </div>
                        </div>
                        <div className="input-row">
                            <span>PSČ:</span>
                            <div className="inputs">
                                <input type="text" name="zip" onChange={this.onChange} value={account.zip}/>
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Země:</span>
                            <div className="inputs">
                                <input type="text" name="country" onChange={this.onChange} value={account.country}/>
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Telefon:</span>
                            <div className="inputs">
                                <input type="text" name="phone" onChange={this.onChange} value={account.phone} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Veřejný e-mail:</span>
                            <div className="inputs">
                                <input type="text" name="public_email" onChange={this.onChange} value={account.public_email} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Více o mně:</span>
                            <div className="inputs">
                                <textarea name="description" onChange={this.onChange} value={account.description}></textarea>
                            </div>
                        </div>


                        <h3>Profilové foto</h3>
                        <div className="input-row">
                            <span>Foto:</span>
                            <div className="inputs">
                                <input type="file" name="avatar" accept="image/*" onChange={this.onChange} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Stávající foto:</span>
                            <div className="inputs">
                                <img src={`https://www.kurzyatac.cz/data/student-avatar/${state.originalAvatarFilename}?r=${random}`} style={{ width: '90px', height: 'auto' }} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span></span>
                            <div className="inputs">
                                <label style={{ padding: 0 }}>
                                    <input type="checkbox" name="delete_avatar" checked={account.delete_avatar === 1} onChange={this.onChange} style={{ verticalAlign: 'middle' }} /> vymazat foto
                                </label>
                            </div>
                        </div>

                        <h3>Pozadí profilu</h3>
                        <div className="input-row">
                            <span>Foto:</span>
                            <div className="inputs">
                                <input type="file" name="cover" accept="image/*" onChange={this.onChange} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Stávající pozadí profilu:</span>
                            <div className="inputs">
                                <img src={`https://www.zofapp.cz/data/zof/cover/${state.originalCoverFilename}?r=${random}`} style={{ width: '320px', height: 'auto' }} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span></span>
                            <div className="inputs">
                                <label style={{ padding: 0 }}>
                                    <input type="checkbox" name="delete_cover" checked={account.delete_cover === 1} onChange={this.onChange} style={{ verticalAlign: 'middle' }} /> vymazat foto
                                </label>
                            </div>
                        </div>

                        <h3>Práce</h3>
                        <div className="input-row">
                            <span>Kde pracujete?</span>
                            <div className="inputs">
                                <input type="text" name="workplace" onChange={this.onChange} value={account.workplace} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Odkaz:</span>
                            <div className="inputs">
                                <input type="text" name="workplace_url" onChange={this.onChange} value={account.workplace_url} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Adresa:</span>
                            <div className="inputs">
                                <input type="text" name="workplace_address" onChange={this.onChange} value={account.workplace_address} />
                            </div>
                        </div>
                        <h3>Sociální sítě</h3>
                        <div className="input-row">
                            <span>Facebook:</span>
                            <div className="inputs">
                                <input type="text" name="social_facebook" onChange={this.onChange} value={account.social_facebook} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Instagram:</span>
                            <div className="inputs">
                                <input type="text" name="social_instagram" onChange={this.onChange} value={account.social_instagram} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>YouTube:</span>
                            <div className="inputs">
                                <input type="text" name="social_youtube" onChange={this.onChange} value={account.social_youtube} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Twitter:</span>
                            <div className="inputs">
                                <input type="text" name="social_twitter" onChange={this.onChange} value={account.social_twitter} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>LinkedIn:</span>
                            <div className="inputs">
                                <input type="text" name="social_linkedin" onChange={this.onChange} value={account.social_linkedin} />
                            </div>
                        </div>
                        <h3>Odkazy</h3>
                        <div className="input-row">
                            <span>Odkaz 1:</span>
                            <div className="inputs">
                                <input type="text" name="generic_link_1" onChange={this.onChange} value={account.generic_link_1} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Odkaz 2:</span>
                            <div className="inputs">
                                <input type="text" name="generic_link_2" onChange={this.onChange} value={account.generic_link_2} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Odkaz 3:</span>
                            <div className="inputs">
                                <input type="text" name="generic_link_3" onChange={this.onChange} value={account.generic_link_3} />
                            </div>
                        </div>
                        <h3>Oblíbené</h3>
                        <div className="input-row">
                            <span>Kuchyně:</span>
                            <div className="inputs">
                                <textarea name="favorite_cuisine" onChange={this.onChange} value={account.favorite_cuisine}></textarea>
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Kuchařky:</span>
                            <div className="inputs">
                                <textarea name="favorite_cookbooks" onChange={this.onChange} value={account.favorite_cookbooks}></textarea>
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Restaurace:</span>
                            <div className="inputs">
                                <textarea name="favorite_restaurants" onChange={this.onChange} value={account.favorite_restaurants}></textarea>
                            </div>
                        </div>
                        <h3>Vzdělání</h3>
                        <div className="input-row">
                            <span>Škola:</span>
                            <div className="inputs">
                                <input type="text" name="education_school" onChange={this.onChange} value={account.education_school} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Kurzy:</span>
                            <div className="inputs">
                                <input type="text" name="education_courses" onChange={this.onChange} value={account.education_courses} />
                            </div>
                        </div>
                    </div>
                </div>
                {loginData}
                <div className="form-submit">
                    <button onClick={this.saveAccount}>Uložit nastavení</button>
                </div>
            </div>
            {saving}
            </React.Fragment>
        );

        if(state.saved) {
            content = (
                <React.Fragment>
                    <div className="panel panel-padded mt-1 mb-1" style={{ textAlign: 'center'}}>
                        <p style={{textAlign:'center',fontSize:'6em',color:'#4eb32d',margin:'0'}}>
                            <i className="fa fa-check"></i>
                        </p>
                        <h2 style={{ color: '#4eb32d' }}>Údaje byly uloženy</h2>
                        <p>Zadané údaje k Vašemu účtu byly uloženy.</p>
                        <p>Kam dále?</p>
                        <p style={{ margin: '2em 0' }}>
                            <a className="button-default" onClick={() => this.setState({saved:false})}>Zpět na úpravu údajů</a>
                        </p>
                        <p style={{ margin: '2em 0' }}>
                            <a className="button-default" onClick={() => this.props.history.push('/')}>Přejít do aplikace</a>
                        </p>
                        <p style={{ margin: '2em 0' }}>
                            <a className="button-default" target="_blank" href={`https://www.zofapp.cz/u/id/${globals.user.id}`}>Přejít na profil</a>
                        </p>
                    </div>
                </React.Fragment>
            );
        }   

        return (
            <div className="page">
                <div className="page-header">
                    <div className="container">
                        <h1>
                            Veřejný profil
                            <aside>{publicProfileLink}</aside>
                        </h1>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        {content}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
