import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import Util from 'utility';

import { 
    showMessage, 
    openModal,
    closeModal,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        showMessage: data => dispatch(showMessage(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
    };
};

class DialogAssignmentInfo extends Component { 

    constructor(props) {
        super(props);
    }

    render() {
        const { data } = this.props;
        const { globals } = data;

        let fpid = Util.getFirstProfileId();
        if(typeof data.appModeData.profileId === 'number') {
            fpid = data.appModeData.profileId;
        }

        const profile = globals.profiles[fpid];
        const bmr = Util.getBMR(profile);

        let energCoef = this.props.data.globals.energyOutputCoefficients[profile.energy_output_coefficient];

        if(typeof data.appModeData.lectureInfoData.lecture_data.energy_coefficient_id === 'number') {
            const newCoefId = data.appModeData.lectureInfoData.lecture_data.energy_coefficient_id;
            energCoef = this.props.data.globals.energyOutputCoefficients[newCoefId];
        }

        const energyExpTotal = bmr * energCoef.coefficient;

        return (
            <div className="lecturetask-profile-dialog">
                <div dangerouslySetInnerHTML={{ __html: this.props.assignmentText }}></div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogAssignmentInfo));
