import React, { Component } from 'react';
import { connect } from "react-redux";
import Util from 'utility';
import { Locale, __ } from 'locale';

import { 
    setTraining,
    setTrainingStart,
    setTrainingLength,
    setTrainingDescription,
} from 'actions/index';


const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        setTraining: data => dispatch(setTraining(data)),
        setTrainingStart: data => dispatch(setTrainingStart(data)),
        setTrainingLength: data => dispatch(setTrainingLength(data)),
        setTrainingDescription: data => dispatch(setTrainingDescription(data)),
    };
};

class PanelSportSettings extends Component { 

    constructor(props) {
        super(props);
        this.state = {
        };
        this.onDone = this.onDone.bind(this);
        this.setTrainingStart = this.setTrainingStart.bind(this);
        this.setTrainingLength = this.setTrainingLength.bind(this);
        this.setTrainingDescription = this.setTrainingDescription.bind(this);
    }

    onDone() {
        this.props.onDone();
    }

    setTrainingStart(e) {
        const id = e.target.dataset.id;
        this.props.setTrainingStart({
            id: id,
            start: e.target.value
        });
    }

    setTrainingLength(e) {
        const id = e.target.dataset.id;
        this.props.setTrainingLength({
            id: id,
            length: e.target.value
        });
    }

    setTrainingDescription(e) {
        const id = e.target.dataset.id;
        this.props.setTrainingDescription({
            id: id,
            description: e.target.value
        });
    }

    render() {
        const { data } = this.props;

        const training1Start = data.trainings[1]['start'];
        const training1Length = data.trainings[1]['length'];
        const training1Description = data.trainings[1]['description'];
        const training2Start = data.trainings[2]['start'];
        const training2Length = data.trainings[2]['length'];
        const training2Description = data.trainings[2]['description'];
        const training3Start = data.trainings[3]['start'];
        const training3Length = data.trainings[3]['length'];
        const training3Description = data.trainings[3]['description'];
        const training4Start = data.trainings[4]['start'];
        const training4Length = data.trainings[4]['length'];
        const training4Description = data.trainings[4]['description'];

        return (
            <React.Fragment>
            <table className="generic-table">
                <tbody>
                <tr>
                    <th className="nw">Sport 1 - začátek / délka:</th>
                    <td>
                        <input type="time" data-id="1" onChange={this.setTrainingStart} value={training1Start} style={{ textAlign: 'center' }} />
                        {'\u00A0'}/{'\u00A0'}
                        <input type="number" data-id="1" onChange={this.setTrainingLength} value={training1Length} style={{ width: '3em', textAlign: 'center' }} />{'\u00A0'}minut
                    </td>
                </tr>
                <tr>
                    <th className="nw">Sport 1 - stručný popis:</th>
                    <td><input type="text" data-id="1" onChange={this.setTrainingDescription} value={training1Description} style={{ width: '100%' }} /></td>
                </tr>
                <tr>
                    <th className="nw">Sport 2 - začátek / délka:</th>
                    <td>
                        <input type="time" data-id="2" onChange={this.setTrainingStart} value={training2Start} style={{ textAlign: 'center' }} />
                        {'\u00A0'}/{'\u00A0'}
                        <input type="number" data-id="2" onChange={this.setTrainingLength} value={training2Length} style={{ width: '3em', textAlign: 'center' }} />{'\u00A0'}minut
                    </td>
                </tr>
                <tr>
                    <th className="nw">Sport 2 - stručný popis:</th>
                    <td><input type="text" data-id="2" onChange={this.setTrainingDescription} value={training2Description} style={{ width: '100%' }} /></td>
                </tr>
                <tr>
                    <th className="nw">Sport 3 - začátek / délka:</th>
                    <td>
                        <input type="time" data-id="3" onChange={this.setTrainingStart} value={training3Start} style={{ textAlign: 'center' }} />
                        {'\u00A0'}/{'\u00A0'}
                        <input type="number" data-id="3" onChange={this.setTrainingLength} value={training3Length} style={{ width: '3em', textAlign: 'center' }} />{'\u00A0'}minut
                    </td>
                </tr>
                <tr>
                    <th className="nw">Sport 3 - stručný popis:</th>
                    <td><input type="text" data-id="3" onChange={this.setTrainingDescription} value={training3Description} style={{ width: '100%' }} /></td>
                </tr>
                <tr>
                    <th className="nw">Sport 4 - začátek / délka:</th>
                    <td>
                        <input type="time" data-id="4" onChange={this.setTrainingStart} value={training4Start} style={{ textAlign: 'center' }} />
                        {'\u00A0'}/{'\u00A0'}
                        <input type="number" data-id="4" onChange={this.setTrainingLength} value={training4Length} style={{ width: '3em', textAlign: 'center' }} />{'\u00A0'}minut
                    </td>
                </tr>
                <tr>
                    <th className="nw">Sport 4 - stručný popis:</th>
                    <td><input type="text" data-id="4" onChange={this.setTrainingDescription} value={training4Description} style={{ width: '100%' }} /></td>
                </tr>
                </tbody>
            </table>
            <p style={{ textAlign: 'center', margin: '1em 0 0' }}><button className="button-primary" onClick={this.onDone}>{__('Uložit a zavřít')}</button></p>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelSportSettings);
