import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loading from 'components/Loading/index';
import Util from 'utility';
import api from 'api';

import { 
    closeModal,
    showMessage,
    loadGlobals,
    loginUser,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
        loadGlobals: data => dispatch(loadGlobals(data)),
        loginUser: data => dispatch(loginUser(data)),
    };
};


class PanelEditGroup extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            title: this.props.title
        };    

        this.update = this.update.bind(this);
        this.save = this.save.bind(this);
    }

    save() {
        api('clients/groups/update', { post: true, data: { id: this.state.id, title: this.state.title }}).then((res) =>{
            this.props.onDone();
        });
    }

    update(e) {
        let state = this.state;
        switch(e.target.name) {
            case 'title':
                state.title = e.target.value;
                break;
        }
        this.setState(state);
    }

    render() {
        const { client } = this.state;
        const { globals } = this.props.data;

        return (
            <React.Fragment>
            <div className="filters">
                <p><input type="text" name="title" onChange={this.update} value={this.state.title} /></p>
                <p><button onClick={this.save}>Uložit</button></p>
            </div>
            </React.Fragment>            
        );            
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PanelEditGroup));
