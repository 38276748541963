import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Util from 'utility';

import MyFoodCopy from './MyFoodCopy/index';
import MyFoodEdit from './MyFoodEdit/index';
import MyFoodDelete from './MyFoodDelete/index';

class MyFood extends Component {
    componentDidMount() {
        Util.setPageTitle('Moje potraviny');
    }

    render() {
        return (
            <Switch>
                <Route path="/myfood/copy/:id" component={MyFoodCopy} />
                <Route path="/myfood/edit/:id" component={MyFoodEdit} />
                <Route path="/myfood/delete/:id" component={MyFoodDelete} />
            </Switch>
        );            
    }
}

export default MyFood;
