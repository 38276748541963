import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { debounce } from "throttle-debounce";   
import '@fortawesome/fontawesome-free/css/all.min.css';
import api from 'api';
import Util from 'utility';
import Loading from 'components/Loading/index';
import { __ } from 'locale';

import { 
    openModal, 
    closeModal
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
    };
};

class RecipeSearch extends Component {
    constructor(props) {
        super(props);

        let initialUserId = ["0"];
        if(props.match.params.action == 'me') initialUserId = ["" + this.props.data.user.id];

        let initialFilter = {
            q: '', 
            user_id: initialUserId,
            tags: [],
            sortBy: '',
            sortOrder: '',
            lastSortIsCustom: false,
            customSortAttribute: 'fiber',
            viewMode: 'list',
            nutritionCalcMode: 'serving',
            nutritionSortMode: 'popular',
        };

        if(props.isDrinkRegime) {
            initialFilter.tags = [3, 106];
        }

        this.state = {
            filter: initialFilter,
            total: 0,
            page: 0,
            perPage: 30,
            loading: false,
            recipes: {},
        }
        this.updateFilter = this.updateFilter.bind(this);
        this.updateSort = this.updateSort.bind(this);
        this.setViewMode = this.setViewMode.bind(this);
        this.setNutritionCalcMode = this.setNutritionCalcMode.bind(this);
        this.setNutritionSortMode = this.setNutritionSortMode.bind(this);
        this.changePage = this.changePage.bind(this);
        this.loadRecipes = this.loadRecipes.bind(this);
        this.loadRecipesDebounced = debounce(250, this.loadRecipes);
    }

    componentDidMount() {
        this.loadRecipes();
    }

    loadRecipes() {
        this.setState({
            loading: true
        }, () => {

            let filter = this.state.filter;
            filter.appMode = this.props.data.appMode;

            api('recipes/list', { post: true, data: { page: this.state.page, filter: filter }}).then((res) =>{
                this.setState({
                    loading: false,
                    total: res.data.total,
                    recipes: res.data.recipes
                });
            });
        });
    }

    updateFilter(e) {
        let filter = this.state.filter;
        const v = e.target.value;
        switch(e.target.name) {
            case 'q':
                filter.q = e.target.value;
                break;
            case 'u':

                if(typeof this.props.isEmbedded === 'undefined') {
                    if(v == 0) {
                        this.props.history.push('/recipe');
                    } else {
                        this.props.history.push('/recipe/me');
                    }
                }
                
                filter.user_id = [v];
                /*
                if(filter.user_id.includes(v)) {
                    filter.user_id = filter.user_id.filter(item => item !== v);                    
                } else {
                    filter.user_id.push(v);
                }
                */
                break;                
            case 't':
                if(filter.tags.includes(v)) {
                    filter.tags = filter.tags.filter(item => item !== v);                    
                } else {
                    filter.tags.push(v);
                }
                break;
        }
        this.setState({
            page: 0,
            filter: filter
        }, () => {
            this.loadRecipesDebounced();
        });
    }

    updateSort(e) {
        let filter = this.state.filter;
        let newSortBy = e.currentTarget.getAttribute('data-sort');
        if(newSortBy == 'custom') {
            if(e.currentTarget.getAttribute('data-change-order-only') == '1') {
                newSortBy = filter.sortBy;
                filter.sortOrder = filter.sortOrder == 'asc' ? 'desc' : 'asc';
            } else {
                newSortBy = e.target.value;
                filter.customSortAttribute = newSortBy;
                filter.sortBy = newSortBy;
                filter.sortOrder = 'desc';
            }
            filter.lastSortIsCustom = true;
        } else {
            filter.lastSortIsCustom = false;
            if(newSortBy == filter.sortBy) {
                switch(filter.sortOrder) {
                    case 'asc':
                        filter.sortOrder = 'desc';
                        break;
                    case 'desc':
                        filter.sortOrder = '';
                        filter.sortBy = '';
                        break;
                }
            } else {
                filter.sortBy = newSortBy;
                filter.sortOrder = 'asc';
            }
        }
        this.setState({
            page: 0,
            filter: filter
        }, () => {
            this.loadRecipesDebounced();
        });
    }

    setViewMode(mode) {
        let filter = this.state.filter;
        filter.viewMode = mode;
        this.setState({
            page: 0,
            filter: filter
        });
    }

    setNutritionCalcMode(mode) {
        let filter = this.state.filter;
        filter.nutritionCalcMode = mode;
        this.setState({
            page: 0,
            filter: filter
        }, () => {
            this.loadRecipesDebounced();
        });
    }

    setNutritionSortMode(mode) {
        let filter = this.state.filter;
        filter.nutritionSortMode = mode;
        this.setState({
            filter: filter
        }, () => {
            this.loadRecipesDebounced();
        });
    }

    changePage(e, page) {
        this.setState({
            page: page,
            changingPageOnly: true
        }, () => {
            this.loadRecipes();
        });
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;
        const { filter, recipes } = state;

        let sortSymbols = {};
        if(filter.sortBy != '') {
            if(filter.sortOrder == 'asc') {
                sortSymbols[filter.sortBy] = <i className="fa fa-angle-up"></i>;
            } else {
                sortSymbols[filter.sortBy] = <i className="fa fa-angle-down"></i>;
            }
        }

        let sortSymbolCustom = false;
        if(filter.lastSortIsCustom) {
            if(filter.sortOrder == 'asc') {
                sortSymbolCustom = <a style={{paddingLeft:'.5em'}} onClick={this.updateSort} data-sort="custom" data-change-order-only="1"><i className="fa fa-angle-up"></i></a>;
            } else {
                sortSymbolCustom = <a style={{paddingLeft:'.5em'}} onClick={this.updateSort} data-sort="custom" data-change-order-only="1"><i className="fa fa-angle-down"></i></a>;
            }
        }

        let sortIgnore = [
            'weight', 'weight_total', 'amount', 'drink_amount', 'energy_output_kcal', 'energy_output_kj',
            'proteins', 'carbohydrates', 'fats', 'fats_omega3_dha_epa', 'energy_kcal', 'energy_kj', 'energy_balance_kcal', 'energy_balance_kj',
            'proteins_ratio', 'fats_saturated_unsaturated_ratio', 'fats_monounsaturated_polyunsaturated_ratio', 'fats_omega3_omega6_ratio',
            'fiber_ratio', 'calcium_magnesium_ratio', 'potassium_sodium_ratio',
            'energy', 'digestibility', 'acidobasicity', 'thermic_effect', 'biotin',
        ];
        let customSortOptions = [];
        for(let attr in globals.attributes) {
            if(sortIgnore.includes(attr)) continue;
            customSortOptions.push(
                <option value={attr}>{globals.attributes[attr]}</option>
            );
        }

        let recipesListHeader = (
            <div className="recipes-header">
                <div className="recipe-item">
                    <div className="picture">
                    </div>
                    <div className="info">
                        <div className="title">
                            <h3>{__('Název')}</h3>
                        </div>
                        <div className="recipe-nutrition-data">
                            <div><a onClick={this.updateSort} data-sort="energy_kcal">{__('Kalorie')} {sortSymbols['energy_kcal'] || false}</a></div>
                            <div><a onClick={this.updateSort} data-sort="proteins">{__('Bílkoviny')} {sortSymbols['proteins'] || false}</a></div>
                            <div><a onClick={this.updateSort} data-sort="carbohydrates">{__('Sacharidy')} {sortSymbols['carbohydrates'] || false}</a></div>
                            <div><a onClick={this.updateSort} data-sort="fats">{__('Tuky')} {sortSymbols['fats'] || false}</a></div>
                            <div>
                                <select name="custom_sort" onChange={this.updateSort} data-sort="custom" style={{fontSize:'11px',width:'6em'}} value={filter.customSortAttribute}>
                                    {customSortOptions}
                                </select>
                                {sortSymbolCustom}
                            </div>
                        </div>
                        <div className="actions"></div>
                    </div>
                </div>
            </div>
        );

        let recipesList = [];
        if(recipes.length > 0) {
            Object.keys(recipes).map(key => {
                const recipe = recipes[key];


                let actionOpenButton = false;
                if(recipe.ownership == 'user') {
                    if(typeof this.props.isEmbedded === 'undefined') {  
                        actionOpenButton = <Link to={`/recipe/open/${recipe.id}`}>{__('Otevřít')}</Link>;
                    }
                } else {
                    if(Util.userHasPurchased('ZOF_COOKBOOK')) {
                        actionOpenButton = <Link to={`/recipe/open/${recipe.id}`}>{__('Otevřít')}</Link>;
                    }
                }

                let tags = false;
                if(recipe.ownership == 'user') {
                    tags = [];
                    recipe.tag_ids.map(tagId => {
                        tags.push(globals.tags[tagId]);
                    })
                    if(tags.length > 0) {
                        tags = <div className="tags">{__('Štítky')}: {tags.join(', ')}</div>
                    }
                }

                let actionsButtons = (
                    <>
                    <a onClick={(e) => this.props.onClick(recipe.id, e)}>Detail</a>
                    {actionOpenButton}
                    </>
                );

                // ATTN:
                actionsButtons = false;

                if(this.props.isEmbedded === true) actionsButtons = false;

                let nutrData = [];
                if(recipe.nutritionData != '') {
                    nutrData.push(<div><span>{__('Energie')}</span>{Math.ceil(recipe.nutritionData['energy_kcal'])} {globals.attribute_units['energy_kcal']}</div>);
                    nutrData.push(<div><span>{__('Bílkoviny')}</span>{parseFloat(recipe.nutritionData['proteins'])} {globals.attribute_units['proteins']}</div>);
                    nutrData.push(<div><span>{__('Sacharidy')}</span>{parseFloat(recipe.nutritionData['carbohydrates'])} {globals.attribute_units['carbohydrates']}</div>);
                    nutrData.push(<div><span>{__('Tuky')}</span>{parseFloat(recipe.nutritionData['fats'])} {globals.attribute_units['fats']}</div>);
                    nutrData.push(<div><span>{globals.attributes[filter.customSortAttribute]}</span>{parseFloat(recipe.nutritionData[filter.customSortAttribute])} {globals.attribute_units[filter.customSortAttribute]}</div>);
                }

                if(filter.viewMode == 'list') {
                    recipesList.push(
                        <div key={recipe.id} className="recipe-item" onClick={(e) => this.props.onClick(recipe.id, e)}>
                            <div className="picture" style={{ backgroundImage: `url(https://www.zofapp.cz/data/${recipe.thumbnail}?${recipe.picture_lastmod})` }}>
                            </div>
                            <div className="info">
                                <div className="title">
                                    <h3>{recipe.title}</h3>
                                    {tags}
                                    <div className="actions">
                                        {actionsButtons}
                                    </div>
                                </div>
                                <div className="recipe-nutrition-data">
                                    {nutrData}
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    recipesList.push(
                        <div key={recipe.id} className="recipe-item-gallery" onClick={(e) => this.props.onClick(recipe.id, e)}>
                            <div className="picture" style={{ backgroundImage: `url(https://www.zofapp.cz/data/${recipe.thumbnail})` }}>
                            </div>
                            <div className="title">
                                <h3>{recipe.title}</h3>
                                <p className="recipe-ownership-info"><span>{recipe.ownership == 'user' ? 'Můj recept' : 'ZOF recept'}</span></p>
                            </div>
                        </div>
                    );
                }
            });
        }

        if(filter.viewMode == 'gallery') {
            recipesList = [
                <div className="recipe-item-gallery-wrap">
                    {recipesList}
                </div>
            ];
            recipesListHeader = false;
        }


        let pages = [];
        let numPages = Math.ceil(this.state.total / this.state.perPage);
        for(let p=0; p<numPages; p++) {
            if(p == this.state.page) {
                pages.push(
                    <strong>{(p+1)}</strong>
                );
            } else {
                pages.push(
                    <a onClick={(e) => this.changePage(e, p)}>{(p+1)}</a>
                );
            }
        }


        let filter1 = (
            <span className="button-list">
                <em>{__('Zobrazení')}:</em>
                <a className={'' + filter.viewMode == 'list' ? 'active' : ''} onClick={() => this.setViewMode('list')}><i className="fa fa-list"></i></a>
                <a className={'' + filter.viewMode == 'gallery' ? 'active' : ''} onClick={() => this.setViewMode('gallery')}><i className="fa fa-th"></i></a>
            </span>
        );

        let filter2 = (
            <span className="button-list">
                <em>{__('Nutriční hodnoty')}:</em>
                <a className={'' + filter.nutritionCalcMode == 'serving' ? 'active' : ''} onClick={() => this.setNutritionCalcMode('serving')}>{__('porce')}</a>
                <a className={'' + filter.nutritionCalcMode == '100g' ? 'active' : ''} onClick={() => this.setNutritionCalcMode('100g')}>100 g</a>
            </span>
        );

        let filter3 = (
            <span className="button-list">
                <em>{__('Řazení')}:</em>
                <a className={'' + filter.nutritionSortMode == 'popular' ? 'active' : ''} onClick={() => this.setNutritionSortMode('popular')}>{__('populární')}</a>
                <a className={'' + filter.nutritionSortMode == 'newest' ? 'active' : ''} onClick={() => this.setNutritionSortMode('newest')}>{__('nejnovější')}</a>
            </span>
        );

        if(filter.viewMode == 'gallery') filter2 = false;

        let links = [];
        if(this.props.newRecipeLink === true) {
            let hasLocalSavedRecipe = false;
            let localRecipe = localStorage.getItem('zof_unsaved_recipe');
            if(localRecipe) {
                localRecipe = JSON.parse(localRecipe);
                if(typeof localRecipe === 'object') {

                    // in single recipe mode, this is the only 
                    // food group we are interested in
                    let foodGroupToSearch = '1|0';      

                    let foodsNum = 0;
                    //Object.keys(localRecipe.foods).map(g => {
                    if(typeof localRecipe.foods[foodGroupToSearch] !== 'undefined') {
                        localRecipe.foods[foodGroupToSearch].map(f => {
                            foodsNum++;
                        });
                    }
                    //});

                    let titlesNum = 0;
                    Object.keys(localRecipe.titles).map(t => {
                        if(localRecipe.titles[t] != '') titlesNum++;
                    });

                    if(foodsNum > 0 && localRecipe.foods.constructor === Object) {
                        hasLocalSavedRecipe = true;
                    }
                    if(titlesNum > 0 && localRecipe.titles.constructor === Object) {
                        hasLocalSavedRecipe = true;
                    }

                    // Main condition - there needs to be a foodGroup with the wanted key!
                    // Because we are interested only in "single" recipe 
                    if(typeof localRecipe.foods[foodGroupToSearch] == 'undefined') {
                        hasLocalSavedRecipe = false;
                    }
                }
            }
            
            if(this.props.data.appMode !== 'LECTURE') {
                if(hasLocalSavedRecipe) {
                    links.push(<Link to="/recipe/create">Dokončit recept <span><i className="fa fa-angle-right"></i></span></Link>);
                } else {
                    links.push(<Link to="/recipe/create">Nový recept <span><i className="fa fa-plus"></i></span></Link>);
                }
            }
        }

        let searchFiltersButton = (
            <aside class="filters-advanced-link">
                <label htmlFor="cb-filters-advanced"><i className="fa fa-ellipsis-h"></i></label>
            </aside>
        );

        if(this.props.isDrinkRegime) {
            searchFiltersButton = false;
        }

        let recipesContent = (
            <>
            {recipesListHeader}
            {recipesList}
            </>
        );

        if(state.loading) recipesContent = <Loading />;


        let content = [
            <div className="page page-recipes">
                <div className="page-header">
                    <div className="container">
                        <h1>Recepty <aside>{links}</aside></h1>
                    </div>
                </div>
                <div className="page-tabs">
                    {Object.keys(globals.recipeFilters.userList).map(key => {
                        return <label key={key} className={'' + (filter.user_id.includes(key) ? 'active' : '')}><input type="checkbox" name="u" value={key} onChange={this.updateFilter} />{globals.recipeFilters.userList[key]}</label>
                    })}
                </div>
                <div className="page-content">
                    <div class="container">
                        <input type="checkbox" id="cb-filters-advanced" />
                        <div className="filters" style={{background:'#fff',paddingBottom:0}}>
                            <div className="box">
                                <div className="search-input with-button">
                                    <i className="fa fa-search"></i>
                                    <input className="search" type="text" name="q" placeholder={__('Hledat v receptech...')} value={filter.q} onChange={this.updateFilter} />
                                    <label htmlFor="cb-filters-advanced"><i className="fa fa-cog"></i> {__('filtr')}</label>
                                </div>
                            </div>
                        </div>

                        <div className="filters-advanced"><div style={{padding:'1em'}}>
                            <div class="filters-advanced-row">
                                <p>{__('Kategorie')}</p>
                                <div className="filters-advanced-item">
                                    {Object.keys(globals.tagsToGroups[1]).map(key => {
                                        const tagId = globals.tagsToGroups[1][key];
                                        return <label key={key}><input type="checkbox" name="t" value={tagId} onChange={this.updateFilter} /><span><i class="fa fa-check"></i></span> {globals.tags[tagId]}</label>
                                    })}
                                </div>
                            </div>
                            <div class="filters-advanced-row">
                                <p>{__('Stravovací režim')}</p>
                                <div className="filters-advanced-item">
                                    {Object.keys(globals.tagsToGroups[11]).map(key => {
                                        const tagId = globals.tagsToGroups[11][key];
                                        if([98,99].includes(tagId)) return false;
                                        return <label key={key}><input type="checkbox" name="t" value={tagId} onChange={this.updateFilter} /><span><i class="fa fa-check"></i></span> {globals.tags[tagId]}</label>
                                    })}
                                </div>
                            </div>
                            <div class="filters-advanced-row">
                                <p>{__('Chuť')}</p>
                                <div className="filters-advanced-item">
                                    {Object.keys(globals.tagsToGroups[10]).map(key => {
                                        const tagId = globals.tagsToGroups[10][key];
                                        return <label key={key}><input type="checkbox" name="t" value={tagId} onChange={this.updateFilter} /><span><i class="fa fa-check"></i></span> {globals.tags[tagId]}</label>
                                    })}
                                </div>
                            </div>
                            <div className="filters-inline">
                                {filter3}
                                {filter1}
                                {filter2}
                            </div>                            
                        </div></div>
                        <div className="panel list">
                            <div className="box">
                                {recipesContent}
                                <div className="pages">{pages}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ];

        return content;
    }  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecipeSearch));
