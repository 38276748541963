import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import FoodSearch from 'components/FoodSearch';
import PanelFoodDetail from './PanelFoodDetail';

import { 
    openModal, 
    closeModal
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
    };
};

class FoodList extends Component {
    constructor(props) {
        super(props);
        this.openFoodDetail = this.openFoodDetail.bind(this);
    }

    openFoodDetail(id, e) {
        e.preventDefault();
        // if (e.target != e.currentTarget) return;
        this.props.closeModal();
        this.props.openModal({
            'title': 'Detail potraviny', 
            'class': 'dialog-big',
            'content': (
                <PanelFoodDetail id={id} />
            )
        });
    }

    render() {
        let content = [
            <FoodSearch onClick={this.openFoodDetail} />
        ];
        return content;
    }  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FoodList));
