import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Saving from 'components/Saving/index';
import api from 'api';
import Util from 'utility';
import { Locale, __ } from 'locale';

import { 
    loadGlobals, 
    showMessage, 
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        loadGlobals: data => dispatch(loadGlobals(data)),
        showMessage: data => dispatch(showMessage(data)),
    };
};

class ProfileCreate extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            profileCreated: false,
            createdProfileId: 0,
            vegeRegimesWarning: false,
            errors: [],
            profile: {
                profile_color: '',
                eating_regimes: [],
                name: '',
                salutation: '',
                persona_key: '',
                birthday_day: 0,
                birthday_month: 0,
                birthday_year: 0,
                height: 0,
                weight: 0,
                diameter_waist: 0,
                diameter_sides: 0,
                photo: '',
            }
        };    
        this.onChange = this.onChange.bind(this);
        this.createProfile = this.createProfile.bind(this);
    }

    componentDidMount() {
        Util.setPageTitle('Vytvořit profil');
    }

    onChange(e) {
        let profile = this.state.profile;
        const key = e.target.name;
        let value = e.target.value;

        // Set numeric values as numbers (instead of strings)
        let valueNumeric = value * 1;
        if(value == valueNumeric.toString()) {
            value = valueNumeric;
        }

        if(key == 'photo') {
            value = e.target.files[0];
        }

        if(key == 'eating_regimes[]') {
            if(e.target.checked) {
                profile.eating_regimes.push(value);
            } else {
                const i = profile.eating_regimes.indexOf(value);
                if(i > -1) {
                    profile.eating_regimes.splice(i, 1);
                }
            }

            // Warning, if more than one vegetarian regime is selected

            const vegetarianRegimes = [10, 20, 30];
            let numVegeRegimes = 0;
            profile.eating_regimes.map(regime => {
                if(vegetarianRegimes.includes(regime)) numVegeRegimes++;
            });

            if(numVegeRegimes > 1) {
                this.setState({
                    vegeRegimesWarning: true
                });
            } else {
                this.setState({
                    vegeRegimesWarning: false
                });
            }

        } else {
            profile[key] = value;
        }

        this.setState({
            profile: profile
        });
    }

    createProfile() {
        const { profile } = this.state;

        // Validate the form
        let errors = [];
        if(profile.name == '') errors.push('name');
        if(profile.salutation == '') errors.push('salutation');
        if(profile.persona_key == '' || profile.persona_key == 0) errors.push('persona_key');
        if(profile.persona_key == '' || profile.persona_key == 0) errors.push('persona_key');
        if(profile.birthday_day == 0) errors.push('birthday_day');
        if(profile.birthday_month == 0) errors.push('birthday_month');
        if(profile.birthday_year == 0) errors.push('birthday_year');
        if(profile.weight == 0) errors.push('weight');
        if(profile.height == 0) errors.push('height');

        this.setState({
            errors: errors
        });

        // If there are errors, show message and return
        if(errors.length > 0) {
            this.props.showMessage({
                show: true,
                type: 'error',
                message: 'Zadejte prosím všechny požadované údaje.'
            });

            window.scrollTo(0,0);

            return false;
        } else {
            this.props.showMessage({
                show: false,
            });
        }

        // If no errors, create the profile
        this.setState({
            saving: true
        }, () => {
            let fd = new FormData;
            for(let i in profile) {
                fd.append(i, profile[i]);
            }
            api('profile/create', {post: true, data: fd}).then(result => {
                const createdProfileData = result.data.data;
                api('load/startup-data').then((result) => {
                    this.props.loadGlobals(result.data);
                    this.setState({
                        profileCreated: true,
                        createdProfileId: createdProfileData.profile_id
                    });
                });
            });
        });
    }

    render() {
        let saving = false;
        if(this.state.saving) {
            saving = <Saving />;
        }

        const { globals } = this.props.data;
        const { profile, errors } = this.state;

        // Form data

        let profileKeys = [];
        profileKeys.push({id: 0, title: 'Vyberte...'});
        for(let pk in globals.profileKeys) {
            profileKeys.push({id: pk, title: globals.profileKeys[pk]});
        }

        let birthdayDays = [];
        for(let n=1; n<=31; n++) birthdayDays.push(n);

        let birthdayMonths =  ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'];
        
        let birthdayYears = [];
        for(let n=1900; n<=(new Date()).getFullYear(); n++) birthdayYears.push(n);

        let profileName = profile.name;
        if(profileName == '') profileName = '\u00A0';

        if(profile.profile_color == '') profile.profile_color = '000000';

        let profileColors = ['000000', 'dd0000', '00cc00', '0088ee', 'ff7700', 'aa00cc', 'ffcc44', 'ff26f2', '00c97b'];
        let profileColorSelect = [];
        profileColors.map(color => {
            profileColorSelect.push(
                <div className="col col-2" key={color}>
                    <label className="check">
                        <input type="radio" name="profile_color" value={color} checked={profile.profile_color == color} onChange={this.onChange} />
                        <b><i className="fa fa-check"></i></b>
                        <span style={{ padding: '3px 4px', fontSize: '.9em', borderRadius: '4px', backgroundColor: '#' + color, color: '#fff'}}>{profileName}</span>
                    </label>
                </div>
            );
        });

        let eatingRegimes = [];
        for(let r in globals.eatingRegimes) {
            eatingRegimes.push(
                <div className="col col-3" key={r}>
                    <label className="check">
                        <input type="checkbox" name="eating_regimes[]" value={r} checked={profile.eating_regimes.includes(r * 1)} onChange={this.onChange} />
                        <b><i className="fa fa-check"></i></b>
                        {globals.eatingRegimes[r].title}
                    </label>                
                </div>
            );
        }

        let energyBalanceOptions = [];
        for(let e in globals.energyBalanceOptions) {
            energyBalanceOptions.push(
                <div className="col" style={{ flexBasis: '100%' }} key={e}>
                    <label class="check">
                        <input type="checkbox" name="energy_balance_option" value={e} checked={profile.energy_balance_option == e} onChange={this.onChange} />
                        <b><i className="fa fa-check"></i></b>
                        {globals.energyBalanceOptions[e]}
                    </label>                
                </div>
            );
        }


        let energyOutputCoefficients = [];
        for (let c in this.props.data.globals.energyOutputCoefficients) {
            const coef = this.props.data.globals.energyOutputCoefficients[c];
            energyOutputCoefficients.push(
                <div className="col" style={{ flexBasis: '100%' }} key={c}>
                    <label class="check">
                        <input type="radio" name="energy_output_coefficient" value={c} checked={profile.energy_output_coefficient == c} onChange={this.onChange} />
                        <b><i className="fa fa-check"></i></b>
                        {coef.title}
                    </label>                
                </div>
            );
        }

        let energyBalanceSettings = (
            <>
            <h3>Energetická bilance</h3>
            <div class="row row-labels-only">
                {energyBalanceOptions}
            </div>
            </>
        );




        let vegeRegimesWarning = false;
        if(this.state.vegeRegimesWarning) {
            vegeRegimesWarning = <span style={{ marginLeft: '1.5em', fontSize: '.7em', color: '#f00' }}>Upozornění: máte vybrán více než jeden vegetariánský profil.</span>;
        }



        // Form JSX

        const random = Math.random() * 10000000;

        let newProfileInfo = false;
        if(typeof globals.profiles == 'object') {
            const currentUrl = window.location.pathname;
            if(Object.keys(globals.profiles).length == 0) {
                newProfileInfo = <p style={{ background: '#ffc', color: '#000', padding: '1em', textAlign: 'center' }}>{__('Před použitím aplikace ZOF je třeba nejprve vytvořit svůj profil. Vyplňte prosím následující formulář')}:</p>
            }
        }


        let content = (
            <React.Fragment>
                <div className="container">
            <div className="panel panel-padded mt-1 mb-1">
                <h1>{__('Vytvořte svůj profil')}</h1>
                {newProfileInfo}
                <p style={{margin:'1em 0',background:'#efefef',padding:'1em'}}>{__('Položky označené hvězdičkou jsou povinné. Ostatní můžete vyplnit až později.')}</p>
                <div className="row">
                    <div className="col">
                        <h3>{__('Jméno profilu')}</h3>
                        <div className="input-row">
                            <span className={errors.includes('name') ? 'error' : ''}>{__('Jméno')}:<span className="form-required">*</span></span>
                            <div className="inputs">
                                <input type="text" name="name" maxlength="50" onChange={this.onChange} value={profile.name} style={{ fontWeight: 'bold' }} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span className={errors.includes('salutation') ? 'error' : ''}>{__('Oslovení')}:<span className="form-required">*</span></span>
                            <div className="inputs">
                                <input type="text" name="salutation" onChange={this.onChange} value={profile.salutation} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span className={errors.includes('persona_key') ? 'error' : ''}>{__('Specifikace')}:<span className="form-required">*</span></span>
                            <div className="inputs">
                                <select name="persona_key" onChange={this.onChange} defaultValue={profile.persona_key}>
                                    {profileKeys.map(pk => {
                                        return <option value={pk.id}>{pk.title}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <h3>{__('Profilová fotka')}</h3>
                        <div className="input-row">
                            <span>{__('Foto')}:</span>
                            <div class="inputs">
                                <input type="file" name="photo" accept="image/*" onChange={this.onChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-full">
                        <h3>{__('Vstupní údaje')}</h3>
                        <div className="input-row">
                            <span className={
                                (errors.includes('birthday_date') || errors.includes('birthday_month') || errors.includes('birthday_year')) ? 'error' : ''
                                }>{__('Datum narození')}:<span className="form-required">*</span></span>
                            <div className="inputs">
                                <select style={{ width: '4em' }} name="birthday_day" defaultValue={profile.birthday_day} onChange={this.onChange}>
                                    <option value="0"></option>
                                    {birthdayDays.map(d => {
                                        return <option value={d}>{d}</option>
                                    })};
                                </select>
                                <select style={{ width: '8em' }} name="birthday_month" defaultValue={profile.birthday_month} onChange={this.onChange}>
                                    <option value="0"></option>
                                    {birthdayMonths.map((d, i) => {
                                        return <option value={(i+1)}>{d}</option>
                                    })};
                                </select>
                                <select style={{ width: '5em' }} name="birthday_year" defaultValue={profile.birthday_year} onChange={this.onChange}>
                                    <option value="0"></option>
                                    {birthdayYears.map(d => {
                                        return <option value={d}>{d}</option>
                                    })};
                                </select>
                            </div>
                        </div>
                        <div className="input-row">
                            <span>{__('Výška')}:<span className="form-required">*</span></span>                                    
                            <div class="inputs">
                                <input className="short" type="text" name="height" onChange={this.onChange} value={profile.height} /> cm
                            </div>
                        </div>
                        <div className="input-row">
                            <span>{__('Váha')}:<span className="form-required">*</span></span>                                    
                            <div class="inputs">
                                <input className="short" type="text" name="weight" onChange={this.onChange} value={profile.weight} /> kg
                            </div>
                        </div>
                        <div className="input-row">
                            <span>{__('Obvod pasu')}:</span>                                    
                            <div class="inputs">
                                <input className="short" type="text" name="diameter_waist" onChange={this.onChange} value={profile.diameter_waist} /> cm    
                            </div>
                        </div>
                        <div className="input-row">
                            <span>{__('Obvod boků')}:</span>                                    
                            <div class="inputs">
                                <input className="short" type="text" name="diameter_sides" onChange={this.onChange} value={profile.diameter_sides} /> cm
                            </div>
                        </div>
                        <h3>{__('Bazální metabolismus')}</h3>
                            <p>{__('Pokud hodnotu neznáte, nevyplňujte, aplikace ji vypočítá sama.')}</p>
                            <div className="input-row">
                                <span>{__('Bazální metabolismus')} (BMR):</span>                                    
                                <div class="inputs">
                                    <input className="short" type="text" name="individual_bmr" onChange={this.onChange} value={profile.individual_bmr} /> kcal
                                </div>
                            </div>
                            <h3>{__('Výdej energie')}<span className="form-required">*</span></h3>
                            <div class="row row-labels-only">
                                {energyOutputCoefficients}
                            </div>
                            <h3>{__('Stravovací režimy')}<span className="form-required">*</span> {vegeRegimesWarning}</h3>
                            <div class="row row-labels-only">
                                {eatingRegimes}
                            </div>                        
                    </div>
                </div>
                <div className="row">
                    <div className="col col-full">
                        <h3>{__('Barva profilu')}</h3>
                        <div className="input-row">
                            <div className="row row-labels-only">
                                {profileColorSelect}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-submit">
                    <button onClick={this.createProfile}>{__('Vytvořit profil')}</button>
                </div>
            </div>
            {saving}
            </div>
            </React.Fragment>            
        );

        if(typeof globals.profiles == 'object') {
            if(Object.keys(globals.profiles).length > 0) {
                content = <p>V aktuální verzi aplikace je možné vytvořit pouze jeden profil.</p>
            }
        }

        if(this.state.profileCreated) {

            if(this.props.data.appMode === 'LECTURE') {

                content = (
                    <React.Fragment>
                        <div className="container">
                            <div className="panel panel-padded mt-1 mb-1" style={{textAlign:'center'}}>
                                <h2 style={{ color: '#4eb32d' }}>Profil vytvořen</h2>
                                <p>Váš profil je vytvořen. Kliknutím na tlačítko níže budete přesměrování zpět na úkol.</p>
                                <p style={{ margin: '2em 0' }}>
                                    <a className="button-primary" onClick={() => this.props.history.push('/')}>Pokračovat na úkol</a>
                                </p>
                            </div>
                        </div>
                    </React.Fragment>
                );


            } else {

                content = (
                    <React.Fragment>
                        <div className="panel panel-padded mt-1 mb-1">
                            <h2 style={{ color: '#4eb32d' }}>Profil vytvořen</h2>
                            <p>Registrací do aplikace jste získali 15 receptů zdarma (najdete je v Kuchařce v sekci <Link to="/cookbook/myrecipes">Moje recepty</Link>).</p>
                            <p>Kam dále?</p>
                            <p>V aplikaci si můžete v databázi potravin označit:</p>
                            <p style={{ textAlign: 'left', margin: '1em 0'}}><b>1. Oblíbené potraviny</b><br /><br />Takto označené potraviny se při tvorbě receptu nabízí přednostně ve speciálním seznamu. Takto si usnadníte opakované vyhledávání těchto potravin, máte všechny na jednom místě.</p>
                            <p style={{ textAlign: 'left', margin: '2em 0 3em 0' }}><b>2. Nežádoucí potraviny</b><br /><br />Potraviny označené jako nežádoucí budou při tvorbě receptu viditelně označeny. Toto je velmi důležité především pro jedince trpící potravinovými introlerancemi a alergiemi. Mohou se tak snadno těchto potravin vyvarovat, případně je nahradit jinými.</p>
                            <p style={{ margin: '2em 0' }}>
                                <a className="button-primary" onClick={() => this.props.history.push('/food' + this.state.createdProfileId)}>Označit oblíbené či nevhodné potraviny</a>
                            </p>
                            <p style={{ margin: '2em 0' }}>
                                <Link to="/recipe/me" className="button-primary">Prohlédnout 15 receptů zdarma</Link>
                            </p>
                            <p style={{ margin: '2em 0' }}>
                                <a className="button-primary" onClick={() => this.props.history.push('/')}>Přejít na úvodní stranu</a>
                            </p>
                        </div>
                    </React.Fragment>
                );
            }
        }

        return content;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileCreate));
