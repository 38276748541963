import React, { Component } from 'react';
import { connect } from "react-redux";
import Util from 'utility';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading/index';

import GeneratorClientCalendar from 'components/Plan/GeneratorClientCalendar';
import { removeMeal } from 'actions';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class DialogGeneratorResults extends Component { 

    constructor(props) {
        super(props);
        let mealsToAdd = [];
        this.props.menu.menu.map(meal => {
            mealsToAdd.push(meal.mealId);
        });

        let clientId = false;
        if(typeof this.props.menu.client_id !== 'undefined') {
            clientId = this.props.menu.client_id * 1;
        }

        this.state = {
            loading: false,
            hash: this.props.menu.hash,
            mealsToAdd: mealsToAdd,
            removeMealIds: [],
            clientId: clientId,
        };
        this.onMealToAddChange = this.onMealToAddChange.bind(this);
        this.replacePlate = this.replacePlate.bind(this);
        this.replaceMeal = this.replaceMeal.bind(this);
        this.onRemoveMeal = this.onRemoveMeal.bind(this);
        this.onClientDateClick = this.onClientDateClick.bind(this);
        this.doImport = this.doImport.bind(this);
    }

    componentDidUpdate(prevProps) {
        // ATTN: WTF?
        if(this.state.loading === true) {
            this.setState({
                loading: false
            });
        }
    }

    xxxxxxxxxxcomponentWillReceiveProps() {
    }

    onMealToAddChange(e) {
        let value = e.target.value * 1;
        let mealsToAdd = this.state.mealsToAdd;
        mealsToAdd = mealsToAdd.filter(id => id !== value);
        if(e.target.checked) mealsToAdd.push(value);
        this.setState({
            mealsToAdd: mealsToAdd
        });
    }

    doImport() {
        this.props.onImport(this.state.mealsToAdd);        
    }

    onClientDateClick(e, date) {
        this.props.onClientDateClick(e, date);
    }

    replacePlate(e, mealId, recipeId) {
        this.setState({
            loading: true
        }, () => {
            this.props.regenerateOnePlate(mealId, recipeId);
        });
    }

    replaceMeal(e, id) {
        this.setState({
            loading: true
        }, () => {
            this.props.regenerateOneMeal(id);
        });
    }

    onRemoveMeal(e, id) {
        let { removeMealIds } = this.state;

        if(removeMealIds.includes(id)) {
            removeMealIds = removeMealIds.filter(i => i !== id);
        } else {
            removeMealIds.push(id);
        }

        this.setState({
            removeMealIds: removeMealIds
        });
    }

    render() {
        const { menu, data } = this.props;
        const { globals } = data;

        let nutritionTotals = {
            'kcal': 0,
            'kj': 0,
            'proteins': 0,
            'carbohydrates': 0,
            'fats': 0,
            'fiber': 0
        };

        // Get current profile day energy output

        let profileId = Util.getActiveProfileId();
        let profile = globals.profiles[profileId];
        let ee = data.energyOutputSettings[profileId];

        const coefficients = {
            '0': 1.2,
            '1': 1.3,
            '2': 1.4,
            '3': 1.5,
            '4': 1.6,
            '5': 1.7,
            '6': 1.8,
            '7': 1.9,
            '8':  2.1
        };

        let eCoefId;
        if(ee) {
            eCoefId = ee.coefficientId >= 0 ? ee.coefficientId : profile.energy_output_coefficient;
        } else {
            eCoefId = profile.energy_output_coefficient;
        }
        let eCoefficient = 1.0;
        if(typeof coefficients[eCoefId] !== 'undefined') eCoefficient = coefficients[eCoefId];

        const males = ['m', 'ms', 'b1', 'b2', 'b3', 'b4', 'b'];
        const females = ['f', 'fs', 'fp', 'fbf', 'g1', 'g2', 'g3', 'g4', 'g'];
        let gender = false;
        if(males.includes(profile.persona_key)) gender = 'm';
        if(females.includes(profile.persona_key)) gender = 'f';
        if(profile.persona_key_child_gender != '' && males.includes(profile.persona_key_child_gender)) gender = 'm';
        if(profile.persona_key_child_gender != '' && females.includes(profile.persona_key_child_gender)) gender = 'f';

        const birthD = new Date(profile.birthday);
        let ageDifMs = Date.now() - birthD.getTime();
        let ageDate = new Date(ageDifMs); // miliseconds from epoch
        let age = Math.abs(ageDate.getUTCFullYear() - 1970);

        let BMR = 0;
        if(gender == 'm') {
            BMR = 10 * profile.weight + 6.25 * profile.height - 5 * age + 5;
        }
        if(gender == 'f') {
            BMR = 10 * profile.weight + 6.25 * profile.height - 5 * age - 161;
        }
        
        if(profile.individual_bmr > 0) BMR = profile.individual_bmr;

        let energyOutputKcal = 0;
        if(ee) {
            if(ee.amount > 0) {
                energyOutputKcal = ee.amount;
                if(ee.unit == 'kj') {
                    energyOutputKcal = ee.amount / 4.184;
                }
            } else {
                energyOutputKcal = BMR * eCoefficient;
            }
        } else {
            energyOutputKcal = BMR * eCoefficient;
        }

        let energyOutputKj = parseFloat(energyOutputKcal * 4.184);

        //

        let content = [];
        menu.menu.map(meal => {

            let totalKcal = 0, totalKj = 0;
            let recipes = [];
            meal.recipes.map(recipe => {
                nutritionTotals.kcal += recipe.nutritionData.energy_kcal * 1;
                nutritionTotals.kj += recipe.nutritionData.energy_kj * 1;
                nutritionTotals.proteins += recipe.nutritionData.proteins * 1;
                nutritionTotals.carbohydrates += recipe.nutritionData.carbohydrates * 1;
                nutritionTotals.fats += recipe.nutritionData.fats * 1;
                nutritionTotals.fiber += recipe.nutritionData.fiber * 1;

                recipes.push(
                    <div className="recipe">
                        <div className="pic" style={{ backgroundImage: 'url(https://www.zofapp.cz/data/zof/' + recipe.data.picture + ')' }}>
                        </div>
                        <div className="info">
                            <h3>{recipe.data.title}</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Množství:</td>
                                        <td>{Math.ceil(recipe.nutritionData.weight)} g</td>
                                    </tr>
                                    <tr>
                                        <td>Energie:</td>
                                        <td>{Math.ceil(recipe.nutritionData.energy_kcal)} kcal, {Math.ceil(recipe.nutritionData.energy_kj)} kJ</td>
                                    </tr>
                                    <tr>
                                        <td>Bílkoviny:</td>
                                        <td>{recipe.nutritionData.proteins} g</td>
                                    </tr>
                                    <tr>
                                        <td>Sacharidy:</td>
                                        <td>{recipe.nutritionData.carbohydrates} g</td>
                                    </tr>
                                    <tr>
                                        <td>Tuky:</td>
                                        <td>{recipe.nutritionData.fats} g</td>
                                    </tr>
                                    <tr>
                                        <td>Vláknina:</td>
                                        <td>{recipe.nutritionData.fiber} g</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="opts">
                            <a onClick={(e) => this.replacePlate(e, meal.mealId, recipe.data.id)}>
                                <i className="fas fa-redo-alt"></i>
                            </a>
                        </div>                        
                    </div>
                );
                totalKcal += parseFloat(recipe.nutritionData.energy_kcal);
                totalKj += parseFloat(recipe.nutritionData.energy_kj);                
            });

            if(!this.state.removeMealIds.includes(meal.mealId)) {
                content.push(
                    <div className="meal">
                        <label>
                            <h2>
                                {data.globals.mealTypes[meal.mealId].title}
                                <span>{Math.ceil(totalKcal)} kcal, {Math.ceil(totalKj)} kJ</span>
                                <aside>
                                    <a onClick={(e) => this.replaceMeal(e, meal.mealId)}><i class="fas fa-redo-alt"></i> Vyměnit</a>
                                    <a onClick={(e) => this.onRemoveMeal(e, meal.mealId)}><i class="fas fa-times"></i> Vymazat</a>
                                </aside>
                            </h2>
                        </label>
                        <div className="recipes">
                            {recipes}
                        </div>
                    </div>
                );
            } else {
                content.push(
                    <div className="meal">
                        <label>
                            <h2>
                                {data.globals.mealTypes[meal.mealId].title}
                                <span>(nebude vloženo do jídelníčku)</span>
                                <aside>
                                    <a onClick={(e) => this.onRemoveMeal(e, meal.mealId)}><i class="fas fa-undo-alt"></i> Vrátit</a>
                                </aside>
                            </h2>
                        </label>
                        <div className="recipes" style={{opacity:'.2'}}>
                            {recipes}
                        </div>
                    </div>
                );
            }
        })
   
        let importPlanButton = false;

        if(Util.userHasPurchased('ZOF_PROFI')) {
            importPlanButton = (
                <>
                <div style={{maxWidth:'440px',margin:'0 auto'}}>
                    <p style={{textAlign:'center'}}>Vložit do plánovače na den:</p>
                    <GeneratorClientCalendar monthData={this.props.monthData} clientId={this.state.clientId} onClientDateClick={this.onClientDateClick} />
                </div>
                <button style={{ cursor:'pointer', background: '#4eb32d', color: '#fff', padding: '.7em 2em', border: 'none'}} type="button" onClick={this.doImport}>Vložit do plánovače</button>
                <span style={{margin:'0 1em'}}>nebo</span>
                <button style={{ cursor:'pointer', background: '#ccc', color: '#222', padding: '.7em 2em', border: 'none'}} type="button" onClick={() => this.props.regenerate()}>Vygenerovat jiný</button>
                </>
            );
        } else {
            if(Util.userHasPurchased('ZOF_COOKBOOK')) {
                importPlanButton = (
                    <>
                    <button style={{ cursor:'pointer', background: '#4eb32d', color: '#fff', padding: '.7em 2em', border: 'none'}} type="button" onClick={this.doImport}>Vložit to dnešního plánu</button>
                    <span style={{margin:'0 1em'}}>nebo</span>
                    <button style={{ cursor:'pointer', background: '#ccc', color: '#222', padding: '.7em 2em', border: 'none'}} type="button" onClick={() => this.props.regenerate()}>Vygenerovat jiný</button>
                    </>
                );
            } else {
                importPlanButton = <span><Link style={{ color: '#4eb32d' }} to="/cookbook/buy/cookbook">Pořiďte si ZOF kuchařku a nechte aplikaci plánovat jídelníček za Vás!</Link></span>
            }
        }

        //

        for(let k in nutritionTotals) {
            nutritionTotals[k] = Math.round((nutritionTotals[k] + Number.EPSILON) * 100) / 100            
        }

        nutritionTotals.kcal = Math.round(nutritionTotals.kcal);
        nutritionTotals.kj = Math.round(nutritionTotals.kj);

        energyOutputKcal = Math.round(energyOutputKcal);
        energyOutputKj = Math.round(energyOutputKj);

        const energyBalanceKcal = nutritionTotals.kcal - energyOutputKcal;
        const energyBalanceKj = nutritionTotals.kj - energyOutputKj;

        let generatorResultsProfiInfo = false;
        if(typeof menu.info.regimes === 'string') {
            let energRegime = 'Vyrovnaný';
            if(menu.info.energetic_regime == '1') energRegime = 'Hubnoucí';
            generatorResultsProfiInfo = (
                <div className="generator-results-info">
                    <table>
                        <tbody>
                            <tr><td>Chody:</td><td>{menu.info.meals_num} ({menu.info.meals})</td></tr>
                            <tr><td>Stravovací režim:</td><td>{menu.info.regimes}</td></tr>
                            <tr><td>Energetický režim:</td><td>{energRegime}</td></tr>
                        </tbody>
                    </table>
                </div>
            );
        }


        let result = (
            <React.Fragment>
            {generatorResultsProfiInfo}
            <div className="generator-meals">
                {content}
            </div>
            <div className="generator-evaluation">
                <h2>Nutriční hodnoty</h2>
                <div className="row">
                    <div className="col">
                        <div>
                            <p>Energetický příjem</p>
                            <strong>{nutritionTotals.kcal} kcal / {nutritionTotals.kj} kJ</strong>
                        </div>
                        <div>
                            <p>Energetický výdej</p>
                            <strong>{energyOutputKcal} kcal / {energyOutputKj} kJ</strong>
                        </div>
                        <div>
                            <p>Energetická bilance</p>
                            <strong>{energyBalanceKcal} kcal / {energyBalanceKj} kJ</strong>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <p>Bílkoviny</p>
                            <strong>{nutritionTotals.proteins} g</strong>
                        </div>
                        <div>
                            <p>Sacharidy</p>
                            <strong>{nutritionTotals.carbohydrates} g</strong>
                        </div>
                        <div>
                            <p>Tuky</p>
                            <strong>{nutritionTotals.fats} g</strong>
                        </div>
                        <div>
                            <p>Vláknina</p>
                            <strong>{nutritionTotals.fiber} g</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div className="generator-action">
                <p style={{ textAlign: 'center', margin: '2em 0' }}>
                    {importPlanButton}
                </p>
            </div>
            </React.Fragment>
        );

        if(this.props.loadingGenerator === true) result = <p>...</p>;

        let loadingOverlay = false;
        if(this.state.loading) {
            loadingOverlay = <div style={{position:'absolute',left:'0',top:'0',width:'100%',height:'100%',zIndex:'10',background:'rgba(255,255,255,.6)'}}></div>;
        }

        return (
            <div style={{position:'relative'}}>
            {result}
            {loadingOverlay}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogGeneratorResults);
