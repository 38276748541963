import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { format } from "date-fns";
import localeCs from 'date-fns/locale/cs';
import Loading from 'components/Loading/index';
import store from 'store/index';
import api from 'api';
import Util from 'utility';

import FoodRow from 'components/Recipe/RecipeEditor/FoodRow/index';
import EvaluationPanelContents from 'components/EvaluationPanel/EvaluationPanelContents';

import { 
    loadDayPlan, 
    loadDayPlanCallback,
} from '../../actions/index';

const mapStateToProps = state => {
    return { data: state };
};


const mapDispatchToProps = dispatch => {
    return {
        loadDayPlan: data => dispatch(loadDayPlan(data)),
        loadDayPlanCallback: data => dispatch(loadDayPlanCallback(data)),
    };
};

class DialogLongTermEvaluation extends Component { 

    constructor(props) {
        super(props);

        let now = Date.now();
        let initialStartDate = new Date(now - 1000*60*60*24*7);
        let initialEndDate = new Date(now);

        this.state = {
            startDate: initialStartDate,
            endDate: initialEndDate,
            graphType: 'bar',
            startDateRaw: '',
            endDateRaw: '',
            invalidDates: false,
            evaluationResult: false,
            dayDetailDate: false,
            dayState: {},
            dayEvaluation: {},
            selectedAttribute: '',
            viewType: 'evaluation',     // 'evaluation' or 'day-detail'
            loading: false,
        };
        this.yyyymmdd = this.yyyymmdd.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleGoToDateClick = this.handleGoToDateClick.bind(this);
        this.handleDateClick = this.handleDateClick.bind(this);
        this.handleGraphTypeClick = this.handleGraphTypeClick.bind(this);
        this.generateLongTermEvaluation = this.generateLongTermEvaluation.bind(this);
    }
    
    handleDateClick(e) {
        let el = e.target.closest("RECT");
        if(!el) {
            el = e.target.closest('rect');
        }
        if (el && e.currentTarget.contains(el)) {
            if(typeof el.dataset['date'] !== 'undefined') {

                let attribute = el.dataset['attribute'];

                let dateRaw = el.dataset['date'];
                let y = dateRaw.substr(0,4);
                let m = dateRaw.substr(5,2) - 1;
                let d = dateRaw.substr(8,2);
                var D = new Date(y,m,d);

                let state = store.getState();
                let stateSnapshot = Object.assign({}, state);
                stateSnapshot.selectedProfileId = Object.keys(state.globals.profiles)[0];
                delete stateSnapshot.globals;
                delete stateSnapshot.modalContent;
                delete stateSnapshot.evaluationResults;
            
                this.setState({
                    loading: true,
                    selectedAttribute: attribute,
                    dayDetailDate: D
                }, () => {
                    const dayFmt = '' + D.getFullYear() + '-' + (D.getMonth() + 1) + '-' + D.getDate();
                    api('load/dayplan?date=' + dayFmt + '&attribute=' + attribute).then(result => {

                        stateSnapshot = Object.assign({}, result.data.state);
                        stateSnapshot.selectedProfileId = Object.keys(state.globals.profiles)[0];
                        delete stateSnapshot.globals;
                        delete stateSnapshot.modalContent;
                        delete stateSnapshot.evaluationResults;

                        this.setState({
                            dayState: result.data,
                        }, () => {
                            api('evaluation/menu', { post: true, data: stateSnapshot } ).then(result => {
                                this.setState({
                                    loading: false,
                                    dayEvaluation: result.data,
                                    viewType: 'day-detail'
                                });
                            });
                        });
                    });   
                });         

                // this.props.gotoDate(D);
            }
        }
    }

    handleGoToDateClick(e) {
        this.props.gotoDate(this.state.dayDetailDate);
    }

    handleGraphTypeClick(e) {
        this.setState({
            graphType: e.target.value
        });
    }

    handleDateChange(e) {
        let lastStartDate = this.state.startDate;
        let lastEndDate = this.state.endDate;

        let newStartDate = lastStartDate;
        let newEndDate = lastEndDate;
        let startDateRaw = this.state.startDateRaw;
        let endDateRaw = this.state.endDateRaw;

        if(e.target.name === 'date-start') {
            startDateRaw = e.target.value;
            newStartDate = new Date(e.target.value)
        }
        if(e.target.name === 'date-end') {
            endDateRaw = e.target.value;
            newEndDate = new Date(e.target.value)
        }

        newStartDate.setHours(0, 0, 1, 0);
        newEndDate.setHours(0, 0, 1, 0);

        if(newStartDate >= newEndDate) {
            this.setState({
                invalidDates: true
            });
            return;
        }

        this.setState({
            invalidDates: false,
            startDate: newStartDate,
            endDate: newEndDate,
            startDateRaw: startDateRaw,
            endDateRaw: endDateRaw
        })
    }


    yyyymmdd(date) {
    
        // year
        let yyyy = '' + date.getFullYear();
    
        // month
        let mm = ('0' + (date.getMonth() + 1));  // prepend 0 // +1 is because Jan is 0
        mm = mm.substr(mm.length - 2);                  // take last 2 chars
    
        // day
        let dd = ('0' + date.getDate());         // prepend 0
        dd = dd.substr(dd.length - 2);                  // take last 2 chars
    
        return yyyy + "-" + mm + "-" + dd;
    }    

    generateLongTermEvaluation() {
        let dateFrom = this.yyyymmdd(this.state.startDate); // this.state.startDate.toISOString().split('T')[0];
        let dateTo = this.yyyymmdd(this.state.endDate); //this.state.endDate.toISOString().split('T')[0];
        const profileId = Util.getActiveProfileId();

        api('evaluation/long-term', {post: true, data: {startDate:dateFrom, endDate:dateTo, profileId: profileId, type: this.state.graphType}}).then(result => {
            this.setState({
                evaluationResult: result.data
            });
        });
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = data;

        let submitButton = <button className="btn-primary" onClick={this.generateLongTermEvaluation}>Zobrazit vyhodnocení</button>;

        let startDateValid = state.startDate instanceof Date && !isNaN(state.startDate);
        let endDateValid = state.endDate instanceof Date && !isNaN(state.endDate);
        if(!startDateValid || !endDateValid) {      // ATTN refactor this so i dont have to write the form twice (see below)
            submitButton = <span style={{ color: 'red' }}>Neplatné datum</span>;
        }

        let invalidDateRangeStyle = {width:'10em'};
        if(state.invalidDates) {
            submitButton = <span style={{ color: 'red' }}>Neplatné časové rozpětí</span>;
            invalidDateRangeStyle = {width:'10em',background:'#fee'};
        }

        let startDateIso = 0;
        if(state.startDate instanceof Date && !isNaN(state.startDate)) {
            startDateIso = state.startDate.toISOString().substr(0,10);
        }

        let endDateIso = 0;
        if(state.endDate instanceof Date && !isNaN(state.endDate)) {
            endDateIso = state.endDate.toISOString().substr(0,10);
        }

        let results = false;
        if(this.state.evaluationResult) {
            results = <div onClick={(e) => this.handleDateClick(e)} dangerouslySetInnerHTML={{ __html: this.state.evaluationResult.html }}></div>;
        }

        let content = false;
    
        if(state.viewType == 'evaluation') {
            content = (
                <React.Fragment>
                    <div className="longterm-evaluation-row">
                        <div className="col">
                            <div className="input-row">
                                <div className="form-inline-span">
                                    <span>Od:</span>
                                    <input style={invalidDateRangeStyle} type="date" name="date-start" defaultValue={startDateIso} onChange={this.handleDateChange} />
                                </div>
                                <div className="form-inline-span">
                                    <span>Do:</span>
                                    <input style={invalidDateRangeStyle} type="date" name="date-end" defaultValue={endDateIso} onChange={this.handleDateChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <label><input checked={this.state.graphType == 'bar'} onClick={this.handleGraphTypeClick} type="radio" name="type" value="bar" /> sloupcový</label>
                            <label><input checked={this.state.graphType == 'line'} onClick={this.handleGraphTypeClick} type="radio" name="type" value="line" /> čárový</label>
                        </div>
                        <div className="col">
                            {submitButton}
                        </div>
                    </div>
                {results}
                </React.Fragment>
            );
        
        } else {
            // day-detail

            let dayState = this.state.dayState.state;
            let mealsList = [];
            for(let m in dayState.meals) {
    
                let foodGroups = [];
                for(let g in dayState.groups[m]) {
                    let group = dayState.groups[m][g];
                    group.id = g;
                    
                    let foods = [];
                    for(let f in dayState.foods[m]) {
                        let food = dayState.foods[m][f];
                        if(food.group_id != group.id) continue;
    
                        food.food_id = food.item_id;        // ATTN - WTF
                        food.image = `https://www.zofapp.cz/data/zof/item/${food.food_id}.svg`;

                        foods.push(
                            <FoodRow food={food} key={food.id} readOnly={true} />
                        );
                    }   
                    foodGroups.push(
                        <div className="day-overview-foodgroup">
                            <h3>{globals.foodGroupTypes[group.type]}: {group.title}</h3>
                            {foods}
                        </div>
                    );
                }
    
                let meal = dayState.meals[m];
    
                let mealProfiles = [];
                meal.profile_ids.split(',').map((profileId) => {
                    let profileName = globals.profiles[profileId].name;
                    if(profileName && profileName != '') {
                        let profileColor = globals.profiles[profileId].color;
                        if (profileColor == '' || !profileColor) profileColor = '999999';
                        let profileStyle = {
                            color: '#fff',
                            padding: '3px 5px',
                            borderRadius: '3px',
                            marginRight: '.25em',
                            backgroundColor: '#' + profileColor
                        };
                        mealProfiles.push(<span style={profileStyle}>{profileName}</span>);
                    }
                });
    
                let sh = '' + meal.startHour;
                if(sh.length < 2) sh = '0' + sh;
    
                let sm = '' + meal.startMinute;
                if(sm.length < 2) sm = '0' + sm;
    
                mealsList.push(
                    <div className="day-overview-meal">
                        <h2>
                            <b>{sh}:{sm} | {globals.mealTypes[meal.meal_id].title}</b>
                            {'\u00A0'}
                            <span>{mealProfiles}</span>
                        </h2>
                        {foodGroups}
                    </div>
                );
            }
    
            if(mealsList.length == 0) {
                mealsList = <p>Nemáte zadány žádné chody / recepty.</p>
            }
            
            let sortedAttributeFoodsContents = false;

            if(1==2 && typeof dayState.attributeFoodContents !== 'undefined') {

                let sortedFoods = [];
                dayState.attributeFoodContents.map(fc => {

                    let foodTitle = '???';
                    if(typeof this.props.data.globals.itemData[fc.id] !== 'undefined') {
                        foodTitle = this.props.data.globals.itemData[fc.id].title;
                    }

                    sortedFoods.push(
                        <tr><td>{foodTitle}:</td><td>{fc.content} {globals.attribute_units[state.selectedAttribute]}</td></tr>
                    );
                })

                sortedAttributeFoodsContents = (
                    <>
                        <h2>Potraviny podle obsahu látky</h2>
                        <table className="table-foods-contents">
                            <thead>
                                <th>Potravina</th>
                                <th>Obsah látky</th>
                            </thead>
                            <tbody>
                                {sortedFoods}
                            </tbody>
                        </table>
                    </>
                );
            }



            let dateText = format(state.dayDetailDate, 'EEEE d. M. yyyy', {locale: localeCs});

            content = (
                <>
                    <p style={{margin:'.5em 0 1 0',fontSize:'.85em',display:'flex'}}>
                    <a style={{background:'#4eb32d',color:'#fff',borderRadius:'4px',padding:'.5em 1.25em',textDecoration:'none'}} onClick={() => this.setState({selectedAttribute: '', viewType: 'evaluation'})}><i className="fa fa-angle-left"></i> Zpět</a>
                    <a style={{marginLeft:'auto',background:'#4eb32d',color:'#fff',borderRadius:'4px',padding:'.5em 1.25em',textDecoration:'none'}} onClick={this.handleGoToDateClick}><i className="fa fa-list"></i> Otevřít v plánovači</a>
                    </p>
                    <h2 style={{margin:'0 0 1em 0',fontSize:'1.6em'}}>Detail dne {dateText}</h2>
                    <div style={{display:'flex'}}>
                        <div>
                            {mealsList}
                            {sortedAttributeFoodsContents}
                        </div>
                        <div style={{flexBasis:'300px',flexShrink:'0'}}>
                            <EvaluationPanelContents 
                                wholeDay={true} 
                                mainStyle={{display:'block'}} 
                                results={state.dayEvaluation.evaluation} 
                                ddd={state.dayEvaluation.ddd} 
                                quickresults={false} 
                                />
                        </div>
                    </div>
                </>
            );
        }

        if(state.loading === true) content = <Loading />;

        return content;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogLongTermEvaluation));
