import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { getQueriesForElement } from '@testing-library/react';
import { findDOMNode } from 'react-dom';
import shortid from 'shortid';
import store from 'store/index';
import { format } from "date-fns";
import api from 'api';
import UnitConvert from 'UnitConverter';
import Util from 'utility';
import { Locale, __ } from 'locale';

import { 
    setActiveMealHash, 
    clearEvaluationResults, 
    addMeal, 
    removeMeal, 
    updateMeal, 
    addActivity, 
    removeActivity, 
    addTraining,
    updateTraining,
    removeTraining,
    openModal, 
    closeModal, 
    showMessage,
    setData,
    removeFood,
    sortFood,
    setFoodAmount,
    moveFoodToPlate,
    addFoodGroup,
    changeFoodGroupType,
    setFoodGroupTitle,
    moveFoodGroup,
    removeFoodGroup,
    modifyFoodGroupAmounts,
    setFoodAsDrink,
    addFood
} from "../../../actions/index";

import OutsideAlerter from 'components/OutsideAlerter';
import DialogNewMeal from '../DialogNewMeal';
import LectureTaskMealDescription from './LectureTaskMealDescription';
import LectureTaskPanelAddFood from './LectureTaskPanelAddFood';
import LectureTaskPanelAddRecipe from './LectureTaskPanelAddRecipe';
import LectureTaskPanelSave from './LectureTaskPanelSave';
import EvaluationPanelContents from 'components/EvaluationPanel/EvaluationPanelContents';
import TaskEvaluator from 'components/Plan/LectureTask/TaskEvaluator';
import LectureTaskPanelFoodModifications from './LectureTaskPanelFoodModifications';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        setActiveMealHash: data => dispatch(setActiveMealHash(data)),
        clearEvaluationResults: data => dispatch(clearEvaluationResults(data)),
        addMeal: data => dispatch(addMeal(data)),
        removeMeal: data => dispatch(removeMeal(data)),
        updateMeal: data => dispatch(updateMeal(data)),
        addActivity: data => dispatch(addActivity(data)),
        removeActivity: data => dispatch(removeActivity(data)),
        addTraining: data => dispatch(addTraining(data)),
        updateTraining: data => dispatch(updateTraining(data)),
        removeTraining: data => dispatch(removeTraining(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
        setData: data => dispatch(setData(data)),
        sortFood: data => dispatch(sortFood(data)),
        removeFood: data => dispatch(removeFood(data)),
        setFoodAmount: data => dispatch(setFoodAmount(data)),
        moveFoodToPlate: data => dispatch(moveFoodToPlate(data)),
        addFoodGroup: data => dispatch(addFoodGroup(data)),
        changeFoodGroupType: data => dispatch(changeFoodGroupType(data)),
        setFoodGroupTitle: data => dispatch(setFoodGroupTitle(data)),
        moveFoodGroup: data => dispatch(moveFoodGroup(data)),
        removeFoodGroup: data => dispatch(removeFoodGroup(data)),
        modifyFoodGroupAmounts: data => dispatch(modifyFoodGroupAmounts(data)),
        setFoodAsDrink: data => dispatch(setFoodAsDrink(data)),
    };
};

class LectureTaskDayPlan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: this.props.date,
            showMealTypeModal: false,
            selectedMealId: 0,
            modalX: 0,
            modalY: 0,
            addMealSelectedMealId: 1,
            addMealSelectedHour: 0,
            addMealSelectedMinute: 0,
            addMealSelectedProfiles: [],
            editMealSelectedMealHash: '',
            editMealSelectedMealId: 1,
            editMealSelectedHour: 0,
            editMealSelectedMinute: 0,
            editMealSelectedProfiles: [],

            mealSettingsHash: '',
            mealAddHash: '',
            addFoodOrRecipeHash: '',
            foodGroupSettingsHash: '',
            foodGroupTypeSettingsHash: '',
            foodGroupVisibility: {},
            adjustAmountId: '',

            moveFoodToGroupMealHash: '',
            moveFoodToGroupId: 0,
            moveFoodToGroupTargetId: '',

            foodModificationsPanelId: '',
            actualFoodModifications: {}, // must be in state because panel is initialized only once

            foodGroupAmountChangePercent: 100,
            
            focusedAmountInputId: 0,
            focusedAmountInputValue: '',

            editTrainingSelectedId: '',
            editTrainingSelectedDescription: 1,
            editTrainingSelectedLength: 0,
            editTrainingSelectedHour: 0,
            editTrainingSelectedMinute: 0,

            currentMouseOverHour: -1,
            currentMouseOverMinute: 0,
            selectedMinute: 0,

            profiMealHash: '',              // ZOF Profi: hash of meal to add comment to
            profiMealDate: '',              // ZOF Profi: current date (redundant - can be taken from data.plannerDate, but...)
            profiMealComment: '',           // ZOF Profi: text of meal comment
        };

        this.handleEditMeal = this.handleEditMeal.bind(this);
        this.handleEditMealSelectType = this.handleEditMealSelectType.bind(this);
        this.handleEditMealSelectHour = this.handleEditMealSelectHour.bind(this);
        this.handleEditMealSelectMinute = this.handleEditMealSelectMinute.bind(this);
        this.doUpdateMeal = this.doUpdateMeal.bind(this);

        this.handleDeleteMeal = this.handleDeleteMeal.bind(this);
        this.doDeleteMeal = this.doDeleteMeal.bind(this);

        this.handleAddMealSelectType = this.handleAddMealSelectType.bind(this);
        this.handleAddMealSelectHour = this.handleAddMealSelectHour.bind(this);
        this.handleAddMealSelectMinute = this.handleAddMealSelectMinute.bind(this);
        this.onAddMealClick = this.onAddMealClick.bind(this);
        this.doAddMealType = this.doAddMealType.bind(this);

        this.toggleMealSettings = this.toggleMealSettings.bind(this);
        this.toggleAddFoodGroup = this.toggleAddFoodGroup.bind(this);
        this.toggleFoodGroupSettings = this.toggleFoodGroupSettings.bind(this);
        this.toggleFoodGroupTypeSettings = this.toggleFoodGroupTypeSettings.bind(this);
        this.saveToMyRecipes = this.saveToMyRecipes.bind(this);        
        this.editMealDescription = this.editMealDescription.bind(this);
        this.toggleFoodGroupVisibility = this.toggleFoodGroupVisibility.bind(this);
        this.openPanelAddFood = this.openPanelAddFood.bind(this);   
        this.openPanelAddRecipe = this.openPanelAddRecipe.bind(this);   
        this.openPanelAddFoodGroup = this.openPanelAddFoodGroup.bind(this);
        this.handleFoodAmountInputFocus = this.handleFoodAmountInputFocus.bind(this);
        this.handleFoodAmountInputBlur = this.handleFoodAmountInputBlur.bind(this);
        this.handleFoodAmountChange = this.handleFoodAmountChange.bind(this);
        this.sortFoodInRecipe = this.sortFoodInRecipe.bind(this);
        this.openFoodModificationsPanel = this.openFoodModificationsPanel.bind(this);
        this.removeFoodFromRecipe = this.removeFoodFromRecipe.bind(this);
        this.loadFoodDescription = this.loadFoodDescription.bind(this);
        this.moveFoodToAnotherGroup = this.moveFoodToAnotherGroup.bind(this);
        this.changeFoodGroupAmount = this.changeFoodGroupAmount.bind(this);
        this.changeFoodGroupType = this.changeFoodGroupType.bind(this);
        this.changeFoodGroupTitle = this.changeFoodGroupTitle.bind(this);
        this.showAlergenInfo = this.showAlergenInfo.bind(this);
        this.moveFoodGroup = this.moveFoodGroup.bind(this);
        this.deleteFoodGroup = this.deleteFoodGroup.bind(this);
        this.handleFoodGroupAmountChange = this.handleFoodGroupAmountChange.bind(this);
        this.doUpdateFoodGroupAmount = this.doUpdateFoodGroupAmount.bind(this);
        this.showMealEvaluation = this.showMealEvaluation.bind(this);
        this.handleDrinkChange = this.handleDrinkChange.bind(this);

        this.saveProgress = this.saveProgress.bind(this);
        this.saveAndUpdateEvaluation = this.saveAndUpdateEvaluation.bind(this);
    
        this.fmtEvalValue = this.fmtEvalValue.bind(this);
    }


    showAlergenInfo(e, alerg) {
        this.props.openModal({
            'title': 'Alergen', 
            'content': (
                <>
                <p>{alerg}</p>
                </>
            )
        });
    }



    handleEditMeal(id, e) {
        this.setState({
            editMealSelectedMealHash: id,
            editMealSelectedHour: this.props.data.meals[id].startHour,
            editMealSelectedMinute: this.props.data.meals[id].startMinute,
            editMealSelectedMealId: this.props.data.meals[id].mealId,
            editMealSelectedProfiles: this.props.data.meals[id].profiles
        }, () => {

            let meal = this.props.data.meals[id];

            let hours = [];
            let minutes = [];
            for (let i = 0; i <= 24; i++) {
                hours.push(i);
            }
            for (let i = 0; i < 60; i++) {
                minutes.push(i);
            }

            let mealTypes = [];
            for (let i in this.props.data.globals.mealTypes) {
                mealTypes.push(<option value={i} key={i}>{this.props.data.globals.mealTypes[i].title}</option>);
            }

            let profilesList = [];
            for (let profileId in this.props.data.globals.profiles) {
                profileId = profileId * 1;
                let checked = this.state.editMealSelectedProfiles.includes(profileId);
                profilesList.push(
                    <label>
                        <input type="checkbox" defaultChecked={checked} data-profile-id={profileId} onClick={this.handleEditSelectProfiles} />
                        <span>{this.props.data.globals.profiles[profileId].name}</span>
                    </label>
                );
            }

            let typeSelectElement = (
                <select name="select-meal-type" defaultSelected={meal.mealId} onChange={this.handleEditMealSelectType}>
                    {mealTypes}
                </select>
            );

            if(Util.isMealDrinkRegime(id)) {
                let x = id.split('|');
                x = x[0] * 1;
                typeSelectElement = <p><b>{this.props.data.globals.mealTypes[x].title}</b></p>;
            }
            const typeBlocked = !Util.isMealDrinkRegime(id);

            this.props.openModal({
                title: 'Upravit chod',
                content: (
                    <table className="generic-table">
                        <tbody>
                            <tr>
                                <th>Typ chodu:</th>
                                <td>{typeSelectElement}</td>
                            </tr>
                            <tr>
                                <th>Čas:</th>
                                <td><select name="select-time-start-h" defaultValue={meal.startHour} onChange={this.handleEditMealSelectHour}>
                                    {hours.map(n => {
                                        return <option value={n} key={n}>{n}</option>;
                                    })};
                                            </select>
                                    {'\u00A0'}:{'\u00A0'}
                                    <select name="select-time-start-m" defaultValue={meal.startMinute} onChange={this.handleEditMealSelectMinute}>
                                        {minutes.map(n => {
                                            return <option value={n} key={n}>{n}</option>;
                                        })};
                            </select>
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <td><button className="btn-primary" onClick={() => this.doUpdateMeal()}>Uložit změny</button></td>
                            </tr>
                        </tbody>
                    </table>
                )
            });
        });
    }

    handleEditMealSelectType(e) {
        this.setState({
            editMealSelectedMealId: e.target.value
        });
    }

    handleEditMealSelectHour(e) {
        this.setState({
            editMealSelectedHour: e.target.value
        });
    }

    handleEditMealSelectMinute(e) {
        this.setState({
            editMealSelectedMinute: e.target.value
        });
    }

    doUpdateMeal() {
        const params = {
            mealHash: this.state.editMealSelectedMealHash,
            mealId: this.state.editMealSelectedMealId,
            mealLength: 0, //this.state.addMealSelectedMealLength,
            startHour: this.state.editMealSelectedHour,
            startMinute: this.state.editMealSelectedMinute,
            profiles: this.state.editMealSelectedProfiles
        };
        this.props.updateMeal(params);
        this.props.onChange();
        this.props.closeModal();
    }





    handleDeleteMeal(e, id) {
        this.props.openModal({
            'title': __('Vymazat chod'), 
            'content': (
                <>
                <p>{__('Opravdu vymazat chod?')}</p>
                <table className="generic-table">
                    <tbody>
                        <tr>
                            <th></th>
                            <td>
                                <button className="btn-primary" onClick={() => this.doDeleteMeal(id)}>{__('Ano, vymazat chod')}</button>
                                <a className="link-cancel" onClick={this.props.closeModal}>{__('Zpět')}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </>
            )
        });
    }

    doDeleteMeal(id) {
        this.props.closeModal();
        this.props.removeMeal(id);
        this.saveAndUpdateEvaluation();
    }

    handleAddMealSelectType(e) {
        this.setState({
            addMealSelectedMealId: e.target.value
        });
    }

    handleAddMealSelectHour(e) {
        this.setState({
            addMealSelectedHour: e.target.value
        });
    }

    handleAddMealSelectMinute(e) {
        this.setState({
            addMealSelectedMinute: e.target.value
        });
    }

    onHourMouseMove(e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;

        let hour = e.target.attributes.getNamedItem('data-hour').value * 1;
        let height = this[`refHour${hour}`].clientHeight;
        let clickY = e.nativeEvent.offsetY;
        let minute = Math.round((clickY / height) * 60);
        minute = Math.round(minute / 5) * 5;
        if (minute < 0) minute = 0;
        if (minute > 55) minute = 55;

        this.setState({
            currentMouseOverHour: hour,
            currentMouseOverMinute: minute
        });
    }

    onHourMouseLeave(e) {
        this.setState({
            currentMouseOverHour: -1,
            currentMouseOverMinute: 0
        });
    }

    onAddMealClick(e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;

        let hour = e.target.attributes.getNamedItem('data-hour').value * 1;
        let height = this[`refHour${hour}`].clientHeight;
        let clickY = e.nativeEvent.offsetY;
        let minute = Math.round((clickY / height) * 60);
        minute = Math.round(minute / 5) * 5;

        let title = 'Přidat chod, pitný režim nebo aktivitu';
        if(Util.getLang() == 'sk') {
            title = 'Pridať chod, pitný režim alebo aktivitu';
        }

        this.props.openModal({
            'title': title, 
            'content': (
                <DialogNewMeal selectedHour={hour} selectedMinute={minute} onAddMeal={this.doAddMealType}/>
            )
        });
    }

    doAddMealType(data) {
        if(data.type == 'meal') {
            const params = {
                mealId: data.mealId,
                mealLength: 0,
                startHour: data.hour,
                startMinute: data.minute,
                profiles: [Util.getActiveProfileId()]
            };

            let hash = params.mealId + '|' + params.profiles.sort((a, b) => a - b).join(',');
            if(typeof this.props.data.meals[hash] !== "undefined") {
                alert("Tento chod s vybranými profily už máte přidán. Zvolte prosím jiný typ chodu, nebo jiný profil / profily.");
                return;
            }
            this.props.addMeal(params);
        }

        if(data.type == 'drink_regime') {

            let profileId = Util.getActiveProfileId();
            if(typeof this.props.data.appModeData.profileId === 'number') {
                profileId = this.props.data.appModeData.profileId;
            }

            const params = {
                mealId: 50,     // First ID of drink regime meal types
                mealLength: 0,
                startHour: data.hour,
                startMinute: data.minute,
                profiles: [profileId]
            };
            this.props.addMeal(params);
        }

        if(data.type == 'activity') {
            const params = {
                start: data.hour + ':' + data.minute,
                length: data.activityLength,
                description: data.activityTitle,
                profiles: [Util.getActiveProfileId()]
            };
            this.props.addTraining(params);
        }

        this.props.closeModal();
        this.saveAndUpdateEvaluation();
    }

    toggleMealSettings(m) {
        let mres = m;
        if(this.state.mealSettingsHash === m) mres = '';
        this.setState({
            mealSettingsHash: mres,
            mealAddHash: '',
            foodGroupSettingsHash: '',
            foodGroupTypeSettingsHash: '',
            addFoodOrRecipeHash: '',
        });
    }

    toggleAddFoodGroup(m) {
        let mres = m;
        if(this.state.mealAddHash === m) mres = '';
        this.setState({
            mealAddHash: mres,
            mealSettingsHash: '',
            foodGroupSettingsHash: '',
            foodGroupTypeSettingsHash: '',
            addFoodOrRecipeHash: '',
        });
    }

    toggleFoodGroupSettings(fg) {
        let fgres = fg;
        if(this.state.foodGroupSettingsHash === fg) fgres = '';
        this.setState({
            foodGroupSettingsHash: fgres,
            foodGroupTypeSettingsHash: '',
            mealSettingsHash: '',
            mealAddHash: '',
            addFoodOrRecipeHash: '',
        });
    }

    toggleFoodGroupTypeSettings(fg) {
        let fgres = fg;
        if(this.state.foodGroupTypeSettingsHash === fg) fgres = '';
        this.setState({
            foodGroupTypeSettingsHash: fgres,
            foodGroupSettingsHash: '',
            mealSettingsHash: '',
            mealAddHash: '',
            addFoodOrRecipeHash: '',
        });
    }

    toggleAddFoodOrRecipe(fg) {
        let fgres = fg;
        if(this.state.addFoodOrRecipeHash === fg) fgres = '';
        this.setState({
            addFoodOrRecipeHash: fgres,
            foodGroupTypeSettingsHash: '',
            foodGroupSettingsHash: '',
            mealSettingsHash: '',
            mealAddHash: ''
        });
    }

    moveFoodToAnotherGroup(e, mealHash, food) {
        const { data } = this.props;

        this.setState({
            moveFoodToGroupId: food.id,
            moveFoodToGroupMealHash: mealHash
        });

        let foodGroupsOptions = [<option value="">Vyberte...</option>];
        for(let g in data.foodGroups[mealHash]) {
            let group = data.foodGroups[mealHash][g];
            let unnamedTitle = '';
            if(group.type === 'plate') unnamedTitle = 'Nepojmenovaný talíř';
            if(group.type === 'bowl') unnamedTitle = 'Nepojmenovaná miska';
            if(group.type === 'cup') unnamedTitle = 'Nepojmenovaný šálek';
            if(group.type === 'glass') unnamedTitle = 'Nepojmenovaná sklenice';
            let fgTitle = group.title;
            if(fgTitle === '') fgTitle = unnamedTitle;
            foodGroupsOptions.push(
                <option value={g}>{fgTitle}</option>
            );
        }

        let title = 'Přesunout potravinu';
        let submitTitle = 'Přesunout';
        if(Util.getLang() == 'sk') {
            title = 'Presunúť potravinu';
            submitTitle = 'Presunúť';
        }


        this.props.closeModal();
        this.props.openModal({
            'title': title, 
            'content': (
                <>
                <div style={{textAlign:'center'}}>
                    <p>{title} <b>{food.title}</b> do:</p>
                </div>
                <div className="dialog-content">
                    <p style={{textAlign:'center',lineHeight:'200%'}}>
                        <select onChange={(e) => this.setState({moveFoodToGroupTargetId: e.target.value})}>{foodGroupsOptions}</select><br />
                        <input type="button" onClick={(e) => this.doMoveFoodToPlate(e)} value={submitTitle} />
                    </p>
                </div>
                </>
            )
        });
    }

    doMoveFoodToPlate(e) {
        if(this.state.moveFoodToGroupTargetId === '') {
            alert("Vyberte cílový chod");
            return;
        }
        this.props.moveFoodToPlate({
            mealHash: this.state.moveFoodToGroupMealHash,
            foodId: this.state.moveFoodToGroupId,
            foodGroupId: this.state.moveFoodToGroupTargetId
        });
        this.props.closeModal();
        this.saveAndUpdateEvaluation();
    }

    saveToMyRecipes(e, m) {
        this.setState({
            mealSettingsHash: '',
            mealAddHash: '',
            foodGroupSettingsHash: '',
        });

        e.preventDefault();
        if (e.target != e.currentTarget) return;

        let currentRecipeId = 0;
        if(this.props.match.params.id !== 'undefined') {
            currentRecipeId = this.props.match.params.id;
        }

        this.props.closeModal();
        this.props.openModal({
            'title': __('Uložit recept'), 
            'class': 'dialog-big',
            'content': (
                <LectureTaskPanelSave mealHash={m} currentRecipeId={currentRecipeId} onSaved={this.props.closeModal} />
            )
        });
    }

    editMealDescription(e, m, fg) {
        this.setState({
            mealSettingsHash: '',
            mealAddHash: '',
            foodGroupSettingsHash: '',
        });

        e.preventDefault();
        if (e.target != e.currentTarget) return;
        this.props.closeModal();
        this.props.openModal({
            'title': __('Postup přípravy receptu'), 
            'class': 'dialog-flexible',
            'closeButton': __('Uložit a zavřít'),
            'onClose': this.saveProgress,
            'content': (
                <LectureTaskMealDescription mealHash={m} foodGroupId={fg} />
            )
        });
    }

    toggleFoodGroupVisibility(fg) {
        let fgv = this.state.foodGroupVisibility;
        if(typeof fgv[fg] === 'undefined') fgv[fg] = 1;
        fgv[fg] = 1 - fgv[fg];
        this.setState({
            foodGroupVisibility: fgv
        });
    }

    fmtEvalValue(v, precision) {
        if(precision === undefined) precision = 2;
        v = v * 1;
        return v.toFixed(precision);
    }

    openPanelAddFood(e, m, g) {
        if(e) {
            e.preventDefault();
            if (e.target != e.currentTarget) return;
        }

        const meal = this.props.data.meals[m];       

        this.props.closeModal();
        this.props.openModal({
            'title': __('Přidat potravinu'),
            'class': 'dialog-big',
            'content': (
                <LectureTaskPanelAddFood mealId={m} groupId={g.id} />
            )
        });
    }

    openPanelAddRecipe(e, m) {
        if(e) {
            e.preventDefault();
            if (e.target != e.currentTarget) return;
        }

        const meal = this.props.data.meals[m];       

        this.props.closeModal();
        this.props.openModal({
            'title': __('Přidat recept'),
            'class': 'dialog-big',
            'content': (
                <LectureTaskPanelAddRecipe mealId={m} onRecipeAdded={this.saveAndUpdateEvaluation} />
            )
        });
    }

    openPanelAddFoodGroup(e, m) {
        let g = shortid();
        this.props.addFoodGroup({
            mealHash: m,
            id: g,
            title: '',
            description: '',
            type: 'plate',
            opened: true
        });
        this.saveAndUpdateEvaluation();
    }

    
    handleFoodAmountInputFocus(e, food) {
        e.target.focus();
        e.target.select();
    
        this.setState({
            focusedAmountInputId: food.id,
            focusedAmountInputValue: parseFloat(food.weight) + ''
        });
    }

    handleFoodAmountInputBlur(e, mealHash, food) {
        let weight = e.target.value.replace(',', '.') * 1;
        let item = this.props.data.globals.itemData[food.food_id];

        if(isNaN(weight) || weight < item.weight_min || weight > item.weight_max) {
            alert("Neplatné množství potraviny - zadejte prosím množství od " + item.weight_min + " do " + item.weight_max + " " + item.weight_unit);
            weight = item.weight;
        }

        this.setState({
            foodWeight: weight,
            invalidWeight: false,
            focusedAmountInputId: 0,
            focusedAmountInputValue: '',
        }, () => {
            this.props.setFoodAmount({ 
                mealHash: mealHash,
                foodId: food.id, 
                weight: weight
            });
            this.saveAndUpdateEvaluation();
        });
    }

    handleFoodAmountChange(e) {
        this.setState({
            focusedAmountInputValue: e.target.value,
        });
    }

    sortFoodInRecipe(foodId, direction, m) {
        this.props.sortFood({foodId: foodId, direction: direction, mealHash: m});
        this.saveProgress();
    }

    removeFoodFromRecipe(e, mealHash, food) {
        this.props.removeFood({
            id: food.id,
            mealHash: mealHash
        });
        this.saveAndUpdateEvaluation();
    }

    openFoodModificationsPanel(e, m, f) {
        e.preventDefault();

        const data = this.props.data;
        const globals = data.globals;

        this.setState({
            actualFoodModifications: data.modifications
        });


        this.props.closeModal();
        this.props.openModal({
            'title': 'Úpravy potraviny ' + f.title,
            'class': 'dialog-flexible',
            'content': <LectureTaskPanelFoodModifications food={f} />
        });
    }

    loadFoodDescription(foodId, amount) {
        const amt = Math.round(amount * 1000);
        api('food/description-lt/' + foodId + '/' + amt + '/' + this.props.data.appModeData.lectureId).then(result => {
            result = result.data;
            if (result.error) return false;
            this.props.closeModal();
            this.props.openModal({
                'title': result.title, 
                'class': 'dialog dialog-flexible',
                'content': (
                    <div className="zof-food-description">
                        <div dangerouslySetInnerHTML={{ __html: result.description }} />
                    </div>
                )
            });
        });
    }

    changeFoodGroupAmount(e, mealHash, foodGroupId) {

        let title = 'Změnit množství potravin v chodu';
        if(Util.getLang() == 'sk') title = 'Zmeniť množstvo potravín v chode';

        this.props.closeModal();
        this.props.openModal({
            'title': title, 
            'content': (
                <div className="meal-item-group-change-amount">
                    <input type="text" defaultValue={this.state.foodGroupAmountChangePercent} onChange={(e) => this.handleFoodGroupAmountChange(e)} /><span>%</span>
                    <button onClick={() => this.doUpdateFoodGroupAmount(mealHash, foodGroupId)}><i className="fa fa-sync"></i></button>
                </div>
            )
        });
    }

    handleFoodGroupAmountChange(e) {
        this.setState({
            foodGroupAmountChangePercent: e.target.value
        });
    }

    doUpdateFoodGroupAmount(mealHash, foodGroupId) {
        let pct = this.state.foodGroupAmountChangePercent * 1;
        this.props.modifyFoodGroupAmounts({
            mealHash: mealHash,
            group_id: foodGroupId,
            coefficient: pct
        });
        this.saveAndUpdateEvaluation();
    }

    changeFoodGroupType(e, mealId, groupId, type) {
        this.props.changeFoodGroupType({
            mealHash: mealId,
            id: groupId,
            type: type
        });
        this.setState({
            foodGroupTypeSettingsHash: ''
        });
        this.saveAndUpdateEvaluation();
    }

    changeFoodGroupTitle(e, mealHash, foodGroupId) {
        this.props.setFoodGroupTitle({
            mealHash: mealHash,
            id: foodGroupId,
            title: e.target.value
        });
    }

    moveFoodGroup(e, mealHash, id, dir) {
        this.setState({
            foodGroupSettingsHash: ''
        });
        this.props.moveFoodGroup({
            mealHash: mealHash,
            id: id,
            direction: dir,
        })
    }

    deleteFoodGroup(e, mealHash, foodGroupId) {
        this.props.removeFoodGroup({
            mealHash: mealHash,
            id: foodGroupId
        });
        this.saveAndUpdateEvaluation();
    }


    saveProgress() {
        const { data } = this.props;

        this.setState({
            saving: true
        });

        if(data.titles[data.activeMealHash] == '') {
            let firstFoodGroupTitle = '';
            for(let g in data.foodGroups[data.activeMealHash]) {
                if(firstFoodGroupTitle == '') {
                    firstFoodGroupTitle = data.foodGroups[data.activeMealHash][g].title;
                }
            }
            data.titles[data.activeMealHash] = firstFoodGroupTitle;
        }

        let dateParsed = this.props.data.plannerDate;

        if(this.props.data.appMode === 'LECTURE') {
            dateParsed = new Date('2000-01-01');
        }

        let dateUTC = new Date(Date.UTC(
            dateParsed.getFullYear(),
            dateParsed.getMonth(),
            dateParsed.getDate(),
            dateParsed.getHours(),
            dateParsed.getMinutes(),
            dateParsed.getSeconds()
            ));

        let dayTimestamp = Math.floor(dateUTC.getTime() / 1000);

        let saveData = {};
        saveData.meals = JSON.stringify(data.meals);
        saveData.groups = JSON.stringify(data.foodGroups);
        saveData.foods = JSON.stringify(data.foods);
        saveData.modifications = JSON.stringify(data.modifications);
        saveData.titles = JSON.stringify(data.titles);
        saveData.desciptions = JSON.stringify(data.desciptions);
        saveData.activities = JSON.stringify(data.activities);
        saveData.trainings = JSON.stringify(data.trainings);
        saveData.dayTimestamp = JSON.stringify(dayTimestamp);
        saveData.energyOutputSettings = JSON.stringify(data.energyOutputSettings);
        saveData = JSON.stringify(saveData);

        api('save/dayplan', {post:true, data: saveData}).then(result => {
            result = result.data;
            this.setState({
                saving: false
                // TODO: show "Done saving" notification?
            });
        });
    }

    saveAndUpdateEvaluation() {
        this.saveProgress();
        Util.doLoadDayEvaluation();
    }

    showMealEvaluation(e, m) {
        const data = this.props.data;
        let profileId = Util.getActiveProfileId();
        if(typeof data.appModeData.profileId === 'number') {
            profileId = data.appModeData.profileId;
        }

        let a = m.split('|'); // this.props.data.activeMealHash.split('|');
        let thisMealHash = a[0] + '|' + profileId;

        const evaluator = new TaskEvaluator({
            'dddInfo': this.showTaskListDDDInfoFunc
        });

        const res = evaluator.evaluate({
            lectureInfo: data.appModeData.lectureInfoData,
            mode: 'meal',
            mealHash: thisMealHash,
            evaluationResults: data.mealsEvaluationResults[m],
        });

        let tasksList = [];
        let taskListMeals = res.taskListMeals;
        let taskListMealsOk = res.taskListMealsOk;
        let taskListUndesirableFoodsUsed = res.taskListUndesirableFoodsUsed;
        let taskListDDD = res.taskListDDD;
        let taskListDDDOk = res.taskListDDDOk;

        if(taskListMeals.length > 0 || taskListMealsOk.length > 0) {
            tasksList.push(<h3>{__('Chody / chuť')}</h3>);
            tasksList.push(taskListMeals);
            tasksList.push(taskListMealsOk);
        }

        if(taskListUndesirableFoodsUsed.length > 0) {
            tasksList.push(taskListUndesirableFoodsUsed);
        }

        if(taskListDDD.length > 0 || taskListDDDOk.length > 0) {
            tasksList.push(<h3>{__('Nutriční hodnoty')}</h3>);

            const tmpEl = (
                <div className="lecturetask-inside-meal">
                {taskListDDD}
                {taskListDDDOk}
                </div>
            );

            tasksList.push(tmpEl);
        }

        // "Sledujte" v adminu - ale v rezimu jednoho receptu (ne celodenniho jidelnicku)
        // zobrazit jen ty, ktere jsou zaskrtle v sekci "Sledovat:" pri editaci lekce/ukolu
        // UPDATE: zobrazi se i pomery, zaskrtle v adminu (musi koncit na "_ratio")

        const mealEvalResults = data.mealsEvaluationResults[m];

        let watchAttribs = [];
        if(typeof mealEvalResults !== 'undefined') {
            const thisMealIsEmpty = mealEvalResults.weight === 0 && mealEvalResults.energy_kcal === 0;
            const lectureWatchAttribs = data.appModeData.lectureInfoData.lecture_data.watch_attributes.split(',');
            let watchArray = ['gi', 'gn', 'digestibility', 'thermic_effect', 'acidobasicity', 'energy'];
            lectureWatchAttribs.map(w => {
                let mealWatchSettings = data.appModeData.lectureInfoData.watch_attributes_meal;
                if(typeof mealWatchSettings === 'object') {
                    if(mealWatchSettings.includes(w)) watchArray.push(w);
                }
                //if(w.indexOf('_ratio') > 0) watchArray.push(w);
            });

            watchArray.map(wa => {
                if(lectureWatchAttribs.includes(wa)) {
                    let watchAttribsAlreadyFilled = [];
                    let resval, textEval;
                    const attr = wa;

                    switch(attr) {
                        case 'gi':
                            resval = mealEvalResults[attr]; 
                            textEval = 'nízký';
                            if(resval > 50) textEval = 'střední';
                            if(resval > 70) textEval = 'vysoký';
                            if(thisMealIsEmpty) textEval = '-';
                            watchAttribs.push(
                                <div className="watch-attribute-row">
                                    <p>{data.globals.attributes[attr]}:</p>
                                    <aside>
                                        {textEval}
                                    </aside>
                                </div>
                            );
                            watchAttribsAlreadyFilled.push(attr);
                            break;

                        case 'gn':
                            resval = mealEvalResults[attr]; 
                            textEval = 'nízká';
                            if(resval > 10) textEval = 'střední';
                            if(resval > 20) textEval = 'vysoká';
                            if(thisMealIsEmpty) textEval = '-';
                            watchAttribs.push(
                                <div className="watch-attribute-row">
                                    <p>{data.globals.attributes[attr]}:</p>
                                    <aside>
                                        {textEval}
                                    </aside>
                                </div>
                            );
                            watchAttribsAlreadyFilled.push(attr);
                            break;

                        default:
                            if(attr.indexOf('_ratio') > 0) {
                                watchAttribs.push(
                                    <div className="watch-attribute-row">
                                        <p>{data.globals.attributes[attr]}:</p>
                                        <aside>
                                            {mealEvalResults[attr]}
                                        </aside>
                                    </div>
                                );
                                watchAttribsAlreadyFilled.push(attr);
                                
                            } else {
                                const attr2 = attr + '_n';
                                let text = '';
                                const val = +mealEvalResults[attr2];
                                switch(attr2) {
                                    case 'acidobasicity_n':
                                        text = data.globals.evaluationTexts['acidobasicity'][val];
                                        break;
                                    case 'digestibility_n':
                                        text = data.globals.evaluationTexts['digestibility'][val];
                                        break;
                                    case 'energy_n':
                                        text = data.globals.evaluationTexts['energytendency'][val];
                                        break;
                                    case 'thermic_effect_n':
                                        text = data.globals.evaluationTexts['thermiceffect'][val];
                                        break;
                                }
                                if(thisMealIsEmpty) text = '-';
                                watchAttribs.push(
                                    <div className="watch-attribute-row">
                                        <p>{data.globals.attributes[attr]}:</p>
                                        <aside>
                                            {text}
                                            {'\u00A0'}
                                            {data.globals.attribute_units[attr]}
                                        </aside>
                                    </div>
                                );
                                watchAttribsAlreadyFilled.push(attr);
                            }
                            break;
                    }
                }
            });
        }

        if(watchAttribs.length > 0) {
            watchAttribs = (
                <div style={{padding:'0 1rem'}}>
                    <div className="lecturetask-taskslist">
                        <h3>Sledujte</h3>
                        {watchAttribs}
                    </div>
                </div>
            );
        } else {
            watchAttribs = false;
        }

        if(tasksList.length > 0) {
            tasksList = (
                <>
                <div style={{padding:'1rem',fontSize:'12px'}}>
                    <div className="lecturetask-taskslist">{tasksList}</div>
                </div>
                {watchAttribs}
                </>
            );
        } else {
            tasksList = (
                <>
                {watchAttribs}
                </>
            );
        }

        let content = (
            <EvaluationPanelContents 
                beforeContent={tasksList}
                wholeDay={false} 
                results={data.mealsEvaluationResults[m]} 
                ddd={data.evaluationResults.ddd} 
                mainStyle={{display:'block'}}
                quickResults={false} 
                evaluationCollapsedAtStart={false}
                macrosOnly={true}
            />                   
        );

        let title = 'Nutriční vyhodnocení chodu';
        if(Util.getLang() == 'sk') {
            title = 'Nutričné vyhodnotenie chodu';
        }

        this.props.closeModal();
        this.props.openModal({
            'title': title, 
            'content': content
        });
    }

    handleDrinkChange(e, mealHash, foodId) {
        this.props.setFoodAsDrink({
            mealHash: mealHash,
            foodId: foodId,
            is_drink: e.currentTarget.checked ? 1 : 0
        });
        this.saveAndUpdateEvaluation();
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = data;

        // When there are predefined meal types,
        // the meal types should not be editable
        let mealsAreEditable = true;
        let taskData = {};
        if(typeof this.props.data.lectureTaskData === 'object') {
            taskData = this.props.data.lectureTaskData;
            if(typeof taskData !== 'undefined' && typeof taskData.lecture_data !== 'undefined') {
                let mealsAreEmpty = true;
                taskData.lecture_data.meal_types.map(mt => {
                    if(mt.type !== "0") mealsAreEmpty = false;
                });
                if(!mealsAreEmpty) {
//                if(Array.isArray(taskData.lecture_data.meal_types)) {
                    mealsAreEditable = false;
                }
            }
        }



        let dayPlanHours = [];
        for (let hour = 0; hour < 24; hour++) {
            let hourItems = [];
            let hourItemsResult = [];
            for (let m in this.props.data.meals) {
                if (this.props.data.meals[m].startHour == hour) {
                    this.props.data.meals[m].mealHash = m;
                    hourItems.push({
                        'type': 'meal',
                        'data': this.props.data.meals[m],
                        'key': m
                    });
                }
            }


            // ATTN: MAXIMUM TRAININGS NUMBER!!!!!
            // MAX NUMBER OF TRAININGS - MAKE CFGABLE

            let trainingId;
            for(trainingId=1; trainingId<=10; trainingId++) {
                if(typeof this.props.data.trainings[trainingId] == 'undefined') continue;
                let training = this.props.data.trainings[trainingId];
                if(training.length == 0) continue;
                if(training.start == '') continue;
                training.id = trainingId;
                let x = training.start.split(':');
                training.startHour = x[0] * 1;
                training.startMinute = x[1] * 1;

                if(training.startHour == hour) {
                    hourItems.push({
                        'type': 'training',
                        'data': training,
                        'key': trainingId
                    });
                }
            }

            hourItems.sort((i1, i2) => {
                let minute1, minute2;
                if(i1.type == 'meal') {
                    minute1 = this.props.data.meals[i1.data.mealHash].startMinute;                    
                } else {
                    minute1 = i1.startMinute;
                }
                if(i2.type == 'meal') {
                    minute2 = this.props.data.meals[i2.data.mealHash].startMinute;                    
                } else {
                    minute2 = i2.startMinute;
                }
                return minute1 - minute2;
            });

            hourItems.map(i => {
                if(i.type == 'meal') {
                    let m = i.data.mealHash;
                    let mealId = this.props.data.meals[m].mealId;
                    let mealProfiles = [];

                    this.props.data.meals[m].profiles.map(profileId => {
                        let profileName = '';
                        if(typeof globals.profiles[profileId] !== 'undefined') {
                            profileName = globals.profiles[profileId].name;
                        }
                        if(profileName && profileName != '') {
                            mealProfiles.push(<span>{profileName}</span>);
                        }
                    });

                    let sh = this.props.data.meals[m].startHour;
                    let sm = this.props.data.meals[m].startMinute;
                    if (sh < 10) sh = '0' + sh;
                    if (sm < 10) sm = '0' + sm;

                    if(typeof this.props.data.globals.mealTypes[mealId] == 'undefined') return false;

                    let hourItemButtons = [];
                    hourItemButtons.push(
                        <>
                            <a onClick={(e) => this.handleEditMeal(m, e)}><i className="fa fa-pencil-alt"></i></a>
                            <a onClick={(e) => this.handleDeleteMeal(m, e)}><i className="fa fa-times"></i></a>
                        </>
                    );

                    let currentCommentButton = false;
                    if(typeof this.props.data.meals[m].profi_comment === 'string' && 
                        this.props.data.meals[m].profi_comment != '') {
                            currentCommentButton = <a onClick={(e) => this.handleMealShowProfiComment(m, e)}><i className="fa fa-comment-dots"></i></a>;

                        hourItemButtons.push(
                            <a onClick={(e) => this.handleMealShowProfiComment(m, e)}><i className="fa fa-comment-dots"></i></a>
                        );

                     }

                    let classes = ['meal-item-new'];
                    let thisItemMealId = m.split('|')[0];
                    if(globals.mealTypes[thisItemMealId].is_drink_regime) {
                        classes.push('meal-item-new-drink-regime');    
                    }

                    // Zrusit editacni tlacitka pouze, pokud neni 
                    // aktualni chod pitny rezim
                    if(!mealsAreEditable &&
                        !globals.mealTypes[thisItemMealId].is_drink_regime) {
                        hourItemButtons = false;
                    }

                    if(!mealsAreEditable) hourItemButtons = false;
                    // Pokud se nemaji zobrazovat editButtons, a typ
                    // chodu je pitny rezim, zobrazit jen Xko na smazani

                    if(!mealsAreEditable &&
                        globals.mealTypes[thisItemMealId].is_drink_regime) {
                            hourItemButtons = <a onClick={(e) => this.handleDeleteMeal(m, e)}><i className="fa fa-times"></i></a>;
                    }



                    let foodGroupTitles = [];
                    Object.keys(this.props.data.foodGroups[m]).map(key => {
                        const fg = this.props.data.foodGroups[m][key];
                        let bgUrl = `https://www.zofapp.cz/data/zof/recipe/t/${Math.floor(fg['from_recipe_id'] / 1000)}/${fg['from_recipe_id']}.jpg`;
                        foodGroupTitles.push(
                            <div className="title-row">
                                {fg.title}
                            </div>
                        )
                    });



                    let ev = false;
                    if(typeof data.mealsEvaluationResults[m] === 'object') {
                        ev = data.mealsEvaluationResults[m];
                    }


                    let sortedFoodGroupKeys = [];
                    for(let g in this.props.data.foodGroups[m]) {
                        sortedFoodGroupKeys.push({
                            id: g,
                            item_order: this.props.data.foodGroups[m][g].item_order
                        });
                    }

            
                    sortedFoodGroupKeys.sort(function(a, b) { 
                        return a.item_order - b.item_order;
                    });

                    let groups = [];
                    sortedFoodGroupKeys.map(k => {
                        const fg = this.props.data.foodGroups[m][k.id];
                        
                        let fgVisible = true;
                        let fgVisIcon = <i className="fa fa-angle-up"></i>;
                        if(this.state.foodGroupVisibility[fg.id] === 0) {
                            fgVisIcon = <i className="fa fa-angle-down"></i>;
                            fgVisible = false;
                        }


                        let foods = [];
                        data.foods[m].map(f => {
                            if(f.group_id !== fg.id) return;    

                            let adjustAmount = false;
                            if(state.adjustAmountId === f.id) {
                                adjustAmount = <div className="meal-item-adjust-amount">
                                    <p>
                                        <input type="text" name="amount" value={f.weight} />
                                        <span>{globals.itemData[f.food_id].weight_unit}</span>
                                        <button>OK</button>
                                    </p>
                                </div>;
                            }

                            let foodItem = {
                                id: f.food_id,
                                selectedWeight: f.weight,
                                weight: this.props.data.globals.itemData[f.food_id].weight,
                                norm_unit_amount: this.props.data.globals.itemData[f.food_id].norm_unit_amount * 1.0,
                                norm_unit_unit: this.props.data.globals.itemData[f.food_id].norm_unit_unit,
                                norm_unit2_amount: this.props.data.globals.itemData[f.food_id].norm_unit2_amount * 1.0,
                                norm_unit2_unit: this.props.data.globals.itemData[f.food_id].norm_unit2_unit
                            };

                            let uc = new UnitConvert;
                            let normalizedAmountInfo = uc.convert(foodItem);
                            if(normalizedAmountInfo !== null && normalizedAmountInfo != '' && normalizedAmountInfo != ' null') {
                                normalizedAmountInfo = <span>({normalizedAmountInfo})</span>;
                            } else {
                                normalizedAmountInfo = false;
                            }

                            let inputValue = parseFloat(f.weight + '');
                            if(this.state.focusedAmountInputId === f.id) {
                                inputValue = this.state.focusedAmountInputValue;
                            }

                            const foodItemData = this.props.data.globals.itemData[f.food_id];
                            let drinkModeSelection = false;
                            if(foodItemData.is_drink == 1) {
                                let cbName = 'is_drink_' + f.food_id;
                                drinkModeSelection = (
                                    <p><input type="checkbox" name={cbName} onChange={(e) => this.handleDrinkChange(e, m, f.id)} checked={f.is_drink == 1}/> pitný režim</p>
                                );
                            }

                            // Alergen info

                            let watchAlergens = [];
                            if(typeof this.props.data.appModeData.watchAlergens === 'object') {
                                watchAlergens = this.props.data.appModeData.watchAlergens;
                            }
                            watchAlergens = watchAlergens.map(a => +a);

                            let alergenList = [];
                            if(typeof this.props.data.globals.foodAlergens[f.food_id] === 'object') {
                                let alSorted = this.props.data.globals.foodAlergens[f.food_id].sort();
                                alSorted.map(id => {
                                    let alergenName = this.props.data.globals.itemAlergens[id];
                                    let alerg = alergenName;
                                    alergenName = alergenName.split('-')[0].trim();

                                    if(watchAlergens.includes(id*1)) {
                                        alergenList.push(<span onClick={(e) => this.showAlergenInfo(e, alerg)} style={{cursor:'pointer',borderBottom:'1px dotted #f00',color:'#f00'}}>{alergenName}</span>);
                                        alergenList.push(<span>, </span>);
                                    } else {
                                        alergenList.push(<span onClick={(e) => this.showAlergenInfo(e, alerg)} style={{cursor:'pointer',borderBottom:'1px dotted #bbb'}}>{alergenName}</span>);
                                        alergenList.push(<span>, </span>);
                                    }
                                });
                            }

                            // Undesirable food info

                            let undesirableInfo = false;
                            if(typeof this.props.data.appModeData.undesirableFoods !== 'undefined') {
                                if(this.props.data.appModeData.undesirableFoods.includes(f.food_id)) {
                                    for(let profileId in this.props.data.globals.undesirableFoods) {
                                        profileId = profileId * 1;
                                        undesirableInfo = <p className="item-undesirable"><span>Nevhodná potravina</span></p>;
                                    }
                                }
                            }

                            if(alergenList.length > 1) {
                                if(undesirableInfo) {
                                    alergenList.pop();
                                    undesirableInfo = <p className="item-undesirable"><span>Nevhodná potravina: <span style={{fontWeight:'normal',color:'#000'}}>{alergenList}</span></span></p>;
                                } else {
                                    alergenList.pop();
                                    undesirableInfo = <p className="item-undesirable" style={{color:'#000'}}>
                                                        <span>Alergeny: 
                                                            <span style={{fontWeight:'normal',color:'#000'}}>{alergenList}</span>
                                                        </span>
                                                      </p>;
                                }
                            }
                            
                            // Food modifications

                            let modList = [];
                            if(typeof this.props.data.modifications[f.id] !== "undefined") {
                                let mods = this.props.data.modifications[f.id];
                                mods.forEach((key, val) => {
                                    modList.push(this.props.data.globals.preparation_types[key]);
                                });
                            }
                            modList = modList.length > 0 ? modList.join(', ') : 'Bez úprav';

                            let modifications = (
                                <div className="item-modifications">
                                    <a data-food-id={f.id} onClick={(e) => this.openFoodModificationsPanel(e, m, f)}>
                                        <span>{modList}</span>
                                    </a>
                                </div>
                            );
    
                            foods.push(
                                <div className="meal-item-food">
                                    <div className="icon" style={{backgroundImage: `url(${f.image})`}}>
                                    </div>
                                    <div className="food-title">
                                        <h4>
                                            {f.title}
                                            {drinkModeSelection}
                                        </h4>
                                        <div className="amount">
                                            <div className="amount-input">
                                                <input  
                                                    className={this.state.invalidWeight ? 'invalid' : ''} 
                                                    type="text" name="weight" 
                                                    value={inputValue} 
                                                    onChange={this.handleFoodAmountChange} 
                                                    onFocus={(e) => this.handleFoodAmountInputFocus(e, f)} 
                                                    onBlur={(e) => this.handleFoodAmountInputBlur(e, m, f)} 
                                                />
                                                <span>{globals.itemData[f.food_id].weight_unit}</span>
                                            </div>
                                            {normalizedAmountInfo}
                                        </div>
                                        {modifications}                                        
                                        {undesirableInfo}
                                    </div>
                                    <div className="options">
                                        <div class="option">
                                            <a onClick={() => this.sortFoodInRecipe(f.id, -1, m)}><i className="fa fa-arrow-up"></i></a>
                                        </div>
                                        <div class="option">
                                            <a onClick={() => this.sortFoodInRecipe(f.id, 1, m)}><i className="fa fa-arrow-down"></i></a>
                                        </div>
                                        <div class="option">
                                            <a onClick={() => this.loadFoodDescription(f.food_id, inputValue)}><i className="fa fa-question"></i></a>
                                        </div>
                                        <div class="option">
                                            <a onClick={(e) => this.moveFoodToAnotherGroup(e, m, f)}><i className="fa fa-external-link-alt"></i></a>
                                        </div>
                                        <div class="option">
                                            <a onClick={(e) => this.removeFoodFromRecipe(e, m, f)} className="delete"><i className="fa fa-trash-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            );
                        });

                        let totalFoodWeight = 0;
                        let foodsNumberInThisGroup = 0;

                        data.foods[m].map(fd => {
                            if(fd.group_id == fg.id) {
                                foodsNumberInThisGroup++;
                                totalFoodWeight += fd.weight * 1;
                            }
                        });
                
                        let foodGroupSettingsPanel = false;
                        if(this.state.foodGroupSettingsHash === fg.id) {
                            foodGroupSettingsPanel = (
                                    <div className="settings">
                                        <a onClick={(e) => this.moveFoodGroup(e, m, fg.id, -1)}>{__('Přesunout výše')}</a>
                                        <a onClick={(e) => this.moveFoodGroup(e, m, fg.id, 1)}>{__('Přesunout níže')}</a>
                                        <a onClick={(e) => this.editMealDescription(e, m, fg.id)}>{__('Popis přípravy')}</a>
                                        <a onClick={(e) => this.deleteFoodGroup(e, m, fg.id)} style={{color:'red'}}>{__('Smazat talíř')}</a>
                                    </div>
                            );
                        }

                        let addFoodOrRecipePanel = false;
                        if(this.state.addFoodOrRecipeHash === fg.id) {
                            addFoodOrRecipePanel = (
                                <div className="settings">
                                    <a onClick={(e) => this.openPanelAddFood(e, m, fg)}>{__('Přidat potravinu')}</a>
                                    <a onClick={(e) => this.openPanelAddRecipe(e, m, fg)}>{__('Přidat recept')}</a>
                                </div>
                            );
                        }
                        
                        if(!fgVisible) foods = false;


                        let groupTypeSelectOpts = [];
                        for(let type in this.props.data.globals.foodGroupTypes) {
                            groupTypeSelectOpts.push(<a onClick={(e) => this.changeFoodGroupType(e, m, fg.id, type)}>{this.props.data.globals.foodGroupTypes[type]}</a>);
                        }

                        groupTypeSelectOpts = (
                            <div class="group-type-select">
                                {groupTypeSelectOpts}
                            </div>
                        );

                        if(state.foodGroupTypeSettingsHash !== fg.id) groupTypeSelectOpts = false;

                        // Food group is visible - show a full version

                        if(fgVisible) {
                            groups.push(
                                <div className="meal-item-group">
                                    <div className="meal-item-group-header">
                                        <div>
                                            <a onClick={(e) => this.toggleFoodGroupTypeSettings(fg.id)}>{globals.foodGroupTypes[fg.type]} <i className="fas fa-angle-down"></i></a>
                                            <input type="text" placeholder={__('Název')} value={fg.title} onChange={(e) => this.changeFoodGroupTitle(e, m, fg.id)} onBlur={(e) => this.saveProgress()} />
                                            {groupTypeSelectOpts}
                                        </div>
                                        <aside>
                                            <div>
                                                <a className="click-outside-disabled btn-settings btn-add-food-or-recipe" onClick={() => this.toggleAddFoodOrRecipe(fg.id)}>
                                                    <i class="fas fa-plus"></i>
                                                </a>
                                                {addFoodOrRecipePanel}
                                            </div>
                                            <div>
                                                <a onClick={() => this.toggleFoodGroupVisibility(fg.id)}>{fgVisIcon}</a>
                                            </div>
                                            <div>
                                                <a onClick={(e) => this.changeFoodGroupAmount(e, m, fg.id)}><i className="fa fa-balance-scale"></i><span>{Math.round(totalFoodWeight*10)/10} g/ml</span></a>
                                            </div>
                                            <div>
                                                <a className="click-outside-disabled" onClick={() => this.toggleFoodGroupSettings(fg.id)}><i className="fa fa-pencil-alt"></i> <span>Možnosti</span></a>
                                                {foodGroupSettingsPanel}
                                           </div>
                                        </aside>
                                    </div>
                                    <div className="meal-item-group-foods">
                                        {foods}
                                    </div>
                                </div>
                            );
                        }

                        // Food group is not visible - show a minimalistic version

                        if(!fgVisible) {
                            groups.push(
                                <div className="meal-item-group-minimalistic"><div className="inner">
                                    <p>
                                        {globals.foodGroupTypes[fg.type]}:
                                        <b>{fg.title}</b>
                                    </p>
                                    <aside>
                                        <a onClick={() => this.toggleFoodGroupVisibility(fg.id)}>{fgVisIcon}</a>
                                    </aside>
                                </div></div>
                            );
                        }
                    });

                    const thisMealIsDrinkRegime = this.props.data.globals.mealTypes[mealId].is_drink_regime;

                    let deleteMealButton = <a onClick={(e) => this.handleDeleteMeal(e, m)} style={{color:'red'}}>Smazat</a>;
                    if(!mealsAreEditable && !thisMealIsDrinkRegime) {
                        deleteMealButton = false;
                    }


                    let editMealButton = false;
                    if(Util.isMealDrinkRegime(m)) {
                        editMealButton = <a onClick={(e) => this.handleEditMeal(m, e)}>Upravit</a>;
                    }

                    let mealSettingsPanel = false;
                    if(this.state.mealSettingsHash === m) {
                        mealSettingsPanel = (
                            <OutsideAlerter onClick={() => this.setState({mealSettingsHash: ''})}>
                            <div className="settings">
                                <a onClick={(e) => this.saveToMyRecipes(e, m)}>{__('Uložit do mých receptů')}</a>
                                <a onClick={(e) => this.editMealDescription(e, m)}>{__('Popis přípravy')}</a>
                                {editMealButton}
                                {deleteMealButton}
                            </div>
                            </OutsideAlerter>
                        );
                    }

                    /*
                    let mealAddPanel = false;
                    if(this.state.mealAddHash === m) {
                        const fg = 1;
                        mealAddPanel = (
                            <OutsideAlerter onClick={() => this.setState({mealAddHash: ''})}>
                            <div className="settings">
                                <a onClick={(e) => this.openPanelAddFoodGroup(e, m)}>Přidat talíř</a>
                            </div>
                            </OutsideAlerter>
                        );
                    }
                    */

                    let addFoodGroupButton = (
                        <div className="meal-item-group-add-food">
                            <a onClick={(e) => this.openPanelAddFoodGroup(e, m)}>
                                <span><i className="fa fa-plus"></i></span>
                                {__('Přidat talíř')}
                            </a>
                        </div>
                    );

                    if(thisMealIsDrinkRegime) addFoodGroupButton = false;

                    hourItemsResult.push(
                        <div className={classes.join(' ')} key={m}>
                            <div className="title">
                                <p>
                                    <span className="time">
                                        <i className="fa fa-clock"></i> {sh}:{sm}
                                    </span>
                                    <em>&mdash;</em>
                                    <b>{this.props.data.globals.mealTypes[mealId].title}</b>
                                </p>
                                <aside>
                                    <div>
                                        <a onClick={(e) => this.showMealEvaluation(e, m)}>
                                            <i className="fa fa-chart-pie"></i> {__('Vyhodnocení')}
                                        </a>
                                    </div>
                                    <div>
                                        <a className="click-outside-disabled btn-settings" onClick={() => this.toggleMealSettings(m)}>
                                            <i class="fas fa-ellipsis-v"></i>
                                        </a>
                                        {mealSettingsPanel}
                                    </div>
                                </aside>
                                <div className="eval">
                                    <div className="col">
                                        {__('Bílkoviny')}: <b>{this.fmtEvalValue(ev.proteins, 1)} g</b>
                                    </div>
                                    <div className="col">
                                    {__('Tuky')}: <b>{this.fmtEvalValue(ev.fats, 1)} g</b>
                                    </div>
                                    <div className="col">
                                        {__('Sacharidy')}: <b>{this.fmtEvalValue(ev.carbohydrates, 1)} g</b>
                                    </div>
                                    <div className="col">
                                        {__('Vláknina')}: <b>{this.fmtEvalValue(ev.fiber, 1)} g</b>
                                    </div>
                                </div>
                            </div>
                            {groups}
                            {addFoodGroupButton}
                        </div>
                    );
                }
/*
                            <div className="meal-item-group-add-food add-recipe-or-plate">
                                <a onClick={(e) => this.openPanelAddRecipe(e, m)}><i className="fa fa-plus"></i> Recept</a>
                                <a onClick={(e) => this.openPanelAddFoodGroup(e, m)}><i className="fa fa-plus"></i> Chod</a>
                            </div>
*/

                if(i.type == 'training') {
                    let sh = i.data.startHour;
                    let sm = i.data.startMinute;
                    if (sh < 10) sh = '0' + sh;
                    if (sm < 10) sm = '0' + sm;

                    let mealItemClass = 'meal-item meal-item-training';
                    let m = i.data.id;

                    let hourItemButtons = (
                        <>
                            <a onClick={(e) => this.handleEditTraining(m, e)}><i className="fa fa-pencil-alt"></i></a>
                            <a onClick={(e) => this.handleDeleteTraining(m, e)}><i className="fa fa-times"></i></a>
                        </>
                    );

                    if(data.profiClientId > 0) {
                        hourItemButtons = false;
                    }

                    if(!mealsAreEditable) hourItemButtons = false;

                    hourItemsResult.push(
                        <div className="meal-item-new meal-item-new-training" key={m}>
                            <div className="title">
                                <p>
                                    <span className="time">
                                        <i className="fa fa-clock"></i> {sh}:{sm}
                                    </span>
                                    <em>&mdash;</em>
                                    <b>{__('Trénink')}</b>
                                </p>
                                <aside>
                                    <p className="meal-item-profiles">
                                        <b style={{color:'#000'}}>{i.data.description}</b><br />
                                        {__('Délka')}: {i.data.length} {__('minut')}
                                    </p>
                                </aside>
                            </div>
                        </div>
                    );
                }                


            });

            let hourFmt = hour >= 10 ? hour + ':00' : '0' + hour + ':00';
            if (this.state.currentMouseOverHour == hour) {
                let spanH = '0' + hour;
                if (hour >= 10) spanH = hour;
                let spanM = '0' + this.state.currentMouseOverMinute;
                if (this.state.currentMouseOverMinute >= 10) spanM = this.state.currentMouseOverMinute;
                hourFmt = spanH + ':' + spanM;
            }

            let addCurrentHour = true;
            if(this.props.data.plannerViewMode == 1) {
                if(hourItemsResult.length == 0) addCurrentHour = false;
            }

            if(addCurrentHour) {
                dayPlanHours.push(
                    <div ref={div => { this[`refHour${hour}`] = div }} data-hour={hour} onClick={(e) => this.onAddMealClick(e)} onMouseMove={(e) => this.onHourMouseMove(e)} onMouseLeave={(e) => this.onHourMouseLeave(e)} className="hour" key={hour}>
                        <span>{hourFmt}</span>
                        {hourItemsResult}
                    </div>
                );
            }
        }

        return dayPlanHours;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LectureTaskDayPlan));
