import React, { Component } from 'react';

class Saving extends Component { 

    render() {
        return (
            <div className="overlay">
                <div className="overlay-inner">
                    <p>Ukládám...</p>
                    <div className="loader"></div>
                </div>
            </div>
        );
    }
}

export default Saving;
