import React, { Component } from 'react';
import { connect } from "react-redux";
import UnitConvert from 'UnitConverter';
import { 
    sortFood, 
    setFoodAmount, 
    setFoodAsDrink, 
    addFoodModification,
    removeFoodModification,
    removeFood, 
    moveFoodToPlate,
    setModalContent, 
    openModal, 
    closeModal } from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        sortFood: data => dispatch(sortFood(data)),
        setFoodAmount: data => dispatch(setFoodAmount(data)),
        setFoodAsDrink: data => dispatch(setFoodAsDrink(data)),
        addFoodModification: data => dispatch(addFoodModification(data)),
        removeFoodModification: data => dispatch(removeFoodModification(data)),
        removeFood: data => dispatch(removeFood(data)),
        moveFoodToPlate: data => dispatch(moveFoodToPlate(data)),
        setModalContent: data => dispatch(setModalContent(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data))
    };
};

class FoodRowSimple extends Component {
    constructor(props) {
        super(props);
        this.state = {
            food: props.food,
        };
    }

    render() {
        const { data } = this.props;
        const { food } = this.props;
        const { globals } = data;

        let foodItem = {
            id: food.food_id,
            selectedWeight: food.amount,
            weight: this.props.data.globals.itemData[food.food_id].weight,
            norm_unit_amount: this.props.data.globals.itemData[food.food_id].norm_unit_amount * 1.0,
            norm_unit_unit: this.props.data.globals.itemData[food.food_id].norm_unit_unit,
            norm_unit2_amount: this.props.data.globals.itemData[food.food_id].norm_unit2_amount * 1.0,
            norm_unit2_unit: this.props.data.globals.itemData[food.food_id].norm_unit2_unit
        };

        let uc = new UnitConvert;
        let normalizedUnitInfo = uc.convert(foodItem);
        let normalizedUnitInfoRaw = normalizedUnitInfo;
        if(normalizedUnitInfo !== null && normalizedUnitInfo != '' && normalizedUnitInfo != ' null') {
            normalizedUnitInfo = <span>({normalizedUnitInfo})</span>;
        } else {
            normalizedUnitInfo = false;
        }

        let mods = [];
        food.modifications.map(m => {
            mods.push(globals.preparation_types[m]);
        });

        if(mods.length > 0) {
            mods = <p style={{fontSize:'.85em',margin:'.5em 0 0',color:'#333'}}>{mods.join(', ')}</p>;
        }

        let foodRow = (
            <div className="food-row" key={food.id}>
                <div className="icon">
                    <img src={`https://www.zofapp.cz/data/zof/item/${food.food_id}.svg`} alt="" />
                </div>
                <div className="title">
                    <h4>{food.title}</h4>
                    <p><b>{parseFloat(food.amount)} {food.unit}</b> {normalizedUnitInfo}</p>
                    {mods}
                </div>
            </div>
        );

        return foodRow;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodRowSimple);
