import React, { Component } from "react";
import { connect } from 'react-redux';
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, getMonth, addDays, addMonths, subMonths, isEqual, isToday } from "date-fns";
import localeCs from 'date-fns/locale/cs';
import api from 'api';
import Loading from 'components/Loading/index';
const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class GeneratorCalendar extends React.Component {
    constructor(props) {
        super(props);

        let initialDate = new Date();

        this.props.onClientDateClick(null, initialDate);

        this.state = {
            currentDate: initialDate,
            selectedDate: initialDate,
            loading: false,
        };

        this.nextMonth = this.nextMonth.bind(this);
        this.prevMonth = this.prevMonth.bind(this);
        this.handleCellClick = this.handleCellClick.bind(this);
    }

    renderHeader() {
        const dateFormat = "LLLL yyyy";
        return (
            <div className="header row flex-middle">
                <div className="col col-start">
                    <div className="icon" onClick={this.prevMonth}>
                        <i className="fa fa-caret-left"></i>
                    </div>
                </div>
                <div className="col col-center">
                    <span>{format(this.state.currentDate, dateFormat, {locale: localeCs})}</span>
                </div>
                <div className="col col-end" onClick={this.nextMonth}>
                    <div className="icon"><i className="fa fa-caret-right"></i></div>
                </div>
            </div>
        );
    }

    renderDays() {
        const days = [];
        let startDate = startOfWeek(this.state.currentDate, {weekStartsOn: 1});

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {format(addDays(startDate, i), 'EEEEEE', {locale: localeCs})}
                </div>
            );
        }

        return <div className="days row">{days}</div>;
    }

    renderCells() {
        const { state } = this;
        const { currentDate } = this.state;
        let monthStart = startOfMonth(currentDate);
        let monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart, {weekStartsOn: 1});
        const endDate = endOfWeek(monthEnd);
        const currentDateNumber = getMonth(currentDate);
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        let ymdDate = "";

        const selectedDayFmt = format(this.state.selectedDate, 'yyyy-MM-dd');

        while (day <= endDate) {

            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, "d");
                ymdDate = format(day, "yyyy-MM-dd");
                const cloneDay = day;
                let dayMonthNumber = getMonth(day);

                let dayClass = `col cell`;
                if(isToday(day)) {
                    dayClass = `col today cell`;
                }

                if(selectedDayFmt == ymdDate) {
                    dayClass = `col active cell`;
                }
                if(dayMonthNumber != currentDateNumber) {
                    dayClass = `col cell other-month`;
                }

                let minimap = [];

                // My calendar (non ZOF Profi, or "my own" planner)
                if(typeof this.props.monthData[ymdDate] !== 'undefined') {
                    for(let hour in this.props.monthData[ymdDate]) {
                        let mmProfiles = [];
                        mmProfiles.push(<div key={0} style={{ width: '3px', height: '3px', opacity: '.5', backgroundColor: '#333'}}></div>);
                        let top = Math.floor((hour / 24) * 100) + '%';
                        minimap.push(<div key={hour} className="minimap-hour" style={{ top: top }}>{mmProfiles}</div>);
                    }
                }
                
                days.push(
                    <div className={dayClass} key={day}>
                        <a className="number" onClick={(e) => this.handleCellClick(e, cloneDay)}>
                            {formattedDate}
                            {minimap}
                        </a>
                    </div>
                );

                day = addDays(day, 1);
            }
            rows.push(
                <div className="calendar-row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    }

    nextMonth() {
        let newDate = addMonths(this.state.currentDate, 1);
        this.setState({
            currentDate: newDate
        }, () => {
            const m = newDate.getMonth() + 1;
            const y = newDate.getFullYear();
            this.props.loadMonthData(m, y);
        });
    };

    prevMonth() {
        let newDate = subMonths(this.state.currentDate, 1);
        this.setState({
            currentDate: newDate
        }, () => {
            const m = newDate.getMonth() + 1;
            const y = newDate.getFullYear();
            this.props.loadMonthData(m, y);
        });
    };

    handleCellClick(e, date) {     
        this.setState({
            selectedDate: date
        });
        this.props.onClientDateClick(e, date);   
    }

    render() {
        let calendarBody;

        if(this.state.loading) return <Loading />;
        if(this.props.monthData === false) return <Loading />;

        calendarBody = (
            <div className="simple-calendar-wrap">
                <div className="simple-calendar">
                    {this.renderHeader()}
                    {this.renderDays()}
                    {this.renderCells()}
                </div>
            </div>
        );

        return calendarBody;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneratorCalendar);
