import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { format } from "date-fns";
import Loading from 'components/Loading/index';
import api from 'api';
import Util from 'utility';

 import {
 } from 'actions/index';

 const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class PanelDecreaseFoods extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            foods: {},
            selectedFoodId: false,
            loading: true,
        };
        this.gotoMeal = this.gotoMeal.bind(this);
        this.lowerFirst = this.lowerFirst.bind(this);
    }

    componentDidMount() {
        api('foods2/decrease/' + this.props.attr + '/' + Util.yyyymmdd(this.props.data.plannerDate)).then((res) =>{
            this.setState({
                loading: false,
                foods: res.data.foods,
            });
        });

    }

    findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    }

    gotoMeal(e, mealId) {
        const profileId = Util.getFirstProfileId();
        this.props.history.push('/recipe/edit/meal/' + mealId + '_' + profileId);
    }

    lowerFirst(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;

        if(state.loading) return <Loading />;

        let foodsArray = [];
        Object.keys(state.foods).map(foodId => {
            foodsArray.push({
                'id': foodId,
                'amount': state.foods[foodId].amount,
                'attribute_amount': state.foods[foodId].attribute_amount,
                'in': state.foods[foodId].in
            });
        });

        foodsArray.sort((a, b) => {
            if(a.attribute_amount < b.attribute_amount) return 1;
            if(a.attribute_amount > b.attribute_amount) return -1;
            return 0;
        });

        let foods = [];
        foodsArray.map(f => {
            let foodImage = `https://www.zofapp.cz/data/zof/item/${f.id}.svg`;
            let meals = [];
            let mealLinks = [];
            f.in.map(i => {
                let text = globals.mealTypes[i.meal_id].title + ' (' + i.meal_time + ')';
                if(!meals.includes(text)) {
                    meals.push(text);
                    mealLinks.push(<span><a onClick={(e) => this.gotoMeal(e, i.meal_id)}>{text}</a>{'\u00A0'}</span>);
                }
            });
            foods.push(
                <div className="recommended-item recommended-item-no-hover">
                    <div className="picture" style={{ backgroundSize:'contain', backgroundImage: `url(${foodImage})` }}>
                    </div>
                    <div className="title">
                        <p><b>{globals.itemData[f.id].title} ({parseFloat(f.amount)} g)</b></p>
                        <p>Obsahuje {parseFloat(f.attribute_amount).toFixed(2)} {globals.attribute_units[this.props.attr]} {this.lowerFirst(globals.attributes_inflected[this.props.attr])}.</p>
                        <p className="meal-links">{mealLinks}</p>
                    </div>
                </div>
            );
        });


        foods = <div className="recommended-wrap">
                    <div className="box recommended">
                        {foods}
                    </div>
                </div>;

        let content = (
            <React.Fragment>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column',maxHeight:'500px',overflowY:'scroll' }}>
                {foods}
            </div>
            </React.Fragment>
        );

        if(state.selectedFoodId !== false) {
            let meals = [];
            Object.keys(this.props.data.meals).map(m => {
                const meal = this.props.data.meals[m];

                let foodGroups = [];
                Object.keys(this.props.data.foodGroups[m]).map(fg => {
                    const fgroup = this.props.data.foodGroups[m][fg];
                    foodGroups.push(
                        <p><a onClick={(e) => this.addFood(e, m, fg)}>{fgroup.title}</a></p>
                    );
                });

                meals.push(
                    <div className="recommended-meal">
                        <h3>{globals.mealTypes[meal.mealId].title}</h3>
                        {foodGroups}
                    </div>
                );
            });
            content = (
                <div className="recommended-wrap">
                    <h3>{globals.itemData[state.selectedFoodId].title} - přidat do:</h3>
                    {meals}
                </div>                
            );
        }

        return content;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PanelDecreaseFoods));
