import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import api from 'api';
import Loading from 'components/Loading/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class ClientChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.id,
            loading: true,
            newMessage: {'message': '', attachment: false},            
            messages: [],
            clientData: {},
            advisorUser: {},
        }
        this.loadChat = this.loadChat.bind(this);
        this.loadResults = this.loadResults.bind(this);
        this.onChange = this.onChange.bind(this);
        this.submitMessage = this.submitMessage.bind(this);
    }

    componentDidMount() {
        this.loadChat();
    }
    
    loadChat() {
        api('profi/chat/get/' + this.state.userId + '?read=1').then((res) =>{
            this.setState({
                messages: res.data.messages,
                advisorUser: res.data.advisorUser,
                loading: false
            });
        });
    }

    loadResults() {
        api('profi/clientdata/' + this.props.data.globals.user.id).then((res) =>{
            this.setState({
                clientData: res.data.clientData,
                advisorUser: res.data.advisorUser,
                loading: false
            });
        });
    }

    onChange(e) {
        const n = e.target.name;
        let v = e.target.value;
        if(n == 'attachment') {
            v = e.target.files[0];
        }
        let message = this.state.newMessage;
        message[n] = v;

        this.setState({
            newMessage: message
        }); 
    }

    submitMessage() {
        const { newMessage } = this.state;

        if(newMessage.message == '') {
            alert("Napište text zprávy");
            return;
        }

        this.setState({
            loading: true,
        }, () => {
    
            let fd = new FormData;
            for(let i in newMessage) {
                fd.append(i, newMessage[i]);
            }
            fd.append('user_id', this.state.userId);

            api('profi/chat/post', {post: true, data: fd}).then(result => {
                result = result.data;
                this.setState({
                    newMessage: {'message': '', attachment: false},
                    messages: result,
                    loading: false,
                });                
            });
        });
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;

        let savedInfo = false;
        if(state.saved) {
            savedInfo = (
                <div className="message message-success">
                    <i className="fa fa-check"></i>
                    Zpráva byla uložena do chatu.
                </div>
                );
        }
                
        let messages = [];
        this.state.messages.map(message => {

            let attachment = false;
            if(message.has_attachment === 1) {
                if(message.is_image === 1) {
                    attachment = <p><img src={`https://www.zofapp.cz/${message.attachment_file}`} alt="" /></p>
                } else {
                    attachment = <p><i className="fa fa-download"></i> Příloha: <a href={`https://www.zofapp.cz/${message.attachment_file}`}>{message.attachment_title}</a></p>;
                }
            }

            let msgClass = '';
            if(message.to_user_id == this.props.data.user.id) msgClass = ' chat-message-admin';

            messages.push(
                <div className={`chat-message${msgClass}`}>
                    <p className="info">{message.author_name}, {message.dateFormatted}</p>
                    <div style={{ whiteSpace: 'pre-line' }}>{message.message}</div>
                    {attachment}
                </div>
            );
        });

        let content = (
            <>
            <div className="chat-form">
                <table>
                    <tr>
                        <td>
                            <textarea name="message" placeholder="Napište zprávu..." onChange={this.onChange} value={state.newMessage.message}></textarea>
                        </td>
                        <td>
                            <button onClick={this.submitMessage}>Odeslat zprávu</button>
                        </td>
                    </tr>
                    <tr>
                        <td>příloha:{'\u00A0'}{'\u00A0'}<input type="file" name="attachment" onChange={this.onChange} /> </td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div className="chat-messages">
                {messages}
            </div>
            </>
        );

        if(state.loading) content = <Loading />;

        let advisorProfile = <div className="profi-advisor-mini-profile">
            <img src={`https://www.zofapp.cz/data/student-avatar/${this.state.advisorUser.avatar}`} alt="" />
            <p>{this.state.advisorUser.name}</p>
        </div>;

        return (
            <>
                {content}
                {savedInfo}
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientChat));
