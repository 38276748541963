import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from "date-fns";
import Loading from 'components/Loading/index';
import api from 'api';
import Util from 'utility';

import {
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class PanelRecommendFoods extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            foods: {},
            selectedFoodId: false,
            loading: true,
        };
        this.selectFood = this.selectFood.bind(this);
        this.addFood = this.addFood.bind(this);
        this.lowerFirst = this.lowerFirst.bind(this);
    }

    componentDidMount() {
        api('foods2/recommended/' + this.props.attr).then((res) =>{
            this.setState({
                loading: false,
                foods: res.data.foods,
            });
        });

    }

    findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    }

    selectFood(e, id) {
        this.setState({
            selectedFoodId: id
        });
    }

    addFood(e, m, fg) {
        const dateString = format(this.props.data.plannerDate, 'yyyy-MM-dd');
        api('foods2/insert-recommended/' + dateString + '/' + m + '/' + fg, {post: true, data: {id: this.state.selectedFoodId}}).then((result) => {
            setTimeout(() => {
                this.props.onFoodAdded();
            }, 1000);
        });
    }

    lowerFirst(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;

        if(state.loading) return <Loading />;

        let profileId = Util.getFirstProfileId();
        const undesirableFoods = globals.undesirableFoods[profileId];

        const amountToOptimum = Math.ceil(this.props.ddd.amount_to_optimum);
        
        let foodsSorted = state.foods;

        foodsSorted.sort((a, b) => {
            if(a.item_order > b.item_order) return 1;
            if(a.item_order < b.item_order) return -1;
            return 0;
        });

        let categories = [];
        foodsSorted.map(category => {

            let foods = [];
            category.foods.map(f => {

                let undesirableWarning = false;
                if(undesirableFoods.includes(f.id)) {
                    let undesirableForName = globals.profiles[profileId].name;
                    undesirableWarning = <p style={{margin:'4px 0 0',color:'red',fontSize:'11px',fontWeight:'bold'}}>
                        <i className="fa fa-exclamation-triangle"></i> nevhodné pro {undesirableForName}
                    </p>
                }
        
        
                let foodImage = `https://www.zofapp.cz/data/zof/item/${f.id}.svg`;
                let optimumAmount = Math.ceil((amountToOptimum / f.value) * f.weight); 
                foods.push(
                    <a onClick={(e) => this.selectFood(e, f.id)} className="recommended-item">
                        <div className="picture" style={{ backgroundSize:'contain', backgroundImage: `url(${foodImage})` }}>
                        </div>
                        <div className="title">
                            <p><b>{globals.itemData[f.id].title} ({parseFloat(f.weight)} {f.weight_unit})</b></p>
                            <p>Obsahuje {parseFloat(f.value)} {globals.attribute_units[this.props.attr]} {this.lowerFirst(globals.attributes_inflected[this.props.attr])}.</p>
                            <p>Pro dosažení optima je třeba navýšit o {optimumAmount} {f.weight_unit}</p>
                            {undesirableWarning}
                        </div>
                    </a>
                );
            });

            categories.push(
                <div className="recommended-wrap">
                    <h3>{category.category_title}:</h3>
                    <div className="box recommended">
                        {foods}
                    </div>
                </div>
            );
        })

        let content = (
            <React.Fragment>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column',maxHeight:'500px',overflowY:'scroll' }}>
                {categories}
            </div>
            </React.Fragment>
        );

        if(state.selectedFoodId !== false) {
            let meals = [];
            Object.keys(this.props.data.meals).map(m => {
                const meal = this.props.data.meals[m];

                let foodGroups = [];
                Object.keys(this.props.data.foodGroups[m]).map(fg => {
                    const fgroup = this.props.data.foodGroups[m][fg];
                    foodGroups.push(
                        <p><a onClick={(e) => this.addFood(e, m, fg)}>{fgroup.title}</a></p>
                    );
                });

                meals.push(
                    <div className="recommended-meal">
                        <h3>{globals.mealTypes[meal.mealId].title}</h3>
                        {foodGroups}
                    </div>
                );
            });
            content = (
                <div className="recommended-wrap">
                    <h3>{globals.itemData[state.selectedFoodId].title} - přidat do:</h3>
                    {meals}
                </div>                
            );
        }

        return content;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelRecommendFoods);
