import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { debounce } from "throttle-debounce";   
import api from 'api';

import {
    showMessage
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        showMessage: data => dispatch(showMessage(data)),
    };
};

class MenuShare extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        api("menus/share/" + id, { post: true, data: {description: localStorage.getItem('shareMenuDescription') }}).then((res) => {
            this.props.showMessage({
                show: true,
                type: 'success',
                message: 'Jídelníček byl sdílen na Váš profil.'
            });               
            this.props.history.push('/menu');
        });
    }



    render() {
        return [];
    }  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuShare));
