import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from "date-fns";
import localeCs from 'date-fns/locale/cs';
import Loading from 'components/Loading/index';
import api from 'api';
import Util from 'utility';
import { Locale, __ } from 'locale';

import { 
    openModal,
    closeModal, 
    loadGlobals, 
    mergeGlobals, 
    loadDayPlan, 
    loadDayPlanCallback,
    setPlannerViewMode, 
    showMessage,
    setAppModeData,
} from '../../actions/index';

import MenuEditorPlan from './MenuEditorPlan';
import MenuEditorDayPlan from './MenuEditorDayPlan';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        loadGlobals: data => dispatch(loadGlobals(data)),
        mergeGlobals: data => dispatch(mergeGlobals(data)),
        loadDayPlan: data => dispatch(loadDayPlan(data)),
        loadDayPlanCallback: data => dispatch(loadDayPlanCallback(data)),
        setPlannerViewMode: data => dispatch(setPlannerViewMode(data)),
        showMessage: data => dispatch(showMessage(data)),
        setAppModeData: data => dispatch(setAppModeData(data)),
    };
};

class MenuEditor extends Component { 

    constructor(props) {
        super(props);

        this.toggleViewModeRef = React.createRef();

        this.state = {
            loading: true,
            menu: false,
        }

        this.showFullPreview = this.showFullPreview.bind(this);
        this.toggleViewModePanel = this.toggleViewModePanel.bind(this);
        this.togglePlannerViewMode = this.togglePlannerViewMode.bind(this);
        this.updateEvaluation = this.updateEvaluation.bind(this);
    }

    componentDidMount() {
        api('load/menu/' + this.props.data.appModeData.menuId).then(result => {

            result = result.data;
            result.callback = () => {
                this.updateEvaluation();
                this.setState({
                    loading: false
                });
            };

            this.props.setAppModeData({menu: result.state.menu});
            this.props.loadDayPlanCallback(result);
        });
    }

    toggleViewModePanel() {
        const tgl = this.state.toggleViewModePanelOpened;
        this.setState({
            toggleViewModePanelOpened: !tgl
        });
    }

    togglePlannerViewMode(mode) {
        let currentViewMode = this.props.data.plannerViewMode;
        //this.props.setPlannerViewMode(1 - currentViewMode);
        this.setState({
            toggleViewModePanelOpened: false,
            showFullPreview: false
        });
        if(currentViewMode !== mode) {
            this.props.setPlannerViewMode(mode);
        }
    }

    showFullPreview() {
        let showFP = !this.state.showFullPreview;
        if(showFP) {
            this.props.setPlannerViewMode(0);
            this.setState({
                toggleViewModePanelOpened: false,
                showFullPreview: showFP
            });
        } else {
            this.setState({
                toggleViewModePanelOpened: false,
                showFullPreview: showFP
            });
        }
    }

    updateEvaluation() {
        Util.doLoadDayEvaluation();        
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = data;

        let content = <Loading />;

        if(this.state.loading) return content;

        let activeProfileId = data.profileId;
        if(activeProfileId == 0) {
            activeProfileId = Object.keys(globals.profiles)[0];
        }
        
        let viewTypeSwitchTitle = state.showFullPreview ? 'Hodinový přehled' : 'Celkový přehled';
        let viewTypeSwitch = <a className="btn-plain" onClick={this.showFullPreview}>{viewTypeSwitchTitle}</a>;

        let compactSwitchIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.058 216.666" width="100%" height="100%"><path d="M46.593 110.218H67.06c3.378 0 6.14 2.763 6.14 6.14v37.357h34.285c5.475 0 8.187 6.6 4.349 10.49l-50.658 50.658a6.146 6.146 0 01-8.699 0L1.82 164.205c-3.889-3.89-1.126-10.49 4.35-10.49h34.283v-37.357c0-3.377 2.763-6.14 6.14-6.14zM67.465 106.449H46.998c-3.378 0-6.14-2.763-6.14-6.14V62.951H6.573c-5.476 0-8.188-6.601-4.35-10.49L52.882 1.804a6.146 6.146 0 018.699 0l50.658 50.658c3.889 3.889 1.126 10.49-4.35 10.49H73.607v37.356c0 3.378-2.763 6.14-6.14 6.14z"/></svg>;
        if(data.plannerViewMode == 0) {
            compactSwitchIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.058 218.231" width="100%" height="100%"><path d="M46.593 0H67.06c3.378 0 6.14 2.763 6.14 6.14v37.357h34.285c5.475 0 8.187 6.601 4.349 10.49l-50.658 50.658a6.146 6.146 0 01-8.699 0L1.82 53.987c-3.889-3.889-1.126-10.49 4.35-10.49h34.283V6.14c0-3.377 2.763-6.14 6.14-6.14zM67.465 218.231H46.998c-3.378 0-6.14-2.763-6.14-6.14v-37.357H6.573c-5.476 0-8.188-6.601-4.35-10.49l50.658-50.658a6.146 6.146 0 018.699 0l50.658 50.658c3.889 3.889 1.126 10.49-4.35 10.49H73.607v37.357c0 3.377-2.763 6.14-6.14 6.14z"/></svg>;
        }

        let compactSwitch = (
            <>
            <input type="checkbox" id="dayplanner_viewmode_toggle" onChange={() => this.togglePlannerViewMode(0)} defaultChecked={data.plannerViewMode == 0} />
            <label className="btn-plain" htmlFor="dayplanner_viewmode_toggle">
                {compactSwitchIcon}
            </label>
            </>
        );

        if(state.showFullPreview) compactSwitch = false;

        let viewTypeSwitchText = __('Hodinový');
        if(this.props.data.plannerViewMode === 1) viewTypeSwitchText = __('Kompaktní');

        let toggleViewPanel = false;
        if(this.state.toggleViewModePanelOpened) {
            toggleViewPanel = (
                <div className="options">
                    <a onClick={() => this.togglePlannerViewMode(0)}>{__('Hodinový')}</a>
                    <a onClick={() => this.togglePlannerViewMode(1)}>{__('Kompaktní')}</a>
                </div>
            );
        }

        let viewTypeSwitch2 = (
            <div className="planner-switch">
                <label for="cb-planner-switch" onClick={() => this.toggleViewModePanel()}>{__('Přehled')}: <b>{viewTypeSwitchText}</b> <i className="fa fa-angle-down"></i></label>
                {toggleViewPanel}
            </div>
        );


        const lectureTaskPlan = <MenuEditorDayPlan 
                                    date={this.state.currentDate} 
                                    onUpdateEvaluation={this.updateEvaluation} 
                                    onChange={this.saveDayPlan} />;

        content = <MenuEditorPlan
                        lectureInfo={state.lectureInfo}
                        viewTypeSwitch2={viewTypeSwitch2}
                        viewTypeSwitch={viewTypeSwitch}
                        compactSwitch={compactSwitch}
                        plan={lectureTaskPlan}
                        openAssignmentInfo={this.openAssignmentInfo}
                        openProfileInfo={this.openProfileInfo}
                        openPanelEnergyOutput={this.openPanelEnergyOutput}
                        openSaveLectureTaskPanel={this.openSaveLectureTaskPanel}
                        openLoadLectureTaskPanel={this.openLoadLectureTaskPanel}
                        openLectureTaskSampleText={this.openLectureTaskSampleText}
                        openSeminarWorkLongtermEvaluationPanel={this.openSeminarWorkLongtermEvaluationPanel}
                    />;

        return content;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuEditor);
