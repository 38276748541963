import Util from 'utility';

export default class UnitConverter {

    constructor() {
        this.units = {
            'ml': 'mililitr',
            'g': 'gram',
            'tsp': 'čajová lžička',
            'tbsp': 'polévková lžíce',
            'cup': 'šálek',
            'slice': 'krajíc',
            'pc': 'ks',
            'cut': 'plátek',
            'pinch': 'špetka',
            'cube': 'kostka',
            'clove': 'stroužek',
        };

        this.unitDescend = {
            'cup': 'tbsp',
            'tbsp': 'tsp',
            'tsp': 'ml'
        };

        this.unitAscend = {
            'ml': 'tsp',
            'tsp': 'tbsp',
            'tbsp': 'cup'
        };

        this.roundTo = [
            [0, '0/0'],
            [0.125, '1/8'],
            [0.25, '1/4'],
            [0.33, '1/3'],
            [0.5, '1/2'],
            [0.66, '2/3'],
            [0.75, '3/4'],
            [1, '1/1']
        ];

        this.unitConversionsCs = {
            'ml': { 'multiplier': 1, 'titles': ['ml', 'ml', 'ml', 'ml'] },
            'tsp': { 'multiplier': 5, 'limits': [0, 1.5], 'titles': ['čajová lžička', 'čajové lžičky', 'čajové lžičky', 'čajových lžiček'] },
            'tbsp': { 'multiplier': 15, 'limits': [0.5, 4], 'titles': ['polévková lžíce', 'polévkové lžíce', 'polévkové lžíce', 'polévkových lžic'] },
            'cup': { 'multiplier': 240, 'format': '%01d', 'limits': [0.25, 99999999999999], 'titles': ['šálek', 'šálku', 'šálky', 'šálků'] },
            'slice': { 'multiplier': 99999999, 'titles': ['krajíc', 'krajíce', 'krajíce', 'krajíců'] },
            'pc': { 'multiplier': 99999999, 'titles': ['kus', 'kusu', 'kusy', 'kusů'] },
            'piece': { 'multiplier': 99999999, 'titles': ['kus', 'kusu', 'kusy', 'kusů'] },
            'cut': { 'multiplier': 99999999, 'titles': ['plátek', 'plátku', 'plátky', 'plátků'] },
            'mg': { 'multiplier': 1, 'titles': ['mg', 'mg', 'mg', 'mg']},
            'mcg': { 'multiplier': 1, 'titles': ['mcg', 'mcg', 'mcg', 'mcg']},
            'pinch': { 'multiplier': 1, 'titles': ['špetka', 'špetky', 'špetky', 'špetek']},
            'cube': { 'multiplier': 1, 'titles': ['kostka', 'kostky', 'kostky', 'kostek']},
            'clove': { 'multiplier': 1, 'titles': ['stroužek', 'stroužku', 'stroužky', 'stroužků']},
        };

        this.unitConversionsSk = {
            'ml': { 'multiplier': 1, 'titles': ['ml', 'ml', 'ml', 'ml'] },
            'tsp': { 'multiplier': 5, 'limits': [0, 1.5], 'titles': ['čajová lyžička', 'čajové lyžičky', 'čajové lyžičky', 'čajových lyžičiek'] },
            'tbsp': { 'multiplier': 15, 'limits': [0.5, 4], 'titles': ['polievková lyžica', 'polievkové lyžice', 'polievkové lyžice', 'polievkových lyžíc'] },
            'cup': { 'multiplier': 240, 'format': '%01d', 'limits': [0.25, 99999999999999], 'titles': ['šálka', 'šálky', 'šálky', 'šálok'] },
            'slice': { 'multiplier': 99999999, 'titles': ['krajec', 'krajce', 'krajce', 'krajcov'] },
            'pc': { 'multiplier': 99999999, 'titles': ['kus', 'kusu', 'kusy', 'kusov'] },
            'piece': { 'multiplier': 99999999, 'titles': ['kus', 'kusu', 'kusy', 'kusov'] },
            'cut': { 'multiplier': 99999999, 'titles': ['plátok', 'plátku', 'plátky', 'plátkov'] },
            'mg': { 'multiplier': 1, 'titles': ['mg', 'mg', 'mg', 'mg']},
            'mcg': { 'multiplier': 1, 'titles': ['mcg', 'mcg', 'mcg', 'mcg']},
            'pinch': { 'multiplier': 1, 'titles': ['štipka', 'štipky', 'štipky', 'štipok']},
            'cube': { 'multiplier': 1, 'titles': ['kocka', 'kocky', 'kocky', 'kociek']},
            'clove': { 'multiplier': 1, 'titles': ['strúčik', 'strúčika', 'strúčiky', 'strúčikov']},
        };

        if(Util.getLang() == 'cs') {
            this.unitConversions = this.unitConversionsCs;
        }

        if(Util.getLang() == 'sk') {
            this.unitConversions = this.unitConversionsSk;
        }

    }

    floatToFraction(n, tolerance) {
        if (!tolerance) tolerance = 1.e-6;

        let nbase = false;
        if (n > 1) {
            nbase = Math.floor(n);
            n -= nbase;
        }

        n = Math.round(n * 8) / 8;
        if (n == 0) return [nbase, ''];

        let h1 = 1, h2 = 0, k1 = 0, k2 = 1;

        let a, b = 1 / n;
        let aux;

        do {
            b = 1 / b;
            a = Math.floor(b);
            aux = h1; h1 = a * h1 + h2; h2 = aux;
            aux = k1; k1 = a * k1 + k2; k2 = aux;
            b = b - a;
        } while (Math.abs(n - h1 / k1) > n * tolerance);

        let r, frac = n, resFrac;
        let diff, maxDiff = 1000;
        for (var k in this.roundTo) {
            r = this.roundTo[k];
            diff = Math.abs(r[0] - frac);
            if (diff < maxDiff) {
                maxDiff = diff;
                resFrac = r[1];
            }
        }

        if (resFrac == '0/0') {
            return [nbase, ''];
        }

        if (resFrac == '1/1') {
            return [nbase + 1, ''];
        }

        return [nbase, resFrac];
    }

    convert(item, selectedWeight) {
        if (!selectedWeight) selectedWeight = item.selectedWeight;

        let weightMult = selectedWeight / item.weight;
        let unitAmount;
        let unit = item.norm_unit_unit;
        let normAmount = item.norm_unit_amount * weightMult;

        let unit2 = item.norm_unit2_unit;
        let normAmount2 = item.norm_unit2_amount * weightMult;

        let result = [];

        if (unit != '') {
            unitAmount = normAmount;

            if(typeof this.unitConversions[unit] != 'undefined') {
                if(typeof this.unitConversions[unit]['limits'] != 'undefined') {
                    let limit = this.unitConversions[unit]['limits'];
                    if (unitAmount < limit[0] && typeof this.unitDescend[unit] != 'undefined') {
                        unit = this.unitDescend[unit];
                        let newUnitMultiplier = this.unitConversions[unit]['multiplier'];
                        unitAmount *= newUnitMultiplier;
                    }
                }
            }

            let titleNr = 0;
            let unitAmountFloat = unitAmount;
            let fraction = this.floatToFraction(unitAmountFloat);
            let amountBase = fraction[0];
            let amountFraction = fraction[1];
            let ab;
            if (amountBase === false) amountBase = '';
            if (amountFraction === false) amountFraction = '';

            if (amountBase == '' && amountFraction == '') {
                titleNr = 1;
            }

            if (amountBase == '' && amountFraction != '') {
                titleNr = 1;
            }

            if (amountBase != '' && amountFraction == '') {
                // samotne cislo
                ab = amountBase * 1;
                if (ab == 1) {
                    titleNr = 0;
                }
                if (ab > 1 && ab < 5) {
                    titleNr = 2;
                }
                if (ab >= 5) {
                    titleNr = 3;
                }
            }

            if (amountBase != '' && amountFraction != '') {
                // cislo se zlomkem
                ab = amountBase * 1;
                if (ab == 1) {
                    titleNr = 1;
                }
                if (ab > 1) {
                    titleNr = 1;
                }
            }

            let unitTitle = unit;
            if(typeof this.unitConversions[unit] !== 'undefined') {
                unitTitle = this.unitConversions[unit]['titles'][titleNr];
            }

            let title = [];
            if (amountBase != '') title.push(amountBase);
            if (amountFraction != '') title.push(amountFraction);

            result.push(title.join(' ') + ' ' + unitTitle);
        }


        if (unit2 != '' && unit2 != '0') {
            unitAmount = normAmount2;

            if(typeof this.unitConversions[unit2] != 'undefined') {
                if(typeof this.unitConversions[unit2]['limits'] != 'undefined') {
                    let limit = this.unitConversions[unit2]['limits'];
                    if (unitAmount < limit[0] && typeof this.unitDescend[unit2] != 'undefined') {
                        unit = this.unitDescend[unit2];
                        let newUnitMultiplier = this.unitConversions[unit2]['multiplier'];
                        unitAmount *= newUnitMultiplier;
                    }
                }
            }

            let titleNr = 0;
            let unitAmountFloat = unitAmount;
            let fraction = this.floatToFraction(unitAmountFloat);
            let amountBase = fraction[0];
            let amountFraction = fraction[1];
            let ab;
            if (amountBase === false) amountBase = '';
            if (amountFraction === false) amountFraction = '';

            if (amountBase == '' && amountFraction == '') {
                titleNr = 1;
            }

            if (amountBase == '' && amountFraction != '') {
                titleNr = 1;
            }

            if (amountBase != '' && amountFraction == '') {
                // samotne cislo
                ab = amountBase * 1;
                if (ab == 1) {
                    titleNr = 0;
                }
                if (ab > 1 && ab < 5) {
                    titleNr = 2;
                }
                if (ab >= 5) {
                    titleNr = 3;
                }
            }

            if (amountBase != '' && amountFraction != '') {
                // cislo se zlomkem
                ab = amountBase * 1;
                if (ab == 1) {
                    titleNr = 1;
                }
                if (ab > 1) {
                    titleNr = 1;
                }
            }

            let unitTitle = unit2;
            if(typeof this.unitConversions[unit2] !== 'undefined') {
                unitTitle = this.unitConversions[unit2]['titles'][titleNr];
            }

            let title = [];
            if (amountBase != '') title.push(amountBase);
            if (amountFraction != '') title.push(amountFraction);

            result.push(title.join(' ') + ' ' + unitTitle);
        }



        if(result.length == 0) return '';

        return result.join(', ');
    }

}
