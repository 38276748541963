import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../utility';
import Loading from 'components/Loading/index';
import api from 'api';
import Util from 'utility.js';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class News extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: {}
        };    
    }

    componentDidMount() {
        Util.setPageTitle('Úvod');
        api('load/main-panel-data').then(result => {
            result = result.data;
            this.setState({
                data: result,
                loading: false
            }, () => {
                Util.writeCookie('zof_news_last_visit', new Date().getTime(), 365);
            });
        });
    }

    render() {
        const { state } = this;

        let content = <Loading />;
        if(!state.loading) {
            let news = [];
            news.push(<h2>Aktuality</h2>);
            Object.keys(state.data.latestNews).map(k => {
                const n = state.data.latestNews[k];
                news.push(
                    <div className="news-item">
                        <h4>{n.title}</h4>
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: n.body }}></div>
                            <div style={{ clear: 'both', float: 'none' }}></div>
                        </div>
                    </div>
                );
            });
            content = (
                <div className="panel panel-padded mt-1 mb-1">
                <div className="news">
                    {news}
                </div>              
                </div>
            );
        }

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(News);
