import React, { Component } from 'react';
import { connect } from 'react-redux';

import { 
    setProductDescription,
} from 'actions/index';


const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setProductDescription: data => dispatch(setProductDescription(data)),
    };
};


class PanelProductDescription extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            description: this.props.data.productDescription,
        };    
        this.update = this.update.bind(this);
    }

    selectFoodGroup(e) {
        const fgId = e.target.value;
        this.setState({
            selectedFoodGroupId: fgId
        });
    }

    update(e) {
        this.setState({
            description: e.target.value
        }, () => {
            this.props.setProductDescription(this.state.description);
        });
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = this.props.data;

        return (
            <React.Fragment>
                <div className="panel-recipe-description">
                    <p>
                        <textarea onChange={this.update} style={{ height: '9em' }} value={state.description}></textarea>
                    </p>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelProductDescription);
