import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, Prompt } from 'react-router-dom';
import store from 'store/index';
import ProfileMenu from './ProfileMenu';
import Util from 'utility';
import api from 'api';
import { Locale, __ } from 'locale';

import PanelPurchaseCookbook from 'components/PanelPurchaseCookbook';
import PanelPurchaseProfi from 'components/PanelPurchaseProfi';
import PanelBuy from 'components/PanelBuy';

import { 
    openModal, 
    closeModal, 
    setData,
    loadGlobals,
    loginUser
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        loadGlobals: data => dispatch(loadGlobals(data)),
        loginUser: data => dispatch(loginUser(data)),
        setData: data => dispatch(setData(data)),
    };
};

class Header extends Component { 
    constructor(props) {
        super(props);
        this.openBuy = this.openBuy.bind(this);
        this.openBuyCookbook = this.openBuyCookbook.bind(this);
        this.openBuyProfi = this.openBuyProfi.bind(this);
        this.profiSwitchBackToAdvisor = this.profiSwitchBackToAdvisor.bind(this);
        this.goToPlan = this.goToPlan.bind(this);
    }

    openBuy() {
        this.props.closeModal();
        this.props.openModal({
            'title': 'Koupit', 
            'class': 'dialog-flexible',
            'content': (
                <PanelBuy onDone={this.props.closeModal} />
            )
        });
    }

    openBuyCookbook() {
        this.props.closeModal();
        this.props.openModal({
            'title': 'ZOF kuchařka - koupit plný přístup', 
            'class': 'dialog-flexible',
            'content': (
                <PanelPurchaseCookbook onDone={this.props.closeModal} />
            )
        });
    }

    openBuyProfi() {
        this.props.closeModal();
        this.props.openModal({
            'title': 'ZOF profi - koupit přístup', 
            'class': 'dialog-flexible',
            'content': (
                <PanelPurchaseProfi onDone={this.props.closeModal} />
            )
        });
    }

    goToPlan(e) {
        if(this.props.data.appMode === 'LECTURE') {
            const url = this.props.location.pathname;
            if(url.substr(0, 18) == '/recipe/edit/meal/') {
                if(window.confirm('Chcete opustit tuto stránku?')) {
                    this.props.history.push('/plan');
                } else {
                    e.preventDefault();
                }
            } else {
                this.props.history.push('/plan');
            }
        } else {
            this.props.history.push('/plan');
        }
    }

    profiSwitchBackToAdvisor(e, client) {
        let returnHash = localStorage.getItem('zof_profi_return_hash');
        if(returnHash) {
            const currentUserId = this.props.data.user.id;
            this.setState({
                loading: true
            }, () => {
                api('clients/switch-back', { post: true, data: { return_hash: returnHash }}).then((res) =>{
                    let result = res.data;
                    if(typeof result.error !== 'undefined') return;
                    localStorage.removeItem('zof_profi_return_hash');
                    localStorage.setItem('jwt_token', result.auth_token);
                    this.props.loadGlobals(result.startup_data);
                    this.props.loginUser({
                        user: result.startup_data.user,
                        callback: () => {
                            this.setState({
                                user: result.startup_data.user,
                                loading: false
                            }, () => {
                                this.props.history.push('/clients/' + currentUserId);
                            });
                        }
                    });
                });
            });
        }
    }

    render() {
        const state = store.getState();
        const { user } = state;
        const { data } = this.props;
        const { globals } = data;

        let activeProfileId = data.profileId;
        if(activeProfileId == 0) {
            if(typeof globals.profiles == 'object') {
                let pk = Object.keys(globals.profiles);
                if(pk.length > 0) {
                    activeProfileId = Object.keys(globals.profiles)[0];
                }
            }
        }

        const navLinks = ['home', 'recipe', 'menu', 'food',  'myfoods', 'user', 'profile', 'myrecipe', 'cookbook', 'plan', 'profiles', 'products', 'product', 'recipe', 'gastroprofile', 'clients', 'clients/chat'];
        let active = 'home';
        navLinks.forEach(link => {
            if(this.props.location.pathname.match('/' + link)) {
                active = link;
            }
        });    

        if(active == 'myfoods') active = 'foods';
        if(active == 'myrecipe') active = 'cookbook';
        if(active == 'product') active = 'products';

        let buyLink = false;
        if(!Util.userHasPurchased('ZOF_COOKBOOK') || !Util.userHasPurchased('ZOF_PROFI')) {
            buyLink = <a onClick={this.openBuy}>Koupit</a>;
        }

        let gastroProfileLink = false;
        if(Util.userHasPurchased('ZOF_GASTRO')) {
            gastroProfileLink = <Link className={"" + active === 'gastroprofile' ? 'active' : ''} to="/gastro/profile" title="Gastro">Gastro</Link>;
        }

        let profiClientsLink = false;
        if(Util.userHasPurchased('ZOF_PROFI')) {
            profiClientsLink = <Link className={"" + active === 'clients' ? 'active' : ''} to="/clients" title="Profi">Profi</Link>;
        }

        let clientProfiLink = false;
        if(typeof this.props.data.user.zof_parent_user_id === "number" && this.props.data.user.zof_parent_user_id > 0) {
            // Check ZOF profi expiry date (date of end of cooperation with advisor)
            let date1 = this.props.data.user.zof_profi_client_expiry + ' 23:59:59';
            const clientExpiryTime = Math.floor(new Date(Date.parse(date1)).getTime() / 1000);
            const nowTime = Math.floor(new Date().getTime() / 1000);
            if(nowTime <= clientExpiryTime) {
                clientProfiLink = <Link className={"" + active === 'clients/chat' ? 'active' : ''} to={`/clients/chat/${this.props.data.user.zof_parent_user_id}`} title="Chat">Můj poradce</Link>;
            }
        }

        let profiLinkBackToAdvisor = false;
        let returnHash = localStorage.getItem('zof_profi_return_hash');
        if(returnHash) {
            profiLinkBackToAdvisor = <a onClick={this.profiSwitchBackToAdvisor}>Zpět na Profi účet</a>
        }
    

        let navButtons = <nav style={{flex:'1'}}>
            <label htmlFor="cb-nav"><i className="fa fa-times"></i></label>
            <Link className={"" + active === 'plan' ? 'active' : ''} to="/plan" title="Plánovač">Plánovač</Link>
            <Link className={"" + active === 'recipe' ? 'active' : ''} to="/recipe" title="Recepty">Recepty</Link>
            <Link className={"" + active === 'food' ? 'active' : ''} to="/food" title="Potraviny">Potraviny</Link>
            <Link className={"" + active === 'menu' ? 'active' : ''} to="/menu" title="Jídelníčky">Jídelníčky</Link>
            <Link className={"" + active === 'generator' ? 'active' : ''} to="/generator" title="Generátor">Generátor</Link>
            {gastroProfileLink}
            {profiClientsLink}
            {clientProfiLink}
            {profiLinkBackToAdvisor}
            {buyLink}
        </nav>;

        if(this.props.data.appMode === 'LECTURE') {

            let taskTitle = 'Úkol';
            if(Util.getLang() == 'sk') taskTitle = 'Úloha';

            let seminarWorkData = this.props.data.appModeData?.lectureInfoData?.seminar_work_data;
            if(typeof seminarWorkData === 'object') {
                taskTitle = __('Jídelníček');
            }


            navButtons = <nav style={{flex:'1'}}>
                <label htmlFor="cb-nav"><i className="fa fa-times"></i></label>
                
                <a onClick={(e) => this.goToPlan(e)} className={"" + active === 'plan' ? 'active' : ''} title="Úkol">{taskTitle}</a>
                <Link className={"" + active === 'recipe' ? 'active' : ''} to="/recipe" title="Recepty">Recepty</Link>
            </nav>;
        }

        return (
            <header>
                <div className="container">
                    <Link to="/">
                        <b>ZOF</b><br />APP
                    </Link>
                    <input type="checkbox" id="cb-nav" />
                    <label htmlFor="cb-nav"><i className="fa fa-bars"></i> Menu</label>
                    {navButtons}
                    <aside>
                        <ProfileMenu />
                    </aside>
                    <div className="nav-shadow"></div>
                </div>
            </header>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
