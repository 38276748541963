import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import api from 'api';

import { 
    setFoodGroupDescription,
} from "actions/index";


const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setFoodGroupDescription: data => dispatch(setFoodGroupDescription(data)),
    };
};


class PanelPurchaseCookbook extends Component { 
    constructor(props) {
        super(props);

        let initialOrderData = {
            'name': this.props.data.user.name,
            'street': this.props.data.user.street,
            'city': this.props.data.user.city,
            'zip': this.props.data.user.zip,
            'country': this.props.data.user.country,
            'company_name': '',
            'company_street': '',
            'company_city': '',
            'company_zip': '',
            'company_country': '',            
            'email': this.props.data.user.email,
            'phone': this.props.data.user.phone,
            'ic': '',
            'dic': '',
            'comment': '',
            'terms': false,
            'currency': 'CZK',
        };

        if(initialOrderData.name.indexOf('@') >= 0) initialOrderData.name = '';

        this.state = {
            orderData: initialOrderData,
            orderSent: false,
            orderId: 0,
            inputCompanyData: false,
            inputComment: false,
            loading: false,
        };    

        this.switchCurrency = this.switchCurrency.bind(this);
        this.onChange = this.onChange.bind(this);
        this.sendOrder = this.sendOrder.bind(this);
    }

    switchCurrency() {
        let orderData = this.state.orderData;
        if(orderData.currency == 'CZK') {
            orderData.currency = 'EUR';
        } else {
            orderData.currency = 'CZK';
        }
        this.setState({
            orderData: orderData
        });
    }

    onChange(e) {
        let orderData = this.state.orderData;
        const key = e.target.name;
        let value = e.target.value;
        if(key == 'terms') {
            orderData[key] = e.target.checked;
        } else {
            orderData[key] = value;
        }
        this.setState({
            orderData: orderData
        });
    }

    sendOrder(e) {
        e.preventDefault();
        const { orderData } = this.state;

        if(orderData.name == '') {
            alert("Prosím vyplňte Vaše jméno.");
            return;
        }
        if(orderData.street == '') {
            alert("Prosím vyplňte ulici.");
            return;
        }
        if(orderData.city == '') {
            alert("Prosím vyplňte město.");
            return;
        }
        if(orderData.zip == '') {
            alert("Prosím vyplňte PSČ.");
            return;
        }
        if(orderData.country == '') {
            alert("Prosím vyplňte zemi.");
            return;
        }
        if(orderData.email == '') {
            alert("Prosím vyplňte e-mail.");
            return;
        }
        if(orderData.terms !== true) {
            alert("Před odesláním objednávky je nutno odsouhlasit obchodní podmínky");
            return;
        }

        let fd = new FormData();
        for(let i in orderData) {
            fd.append(i, orderData[i]);
        }

        api('account/purchase-cookbook', {post: true, data: fd}).then(result => {
            this.setState({
                orderSent: true,
                orderId: result.data.order_id,
            })
        });        
    }

    render() {
        const { state } = this;

        let companyData = (
            <React.Fragment>
                <p><input type="checkbox" onChange={() => this.setState({inputCompanyData:true})} name="inputCompanyData" /> fakturujete na firmu?</p>
            </React.Fragment>
        );

        if(state.inputCompanyData) {
            companyData = (
                <React.Fragment>
                <h3>Firemní údaje</h3>
                <p><span>Název firmy:</span><br /><input onChange={this.onChange} type="text" name="company_name" value={state.orderData.company_name} /></p>
                <div className="row">
                    <p><span>IČ:</span><br /><input type="text" onChange={this.onChange} name="ic" value={state.orderData.ic} /></p>
                    <p><span>DIČ:</span><br /><input type="text" onChange={this.onChange} name="dic" value={state.orderData.dic} /></p>
                </div>
                </React.Fragment>
            );
        }

        let commentData = (
            <React.Fragment>
                <p><input type="checkbox" onChange={() => this.setState({inputComment:true})} name="inputComment" /> připojit poznámku</p>
            </React.Fragment>
        );

        if(state.inputComment) {
            commentData = (
                <React.Fragment>
                <h3>Poznámka</h3>
                <p><textarea onChange={this.onChange} type="text" name="comment" value={state.orderData.comment} /></p>
                </React.Fragment>
            );
        }



        let priceInfo = '990 Kč';
        let currencySwitch = <a className="button-default" style={{fontSize:'12px',padding:'4px 5px'}} onClick={() => this.switchCurrency()}>Chci zaplatit v EUR</a>;
        if(state.orderData.currency == 'EUR') {
            priceInfo = '40 EUR';
            currencySwitch = <a className="button-default" style={{fontSize:'12px',padding:'4px 5px'}} onClick={() => this.switchCurrency()}>Chci zaplatit v CZK</a>;            
        }

        let content = (
            <div className="panel-purchase" style={{padding:'1em'}}>
                <div style={{height:'80vh',maxHeight:'700px'}}>
                <Scrollbars heightrelativetoparent="1">
                    <div style={{ flex: '1', overflow: 'hidden', padding: '0 14px 0 0' }}>
                        <div className="why">
                            <div><i className="fa fa-check"></i> <p>Získejte neomezený přístup k ZOF kuchařce, která aktuálně obsahuje více než 700 receptů (další neustále přibývají).</p></div>
                            <div><i className="fa fa-check"></i> <p>Vybírejte a používejte tyto recepty ve svých jídelníčcích a upravujte je dle svých potřeb a představ.</p></div>
                            <div><i className="fa fa-check"></i> <p>Využívejte potenciál generátoru jídelníčků - vytvoří stravovací plán, který nutričně vyhovuje vašemu profilu.</p></div>
                        </div>
                        <p style={{margin:'2em 0'}}>Jednorázová cena přístupu k ZOF kuchařce je <b>{priceInfo}</b>. {currencySwitch}</p>
                        <form>
                            <p><span>Vaše jméno *:</span><br /><input onChange={this.onChange} type="text" name="name" value={state.orderData.name} /></p>
                            <div className="row">
                                <p><span>Ulice *:</span><br /><input onChange={this.onChange} type="text" name="street" value={state.orderData.street} /></p>
                                <p><span>Město *:</span><br /><input onChange={this.onChange} type="text" name="city" value={state.orderData.city} /></p>
                            </div>
                            <div className="row">
                                <p><span>PSČ *:</span><br /><input onChange={this.onChange} type="text" name="zip" value={state.orderData.zip} /></p>
                                <p><span>Země *:</span><br /><input onChange={this.onChange} type="text" name="country" value={state.orderData.country} /></p>
                            </div>
                            <div className="row">
                                <p><span>E-mail *:</span><br /><input onChange={this.onChange} type="text" name="email" value={state.orderData.email} /></p>
                                <p><span>Telefon:</span><br /><input onChange={this.onChange} type="text" name="phone" value={state.orderData.phone} /></p>
                            </div>
                            {companyData}
                            {commentData}
                            <p class="info">Forma úhrady je bankovní převod (platbu kartou připravujeme). Údaje k platbě Vám budou zaslány na e-mail obratem po odeslání objednávky. Plnohodnotný přístup Vám bude aktivován do 2 pracovních dnů po obdržení platby.</p>
                            <p><input type="checkbox" name="terms" onChange={this.onChange} defaultChecked={state.orderData.terms} /> souhlasím s <a target="_blank" href="https://www.zofapp.cz/obchodni-podminky">obchodními podmínkami</a></p>
                            <p><button onClick={this.sendOrder}>Odeslat objednávku</button></p>
                        </form>
                    </div>
                </Scrollbars>
                </div>
            </div>
        );

        if(this.state.orderSent) {
            content = (
                <div className="panel-purchase" style={{ textAlign: 'center' }}>
                    <p style={{ fontSize: '5em', margin: '0 0 .15em 0', color: '#4eb32d' }}><i className="fa fa-check"></i></p>
                    <p><b>Děkujeme! Vaše objednávka byla úspěšně odeslána.</b></p>
                    <p>Zaslali jsme na Váš e-mail potvrzení objednávky společně s platebními údaji pro její úhradu.</p>
                </div>
            );
        }

        return content;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPurchaseCookbook);
