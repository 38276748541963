import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Util from 'utility';
import api from 'api';
import { Locale, __ } from 'locale';


import {
    openModal,
    closeModal
} from 'actions/index';

import EvaluationPanel from 'components/EvaluationPanel';
import { isAfter } from 'date-fns';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: (data) => dispatch(openModal(data)),
        closeModal: (data) => dispatch(closeModal(data)),
    };
};

class MenuEditorPlan extends Component {

    constructor(props) {
        super(props);

        this.openLectureTaskSampleMenu = this.openLectureTaskSampleMenu.bind(this);
        this.showTaskListDDDInfoFunc = this.showTaskListDDDInfoFunc.bind(this);
        this.seminarWorkChangeDay = this.seminarWorkChangeDay.bind(this);
    }

    openLectureTaskSampleMenu() {
        const sampleLectureTaskId = this.props.data.appModeData.lectureInfoData.lecture_data.sample_lecturetask_id;

        let id = sampleLectureTaskId;
        id = 8800000 + id * 7;      // TODO: this is shitty as fuck, at least make a hash or something
                                    // (update in lecturetask/load API call too)

        this.props.setAppSubMode('LECTURETASK_SAMPLE');
        this.props.history.push('/load/task/' + id + '/' + this.props.data.appModeData.lectureId);
    }

    seminarWorkChangeDay(l, e) {
        this.props.history.push('/load/seminarwork-task/' + l + '/' + l);
    }

    showTaskListDDDInfoFunc(info, id, type) {
        let content = [];
        let mealsParts = {};

        let data = this.props.data;

        if(type === 'category') {
            if(typeof info['categories'][id] !== 'undefined') {
                let tmp = {};
                info['categories'][id].map(f => {
                    const mk = f.meal + '|' + f.meal_id;
                    if(typeof tmp[mk] === 'undefined') tmp[mk] = {};
                    if(typeof tmp[mk][f.baseFood.title] === 'undefined') tmp[mk][f.baseFood.title] = [];                   
                    if(f.baseFood.title !== f.food.title) {
                        tmp[mk][f.baseFood.title].push(f.food.title);
                    }
                });

                Object.keys(data.globals.mealTypes).map(m => {
                    const mt = data.globals.mealTypes[m].title;
                    const mk = mt + '|' + m;
                    if(typeof tmp[mk] !== 'undefined') {
                        content.push(<p><b>{mt}</b></p>);
                        let subresult = [];
                        Object.keys(tmp[mk]).map(fd => {
                            const subFoods = tmp[mk][fd];
                            if(subFoods.length > 0) {
                                subresult.push(<p>{fd}: {subFoods.join(', ')}</p>);
                            } else {
                                subresult.push(<p>{fd}</p>);
                            }
                        });
                        content.push(<div>{subresult}</div>);
                    }
                });

                content = <div className="lecturetask-foodslist">{content}</div>;
            }
        

            let titlePart = 'Chody/potraviny obsahující'

            this.props.closeModal();
            this.props.openModal({
                title: __('Chody/potraviny obsahující') + ' "' + this.props.data.globals.foodCategories[id].title + '"',
                closeButton: __('Zavřít'),
                class: 'dialog-flexible',
                content: content
            });
        }


        if(type === 'tag') {

            if(typeof info['tags'][id] !== 'undefined') {
                let tmp = {};
                info['tags'][id].map(f => {
                    const mk = f.meal + '|' + f.meal_id;
                    if(typeof tmp[mk] === 'undefined') tmp[mk] = {};
                    if(typeof tmp[mk][f.baseFood.title] === 'undefined') tmp[mk][f.baseFood.title] = [];                   
                    if(f.baseFood.title !== f.food.title) {
                        tmp[mk][f.baseFood.title].push(f.food.title);
                    }
                });

                Object.keys(data.globals.mealTypes).map(m => {
                    const mt = data.globals.mealTypes[m].title;
                    const mk = mt + '|' + m;
                    if(typeof tmp[mk] !== 'undefined') {
                        content.push(<p><b>{mt}</b></p>);
                        let subresult = [];
                        Object.keys(tmp[mk]).map(fd => {
                            const subFoods = tmp[mk][fd];
                            if(subFoods.length > 0) {
                                subresult.push(<p>{fd}: {subFoods.join(', ')}</p>);
                            } else {
                                subresult.push(<p>{fd}</p>);
                            }
                        });
                        content.push(<div>{subresult}</div>);
                    }
                });

                content = <div className="lecturetask-foodslist">{content}</div>;
            }
        
            this.props.closeModal();
            this.props.openModal({
                title: 'Chody/potraviny obsahujicí "' + this.props.data.globals.foodTags[id] + '"',
                closeButton: __('Zavřít'),
                class: 'dialog-flexible',
                content: content
            });
        }
        
    }

    render() {
        const { data } = this.props;
        const { globals } = data;
        const taskData = data.lectureTaskData;

        let evaluationPanelBeforeContent = false;

        let profileId = Util.getActiveProfileId();
        if(typeof data.appModeData.profileId === 'number') {
            profileId = data.appModeData.profileId;
        }

        let content = (
            <div className="page page-bg page-plan">
                <div className="page-header" style={{padding:'0'}}>
                    <div className="container">
                        <h1>{this.props.data.appModeData.menu.title}</h1>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container" style={{paddingTop:'0',paddingBottom:'1em'}}>
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-side" />
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-main" defaultChecked={true} />
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-evaluation" />
                        <div className="plan-mobile-nav">
                            <label htmlFor="cb-plan-main"><i className="fa fa-angle-down"></i> Jídelníček</label>
                            <label htmlFor="cb-plan-evaluation"><i className="fa fa-angle-down"></i> Vyhodnocení</label>
                        </div>
                        <div className="plan">
                            <div className="plan-row">
                                <input checked="checked" className="radio-plan-mobile-nav" type="checkbox" id="cb-panel-evaluation" />
                                <div className="plan-main" style={{paddingTop:'2em',marginRight:'0em'}}>
                                    <div className="planner-hours">
                                        {this.props.plan}
                                    </div>
                                </div>
                                <EvaluationPanel 
                                    beforeContent={evaluationPanelBeforeContent} 
                                    wholeDay={true} 
                                    quickResults={false}
                                    evaluationCollapsedAtStart={false}
                                    mainStyle={{paddingTop:'2em'}}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return content;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuEditorPlan));
