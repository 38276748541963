import axios from 'axios';
// import https from 'https';

function refreshToken(callback) {
    let jwtToken = localStorage.getItem('jwt_token');
    let instance = axios.create();

    let lang = localStorage.getItem('zofapp_lang');
    if(!lang) lang = 'cz';
    if(lang == 'cs') lang = 'cz';

    return new Promise((resolve, reject) => {
        return instance.post('https://www.zofapp.' + lang + '/api2/refresh-token', {token: jwtToken})
            .then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
    });
}

function jwt_decode(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
};

async function apiCall(url, args) {

    const mode = localStorage.getItem('zofapp_mode');

    let lang = localStorage.getItem('zofapp_lang');
    if(!lang) lang = 'cz';
    if(lang == 'cs') lang = 'cz';

    let jwtToken = localStorage.getItem('jwt_token');
    let instance;
    let params;

    if(args === undefined) args = { auth: true };
    if(args.auth === undefined) args.auth = true;

    if(mode === 'RECIPE_EDITOR' || mode == 'MENU_EDITOR') {
        // delete args.auth;
    }

    params = {
        //httpsAgent: new https.Agent({  
        //    rejectUnauthorized: false
        //})
    };

    if(typeof args.auth !== 'undefined' && args.auth === true) {
        params.headers = {
            Authorization: {
                toString () {
                    return `Bearer ${jwtToken}`
                }
            }
        };
    }

    if(typeof args.data === 'undefined') {
        args.data = {};
    }

    const apiUrl = 'https://www.zofapp.' + lang + '/api2';

    let result;
    instance = axios.create(params);
    if(typeof args.post !== 'undefined' && args.post === true) {
        result =  await instance.post(apiUrl + '/' + url, args.data);
    } else {
        result =  await instance.get(apiUrl + '/' + url, { params: args.data });
    }
    return result;
}

export default async function api(url, args) {
    let jwtToken = localStorage.getItem('jwt_token');
    if(jwtToken === 'undefined') {
        localStorage.setItem('redirect_to', window.location.href);
        window.location.href = '/login';
    }

    const appMode = localStorage.getItem('zofapp_mode');

    let timeToExpiry = 86400;

    if(!appMode || appMode === 'LECTURE') {
        let decodedToken = jwt_decode(jwtToken);
        let tokenExpiry = decodedToken.exp;
        let currentTime = Math.ceil(+ new Date / 1000);
        timeToExpiry = tokenExpiry - currentTime;
    } 

    if(timeToExpiry < -86400) {
        localStorage.setItem('redirect_to', window.location.href);
        window.location.href = '/login';
    }

    if(timeToExpiry < 5) {      // ATTN - why 5?
        return refreshToken().then(response => {
            jwtToken = response.data.new_token;
            localStorage.setItem('jwt_token', jwtToken);
            return apiCall(url, args);
        });
    } else {
        return apiCall(url, args);
    }
}
