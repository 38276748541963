import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import onClickOutside from "react-onclickoutside";
import store from 'store/index';
import Loading from 'components/Loading/index';
import Util from 'utility';
import api from 'api';
import { Locale, __ } from 'locale';

import { 
    setData,
    loadGlobals,
    loginUser
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setData: data => dispatch(setData(data)),
        loadGlobals: data => dispatch(loadGlobals(data)),
        loginUser: data => dispatch(loginUser(data)),
    };
};

class ProfileMenu extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            newNewsNumber: 0,
            loading: false,
        };

        this.getNewNewsNumber = this.getNewNewsNumber.bind(this);
        this.debug = this.debug.bind(this);
        this.hidePanel = this.hidePanel.bind(this);
        this.profiSwitchBackToAdvisor = this.profiSwitchBackToAdvisor.bind(this);
    }

    debug() {
        this.hidePanel();
        console.log(store.getState());
    }

    getNewNewsNumber() {
        let cookie = Util.readCookie('zof_news_last_visit');
        let lastVisitTimestamp = 0;
        if(cookie) lastVisitTimestamp = parseInt(cookie);

        lastVisitTimestamp = 1574255875;

        let newNewsItems = 0;
        this.props.data.globals.newsDates.map(nd => {
            if(nd > lastVisitTimestamp) {
                newNewsItems++;
            }
        });
        if(newNewsItems > 10) {
            newNewsItems = '10+';
        }


        this.setState({
            newNewsNumber: newNewsItems
        })
    }

    handleClickOutside = evt => {
        this.hidePanel();
    };

    hidePanel() {
        this.refs['refCb'].checked = false;
    }


    profiSwitchBackToAdvisor(e, client) {
        let returnHash = localStorage.getItem('zof_profi_return_hash');
        if(returnHash) {
            this.setState({
                loading: true
            }, () => {
                api('clients/switch-back', { post: true, data: { return_hash: returnHash }}).then((res) =>{
                    let result = res.data;
                    if(typeof result.error !== 'undefined') return;
                    localStorage.removeItem('zof_profi_return_hash');
                    localStorage.setItem('jwt_token', result.auth_token);
                    this.props.loadGlobals(result.startup_data);
                    this.props.loginUser({
                        user: result.startup_data.user,
                        callback: () => {
                            this.setState({
                                user: result.startup_data.user,
                                loading: false
                            }, () => {
                                this.props.history.push('/clients');
                            });
                        }
                    });
                });
            });
    }
    }

    render() {
        const { user } = this.props.data;

        if(this.state.loading) return false;

        let debugButton = false;
        if(user.id == 1485 || user.id == 90) {
            debugButton = <a onClick={this.debug}><span><i className="fa fa-bug"></i></span> Debug</a>;
        }

        let gastroLinks = false;
        if(Util.userHasPurchased('ZOF_GASTRO')) {
            gastroLinks = (
                <React.Fragment>
                <Link to="/gastro/profile">Gastro profil</Link>
                </React.Fragment>
            );
        }

        let cookie = Util.readCookie('zof_news_last_visit');
        let lastVisitTimestamp = 0;
        if(cookie) lastVisitTimestamp = parseInt(cookie);
        let newNewsItems = 0;
        this.props.data.globals.newsDates.map(nd => {
            if(nd > lastVisitTimestamp) {
                newNewsItems++;
            }
        });

        let newNewsBadge = false;
        if(newNewsItems > 0) {
            if(newNewsItems > 10) newNewsItems = '10+';
            newNewsBadge = <b>{newNewsItems}</b>;
        }

        // If curretly on news page, it means that there are no new news, so immediately remove the badge
        // (because header is drawn before the actual News page, so cookies are not set in time header gets drawn)
        if(this.props.location.pathname.match('/news')) {
            newNewsBadge = false;
        }

        const profId1 = Util.getOtherProfileId();
        const profId2 = Util.getFirstProfileId();

        const random = Math.random() * 10000000;
        let profileId = Util.getOtherProfileId();
        let avatar = false;
        if(profileId > 0) {
            let profile = this.props.data.globals.profiles[profileId];
            avatar = <img src={`https://www.zofapp.cz/data/zof/profile/${profile.picture}?r=${random}`} />;
        }

        let menu = (
            <>
                <Link onClick={this.hidePanel} to="/support"><span><i className="fa fa-question-circle"></i></span> <strong>Nápověda</strong></Link>
                <Link onClick={this.hidePanel} to="/profile" title="Profil"><span><i className="fa fa-user-circle"></i></span> Veřejný profil</Link>
                <Link onClick={this.hidePanel} to={`/user/edit/${profileId}`}><span><i className="fa fa-cog"></i></span> Nastavení</Link>
                <Link onClick={this.hidePanel} to="/news"><span><i className="fa fa-newspaper"></i></span> Aktuality {newNewsBadge}</Link>
                {debugButton}
                <a href="https://www.zofapp.cz/user/logout"><span><i className="fa fa-sign-out-alt"></i></span> Odhlásit se</a>
            </>
        );

        
        if(this.props.data.appMode === 'LECTURE') {

            profileId = Util.getActiveProfileId();
            if(profileId > 0) {
                let profile = this.props.data.globals.profiles[profileId];
                avatar = <img src={`https://www.zofapp.cz/data/zof/profile/${profile.picture}?r=${random}`} />;
            }
    
            // <Link onClick={this.hidePanel} to="/support"><span><i className="fa fa-question-circle"></i></span> <strong>Nápověda</strong></Link>
            menu = (
                <>
                <Link onClick={this.hidePanel} to={`/user/edit/${profileId}`}><span><i className="fa fa-cog"></i></span> {__('Nastavení')}</Link>
                    <a href="https://www.zofapp.cz/user/logout"><span><i className="fa fa-sign-out-alt"></i></span> {__('Odhlásit se')}</a>
                </>
            );
        }

        return (
            <div className="profile">
                <input type="checkbox" id="cb-profile" ref={'refCb'} />
                <label htmlFor="cb-profile">
                    {avatar}
                </label>
                <div className="profile-menu">
                    {menu}
                </div>
            </div>
        );

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(onClickOutside(ProfileMenu)));
