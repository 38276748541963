import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import api from 'api';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class DialogShoppingList extends Component { 

    constructor(props) {
        super(props);

        let now = Date.now();
        let initialStartDate = new Date(now);
        let initialEndDate = new Date(now + 1000*60*60*24*5);

        //initialStartDate.setHours(0, 0, 0, 0);
        //initialEndDate.setHours(0, 0, 0, 0);

        this.state = {
            startDate: initialStartDate,
            endDate: initialEndDate,
            startDateRaw: '',
            endDateRaw: '',
            shoppingList: false,
            invalidDates: false,
        };
        this.yyyymmdd = this.yyyymmdd.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.generateShoppingList = this.generateShoppingList.bind(this);
    }

    handleDateChange(e) {
        let lastStartDate = this.state.startDate;
        let lastEndDate = this.state.endDate;

        let newStartDate = lastStartDate;
        let newEndDate = lastEndDate;
        let startDateRaw = this.state.startDateRaw;
        let endDateRaw = this.state.endDateRaw;

        if(e.target.name == 'date-start') {
            startDateRaw = e.target.value;
            newStartDate = new Date(e.target.value)
        }
        if(e.target.name == 'date-end') {
            endDateRaw = e.target.value;
            newEndDate = new Date(e.target.value)
        }

        newStartDate.setHours(0, 0, 1, 0);
        newEndDate.setHours(0, 0, 1, 0);

        if(newStartDate >= newEndDate) {
            this.setState({
                invalidDates: true
            });
            return;
        }

        this.setState({
            invalidDates: false,
            startDate: newStartDate,
            endDate: newEndDate,
            startDateRaw: startDateRaw,
            endDateRaw: endDateRaw
        })
    }


    yyyymmdd(date) {
    
        // year
        let yyyy = '' + date.getFullYear();
    
        // month
        let mm = ('0' + (date.getMonth() + 1));  // prepend 0 // +1 is because Jan is 0
        mm = mm.substr(mm.length - 2);                  // take last 2 chars
    
        // day
        let dd = ('0' + date.getDate());         // prepend 0
        dd = dd.substr(dd.length - 2);                  // take last 2 chars
    
        return yyyy + "-" + mm + "-" + dd;
    }    

    generateShoppingList() {
        let dateFrom = this.yyyymmdd(this.state.startDate); // this.state.startDate.toISOString().split('T')[0];
        let dateTo = this.yyyymmdd(this.state.endDate); //this.state.endDate.toISOString().split('T')[0];

        api('planner/shopping-list', {post: true, data: {from:dateFrom, to:dateTo}}).then(result => {
            var link = document.createElement('a');
            link.href = 'https://www.zofapp.cz/data/zofapp/shoppinglist/' + result.data.filename + '.pdf';
            link.download = 'true';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            /*
            this.setState({
                shoppingList: result.data
            });
            */
        });

    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = data;

        let startDateValid = state.startDate instanceof Date && !isNaN(state.startDate);
        let endDateValid = state.endDate instanceof Date && !isNaN(state.endDate);
        if(!startDateValid || !endDateValid) {      // ATTN refactor this so i dont have to write the form twice (see below)
            return (
                <React.Fragment>
                    <div style={{ marginBottom: '2em' }}>
                    <div className="input-row">
                        <div className="form-inline-span">
                            <span>Od:</span>
                            <input style={{ width: '11em' }} type="date" name="date-start" defaultValue={this.state.startDateRaw} onChange={this.handleDateChange} />
                        </div>
                        <div className="form-inline-span">
                            <span>Do:</span>
                            <input style={{ width: '11em' }} type="date" name="date-end" defaultValue={this.state.endDateRaw} onChange={this.handleDateChange} />
                        </div>
                        <div className="form-inline-span">
                            <span style={{ color: 'red' }}>Neplatné datum</span>
                        </div>
                    </div>
                    </div>
                </React.Fragment>
            );
        }

        let invalidDateRangeWarning = false;
        if(state.invalidDates) {
            invalidDateRangeWarning = <span style={{color: 'red', fontSize: '.85em'}}>Neplatné časové rozpětí.</span>;
        }
        
        let startDateIso = state.startDate.toISOString().substr(0,10);
        let endDateIso = state.endDate.toISOString().substr(0,10);

        let shoppingList = false;
        if(state.shoppingList !== false) {
            shoppingList = [];
            Object.keys(state.shoppingList.list).map(key => {
                const item = state.shoppingList.list[key];
                if(item.type == 'c') {
                    shoppingList.push(
                        <strong><br />{globals.foodCategories[item.id].title}<br /></strong>
                    );
                }
                if(item.type == 'i') {
                    shoppingList.push(
                        <span>{globals.itemData[item.id].title} ({item.amount} {globals.itemData[item.id].weight_unit})<br /></span>
                    );
                }
            });
            shoppingList = <div style={{ maxHeight: '240px', overflowY: 'scroll' }}>
                {shoppingList}
            </div>;
        }

        return (
            <React.Fragment>
            {invalidDateRangeWarning}
            <table className="generic-table">
                <tbody>
                    <tr>
                        <th>Od:</th>
                        <td><input style={{ width: '11em' }} type="date" name="date-start" defaultValue={startDateIso} onChange={this.handleDateChange} /></td>
                    </tr>
                    <tr>
                        <th>Do:</th>
                        <td><input style={{ width: '11em' }} type="date" name="date-end" defaultValue={endDateIso} onChange={this.handleDateChange} /></td>
                    </tr>
                    <tr>
                        <th></th>
                        <td><button className="btn-primary" onClick={this.generateShoppingList}>Vytvořit nákupní seznam</button></td>
                    </tr>
                </tbody>
            </table>
            {shoppingList}
            </React.Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogShoppingList));
