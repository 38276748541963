import React, { Component } from 'react';
import { connect } from 'react-redux';
import Util from 'utility';

import { 
    setFoodGroupDescription,
} from "actions/index";
import PanelPurchaseCookbook from 'components/PanelPurchaseCookbook';
import PanelPurchaseProfi from 'components/PanelPurchaseProfi';


const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setFoodGroupDescription: data => dispatch(setFoodGroupDescription(data)),
    };
};


class PanelBuy extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            tab: 'cookbook',
        };    

        this.changeTab = this.changeTab.bind(this);
    }

    changeTab(e, t) {
        this.setState({
            tab: t
        });
    }

    render() {
        const { state } = this;

        let pageContent = false; 
        
        if(state.tab == 'cookbook') {        
            pageContent = <PanelPurchaseCookbook />;
            if(Util.userHasPurchased('ZOF_COOKBOOK')) {
                pageContent = (
                    <>
                    <p style={{textAlign:'center',margin:'4rem 0 1rem 0',fontSize:'5em',color:'#4eb32d'}}><i className="fa fa-check"></i></p>
                    <p style={{textAlign:'center',margin:'0 0 4rem 0'}}>ZOF kuchařku již máte zakoupenou.</p>;
                    </>
                );
            }
        }

        if(state.tab == 'profi') {
            pageContent = <PanelPurchaseProfi />;
            if(Util.userHasPurchased('ZOF_PROFI')) {
                pageContent = (
                    <>
                    <p style={{textAlign:'center',margin:'4rem 0 1rem 0',fontSize:'5em',color:'#4eb32d'}}><i className="fa fa-check"></i></p>
                    <p style={{textAlign:'center',margin:'0 0 4rem 0'}}>ZOFapp Profi již máte zakoupeno.</p>;
                    </>
                );
            }
        }

        let content = (
            <div style={{margin:'-1rem'}}>
            <div className="page">
                <div className="page-header">
                    <h1>KOUPIT</h1>
                </div>
                <div className="page-tabs">
                    <a onClick={(e) => this.changeTab(e, 'cookbook')} className={'' + state.tab == 'cookbook' ? 'active' : ''}>ZOF kuchařka</a>
                    <a onClick={(e) => this.changeTab(e, 'profi')} className={'' + state.tab == 'profi' ? 'active' : ''}>ZOF Profi</a>
                </div>
                <div className="page-content">
                    <div className="container">
                        {pageContent}
                    </div>
                </div>
            </div>
            </div>
        );


        return content;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelBuy);
