import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';

import {
    addFoodGroup
 } from 'actions/index';

 const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        addFoodGroup: data => dispatch(addFoodGroup(data)),
    };
};

class PanelAddFoodGroup extends Component { 
    constructor(props) {
        super(props);
        this.state = {
        };

        this.addToRecipe = this.addToRecipe.bind(this);
    }

    findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    }

    addToRecipe(foodGroupType) {
        let g = shortid();
        this.props.addFoodGroup({
            id: g,
            title: '',
            description: '',
            type: foodGroupType,
            opened: true
        });                
        this.props.onFoodGroupAdded();
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;

        return (
            <React.Fragment>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div className="zof-items-selection">
                <div className="zof-items-list">
                    <div className="zof-items-selection-form">
                        <p><a onClick={() => this.addToRecipe('plate')}>Přidat talíř</a></p>
                        <p><a onClick={() => this.addToRecipe('bowl')}>Přidat misku</a></p>
                        <p><a onClick={() => this.addToRecipe('cup')}>Přidat šálek</a></p>
                        <p><a onClick={() => this.addToRecipe('glass')}>Přidat sklenici</a></p>
                    </div>
                </div>
            </div>
            </div>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelAddFoodGroup);
