import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import UnitConvert from 'UnitConverter';
import Util from 'utility';
import api from 'api';

import { 
    openModal, 
    closeModal, 
    setFoodAmount,
} from "../../../../actions/index";

import LectureTaskPanelFoodModifications from '../../../Plan/LectureTask/LectureTaskPanelFoodModifications';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        setFoodAmount: data => dispatch(setFoodAmount(data)),
    };
};

class PanelMain extends Component { 
    constructor(props) {
        super(props);
        this.state = {
        };    

        this.openFoodModificationsPanel = this.openFoodModificationsPanel.bind(this);
        this.showAlergenInfo = this.showAlergenInfo.bind(this);
        this.handleFoodAmountInputFocus = this.handleFoodAmountInputFocus.bind(this);
        this.handleFoodAmountInputBlur = this.handleFoodAmountInputBlur.bind(this);
        this.handleFoodAmountChange = this.handleFoodAmountChange.bind(this);
        this.loadFoodDescription = this.loadFoodDescription.bind(this);
    }

    componentDidMount() {
    }

    openFoodModificationsPanel(e, m, f) {
        e.preventDefault();

        const data = this.props.data;
        const globals = data.globals;

        this.setState({
            actualFoodModifications: data.modifications
        });


        this.props.closeModal();
        this.props.openModal({
            'title': 'Úpravy potraviny ' + f.title,
            'class': 'dialog-flexible',
            'content': <LectureTaskPanelFoodModifications food={f} />
        });
    }

    showAlergenInfo(e, alerg) {
        this.props.openModal({
            'title': 'Alergen', 
            'content': (
                <>
                <p>{alerg}</p>
                </>
            )
        });
    }

    handleFoodAmountInputFocus(e, food) {
        e.target.focus();
        e.target.select();
    
        this.setState({
            focusedAmountInputId: food.id,
            focusedAmountInputValue: parseFloat(food.weight) + ''
        });
    }

    handleFoodAmountInputBlur(e, mealHash, food) {
        let weight = e.target.value.replace(',', '.') * 1;
        let item = this.props.data.globals.itemData[food.food_id];

        if(isNaN(weight) || weight < item.weight_min || weight > item.weight_max) {
            alert("Neplatné množství potraviny - zadejte prosím množství od " + item.weight_min + " do " + item.weight_max + " " + item.weight_unit);
            weight = item.weight;
        }

        this.setState({
            foodWeight: weight,
            invalidWeight: false,
            focusedAmountInputId: 0,
            focusedAmountInputValue: '',
        }, () => {
            this.props.setFoodAmount({ 
                mealHash: mealHash,
                foodId: food.id, 
                weight: weight
            });
            Util.doLoadDayEvaluation();
            // this.saveAndUpdateEvaluation();
        });
    }

    handleFoodAmountChange(e) {
        this.setState({
            focusedAmountInputValue: e.target.value,
        });
    }

    loadFoodDescription(foodId) {
        api('food/description/' + foodId).then(result => {
            result = result.data;
            if (result.error) return false;
            this.props.closeModal();
            this.props.openModal({
                'title': result.title, 
                'class': 'dialog dialog-flexible',
                'content': (
                    <div className="zof-food-description">
                        <div dangerouslySetInnerHTML={{ __html: result.description }} />
                    </div>
                )
            });
        });
    }


    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = this.props.data;

        let mealHash = data.activeMealHash;
        let foodGroups = data.foodGroups[mealHash];
        
        let sortedFoodGroupKeys = [];
        for(let g in foodGroups) {
            sortedFoodGroupKeys.push({
                id: g,
                item_order: foodGroups[g].item_order
            });
        }

        sortedFoodGroupKeys.sort(function(a, b) { 
            return a.item_order - b.item_order;
        });

        let elementFoodGroups = [];
        sortedFoodGroupKeys.map(key => {
            const fg = foodGroups[key.id];
            const m = '1|0';

            let foods = [];
            data.foods[m].map(f => {
                if(f.group_id !== fg.id) return;    

                let adjustAmount = false;
                if(state.adjustAmountId === f.id) {
                    adjustAmount = <div className="meal-item-adjust-amount">
                        <p>
                            <input type="text" name="amount" value={f.weight} />
                            <span>{globals.itemData[f.food_id].weight_unit}</span>
                            <button>OK</button>
                        </p>
                    </div>;
                }

                let foodItem = {
                    id: f.food_id,
                    selectedWeight: f.weight,
                    weight: this.props.data.globals.itemData[f.food_id].weight,
                    norm_unit_amount: this.props.data.globals.itemData[f.food_id].norm_unit_amount * 1.0,
                    norm_unit_unit: this.props.data.globals.itemData[f.food_id].norm_unit_unit,
                    norm_unit2_amount: this.props.data.globals.itemData[f.food_id].norm_unit2_amount * 1.0,
                    norm_unit2_unit: this.props.data.globals.itemData[f.food_id].norm_unit2_unit
                };

                let uc = new UnitConvert;
                let normalizedAmountInfo = uc.convert(foodItem);
                if(normalizedAmountInfo !== null && normalizedAmountInfo != '' && normalizedAmountInfo != ' null') {
                    normalizedAmountInfo = <span>({normalizedAmountInfo})</span>;
                } else {
                    normalizedAmountInfo = false;
                }

                let inputValue = parseFloat(f.weight + '');
                if(this.state.focusedAmountInputId === f.id) {
                    inputValue = this.state.focusedAmountInputValue;
                }

                const foodItemData = this.props.data.globals.itemData[f.food_id];
                let drinkModeSelection = false;
                if(foodItemData.is_drink == 1) {
                    let cbName = 'is_drink_' + f.food_id;
                    drinkModeSelection = (
                        <p><input type="checkbox" name={cbName} onChange={(e) => this.handleDrinkChange(e, m, f.id)} checked={f.is_drink == 1}/> pitný režim</p>
                    );
                }

                // Alergen info

                let watchAlergens = [];
                if(typeof this.props.data.appModeData.watchAlergens === 'object') {
                    watchAlergens = this.props.data.appModeData.watchAlergens;
                }
                watchAlergens = watchAlergens.map(a => +a);

                let alergenList = [];
                if(typeof this.props.data.globals.foodAlergens[f.food_id] === 'object') {
                    let alSorted = this.props.data.globals.foodAlergens[f.food_id].sort();
                    alSorted.map(id => {
                        let alergenName = this.props.data.globals.itemAlergens[id];
                        let alerg = alergenName;
                        alergenName = alergenName.split('-')[0].trim();

                        if(watchAlergens.includes(id*1)) {
                            alergenList.push(<span onClick={(e) => this.showAlergenInfo(e, alerg)} style={{cursor:'pointer',borderBottom:'1px dotted #f00',color:'#f00'}}>{alergenName}</span>);
                            alergenList.push(<span>, </span>);
                        } else {
                            alergenList.push(<span onClick={(e) => this.showAlergenInfo(e, alerg)} style={{cursor:'pointer',borderBottom:'1px dotted #bbb'}}>{alergenName}</span>);
                            alergenList.push(<span>, </span>);
                        }
                    });
                }

                // Undesirable food info

                let undesirableInfo = false;
                if(typeof this.props.data.appModeData.undesirableFoods !== 'undefined') {
                    if(this.props.data.appModeData.undesirableFoods.includes(f.food_id)) {
                        for(let profileId in this.props.data.globals.undesirableFoods) {
                            profileId = profileId * 1;
                            undesirableInfo = <p className="item-undesirable"><span>Nevhodná potravina</span></p>;
                        }
                    }
                }

                if(alergenList.length > 1) {
                    if(undesirableInfo) {
                        alergenList.pop();
                        undesirableInfo = <p className="item-undesirable"><span>Nevhodná potravina: <span style={{fontWeight:'normal',color:'#000'}}>{alergenList}</span></span></p>;
                    } else {
                        alergenList.pop();
                        undesirableInfo = <p className="item-undesirable" style={{color:'#000'}}>
                                            <span>Alergeny: 
                                                <span style={{fontWeight:'normal',color:'#000'}}>{alergenList}</span>
                                            </span>
                                          </p>;
                    }
                }
                
                // Food modifications

                let modList = [];
                if(typeof this.props.data.modifications[f.id] !== "undefined") {
                    let mods = this.props.data.modifications[f.id];
                    mods.forEach((key, val) => {
                        modList.push(this.props.data.globals.preparation_types[key]);
                    });
                }
                modList = modList.length > 0 ? modList.join(', ') : 'Bez úprav';

                let modifications = (
                    <div className="item-modifications">
                        <a data-food-id={f.id} onClick={(e) => this.openFoodModificationsPanel(e, m, f)}>
                            <span>{modList}</span>
                        </a>
                    </div>
                );

                let mealItemClasses = ['meal-item-food'];
                if(this.props.data.appMode == 'RECIPE_EDITOR') {
                    mealItemClasses.push('meal-item-food-nohover');
                }


                foods.push(
                    <div className={mealItemClasses.join(' ')}>
                        <div className="icon" style={{backgroundImage: `url(${f.image})`}}>
                        </div>
                        <div className="food-title">
                            <h4>
                                {f.title}
                                {drinkModeSelection}
                            </h4>
                            <div className="amount">
                                <div className="amount-input">
                                    <input  
                                        className={this.state.invalidWeight ? 'invalid' : ''} 
                                        type="text" name="weight" 
                                        value={inputValue} 
                                        onChange={this.handleFoodAmountChange} 
                                        onFocus={(e) => this.handleFoodAmountInputFocus(e, f)} 
                                        onBlur={(e) => this.handleFoodAmountInputBlur(e, m, f)} 
                                    />
                                    <span>{globals.itemData[f.food_id].weight_unit}</span>
                                </div>
                                {normalizedAmountInfo}
                            </div>
                            {modifications}                                        
                            {undesirableInfo}
                        </div>
                        <div className="options">
                            <div class="option">
                                <a onClick={() => this.loadFoodDescription(f.food_id, inputValue)}><i className="fa fa-question"></i></a>
                            </div>
                        </div>
                    </div>
                );
            });


            //


            elementFoodGroups.push(
                <div className="meal-item-new" style={{margin:'0',width:'100%',background:'#fff'}}>
                <div className="meal-item-group">
                    <div className="meal-item-group-foods">
                        {foods}
                    </div>
                </div>
                </div>
            );
        });

        let contents = (
            <div className="zof-recipe">
                <div className="zof-recipe-contents">
                    {elementFoodGroups}
                </div>
            </div>
        );        

        return contents;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelMain);
