import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import store from 'store/index';
import Loading from 'components/Loading/index';
import Saving from 'components/Saving/index';
import api from 'api';
import Util from 'utility';
import { fstat } from 'fs';
import BuildMetadata from 'metadata.json';


import PanelPurchaseCookbook from 'components/PanelPurchaseCookbook/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class CookbookBuy extends Component { 
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        Util.setPageTitle('Podpora');
    }

    render() {
        return (
            <div className="page">
                <div className="page-header">
                    <h1>Koupit ZOF kuchařku</h1>
                </div>
                <div className="page-content">
                    <div className="container" style={{paddingTop:'1em'}}>
                        <PanelPurchaseCookbook />
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookbookBuy);
