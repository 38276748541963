import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import { Link } from 'react-router-dom';
import Util from 'utility';
import Loading from 'components/Loading/index';
import Saving from 'components/Saving/index';
import api from 'api';
import FoodRow from 'components/Recipe/RecipeEditor/FoodRow/index';

import { 
    setData,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setData: data => dispatch(setData(data)),
    };
};

class DayPlanOverview extends Component { 
    constructor(props) {
        super(props);
    }

    render() {
        const { props } = this;
        const { data } = props;

        let sortedMeals = [];
        for(let m in data.meals) {
            let tmpMeal = data.meals[m];
            tmpMeal.meal_key = m;
            sortedMeals.push(tmpMeal);
        }

        sortedMeals.sort((a, b) => {
            const t1 = a.startHour * 60 + a.startMinute * 1;
            const t2 = b.startHour * 60 + b.startMinute * 1;
            if(t1 > t2) return 1;
            if(t1 < t2) return -1;
            return 0;
        });
        
        let mealsList = [];
        sortedMeals.map(meal => {
            const m = meal.meal_key;

            let foodGroups = [];
            for(let g in data.foodGroups[m]) {
                let group = data.foodGroups[m][g];

                let foods = [];
                for(let f in data.foods[m]) {
                    let food = data.foods[m][f];
                    if(food.group_id != group.id) continue;

                    foods.push(
                        <FoodRow food={food} key={food.id} readOnly={true} />
                    );
                }

                foodGroups.push(
                    <div className="day-overview-foodgroup">
                        <h3>{data.globals.foodGroupTypes[group.type]}: {group.title}</h3>
                        {foods}
                    </div>
                );
            }

            let mealProfiles = [];
            meal.profiles.map((profileId) => {
                if(typeof data.globals.profiles[profileId] !== 'undefined') {
                    let profileName = data.globals.profiles[profileId].name;
                    if(profileName && profileName != '') {
                        let profileColor = data.globals.profiles[profileId].color;
                        if (profileColor == '' || !profileColor) profileColor = '999999';
                        let profileStyle = {
                            color: '#fff',
                            padding: '3px 5px',
                            borderRadius: '3px',
                            marginRight: '.25em',
                            backgroundColor: '#' + profileColor
                        };
                        mealProfiles.push(<span style={profileStyle}>{profileName}</span>);
                    }
                }
            });

            let sh = '' + meal.startHour;
            if(sh.length < 2) sh = '0' + sh;

            let sm = '' + meal.startMinute;
            if(sm.length < 2) sm = '0' + sm;

            if(meal.mealId > 0) {
                mealsList.push(
                    <div className="day-overview-meal">
                        <h2>
                            <b>{sh}:{sm} | {data.globals.mealTypes[meal.mealId].title}</b>
                            {'\u00A0'}
                            <span>{mealProfiles}</span>
                        </h2>
                        {foodGroups}
                    </div>
                );
            }
        });

        if(mealsList.length == 0) {
            mealsList = <p>Nemáte zadány žádné chody / recepty.</p>
        }


        return (
            <React.Fragment>
                <div className="day-overview-wrap">
                    {mealsList}
                </div>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DayPlanOverview);
