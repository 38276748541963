import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from 'components/Loading/index';
import Util from 'utility';
import api from 'api';

import { 
    setFoodGroupDescription
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setFoodGroupDescription: data => dispatch(setFoodGroupDescription(data)),
    };
};


class PanelFoodDetail extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            food: {},
            loading: true,
        };    
    }

    componentDidMount() {
        api('foods2/detail/' + this.props.id).then((res) =>{
            this.setState({
                loading: false,
                food: res.data
            });
        });
    }
    
    render() {
        if(this.state.loading === true) return <Loading />;
        const { food } = this.state;

        food.proteins = parseFloat(food.proteins);
        food.carbohydrates = parseFloat(food.carbohydrates);
        food.fats = parseFloat(food.fats);
        food.fiber = parseFloat(food.fiber);

        let kcal = food.proteins * 4.18 + food.carbohydrates * 4.18 + food.fats * 9 + food.fiber * 2;
        let kj = kcal * 4.184;

        let chartColours = ['#96c999','#eb714a','#eae869','#8bcbce','#c694be'];

        let chart1 = Util.pieChart(
            [food.proteins, food.carbohydrates, food.fats, food.fiber],
            100, 100, 95, chartColours
        );

        let enProt = food.proteins * 4.18;
        let enCarb = food.carbohydrates * 4.18;
        let enFats = food.fats * 9;
        let enFibr = food.fiber * 2;      
        
        let total = enProt + enCarb + enFats + enFibr;
        if(total == 0) total = 0.00001;

        enProt = (enProt / total) * 100;
        enCarb = (enCarb / total) * 100;
        enFats = (enFats / total) * 100;
        enFibr = (enFibr / total) * 100;

        let chart2 = Util.pieChart(
            [enProt, enCarb, enFats, enFibr],
            100, 100, 95, chartColours
        );

        enProt = Math.round(enProt * 10) / 10;
        enCarb = Math.round(enCarb * 10) / 10;
        enFats = Math.round(enFats * 10) / 10;
        enFibr = Math.round(enFibr * 10) / 10;


        return (
            <React.Fragment>
            <div className="food-detail">
                <div class="food-detail-heading" style={{ paddingTop: 0 }}>
                    <img src={`https://www.zofapp.cz/data/zof/item/${food.id}.svg`} alt="" />
                    <h1>{food.title}</h1>
                </div>
                <h2>Nutriční hodnoty ({food.weight} {food.weight_unit})</h2>
                <div class="food-detail-values">
                    <div class="value">
                        <p>Energie kcal</p>
                        <p><strong>{parseFloat(Math.ceil(kcal))} kcal</strong></p>
                    </div>
                    <div class="value">
                        <p>Energie kJ</p>
                        <p><strong>{parseFloat(Math.ceil(kj))} kJ</strong></p>
                    </div>
                    <div class="value">
                        <p>Bílkoviny</p>
                        <p><strong>{parseFloat(food.proteins)} g</strong></p>
                    </div>
                    <div class="value">
                        <p>Sacharidy</p>
                        <p><strong>{parseFloat(food.carbohydrates)} g</strong></p>
                    </div>
                    <div class="value">
                        <p>Tuky</p>
                        <p><strong>{parseFloat(food.fats)} g</strong></p>
                    </div>
                    <div class="value">
                        <p>Vláknina</p>
                        <p><strong>{parseFloat(food.fiber)} g</strong></p>
                    </div>
                </div>

                <div class="food-detail-graphs">
                    <div class="graph">
                        <h3>Hmotnostní poměry makroživin</h3>
                        <div class="svg-wrap">
                        <svg viewBox="0 0 400 200" width="100%" height="100%">
                            {chart1}
                            <rect x="220" y="40" width="20" height="20" style={{ fill: chartColours[0], strokeWidth: 0 }} />	
                            <text x="250" y="55" fill="#444">Bílkoviny ({parseFloat(food.proteins)} g)</text>
                            <rect x="220" y="70" width="20" height="20" style={{ fill: chartColours[1], strokeWidth: 0 }} />	
                            <text x="250" y="85" fill="#444">Sacharidy ({parseFloat(food.carbohydrates)} g)</text>
                            <rect x="220" y="100" width="20" height="20" style={{ fill: chartColours[2], strokeWidth: 0 }} />	
                            <text x="250" y="115" fill="#444">Tuky ({parseFloat(food.fats)} g)</text>
                            <rect x="220" y="130" width="20" height="20" style={{ fill: chartColours[3], strokeWidth: 0 }} />	
                            <text x="250" y="145" fill="#444">Vláknina ({parseFloat(food.fiber)} g)</text>
                        </svg>
                        </div>
                    </div>
                    <div class="graph">
                        <h3>Energetické poměry makroživin</h3>
                        <div class="svg-wrap">
                        <svg viewBox="0 0 400 200" width="100%" height="100%">
                            {chart2}
                            <rect x="220" y="40" width="20" height="20"style={{ fill: chartColours[0], strokeWidth: 0 }} />	
                            <text x="250" y="55" fill="#444">Bílkoviny ({parseFloat(enProt)} %)</text>
                            <rect x="220" y="70" width="20" height="20" style={{ fill: chartColours[1], strokeWidth: 0 }} />	
                            <text x="250" y="85" fill="#444">Sacharidy ({parseFloat(enCarb)} %)</text>
                            <rect x="220" y="100" width="20" height="20" style={{ fill: chartColours[2], strokeWidth: 0 }} />	
                            <text x="250" y="115" fill="#444">Tuky ({parseFloat(enFats)} %)</text>
                            <rect x="220" y="130" width="20" height="20" style={{ fill: chartColours[3], strokeWidth: 0 }} />	
                            <text x="250" y="145" fill="#444">Vláknina ({parseFloat(enFibr)} %)</text>
                        </svg>
                        </div>
                    </div>
                </div>
                {food.description != '' ? 
                    <React.Fragment><h2>Popis</h2><div dangerouslySetInnerHTML={{__html: food.description}} /></React.Fragment>
                    : false}
            </div>
            </React.Fragment>         
        );            
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelFoodDetail);
