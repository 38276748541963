import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import api from 'api';
import Loading from 'components/Loading/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class ClientChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.match.params.id,
            loading: true,
            newMessage: {'message': '', attachment: false},            
            messages: [],
            clientData: {},
            advisorUser: {},
            tab: 'chat',
        }
        this.loadChat = this.loadChat.bind(this);
        this.loadResults = this.loadResults.bind(this);
        this.onChange = this.onChange.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.submitMessage = this.submitMessage.bind(this);
    }

    componentDidMount() {
        this.loadChat();
    }
    
    loadChat() {
        api('profi/chat/get/' + this.state.userId).then((res) =>{
            this.setState({
                messages: res.data.messages,
                advisorUser: res.data.advisorUser,
                loading: false
            });
        });
    }

    loadResults() {
        api('profi/clientdata/' + this.props.data.globals.user.id).then((res) =>{
            this.setState({
                clientData: res.data.clientData,
                advisorUser: res.data.advisorUser,
                loading: false
            });
        });
    }

    changeTab(e, tab) {
        this.setState({
            loading: true,
            tab: tab
        }, () => {
            if(tab == 'chat') this.loadChat();
            if(tab == 'results') this.loadResults();
        });
    }

    onChange(e) {
        const n = e.target.name;
        let v = e.target.value;
        if(n == 'attachment') {
            v = e.target.files[0];
        }
        let message = this.state.newMessage;
        message[n] = v;

        this.setState({
            newMessage: message
        }); 
    }

    submitMessage() {
        const { newMessage } = this.state;

        if(newMessage.message == '') {
            alert("Napište text zprávy");
            return;
        }

        this.setState({
            loading: true,
        }, () => {
    
            let fd = new FormData;
            for(let i in newMessage) {
                fd.append(i, newMessage[i]);
            }
            fd.append('user_id', this.state.userId);

            api('profi/chat/post', {post: true, data: fd}).then(result => {
                result = result.data;
                this.setState({
                    newMessage: {'message': '', attachment: false},
                    messages: result,
                    loading: false,
                });                
            });
        });
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;

        let savedInfo = false;
        if(state.saved) {
            savedInfo = (
                <div className="message message-success">
                    <i className="fa fa-check"></i>
                    Zpráva byla uložena do chatu.
                </div>
                );
        }
                
        let messages = [];
        this.state.messages.map(message => {

            let attachment = false;
            if(message.has_attachment === 1) {
                if(message.is_image === 1) {
                    attachment = <p><img src={`https://www.zofapp.cz/${message.attachment_file}`} alt="" /></p>
                } else {
                    attachment = <p><i className="fa fa-download"></i> Příloha: <a href={`https://www.zofapp.cz/${message.attachment_file}`}>{message.attachment_title}</a></p>;
                }
            }

            let msgClass = '';
            if(message.to_user_id == this.props.data.user.id) msgClass = ' chat-message-admin';

            messages.push(
                <div className={`chat-message${msgClass}`}>
                    <p className="info">{message.author_name}, {message.dateFormatted}</p>
                    <div style={{ whiteSpace: 'pre-line' }}>{message.message}</div>
                    {attachment}
                </div>
            );
        });

        let content = false;

        if(state.tab == 'chat') {
            content = (
                <>
                <div className="chat-form">
                    <table>
                        <tr>
                            <td>
                                <textarea name="message" placeholder="Napište zprávu..." onChange={this.onChange} value={state.newMessage.message}></textarea>
                            </td>
                            <td>
                                <button onClick={this.submitMessage}>Odeslat zprávu</button>
                            </td>
                        </tr>
                        <tr>
                            <td>příloha:{'\u00A0'}{'\u00A0'}<input type="file" name="attachment" onChange={this.onChange} /> </td>
                            <td></td>
                        </tr>
                    </table>
                </div>
                <div className="chat-messages">
                    {messages}
                </div>
                </>
            );
        }

        if(state.tab == 'results') {
            let dataList = [];

            let clientData = state.clientData;
            if(!clientData) clientData = {};
            if(Object.keys(clientData).length === 0 && clientData.constructor === Object) clientData = {};
            Object.keys(clientData).map(i => {
                let dataItem = clientData[i];
                dataList.push(
                    <div className="client-data-item">
                        <p><b>{dataItem.target_date}</b></p>
                        <div className="row">
                            <p><span>Váha:</span> <b>{dataItem.weight} kg</b></p>
                            <p><span>Výška:</span> <b>{dataItem.height} kg</b></p>
                            <p><span>Obvod pasu:</span> <b>{dataItem.diameter_waist} cm</b></p>
                            <p><span>Obvod boků:</span> <b>{dataItem.diameter_sides} cm</b></p>
                            <p><span>Procento tuků:</span> <b>{dataItem.percent_fat} %</b></p>
                            <p><span>Viscerální tuk:</span> <b>{dataItem.visceral_fat}</b></p>
                            <p><span>Objem vody v těle:</span> <b>{dataItem.water_content}</b></p>
                            <p><span>Voda mimobuněčná:</span> <b>{dataItem.water_extracellular}</b></p>
                            <p><span>Voda vnitrobuněčná:</span> <b>{dataItem.water_content}</b></p>
                            <p><span>Mineralizace kostí:</span> <b>{dataItem.bone_mineralization}</b></p>
                            <p><span>Metabolický věk:</span> <b>{dataItem.metabolic_age} let</b></p>
                            <p><span>BMR kcal:</span> <b>{dataItem.bmr_kcal} kcal</b></p>
                            <p><span>BMR kJ:</span> <b>{dataItem.bmr_kj} kJ</b></p>
                            <p><span>BMI:</span> <b>{dataItem.bmi}</b></p>
                            <p><span>WHR:</span> <b>{dataItem.whr}</b></p>
                        </div>
                        <div className="options">
                            <a onClick={(e) => this.editItem(e, i)}>Upravit</a>
                        </div>
                    </div>
                );
            });
    
            if(dataList.length > 0) {
                content = (
                    <>
                    {dataList}
                    </>
                );
    
            } else {
                content = <p>U Vašeho profilu nejsou prozatím uloženy žádné výsledky.</p>
            }            
        }

        if(state.loading) content = <Loading />;

        let advisorProfile = <div className="profi-advisor-mini-profile">
            <img src={`https://www.zofapp.cz/data/student-avatar/${this.state.advisorUser.avatar}`} alt="" />
            <p>{this.state.advisorUser.name}</p>
        </div>;


        let header = false;
        if(globals.user.zof_parent_user_id || globals.user.zof_parent_user_id > 0) {
            header = (
                <>
                    <div className="page-header">
                        <h1>Můj poradce</h1>
                        {advisorProfile}
                    </div>
                    <div className="page-tabs">
                        <a onClick={(e) => this.changeTab(e, 'chat')} className={'' + state.tab == 'chat' ? 'active' : ''}>Chat</a>
                        <a onClick={(e) => this.changeTab(e, 'results')} className={'' + state.tab == 'results' ? 'active' : ''}>Výsledky</a>
                    </div>
                </>
            );
    
        } 

        return (
            <>
            <div className="page">
                {header}
                <div className="page-content">
                    <div className="container" style={{paddingTop:'1em'}}>
                        {content}
                        {savedInfo}
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientChat));
