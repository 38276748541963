import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import FoodGroup from '../FoodGroup/index';
import FoodRow from '../FoodRow/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class MainPanelSingleRecipe extends Component { 
    constructor(props) {
        super(props);
        this.state = {
        };    
    }

    componentDidMount() {
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = this.props.data;

        let mealHash = data.activeMealHash;
        let foods = data.foods[mealHash];
        let foodGroups = data.foodGroups[mealHash];

        if(!foods) foods = [];        
        
        let sortedFoodGroupKeys = [];
        for(let g in foodGroups) {
            sortedFoodGroupKeys.push({
                id: g,
                item_order: foodGroups[g].item_order
            });
        }

        sortedFoodGroupKeys.sort(function(a, b) { 
            return a.item_order - b.item_order;
        });

        let elementFoodGroups = [];
        sortedFoodGroupKeys.map(key => {
            elementFoodGroups.push(
                <FoodGroup action={this.props.action} group={foodGroups[key.id]} key={key.id} onModificationsOpen={this.openFoodModificationsModal} />
            );  
        });

        let emptyInfo = false;
        if(foods.length == 0) {
            emptyInfo = <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                <p><img src="https://www.zofapp.cz/assets/images/zofapp/empty.svg" alt="" /></p>
                <p><b>Recept je prázdný.</b></p>
                <p>
                    Přidejte 
                    {'\u00A0'}
                    <a style={{color:'#4eb32d',textDecoration:'underline' }} onClick={() => window.globalBroadcaster.dispatch('openAddFoodPanel')}>potravinu</a> 
                    {'\u00A0'}
                    nebo 
                    {'\u00A0'}
                    <a style={{color:'#4eb32d',textDecoration:'underline' }} onClick={() => window.globalBroadcaster.dispatch('openAddRecipePanel')}>recept</a>.
                </p>
            </div>;
        }

        let contents = (
            <div className="zof-recipe">
                <div className="zof-recipe-contents">
                    <Scrollbars heightrelativetoparent="1">
                        {foods.map(food => {
                            if(typeof food.group_id === 'undefined' || food.group_id == '' || food.group_id == '0') {
                                return <FoodRow action={this.props.action} food={food} key={food.id} onModificationsOpen={this.openFoodModificationsModal} />
                            }
                        })}
                        {elementFoodGroups}
                        {emptyInfo}
                    </Scrollbars>
                </div>
            </div>
        );        


        return (
            contents
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPanelSingleRecipe);



// ORIG - LINE 40
/*
let foodModificationsModal = false;
if (this.state.showFoodModificationsModal) {
    let modalContent = (
        <div>
            <h3>Úpravy potraviny:</h3>
            <div className="modal-content-preparations">{preparationsArray}</div>
        </div>
    );
    //foodModificationsModal = (<ZOFModal x={this.state.foodModificationsModalX} y={this.state.foodModificationsModalY} content={modalContent} onClose={this.closeFoodModificationsModal} />);
    foodModificationsModal = <div>MODIFICATIONS MODAL...</div>;
}
*/

