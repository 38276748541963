import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from "date-fns";
import localeCs from 'date-fns/locale/cs';
import Loading from 'components/Loading/index';
import api from 'api';
import Util from 'utility';
import BuildMetadata from 'metadata.json';

import { 
    loadGlobals, 
    mergeGlobals, 
    loadDayPlan 
} from '../../actions/index';


const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        loadGlobals: data => dispatch(loadGlobals(data)),
        mergeGlobals: data => dispatch(mergeGlobals(data)),
        loadDayPlan: data => dispatch(loadDayPlan(data)),
    };
};

class Index extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tab: 'about',
            data: {}
        };

        Util.setPageTitle('Úvod');
        api('load/index-data').then(result => {
            result = result.data;
            this.setState({
                data: result,
                loading: false
            });
        });

    }

    render() {
        const { state } = this;
        const { data } = state;
        const { globals } = this.props.data;

        const user = this.props.data.globals.user;

        let content = <Loading />;
        if(!state.loading) {
            const today = new Date();
            const todayDate = format(today, 'eeee d. LLLL', {locale: localeCs});
            let salutation = false;
            let salutationHeader = "Dobrý den,";
            let firstProfileKey = Object.keys(globals.profiles)[0];
            if(firstProfileKey) {
                if(globals.profiles[firstProfileKey].salutation != '') {
                    salutationHeader = `Dobrý den, ${globals.profiles[firstProfileKey].salutation}`;
                }
            }


            const randomBgUrl = `https://www.zofapp.cz/data/zof/randombg/${data.randomPhoto}`;
            const dateString = format(new Date(), 'yyyy-MM-dd');

            const random = Math.random() * 10000000;
            let profile = this.props.data.globals.profiles[firstProfileKey];
            let avatar = <div style={{backgroundImage:"url(https://www.zofapp.cz/data/zof/profile/" + profile.picture + "?r=" + random + ")"}}></div>;

            // <a href={`https://www.zofapp.cz/u/id/${this.props.data.user.id}`}><i className="fa fa-user-cog"></i> Přejít na profil</a>


            content = (
                <>
                <div className="page page-index" style={{backgroundImage:'url(' + randomBgUrl + ')'}}>
                    <div className="panel user">
                        <article>
                            <div className="avatar">
                                {avatar}
                            </div>
                            <div>
                                <h2>{salutationHeader}</h2>
                                <p style={{marginBottom:'.65em'}}>Dnes je <Link to={`/plan/${format(today, 'yyyy-MM-dd')}`}>{todayDate}</Link></p>
                            </div>
                            <div className="links">
                                <Link to="/recipe/create"><i className="fa fa-utensils"> </i>Vytvořit recept</Link>
                                <Link to={`/plan/${dateString}/generate`}><i className="fa fa-plus-circle"></i> Vygenerovat jídelníček</Link>
                                <Link to="/plan"><i className="fa fa-list-alt"></i> Upravit denní plán</Link>
                                <a style={{background:'#fff',border:'1px solid #4eb32d',color:'#4eb32d'}} href={`https://www.kurzyatac.cz`} target="_blank"><i className="fa fa-graduation-cap"></i> Kurzy výživy</a>
                            </div>
                            <Link to="/support">Nápověda <span><i className="fa fa-info-circle"></i></span></Link>
                        </article>
                    </div>
                    <div className="build-info">
                        <p>Verze aplikace: {BuildMetadata.buildNumber}.{BuildMetadata.buildDate}</p>
                    </div>
                </div> 
                </>
            );    
        }

        return content;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
