import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import api from 'api';

import { 
    showMessage, 
    closeModal,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        showMessage: data => dispatch(showMessage(data)),
        closeModal: data => dispatch(closeModal(data)),
    };
};

class DialogSaveMenu extends Component { 

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            comment: '',
        };
        this.onChange = this.onChange.bind(this);
        this.doSave = this.doSave.bind(this);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    doSave() {
        const { data } = this.props;
        const title = this.state.title;
        const comment = this.state.comment;


        let saveData = {};
        saveData.title = JSON.stringify(title);
        saveData.comment = JSON.stringify(comment);
        saveData.meals = JSON.stringify(data.meals);
        saveData.groups = JSON.stringify(data.foodGroups);
        saveData.foods = JSON.stringify(data.foods);
        saveData.modifications = JSON.stringify(data.modifications);
        saveData = JSON.stringify(saveData);

        if(title.trim() == '') {
            alert("Zadejte prosím název jídelníčku");
            return;
        }

        api('save/menu', {post: true, data: saveData}).then(result => {
            this.props.closeModal();
            this.props.showMessage({
                show: true,
                type: 'success',
                message: 'Jídelníček byl uložen.'
            });               

        });

    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = data;

        return (
            <React.Fragment>
            <div className="input-row input-row-primary">
                <input type="text" name="title" defaultValue={state.title} onChange={this.onChange} placeholder="Název jídelníčku" />
            </div>
            <div className="input-row">
                <textarea style={{width:'100%',maxWidth:'none',height:'20em'}} name="comment" onChange={this.onChange} placeholder="Poznámky k jídelníčku...">{state.comment}</textarea>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th></th>
                        <td><button className="btn-primary" onClick={this.doSave}>Uložit jídelníček</button></td>
                    </tr>
                </tbody>
            </table>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogSaveMenu));
