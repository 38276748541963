import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'reducers/index';

const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

/*
function handleStoreChange() 
{
    const currentStore = store.getState();
}
store.subscribe(handleStoreChange);
*/

export default store;
