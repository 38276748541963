import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import shortid from 'shortid';
import Loading from 'components/Loading/index';
import Saving from 'components/Saving/index';
import api from 'api';
import Util from 'utility';

import { 
    setData,
    resetRecipe,
    deleteEmptyFoodGroups, 
    addFoodGroup, 
    addFood, 
    setFoodGroupDescription,
    addFoodModification, 
    setTags,
    showMessage,
    setRecipeTitle,
    setProfileId,
    setActiveMealHash,
    setPlannerEditMode,
    setAppModeData,
    openModal,
    closeModal,
} from 'actions/index';

import MenuPanel from './MenuPanel/index';
import MainPanel from './MainPanel/index';
import MainPanelSingleRecipe from './MainPanelSingleRecipe/index';
import EvaluationPanel from 'components/EvaluationPanel';
import EvaluationPanelContents from 'components/EvaluationPanel/EvaluationPanelContents';
import EvaluationPanelContentsRecipeEditor from 'components/EvaluationPanel/EvaluationPanelContentsRecipeEditor';
import TaskEvaluator from 'components/Plan/LectureTask/TaskEvaluator';
import { ContentBlock } from 'draft-js';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setData: data => dispatch(setData(data)),
        resetRecipe: data => dispatch(resetRecipe(data)),
        deleteEmptyFoodGroups: data => dispatch(deleteEmptyFoodGroups(data)),
        addFoodGroup: data => dispatch(addFoodGroup(data)),
        addFood: data => dispatch(addFood(data)),
        setFoodGroupDescription: data => dispatch(setFoodGroupDescription(data)),
        addFoodModification: data => dispatch(addFoodModification(data)),
        setTags: data => dispatch(setTags(data)),
        showMessage: data => dispatch(showMessage(data)),
        setRecipeTitle: data => dispatch(setRecipeTitle(data)),
        setProfileId: data => dispatch(setProfileId(data)),
        setActiveMealHash: data => dispatch(setActiveMealHash(data)),
        setPlannerEditMode: data => dispatch(setPlannerEditMode(data)),
        setAppModeData: data => dispatch(setAppModeData(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
    };
};

class RecipeEditor extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            saving: false,
            unsavedChanges: false,
            recipe: {},
            selectedProfile: -1,
        };    
        this.selectActiveProfile = this.selectActiveProfile.bind(this);
        this.updateRecipeTitle = this.updateRecipeTitle.bind(this);
        this.openRecipeEditorHelp = this.openRecipeEditorHelp.bind(this);
    }

    cleanLocalStoredRecipe(data) {
        let result = {
            'foodGroups': {},
            'foods': {},
            'titles': {},
            'descriptions': {},
        };

        Object.keys(data.foodGroups).map(k => {
            if(k == '1|0') result['foodGroups'][k] = data.foodGroups[k];
        });

        // If food groups are empty, create a default one
        if(Object.keys(result['foodGroups']).length == 0) {
            let g = shortid();
            result['foodGroups']['1|0'] = {};
            result['foodGroups']['1|0'][g] = {
                'id': g,
                'title': '',
                'description': '',
                'type': 'plate',
                'opened': true,
            };
        }

        Object.keys(data.foods).map(k => {
            if(k == '1|0') result['foods'][k] = data.foods[k];
        });

        Object.keys(data.titles).map(k => {
            if(k == '1|0') result['titles'][k] = data.titles[k];
        });

        Object.keys(data.descriptions).map(k => {
            if(k == '1|0') result['descriptions'][k] = data.description[k];
        });

        return result;
    }

    componentDidMount() {
        Util.resetCookbookFilter();
        Util.resetFoodsFilter();
        Util.setPageTitle('Úprava receptu');
        const { props } = this;

        // Is there a unsaved recipe (stored in local storage)?
        let localStoredRecipe = localStorage.getItem('zof_unsaved_recipe');

        let activeMealHash = '1|0';
        if(props.data.activeMealHash != '') activeMealHash = props.data.activeMealHash;

        this.props.setPlannerEditMode('');

        if(!localStoredRecipe) {
            this.props.setData({
                lastOpenedCookBookRecipe: -1,
            });   
        }

        if(
            typeof this.props.match.params.id === 'undefined' && 
            typeof this.props.match.params.hash === 'undefined'
        ) {

            if(localStoredRecipe) {
                this.props.resetRecipe();
                let g = shortid();
                this.props.addFoodGroup({
                    id: g,
                    title: '',
                    description: '',
                    type: 'plate',
                    opened: true
                });

                let localStoredRecipeData = JSON.parse(localStoredRecipe);
                localStoredRecipeData = this.cleanLocalStoredRecipe(localStoredRecipeData);

                this.props.setData(localStoredRecipeData);

            } else {

                // No ID or HASH parameter given - create a new empty recipe
                this.props.resetRecipe();
                let g = shortid();
                this.props.addFoodGroup({
                    id: g,
                    title: '',
                    description: '',
                    type: 'plate',
                    opened: true
                });

            }
            
            // Load recipe when in RECIPE_EDITOR mode

            if(props.data.appMode === 'RECIPE_EDITOR') {
                const recipeId = props.data.appModeData.recipeId;
                const articleId = props.data.appModeData.articleId;

                api('recipes/add/public/' + articleId + '/' + recipeId).then(result => {
                    if(typeof result.data.error !== 'undefined') {
                        this.props.history.push('/recipe');
                        return;
                    }
                    this.props.resetRecipe();
                    this.props.deleteEmptyFoodGroups();                    
                    result = result.data;
                    Object.keys(result.groups).map(g => {
                        const group = result.groups[g];
                        this.props.addFoodGroup({
                            id: group.group_id,
                            title: group.title,
                            type: group.type,
                            opened: true
                        });    
                        this.props.setFoodGroupDescription({
                            id: group.group_id,
                            description: group.description
                        });                
                    }); 
        
                    Object.keys(result.foods).map(f => {
                        const food = result.foods[f];
                        const newFoodId = shortid();
                        this.props.addFood({
                            id: newFoodId,
                            food_id: food.food_id,
                            group_id: food.group_id,
                            is_drink: food.is_drink,
                            weight: food.amount,
                            image: "https://www.zofapp.cz/data/zof/item/" + food.food_id + ".svg",
                            title: this.props.data.globals.itemData[food.food_id]['title'],
                        });
                        Object.keys(food.modifications).map(m => {
                            const modificationId = food.modifications[m];
                            this.props.addFoodModification({
                                foodId: newFoodId,
                                modificationId: modificationId
                            });
                        });
                    });

                    this.props.setRecipeTitle({
                        mealHash: activeMealHash,
                        title: result.title
                    });

                    this.props.setTags(result.tags);

                    this.props.setData({
                        lastOpenedCookBookRecipe: props.match.params.id,
                    });   

                    this.props.setAppModeData({
                        evalHighlight: result.eval_highlight
                    });
        
                    this.setState({
                        recipe: result,
                        loading: false
                    }, () => {
                        Util.doLoadEvaluation();
                    });                    
                });


            } else {

                this.setState({
                    loading: false
                }, () => {
                    Util.doLoadEvaluation();
                });

            }

        } else {

            // ID parameter given - load recipe by given ID
            if(props.match.params.id) {
                api('recipes/add/' + this.props.match.params.id).then(result => {
                    if(typeof result.data.error !== 'undefined') {
                        this.props.history.push('/recipe');
                        return;
                    }
                    this.props.resetRecipe();
                    this.props.deleteEmptyFoodGroups();                    
                    result = result.data;
                    Object.keys(result.groups).map(g => {
                        const group = result.groups[g];
                        this.props.addFoodGroup({
                            id: group.group_id,
                            title: group.title,
                            type: group.type,
                            opened: true
                        });    
                        this.props.setFoodGroupDescription({
                            id: group.group_id,
                            description: group.description
                        });                
                    }); 
        
                    Object.keys(result.foods).map(f => {
                        const food = result.foods[f];
                        const newFoodId = shortid();
                        this.props.addFood({
                            id: newFoodId,
                            food_id: food.food_id,
                            group_id: food.group_id,
                            is_drink: food.is_drink,
                            weight: food.amount,
                            image: "https://www.zofapp.cz/data/zof/item/" + food.food_id + ".svg",
                            title: this.props.data.globals.itemData[food.food_id]['title'],
                        });
                        Object.keys(food.modifications).map(m => {
                            const modificationId = food.modifications[m];
                            this.props.addFoodModification({
                                foodId: newFoodId,
                                modificationId: modificationId
                            });
                        });
                    });

                    this.props.setRecipeTitle({
                        mealHash: activeMealHash,
                        title: result.title
                    });

                    this.props.setTags(result.tags);

                    this.props.setData({
                        lastOpenedCookBookRecipe: props.match.params.id,
                    });   
        
                    this.setState({
                        recipe: result,
                        loading: false
                    }, () => {
                        Util.doLoadEvaluation();
                    });                    
                });
            }

            // HASH parameter given - load meal from planner
            if(props.match.params.hash) {
                // props.resetRecipe();

                const mealHash = this.props.match.params.hash.replace('_', '|');

                let createNewFoodGroup = false;
                if(typeof this.props.data.foodGroups[mealHash] == 'undefined') {
                    createNewFoodGroup = true;
                } else {
                    let keys = Object.keys(this.props.data.foodGroups[mealHash]);
                    if(keys.length == 0) createNewFoodGroup = true;
                }
    
                if(createNewFoodGroup) {
                    let g = shortid();
                    props.addFoodGroup({
                        id: g,
                        title: '',
                        description: '',
                        type: 'plate',
                        opened: true
                    });                
                }
    
                props.setActiveMealHash(mealHash);
                this.setState({
                    loading: false
                }, () => {
                    Util.doLoadEvaluation();
                });    
    
                if(typeof this.props.data.meals[this.props.data.activeMealHash] == 'object') {
                    const mealId = this.props.data.meals[this.props.data.activeMealHash].mealId;
                    if(this.props.data.globals.mealTypes[mealId].gastro_only === true) {
                        this.props.setPlannerEditMode('GASTRO_DAILY_MENU');
                    }
                }
            }

        }

    }

    selectActiveProfile(e) {
        this.props.setProfileId(e.target.value);
    }

    updateRecipeTitle(e) {
        this.props.setRecipeTitle({
            mealHash: this.props.data.activeMealHash,
            title: e.target.value
        });
        Util.saveCurrentRecipeLocal();
    }

    openRecipeEditorHelp() {

        const helpContent = (
            <div className="recipe-editor-help">
                <p>Recept je vytvořen v <a style={{color:'#4eb32d'}} href="https://www.zofapp.cz" target="_blank">aplikaci ZOF</a>. Obsahuje:</p>
                <p><b>1. Seznam surovin</b></p>
                <p>U každé potraviny je uveden:</p>
                <ul>
                    <li>Objem/hmotnost (v gramech nebo mililitrech) - tyto hodnoty můžete upravovat.</li>
                    <li>Seznam alergenů (pokud je obsahuje) - A1 - A14.</li>
                    <li>Způsob kulinářské úpravy (můžete měnit).</li>
                </ul>
                <p><b>2. Popis potraviny</b></p>
                <p>Zobrazí se kliknutím na otazník u každé suroviny.</p>
                <p><b>3. Popis přípravy receptu</b></p>
                <p>Zobrazí se kliknutím na tlačítko "Popis přípravy".</p>
                <p><b>4. Nutriční vyhodnocení</b></p>
                <p>Nachází se v pravé části receptu, zobrazuje více než 60 nutričních hodnot. Při úpravě množství surovin v receptu se budou měnit i nutriční charakteristiky. U každé nutriční hodnoty se nachází otazník, kliknutím na něj se zobrazí bližší informace o sledované živině.</p>
            </div>
        );

        this.props.closeModal();
        this.props.openModal({
            'title': 'Nápověda', 
            'class': 'dialog-flexible',
            'content': helpContent
        });

    }

    render() {
        const { data } = this.props;
        const { globals } = data;

        if(this.state.loading) return <Loading />;
        let saving = this.state.saving ? <Saving /> : false;

        let recipeNameInput = false;
        if(
            typeof this.props.action === 'undefined' ||
            this.props.action == '' ||
            this.props.action == 'recipe-new'      
        ) {
            recipeNameInput = (
                <div className="container" style={{flex:'0',padding:'.5em 1em'}}>
                    <input className="input-recipe-name" type="text" onChange={this.updateRecipeTitle} placeholder="Zde napište název receptu" value={this.props.data.titles[this.props.data.activeMealHash]} />
                </div>
            );
        }

        let panelParameters = {};
        panelParameters.hash = this.props.match.params.hash;

        let content = (
            <div className="page page-bg">
                {recipeNameInput}
                <MenuPanel action={this.props.action} initialRecipeName={this.state.recipe.title} parameters={panelParameters} />
                <div className="page-content">
                    <div className="container" style={{flex:'1',paddingTop:'1em',paddingBottom:'1em'}}>
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-main" defaultChecked={true} />
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-evaluation" />
                        <div className="plan-mobile-nav">
                            <label htmlFor="cb-plan-main"><i className="fa fa-angle-down"></i> Rozpis</label>
                            <label htmlFor="cb-plan-evaluation"><i className="fa fa-angle-down"></i> Vyhodnocení</label>
                        </div>
                        <div className="plan">
                            <div className="plan-row">
                                <input type="checkbox" id="cb-panel-evaluation" />
                                <div className="plan-main">
                                    <MainPanel action={this.props.action} parameters={panelParameters} />
                                </div>
                                <EvaluationPanel 
                                    beforeContent={false}
                                    wholeDay={false}
                                    quickResults={true}
                                />
                            </div>
                        </div>
                        {saving}
                    </div>
                </div>
            </div>
        );

        // Lecture task mode - add assistant relevant to this meal to evaluation panel

        if(data.appMode === 'LECTURE' && typeof data.appModeData.lectureInfoData === 'object') {

            let profileId = Util.getActiveProfileId();
            if(typeof data.appModeData.profileId === 'number') {
                profileId = data.appModeData.profileId;
            }
    
            let a = this.props.data.activeMealHash.split('|');
            let thisMealHash = a[0] + '|' + profileId;
    
            /*
            const evaluator = new TaskEvaluator({
                'dddInfo': this.showTaskListDDDInfoFunc
            });
            const res = evaluator.evaluate({
                lectureInfo: data.appModeData.lectureInfoData,
                mode: 'meal',
                mealHash: thisMealHash
            });

            let tasksList = [];
            let taskListMeals = res.taskListMeals;
            let taskListMealsOk = res.taskListMealsOk;
            let taskListUndesirableFoodsUsed = res.taskListUndesirableFoodsUsed;
            let taskListDDD = res.taskListDDD;
            let taskListDDDOk = res.taskListDDDOk;

            if(taskListMeals.length > 0 || taskListMealsOk.length > 0) {
                tasksList.push(<h3>Chody / chuť</h3>);
                tasksList.push(taskListMeals);
                tasksList.push(taskListMealsOk);
            }

            if(taskListUndesirableFoodsUsed.length > 0) {
                tasksList.push(taskListUndesirableFoodsUsed);
            }

            if(taskListDDD.length > 0 || taskListDDDOk.length > 0) {
                tasksList.push(<h3>Nutriční hodnoty</h3>);

                const tmpEl = (
                    <div className="lecturetask-inside-meal">
                    {taskListDDD}
                    {taskListDDDOk}
                    </div>
                );

                tasksList.push(tmpEl);
            }

            // "Sledujte" v adminu - ale v rezimu jednoho receptu (ne celodenniho jidelnicku)
            // zobrazit jen ty, ktere jsou zaskrtle v sekci "Sledovat:" pri editaci lekce/ukolu


            let watchAttribs = [];
            if(typeof data.evaluationResults !== 'undefined') {
                if(typeof data.evaluationResults.results !== 'undefined') {

                    const thisMealIsEmpty = data.evaluationResults.results.weight === 0 && data.evaluationResults.results.energy_kcal === 0;

                    const lectureWatchAttribs = data.appModeData.lectureInfoData.lecture_data.watch_attributes.split(',');
                    ['gi', 'gn', 'digestibility', 'thermic_effect', 'acidobasicity', 'energy'].map(wa => {
                        if(lectureWatchAttribs.includes(wa)) {

                            let watchAttribsAlreadyFilled = [];
                            let resval, textEval;
                            const attr = wa;

                            switch(attr) {
                                
                                case 'gi':

                                    resval = data.evaluationResults.results[attr]; 
                                    textEval = 'nízký';
                                    if(resval > 50) textEval = 'střední';
                                    if(resval > 70) textEval = 'vysoký';

                                    if(thisMealIsEmpty) textEval = '-';

                                    watchAttribs.push(
                                        <div className="watch-attribute-row">
                                            <p>{data.globals.attributes[attr]}:</p>
                                            <aside>
                                                {textEval}
                                            </aside>
                                        </div>
                                    );
                                    watchAttribsAlreadyFilled.push(attr);
                                    break;

                                case 'gn':

                                    // Toto je celodenni vyhodnoceni 
                                    // tj. limity pro GN jsou jine nez pro mod jednoho receptu

                                    resval = data.evaluationResults.results[attr]; 
                                    textEval = 'nízká';
                                    if(resval > 10) textEval = 'střední';
                                    if(resval > 20) textEval = 'vysoká';

                                    if(thisMealIsEmpty) textEval = '-';

                                    watchAttribs.push(
                                        <div className="watch-attribute-row">
                                            <p>{data.globals.attributes[attr]}:</p>
                                            <aside>
                                                {textEval}
                                            </aside>
                                        </div>
                                    );
                                    watchAttribsAlreadyFilled.push(attr);
                                    break;

                                default:
                                    const attr2 = attr + '_n';
                                    let text = '';
                                    const val = +data.evaluationResults.results[attr2];
                                    switch(attr2) {
                                        case 'acidobasicity_n':
                                            text = data.globals.evaluationTexts['acidobasicity'][val];
                                            break;
                                        case 'digestibility_n':
                                            text = data.globals.evaluationTexts['digestibility'][val];
                                            break;
                                        case 'energy_n':
                                            text = data.globals.evaluationTexts['energytendency'][val];
                                            break;
                                        case 'thermic_effect_n':
                                            text = data.globals.evaluationTexts['thermiceffect'][val];
                                            break;
                                    }

                                    if(thisMealIsEmpty) text = '-';

                                    watchAttribs.push(
                                        <div className="watch-attribute-row">
                                            <p>{data.globals.attributes[attr]}:</p>
                                            <aside>
                                                {text}
                                                {'\u00A0'}
                                                {data.globals.attribute_units[attr]}
                                            </aside>
                                        </div>
                                    );
                                    watchAttribsAlreadyFilled.push(attr);
                            }
                        }
                    });
                }
            }

            if(watchAttribs.length > 0) {
                watchAttribs = (
                    <div style={{padding:'0 1rem'}}>
                        <div className="lecturetask-taskslist">
                            <h3>Sledujte</h3>
                            {watchAttribs}
                        </div>
                    </div>
                );
            }

            if(tasksList.length > 0) {
                tasksList = (
                    <>
                    <div style={{padding:'1rem',fontSize:'12px'}}>
                        <div className="lecturetask-taskslist">{tasksList}</div>
                    </div>
                    {watchAttribs}
                    </>
                );
            }
            */

            content = (
                <div className="page page-bg">
                    {recipeNameInput}
                    <MenuPanel action={this.props.action} initialRecipeName={this.state.recipe.title} parameters={panelParameters} />
                    <div className="page-content">
                        <div className="container" style={{flex:'1',paddingTop:'1em',paddingBottom:'1em'}}>
                            <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-main" defaultChecked={true} />
                            <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-evaluation" />
                            <div className="plan-mobile-nav">
                                <label htmlFor="cb-plan-main"><i className="fa fa-angle-down"></i> Rozpis</label>
                                <label htmlFor="cb-plan-evaluation"><i className="fa fa-angle-down"></i> Vyhodnocení</label>
                            </div>
                            <div className="plan">
                                <div className="plan-row">
                                    <input type="checkbox" id="cb-panel-evaluation" />
                                    <div className="plan-main">
                                        <MainPanel action={this.props.action} parameters={panelParameters} />
                                    </div>
                                    <div style={{flexBasis:'300px',flexShrink:'0',order:3}}>
                                        <EvaluationPanelContents 
                                            wholeDay={false} 
                                            results={data.evaluationResults.results} 
                                            ddd={data.evaluationResults.ddd} 
                                            mainStyle={{display:'block'}}
                                            quickResults={false} 
                                            evaluationCollapsedAtStart={false}
                                            macrosOnly={true}
                                        />                                        
                                    </div>
                                </div>
                            </div>
                            {saving}
                        </div>
                    </div>
                </div>
            );
        }


        // Standalone recipe editor mode

        if(data.appMode === 'RECIPE_EDITOR') {

            let desc = [];
            Object.keys(data.foodGroups).map(k => {
                Object.keys(data.foodGroups[k]).map(g => {
                    const fg = data.foodGroups[k][g];
                    desc.push(
                        <>
                            <h3>{fg.title}</h3>  
                            <div>{Util.parseRecipeDescription(fg.description)}</div>
                        </>
                    );
                });
            });


            /*
            $primary: #26AA52;
            $primary-lightest: #e9f5e5;
            $primary-dark: #4f842d;
            $background-light: #f7f7f7;
            */

            content = (
                <div className="page page-bg">
                    <div className="recipe-editor-heading">
                        <div className="container">
                            <h1>{this.props.data.titles['1|0']}</h1>
                            <aside>
                                <label htmlFor="cb-recipe-editor-description">Popis přípravy</label>
                                <a onClick={() => this.openRecipeEditorHelp()}>Nápověda</a>
                            </aside>
                        </div>
                    </div>
                    {recipeNameInput}
                    <MenuPanel action={this.props.action} initialRecipeName={this.state.recipe.title} parameters={panelParameters} />
                    <input type="checkbox" id="cb-recipe-editor-description" />
                    <div className="recipe-editor-description">
                        <div className="container">
                            {desc}
                        </div>
                    </div>
                    <div className="page-content" style={{borderTop:'none'}}>
                        <div className="container" style={{flex:'1',paddingBottom:'1em'}}>
                            <div className="plan" style={{background:'#fff',marginLeft:'-1rem',marginRight:'-1rem'}}>
                                <div className="recipe-editor-row recipe-editor-limited-row">
                                    <input type="checkbox" id="cb-panel-evaluation" />
                                    <div className="recipe-editor-main">
                                        <MainPanelSingleRecipe action={this.props.action} parameters={panelParameters} />
                                    </div>
                                    <div className="recipe-editor-desc">
                                        <h2>Nutriční vyhodnocení</h2>
                                        <EvaluationPanelContentsRecipeEditor
                                            wholeDay={false} 
                                            results={data.evaluationResults.results} 
                                            ddd={data.evaluationResults.ddd} 
                                            mainStyle={{display:'block'}}
                                            quickResults={false} 
                                            evaluationCollapsedAtStart={false}
                                        />                               
                                    </div>
                                </div>
                            </div>
                            {saving}
                        </div>
                    </div>
                </div>
            );
        }

        return content;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecipeEditor));
