import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Loading from 'components/Loading';
import RecipeSearch from 'components/RecipeSearch';
import RecipeDetail from './RecipeDetail';

import { 
    openModal, 
    closeModal
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
    };
};

class RecipeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }

        this.openRecipeDetail = this.openRecipeDetail.bind(this);
        this.beginReload = this.beginReload.bind(this);
        this.endReload = this.endReload.bind(this);
        this.showMyRecipes = this.showMyRecipes.bind(this);
    }

    componentDidMount() {
        // If LECTURE mode, and no loaded lecture data, redirect to "start" page to load them
        const d = this.props.data;
        if(d.appMode === 'LECTURE' && typeof d.appModeData.lectureInfoData !== 'object') {
            this.props.history.push('/');
        }


        if(typeof this.props.match.params.hash === "string") {
            this.openRecipeDetail(this.props.match.params.hash, false);
        }
    }

    showMyRecipes() {
        this.props.history.push('/recipe');
        this.props.history.push('/recipe/me');
    }

    openRecipeDetail(id, e) {
        if(e) {
            e.preventDefault();
            // if (e.target != e.currentTarget) return;
        }
        this.props.closeModal();
        this.props.openModal({
            'title': 'Detail receptu', 
            'class': 'dialog-big',
            'content': (
                <RecipeDetail id={id} showMyRecipes={this.showMyRecipes} beginReload={this.beginReload} endReload={this.endReload} />
            )
        });
    }

    beginReload() {
        this.setState({
            loading: true
        });
    }

    endReload() {
        this.setState({
            loading: false
        });
    }

    render() {
        if(this.state.loading) return <Loading />;

        let content = [
            <div className="page page-recipes">
                <RecipeSearch onClick={this.openRecipeDetail} newRecipeLink={true} />
            </div>
        ];

        return content;
    }  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecipeList));
