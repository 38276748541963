import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parse, format } from "date-fns";
import Loading from 'components/Loading/index';
import Util from 'utility';
import api from 'api';

import { 
    openModal,
    closeModal,
    showMessage,
    setFoodGroupDescription,
    loadGlobals,
    loginUser,
} from 'actions/index';

import ClientChat from './ClientChat';
import ClientData from './ClientData';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
        setFoodGroupDescription: data => dispatch(setFoodGroupDescription(data)),
        loadGlobals: data => dispatch(loadGlobals(data)),
        loginUser: data => dispatch(loginUser(data)),
    };
};


class ClientPage extends Component { 
    constructor(props) {
        super(props);

        let initialTab = 'index';
        if(typeof this.props.match.params.page !== 'undefined') {
            if(this.props.match.params.page == 'chat') {
                initialTab = 'chat';
            }
        }

        this.state = {
            tab: initialTab,
            client: {},
            groups: {},
            loading: true,
        };    

        this.loadClients = this.loadClients.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.switchToUser = this.switchToUser.bind(this);
        this.saveClientGroup = this.saveClientGroup.bind(this);
        this.handleExpiryDateChange = this.handleExpiryDateChange.bind(this);
        this.onNotesChange = this.onNotesChange.bind(this);
        this.saveClientNotes = this.saveClientNotes.bind(this);
        this.removeClient = this.removeClient.bind(this);
        this.doRemoveClient = this.doRemoveClient.bind(this);
    }

    componentDidMount() {
        api('clients/list', { post: true, data: { filter: this.state.filter }}).then((res) =>{
            this.setState({
                clients: res.data.clients,
                groups: res.data.groups
            }, () => {
                api('clients/load/' + this.props.match.params.id).then((res) =>{
                    this.setState({
                        loading: false,
                        client: res.data.client
                    });
                });        
            });
        });

    }

    changeTab(e, tab) {
        this.setState({
            tab: tab
        });
    }

    
    loadClients() {
        api('clients/list', { post: true, data: { filter: this.state.filter }}).then((res) =>{
            this.setState({
                clients: res.data.clients,
                groups: res.data.groups
            });
        });
    }

    switchToUser(e, client) {
        api('clients/switch-to', { post: true, data: { id: client.id }}).then((res) =>{
            let result = res.data;
            if(typeof result.error !== 'undefined') return;

            localStorage.setItem('zof_profi_return_hash', result.return_hash);
            localStorage.setItem('jwt_token', result.auth_token);
            this.props.loadGlobals(result.startup_data);
            this.props.loginUser({
                user: result.startup_data.user,
                callback: () => {
                    this.props.history.push('/');
                }
            });
        });
    }

    handleExpiryDateChange(e) {
        //let expiryDate = parse(e.target.value, 'yyyy-MM-dd', new Date());
        let client = this.state.client;
        client.zof_profi_client_expiry = e.target.value;
        this.setState({
            client: client
        }, () => {
            api('clients/set-expiry-date', {post:true, data:{client_id:this.state.client.id, expiry_date:client.zof_profi_client_expiry}}).then(res => {
                this.loadClients();
            });
        });
    }


    saveClientGroup(e) {
        api('clients/set-group', {post:true, data:{client_id:this.state.client.id, group_id:e.target.value}}).then(res => {
            this.loadClients();
        });
    }



    onNotesChange(e) {
        let client = this.state.client;
        client.zof_profi_client_notes = e.target.value;
        this.setState({
            client: client
        });
    }

    saveClientNotes(e) {
        api('clients/save-notes', {post:true, data:{client_id:this.state.client.id, notes:this.state.client.zof_profi_client_notes}}).then(res => {
            this.loadClients();
            this.props.showMessage({
                show: true,
                type: 'success',
                message: 'Poznámky byly uloženy.'
            });               
        });
    }

    removeClient(id, e) {

        this.props.closeModal();
        this.props.openModal({
            'title': 'Přidat nový tým', 
            'class': 'dialog',
            'content': (
                <div style={{textAlign:'center'}}>
                    <p><b>Chcete opravdu odstranit klienta?</b></p>
                    <p style={{margin:'2em 0 1em 0'}}><a style={{fontWeight:'bold',color:'#f00',textDecoration:'underline'}} onClick={(e) => this.doRemoveClient(id, e)}>Ano, odstranit</a></p>
                    <p><a onClick={(e) => this.props.closeModal()}>Ne, jít zpět</a></p>
                </div>
            )
        });
    }

    doRemoveClient(id) {
        api('clients/remove', {post:true, data: {id:id}}).then((res) => {
            this.props.closeModal();
            this.props.history.push('/clients');
        });
    }


    render() {
        const { state } = this;
        const { client } = this.state;
        const { globals } = this.props.data;

        if(state.loading === true) return <Loading />;

        // Birthday

        let birthday = 'nezadáno';
        if(typeof client.profile.birthday == 'string') {
            birthday = client.profile.birthday.split('-');
            birthday = birthday[2] + '. ' + birthday[1] + '. ' + birthday[0];
        }

        // BMR

        let bmr = client.profile.bmr;
        if(client.profile.individual_bmr == 0) {
            bmr += ' (vypočítaný)';
        } else {
            bmr += ' (zadaný uživatelem)';
        }

        // Energetic regime

        let erOptions = {
            'reduction': 'chci hubnout', 
            'balanced': 'vyrovnaný', 
            'gain': 'chci přibrat'
        };

        let reg = client.profile.energetic_regime;
        if(reg === null) reg = 'balanced';

        let energeticRegime = erOptions[reg];

        // Energy output coefficient

        const eeCoef = client.profile.energy_output_coefficient;
        let energyOutput = '-';
        if(typeof globals.energyOutputCoefficients[eeCoef] !== 'undefined') {
            energyOutput = globals.energyOutputCoefficients[eeCoef].title;
        }
        
        // Nutrition regimes

        let nutritionRegimes = [];
        client.profile.nutrition_regimes.map(regimeId => {
            if(typeof globals.eatingRegimes[regimeId] !== 'undefined') {
                nutritionRegimes.push(globals.eatingRegimes[regimeId].title);
            }
        });


        let groups = [];
        groups.push(<option value={0}>- Není přiřazen do týmu -</option>);
        state.groups.map(g => {
            groups.push(<option value={g.id}>{g.title}</option>);
        })

        //


        let startDateIso = 0;
        if(client.zof_profi_client_expiry && client.zof_profi_client_expiry != '') {
            startDateIso = this.state.client.zof_profi_client_expiry;
            //startDateIso = state.startDate.toISOString().substr(0,10);
        }

        let tabContent = false;

        if(this.state.tab == 'index') {
            tabContent = (
                <>
                    <table className="client-basic-table">
                        <tbody>
                            <tr>
                                <th>Spolupráce do:</th>
                                <td>
                                    <input type="date" name="zof_profi_client_expiry" defaultValue={startDateIso} onChange={this.handleExpiryDateChange} />  
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="client-basic-table">
                        <tbody>
                            <tr>
                                <th>Datum narození:</th>
                                <td>{birthday}</td>
                            </tr>
                            <tr>
                                <th>Výška:</th>
                                <td>{client.profile.height} cm</td>
                            </tr>
                            <tr>
                                <th>Váha:</th>
                                <td>{client.profile.weight} kg</td>
                            </tr>
                            <tr>
                                <th>Obvod pasu:</th>
                                <td>{client.profile.diameter_waist} cm</td>
                            </tr>
                            <tr>
                                <th>Obvod boků:</th>
                                <td>{client.profile.diameter_sides} cm</td>
                            </tr>
                            <tr>
                                <th>Bazální metabolismus:</th>
                                <td>{bmr}</td>
                            </tr>
                            <tr>
                                <th>Energetický režim:</th>
                                <td>{energeticRegime}</td>
                            </tr>
                            <tr>
                                <th>Výdej energie:</th>
                                <td>{energyOutput}</td>
                            </tr>
                            <tr>
                                <th>Stravovací režimy:</th>
                                <td>{nutritionRegimes.join(', ')}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="client-basic-table">
                        <tbody>
                            <tr>
                                <th>Tým:</th>
                                <td>
                                    <select defaultValue={this.state.client.zof_group_id} onChange={this.saveClientGroup}>
                                        {groups}
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p style={{margin:'3em 0',color:'#f00',textAlign:'center'}}>
                        <a style={{textDecoration:'underline'}} className="client-remove" onClick={(e) => this.removeClient(client.id)}>Odstranit klienta</a>
                    </p>

                </>
            );
        }

        if(this.state.tab == 'notes') {
            tabContent = (
                <div className="client-notes">
                    <p>Vaše poznámky k tomuto uživateli:</p>
                    <p><textarea onChange={this.onNotesChange} name="zof_profi_client_notes">{client.zof_profi_client_notes}</textarea></p>
                    <p><button onClick={this.saveClientNotes}>Uložit poznámky</button></p>
                </div>
            );
        }

        if(this.state.tab == 'chat') {
            tabContent = <ClientChat id={client.id} />;
        }

        if(this.state.tab == 'data') {
            tabContent = <ClientData id={client.id} />;
        }

        return (
            <div className="page">
                <div className="page-header">
                    <h1>{client.name}</h1>
                </div>
                <div className="page-tabs">
                    <a onClick={(e) => this.changeTab(e, 'index')} className={'' + state.tab == 'index' ? 'active' : ''}>Přehled</a>
                    <a onClick={(e) => this.changeTab(e, 'notes')} className={'' + state.tab == 'notes' ? 'active' : ''}>Poznámky</a>
                    <a onClick={(e) => this.changeTab(e, 'chat')} className={'' + state.tab == 'chat' ? 'active' : ''}>Chat</a>
                    <a onClick={(e) => this.changeTab(e, 'data')} className={'' + state.tab == 'data' ? 'active' : ''}>Data</a>
                    <a onClick={(e) => this.switchToUser(e, client)}><span><i className="fa fa-sign-in-alt"></i></span> Do účtu</a>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="client-page-wrap">
                            {tabContent}
                        </div>
                    </div>
                </div>
            </div>
        );
/*
            <React.Fragment>
            <div className="client-detail" style={{margin:'-1em'}}>
                <div className="page">
                    <div className="page-header">
                        <p>
                            <img src={`https://www.zofapp.cz/data/student-avatar/${client.avatar}`} alt="" style={{height:'64px',width:'auto',borderRadius:'200px',border:'1px solid #ddd'}} />
                        </p>
                        <h1 style={{margin:0}}>{client.name}</h1>
                        <p>{client.username}</p>
                    </div>
                    <div className="page-tabs">
                        <label className={'' + (this.state.tab == 'basic' ? 'active' : '')}><input type="checkbox" name="s" onChange={(e) => this.changeTab(e, 'basic')} defaultChecked={this.state.tab == 'basic'} /><span></span> Přehled</label>
                        <label className={'' + (this.state.tab == 'notes' ? 'active' : '')}><input type="checkbox" name="s" onChange={(e) => this.changeTab(e, 'notes')} defaultChecked={this.state.tab == 'notes'} /><span></span> Poznámky</label>
                    </div>
                    <div className="page-content">
                        <div className="container" style={{paddingTop:'1em',paddingBottom:'1em'}}>
                            {tabContent}
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>            
        );            
*/
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientPage));
