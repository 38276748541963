import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { debounce } from "throttle-debounce";   
import Util from 'utility';
import api from 'api';

import PanelNewClient from './PanelNewClient';
import PanelClientDetail from './PanelClientDetail';
import PanelNewGroup from './PanelNewGroup';
import PanelEditGroup from './PanelEditGroup';
import PanelDeleteGroup from './PanelDeleteGroup';
import PanelGroupDetail from './PanelGroupDetail';

import { 
    openModal, 
    closeModal
} from 'actions/index';
import utility from 'utility';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
    };
};

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: { q: '', group_q: '' },
            total: 0,
            tab: 'clients',
            clients: {},
            groups: {},
        }
        this.update = this.update.bind(this);
        this.loadClients = this.loadClients.bind(this);
        this.loadClientsDebounced = debounce(250, this.loadClients);
        this.openClientDetail = this.openClientDetail.bind(this);
        this.beginReload = this.beginReload.bind(this);
        this.endReload = this.endReload.bind(this);
        this.showClients = this.showClients.bind(this);
        this.changeTab = this.changeTab.bind(this); 
        this.openNewClientDialog = this.openNewClientDialog.bind(this);
        this.openNewGroupDialog = this.openNewGroupDialog.bind(this);
        this.openEditGroupDialog = this.openEditGroupDialog.bind(this);
        this.openDeleteGroupDialog = this.openDeleteGroupDialog.bind(this);
        this.openGroupDetail = this.openGroupDetail.bind(this);
        this.newGroupDone = this.newGroupDone.bind(this);
        this.sortClient = this.sortClient.bind(this);
    }

    componentDidMount() {
        this.loadClients();
        if(typeof this.props.match.params.id === "string") {
            this.openClientDetail(this.props.match.params.id * 1, false);
        }
    }

    showClients() {
        this.props.history.push('/');
        this.props.history.push('/clients');
    }

    loadClients() {
        api('clients/list', { post: true, data: { filter: this.state.filter }}).then((res) =>{
            this.setState({
                clients: res.data.clients,
                groups: res.data.groups
            });
        });
    }

    update(e) {
        let filter = this.state.filter;
        switch(e.target.name) {
            case 'q':
                filter.q = e.target.value;
                break;
            case 'group_q':
                filter.group_q = e.target.value;
                break;
        }
        this.setState({
            filter: filter
        }, () => {
            this.loadClientsDebounced();
        });
    }

    openClientDetail(id, e) {
        if(e) e.preventDefault();

        let el_sort = e.target.closest('.sort');
        if(el_sort) {
            return false;
        }

        let el_chat = e.target.closest(".link-chat");
        if(el_chat) {
            // Open chat
            this.props.history.push('/clients/' + id + '/chat');
        } else {
            this.props.history.push('/clients/' + id)
        }
    }

    sortClient(id, dir, e) {
        api('clients/sort', { post: true, data: { id:id, dir:dir }}).then((res) =>{
            this.setState({
                clients: res.data.clients,
                groups: res.data.groups
            });
        });
    }


    openNewClientDialog() {
        this.props.closeModal();
        this.props.openModal({
            'title': 'Přidat nového uživatele', 
            'class': 'dialog',
            'content': (
                <PanelNewClient onDone={this.newGroupDone} />
            )
        });
    }

    openNewGroupDialog() {
        this.props.closeModal();
        this.props.openModal({
            'title': 'Přidat nový tým', 
            'class': 'dialog',
            'content': (
                <PanelNewGroup onDone={this.newGroupDone} />
            )
        });
    }

    openEditGroupDialog(e, group) {
        this.props.closeModal();
        this.props.openModal({
            'title': 'Upravit tým', 
            'class': 'dialog',
            'content': (
                <PanelEditGroup id={group.id} title={group.title} onDone={this.newGroupDone} />
            )
        });
    }

    openDeleteGroupDialog(e, group) {
        this.props.closeModal();
        this.props.openModal({
            'title': 'Smazat tým', 
            'class': 'dialog',
            'content': (
                <PanelDeleteGroup id={group.id} title={group.title} onDone={this.newGroupDone} />
            )
        });
    }


    openGroupDetail(id, e) {
        if(e) e.preventDefault();
        if(e.target.closest('A') !== null) return;
        // if (e.target != e.currentTarget) return;
        this.props.closeModal();
        this.props.openModal({
            'title': 'Detail týmu', 
            'class': 'dialog',
            'content': (
                <PanelGroupDetail id={id} loadClients={this.loadClients} beginReload={this.beginReload} endReload={this.endReload} clients={this.state.clients} groups={this.state.groups}  />
            )
        });
    }    

    newGroupDone() {
        this.loadClients();
        this.props.closeModal();
    }

    beginReload() {
        this.setState({
            loading: true
        });
    }

    endReload() {
        this.setState({
            loading: false
        });
    }

    changeTab(e, tab) {
        this.setState({
            tab: tab
        });
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;
        const { filter, clients, groups } = state;

        if(!Util.userHasPurchased('ZOF_PROFI')) return false;

        let pageContent = false;
        if(state.tab == 'clients') {
            let clientListHeader = (
                <div className="recipes-header">
                    <div className="recipe-item">
                        <div className="avatar">
                            Foto
                        </div>
                        <div className="title">
                            Jméno, e-mail, tým
                        </div>
                        <div className="generic">
                            Spolupráce do
                        </div>
                    </div>
                </div>
            );
    
            let clientList = [];
            if(clients.length > 0) {
                Object.keys(clients).map(key => {
                    const client = clients[key];

                    let groupName = false;
                    let group = false;
                    Object.keys(groups).map(g => {
                        if(groups[g].id == client.zof_group_id) {
                            group = groups[g];
                        }
                    });

                    if(group) {
                        groupName = <p style={{fontSize:'12px',marginTop:'4px',color:'#555'}}>Tým: {group.title}</p>;
                    }

                    let expiryInfo = false;
                    if(client.profi_expiry != '') {
                        let days = 'den';
                        if(Math.abs(client.profi_expiry_days) > 1) days = 'dny';
                        if(Math.abs(client.profi_expiry_days) > 4) days = 'dnů';
                        if(client.profi_expiry_days > 0) {
                            expiryInfo = <span>{client.profi_expiry} ({client.profi_expiry_days} {days})</span>;
                        } else {
                            expiryInfo = <span style={{color:'red'}}>{client.profi_expiry} ({client.profi_expiry_days} {days})</span>;
                        }
                    }

                    
//                    <div key={client.id} className="recipe-item" onClick={(e) => this.openClientDetail(client.id, e)}>

                    const random = Math.random() * 10000000;
                    let avatar = <img src={`https://www.zofapp.cz/data/zof/profile/${client.profile_picture}?r=${random}`} />;

                    let unreadMessagesWarning = false;
                    if(client.unread_messages > 0) {
                        unreadMessagesWarning = (
                            <a className="link-chat" onClick={(e) => this.openClientDetail(client.id, e)}>
                                <span style={{fontSize:'1.2em',color:'#f00',display:'inline-flex',alignItems:'center'}}>
                                    <i style={{fontSize:'1.4em',marginRight:'.2em'}} className="fa fa-envelope-square"></i>
                                    {client.unread_messages}
                                </span>
                            </a>
                        );
                    }

                    clientList.push(
                        <div key={client.id} className="recipe-item" onClick={(e) => this.openClientDetail(client.id, e)}>
                            <div className="sort">
                                <a className="sort" onClick={(e) => this.sortClient(client.id, 'up', e)}><i className="fa fa-chevron-up"></i></a>
                                <a className="sort" onClick={(e) => this.sortClient(client.id, 'down', e)}><i className="fa fa-chevron-down"></i></a>
                            </div>
                            <div className="avatar">
                                {avatar}
                            </div>
                            <div className="title">
                                <h3>{client.name}</h3>
                                <p style={{fontSize:'12px',margin:'4px 0 0'}}>{client.username}</p>
                                {groupName}
                            </div>
                            <div className="generic">
                                {unreadMessagesWarning}
                            </div>
                            <div className="generic">
                                {expiryInfo}
                            </div>
                        </div>
                    );
                });
            }

            pageContent = (
                <>
                <div className="filters" style={{background:'#fff'}}>
                    <div className="box">
                        <div className="filters">
                            <div className="search-input with-button">
                                <i className="fa fa-search"></i>
                                <input className="search" type="text" name="q" placeholder="Hledat v uživatelech..." value={filter.q} onChange={this.update} />
                                <button onClick={this.openNewClientDialog}><i className="fa fa-plus"></i> Přidat uživatele</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list">
                    {clientListHeader}
                    {clientList}
                </div>
                </>
            );
        }



        if(state.tab == 'groups') {

            let groupListHeader = (
                <div className="recipes-header">
                    <div className="recipe-item">
                        <div className="title">
                            Název týmu
                        </div>
                        <div className="generic">
                        </div>
                    </div>
                </div>
            );
    
            let groupList = [];
            if(groups.length > 0) {
                Object.keys(groups).map(key => {
                    const group = groups[key];
    
                    groupList.push(
                        <div key={group.id} className="recipe-item" onClick={(e) => this.openGroupDetail(group.id, e)}>
                            <div className="title" style={{padding:'.5em 1em'}}>
                                <h3 style={{textDecoration:'none'}}>{group.title}</h3>
                                <p style={{margin:'.5em 0 0',fontSize:'.9em'}}>Počet členů: {group.number_of_members}</p>
                            </div>
                            <div className="actions">
                                <a onClick={(e) => this.openEditGroupDialog(e, group)}><i className="far fa-edit"></i></a>
                                <a onClick={(e) => this.openDeleteGroupDialog(e, group)}><i className="far fa-trash-alt"></i></a>
                            </div>
                        </div>
                    );
                });
            }

            pageContent = (
                <>
                <div className="filters" style={{background:'#fff'}}>
                    <div className="box">
                        <div className="filters">
                            <div className="search-input with-button">
                                <i className="fa fa-search"></i>
                                <input className="search" type="text" name="group_q" placeholder="Hledat v týmech..." value={filter.group_q} onChange={this.update} />
                                <button onClick={this.openNewGroupDialog}><i className="fa fa-plus"></i> Přidat tým</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list">
                    {groupListHeader}
                    {groupList}
                </div>
                </>
            );
        }


        let content = [
            <div className="page">
                <div className="page-header">
                    <h1>PROFI</h1>
                </div>
                <div className="page-tabs">
                    <a onClick={(e) => this.changeTab(e, 'clients')} className={'' + state.tab == 'clients' ? 'active' : ''}>Uživatelé</a>
                    <a onClick={(e) => this.changeTab(e, 'groups')} className={'' + state.tab == 'groups' ? 'active' : ''}>Týmy</a>
                </div>
                <div className="page-content">
                    <div className="container">
                        {pageContent}
                    </div>
                </div>
            </div>
        ];

        return content;
    }  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Clients));
