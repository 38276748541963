import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import api from 'api';
import Loading from 'components/Loading/index';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class DialogExportToPDF extends Component { 

    constructor(props) {
        super(props);

        let now = Date.now();
        let initialDate = new Date(now);

        this.state = {
            type: 'normal',
            currentDate: this.props.currentDate,
            menuDate: initialDate,
            pdfLink: false,
        };
        this.yyyymmdd = this.yyyymmdd.bind(this);
        this.changeType = this.changeType.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
    }

    yyyymmdd(date) {
    
        // year
        let yyyy = '' + date.getFullYear();
    
        // month
        let mm = ('0' + (date.getMonth() + 1));  // prepend 0 // +1 is because Jan is 0
        mm = mm.substr(mm.length - 2);                  // take last 2 chars
    
        // day
        let dd = ('0' + date.getDate());         // prepend 0
        dd = dd.substr(dd.length - 2);                  // take last 2 chars
    
        return yyyy + "-" + mm + "-" + dd;
    }    

    changeType(e) {
        this.setState({
            type: e.target.value
        });
    }

    exportPDF() {
        const menuDate = this.yyyymmdd(this.props.data.plannerDate);
        this.setState({
            loading: true,
            pdfLink: false,
        }, () => {
            api('planner/export-pdf', {post: true, data: {date:menuDate, type:this.state.type}}).then(result => {
                this.setState({
                    loading: false,
                    pdfLink: 'https://www.zofapp.cz/data/zofapp/menu-pdf/' + result.data.filename + '.pdf'
                });
            });
        });

    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = data;

        let loading = false;
        if(state.loading) {
            loading = <p style={{padding:'0 0 2em 0',margin:'0',fontWeight:'bold'}}>Generuji PDF...</p>;
        }

        let downloadLink = false;
        if(state.pdfLink !== false) {
            downloadLink = <p style={{padding:'0 0 2em 0',margin:'0',fontWeight:'bold'}}><a style={{color:'#4ebe55',textDecoration:'none',padding:'.35em 1em',border:'1px solid #4ebe55',borderRadius:'3px'}} target="_blank" href={state.pdfLink}><i className="fa fa-file-pdf"></i> Stáhnout PDF</a></p>
        }


        return (
            <React.Fragment>
            <table className="generic-table">
                <tbody>
                    <tr>
                        <th style={{verticalAlign:'top'}}>Typ exportu:</th>
                        <td>
                            <p>
                                <label><input onClick={this.changeType} type="radio" name="type" value="normal" checked={state.type == 'normal'} /> běžný</label>
                            </p>
                            <p>
                                <label><input onClick={this.changeType} type="radio" name="type" value="compact" checked={state.type == 'compact'} /> kompaktní</label>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th></th>
                        <td>
                            {loading}
                            {downloadLink}
                        </td>
                    </tr>
                    <tr>
                        <th></th>
                        <td><button className="btn-primary" onClick={this.exportPDF}>Vytvořit PDF</button></td>
                    </tr>
                </tbody>
            </table>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogExportToPDF));
