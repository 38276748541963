import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from "date-fns";
import localeCs from 'date-fns/locale/cs';
import Loading from 'components/Loading/index';
import api from 'api';
import Util from 'utility';
import { Locale, __ } from 'locale';

import { 
    openModal,
    closeModal, 
    loadGlobals, 
    mergeGlobals, 
    loadDayPlan, 
    loadDayPlanCallback,
    setProfileId,
    setPlannerDate,
    setPlannerViewMode, 
    profiSetClientId,
    profiSetClientIdCallback,
    showMessage,
    resetRecipe,
    setProfileData,
    setLectureTaskData,
    addMeal,
    addTraining,
    setAppModeData,
} from '../../actions/index';

import EvaluationPanel from 'components/EvaluationPanel';
import SimpleCalendar from './SimpleCalendar';  
import DayPlan from './DayPlan';
import DayPlanOverview from './DayPlanOverview';
import PanelEnergyOutput from './PanelEnergyOutput';
import PanelSportSettings from './PanelSportSettings';
import DialogAssignmentInfo from './DialogAssignmentInfo';
import DialogProfileInfo from './DialogProfileInfo';
import DialogGenerator from './DialogGenerator';
import DialogGeneratorResults from './DialogGeneratorResults';
import DialogShoppingList from './DialogShoppingList';
import DialogDayNotes from './DialogDayNotes';
import DialogExportToPDF from './DialogExportToPDF';
import DialogLongTermEvaluation from './DialogLongTermEvaluation';
import DialogSaveMenu from './DialogSaveMenu';
import DialogSaveLectureTask from './DialogSaveLectureTask';
import DialogLoadLectureTask from './DialogLoadLectureTask';
import DialogLectureTaskSampleText from './DialogLectureTaskSampleText';

import LectureTaskDayPlan from './LectureTask/LectureTaskDayPlan';
import LectureTaskPlan from './LectureTask/LectureTaskPlan';
import LectureTaskAssistantDDD from './LectureTask/LectureTaskAssistantDDD';
import LectureTaskAssistantDDDLower from './LectureTask/LectureTaskAssistantDDDLower';
import SeminarWorkLongtermEvaluation from './LectureTask/SeminarWorkLongtermEvaluation';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        loadGlobals: data => dispatch(loadGlobals(data)),
        mergeGlobals: data => dispatch(mergeGlobals(data)),
        loadDayPlan: data => dispatch(loadDayPlan(data)),
        loadDayPlanCallback: data => dispatch(loadDayPlanCallback(data)),
        setProfileId: data => dispatch(setProfileId(data)),
        setPlannerDate: data => dispatch(setPlannerDate(data)),
        setPlannerViewMode: data => dispatch(setPlannerViewMode(data)),
        profiSetClientId: data => dispatch(profiSetClientId(data)),
        profiSetClientIdCallback: data => dispatch(profiSetClientIdCallback(data)),
        resetRecipe: data => dispatch(resetRecipe(data)),
        showMessage: data => dispatch(showMessage(data)),
        setProfileData: data => dispatch(setProfileData(data)),
        setLectureTaskData: data => dispatch(setLectureTaskData(data)),
        addMeal: data => dispatch(addMeal(data)),
        addTraining: data => dispatch(addTraining(data)),
        setAppModeData: data => dispatch(setAppModeData(data)),
    };
};

class Plan extends Component { 

    constructor(props) {
        super(props);

        this.toggleViewModeRef = React.createRef();

        let currentDate = new Date();        
        if(typeof props.match.params.date != 'undefined') {
            currentDate = new Date(props.match.params.date);
        }

        if(props.data.appMode === 'LECTURE') {
            currentDate = new Date('2000-01-01');
        }

        if(props.data.appMode === 'MENU_EDITOR') {
            currentDate = new Date('1990-01-01');
        }

        this.state = {
            loading: true,
            saving: false,
            loadingPlan: false,
            loadingGenerator: false,
            monthData: false,               // holds data for whole actual month
            monthDataKey: '',               // for caching last monthData request (format: yyyy-mm)s
            currentDate: currentDate,
            generatorFormData: {},
            generatorInputData: {},
            generatorData: false,
            generatorClientInsertToDate: false,
            copyDaySourceDate: false,
            showFullPreview: false,
            clients: {},                    // ZOF Profi: client list (for switching between them)
            lectureInfo: false,             // When appMode === LECTURE
            toggleViewModePanelOpened: false,
        }

        this.reloadDayPlan = this.reloadDayPlan.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.saveDayPlan = this.saveDayPlan.bind(this);
        this.generate = this.generate.bind(this);
        this.generateOneMeal = this.generateOneMeal.bind(this);
        this.generateOnePlate = this.generateOnePlate.bind(this);
        this.openGenerator = this.openGenerator.bind(this);
        this.openGeneratorResults = this.openGeneratorResults.bind(this);
        this.setGeneratorClientInsertDate = this.setGeneratorClientInsertDate.bind(this);
        this.importGeneratedMenu = this.importGeneratedMenu.bind(this);
        this.openPanelEnergyOutput = this.openPanelEnergyOutput.bind(this);
        this.openPanelSport = this.openPanelSport.bind(this);
        this.saveEnergyOutputSettings = this.saveEnergyOutputSettings.bind(this);
        this.saveSportSettings = this.saveSportSettings.bind(this);
        this.showFullPreview = this.showFullPreview.bind(this);
        this.copyDay = this.copyDay.bind(this);
        this.pasteDay = this.pasteDay.bind(this);
        this.abortCopyDay = this.abortCopyDay.bind(this);
        this.openShoppingList = this.openShoppingList.bind(this);
        this.openDayNotes = this.openDayNotes.bind(this);
        this.dayNotesSaved = this.dayNotesSaved.bind(this);
        this.exportToPDF = this.exportToPDF.bind(this);
        this.openSaveMenuPanel = this.openSaveMenuPanel.bind(this);
        this.openLongTermEvaluation = this.openLongTermEvaluation.bind(this);
        this.toggleViewModePanel = this.toggleViewModePanel.bind(this);
        this.togglePlannerViewMode = this.togglePlannerViewMode.bind(this);
        this.onRecommendedFoodAdded = this.onRecommendedFoodAdded.bind(this);
        this.loadClients = this.loadClients.bind(this);
        this.switchToClient = this.switchToClient.bind(this);
        this.updateEvaluation = this.updateEvaluation.bind(this);

        this.initLectureTask = this.initLectureTask.bind(this);

        this.showLectureTask = this.showLectureTask.bind(this);
        this.reloadTask = this.reloadTask.bind(this);
        this.openSaveLectureTaskPanel = this.openSaveLectureTaskPanel.bind(this);
        this.openLoadLectureTaskPanel = this.openLoadLectureTaskPanel.bind(this);
        this.onLectureTaskLoaded = this.onLectureTaskLoaded.bind(this);
        this.openAssignmentInfo = this.openAssignmentInfo.bind(this);
        this.openProfileInfo = this.openProfileInfo.bind(this);
        this.openLectureTaskSampleText = this.openLectureTaskSampleText.bind(this);
        this.openSeminarWorkLongtermEvaluationPanel = this.openSeminarWorkLongtermEvaluationPanel.bind(this);
        this.lectureTaskAssistantDDD = this.lectureTaskAssistantDDD.bind(this);

        if(props.data.appMode === 'DEFAULT') {
            Util.setPageTitle('Plánovač');
            this.reloadDayPlan(() => {
                if(this.props.match.params.action) {
                    if(this.props.match.params.action == 'generate') {
                        this.openGenerator();
                    }
                }
            });
        }
    
        if(props.data.appMode === 'LECTURE') {
            Util.setPageTitle('Úkol k lekci');
            // this.reloadTask();
        }

    }

    componentDidUpdate(prevProps) {
        if(this.props.data.appMode === 'DEFAULT') {
            if(typeof this.props.match.params.date == 'undefined') {
                const dateString = format(new Date(), 'yyyy-MM-dd');
                this.props.history.push('/plan/' + dateString);
                this.handleDateChange(dateString);
                this.loadClients();
            } else {
                const curDateFmt = format(this.state.currentDate, 'yyyy-MM-dd');
                if(this.props.match.params.date !== curDateFmt) {
                    this.setState({
                        currentDate: new Date(this.props.match.params.date)
                    });
                }
            }
        }
    }

    componentDidMount() {
        if(this.props.data.appMode === 'LECTURE') {
            const lectureId = this.props.data.appModeData.lectureId;

            let url = 'lecturetask/info/' + lectureId;
            if(this.props.data.appSubMode === 'LECTURETASK_SAMPLE') url += '?lectureTaskSample=1';
        
            api(url).then(result => {
                if(result.data.profile !== false) {
                    this.props.setProfileData({
                        id: result.data.profile.id,
                        data: result.data.profile
                    });
                    this.props.setAppModeData({lectureInfoData: result.data});
                    this.props.setAppModeData({profileId: result.data.profile.id});
                    this.props.setAppModeData({undesirableFoods: result.data.undesirable_foods});
                    this.props.setAppModeData({watchAttributes: result.data.watch_attributes});
                    this.props.setAppModeData({watchAlergens: result.data.watch_alergens});
                }    
                if(result.data.profile_override !== false) {
                    this.props.setAppModeData({
                        profileOverride: result.data.profile_override
                    });
                }
                this.setState({
                    lectureInfo: result.data,
                    loading: false
                });

                this.props.setLectureTaskData(result.data);

                let reloadPlan = true;
                if(Util.isCurrentTaskSeminarWork() && !Util.isCurrentTaskSeminarWorkAssignment()) {
                    // reloadPlan = false;
                }

                if(reloadPlan) {
                    api('load/dayplan?date=2000-01-01&clientId=' + this.props.data.profiClientId).then(result => {
                        result = result.data;
                        result.callback = () => {
                            this.updateEvaluation();

                            // !!! is empty, create at least meals and trainings
                            if(typeof result.empty === 'number') {
                                this.initLectureTask();
                            }
    
                            this.setState({
                                loadingPlan: false
                            });
                        };
                        this.props.loadDayPlanCallback(result);
                        this.setState({
                            loadingPlan: false
                        });
                    });            
                } else {
                    this.setState({
                        loadingPlan: false
                    });
                }
            });            
        }

        /*
        if(this.props.data.appMode == 'MENU_EDITOR') {
            api('menus/insert/public/' + this.props.data.appModeData.articleId + '/' + this.props.data.appModeData.menuId).then(result => {
                this.setState({
                    loading: false,
                    loadingPlan: false
                });
                this.props.history.push('/plan/1990-01-01');
            });
        }
        */
    }


    // copied from LectureSeminarWorkTaskLoad (initTask(), line 95)
    initLectureTask() {
        const data = this.state.lectureInfo.lecture_data;
        this.props.resetRecipe();

        // Prepare meals, if they are a part of the task
        if(Array.isArray(data.meal_types)) {
            data.meal_types.map(m => {
                let t = m.time.split(':');
                const params = {
                    mealId: m.type * 1,
                    mealLength: 0,
                    startHour: t[0] * 1,
                    startMinute: t[1] * 1,
                    profiles: [Util.getActiveProfileId()]
                };
                this.props.addMeal(params);
            });
        }

        // Prepare trainings, if they are a part of the task
        if(Array.isArray(data.activity_types)) {
            data.activity_types.map(a => {
                if(a.time == "") return;
                if(a.length == "") return;
                let t = a.time.split(':');
                const params = {
                    start: t[0] + ':' + t[1],
                    length: a.length,
                    description: a.title,
                    profiles: [Util.getActiveProfileId()]
                };
                this.props.addTraining(params);
            });
        }
    }


    toggleViewModePanel() {
        const tgl = this.state.toggleViewModePanelOpened;
        this.setState({
            toggleViewModePanelOpened: !tgl
        });
    }

    togglePlannerViewMode(mode) {
        let currentViewMode = this.props.data.plannerViewMode;
        //this.props.setPlannerViewMode(1 - currentViewMode);
        this.setState({
            toggleViewModePanelOpened: false,
            showFullPreview: false
        });
        if(currentViewMode !== mode) {
            this.props.setPlannerViewMode(mode);
        }
    }

    showFullPreview() {
        let showFP = !this.state.showFullPreview;
        if(showFP) {
            this.props.setPlannerViewMode(0);
            this.setState({
                toggleViewModePanelOpened: false,
                showFullPreview: showFP
            });
        } else {
            this.setState({
                toggleViewModePanelOpened: false,
                showFullPreview: showFP
            });
        }
    }

    copyDay() {
        this.setState({
            copyDaySourceDate: this.state.currentDate
        });
    }

    pasteDay() {
        this.setState({
            saving: true
        }, () => {
            api('planner/copy-day', {post: true, data: {sourceDate:this.state.copyDaySourceDate, targetDate:this.state.currentDate}}).then(result => {
                this.setState({
                    saving: false,
                    copyDaySourceDate: false
                }, () => {
                    this.reloadDayPlan();
                });
            });
        });
    }

    abortCopyDay() {
        this.setState({
            copyDaySourceDate: false
        });
    }

    reloadDayPlan(callback) {
        this.loadClients();     // Put this elsewhere? This loads ZOF Profi clients (for switching between them)
    
        const m = this.state.currentDate.getMonth() + 1;
        const y = this.state.currentDate.getFullYear();

        api('load/month-data?m=' + m + '&y=' + y + '&clientId=' + this.props.data.profiClientId).then(result => {
            result = result.data;
            this.handleDateChange(this.state.currentDate);
            this.setState({
                monthData: result,
                monthDataKey: y + '-' + m,
                loading: false
            }, () => {
                if(typeof callback === 'function') callback();
            });
        });
    }

    reloadTask(callback) {
        const dayFmt = '2000-01-01';

        api('load/dayplan?date=' + dayFmt).then(result => {
            result = result.data;
            result.callback = () => {
                this.updateEvaluation();
                this.setState({
                    loadingPlan: false
                });
            };
            this.props.loadDayPlanCallback(result);


            const lectureData = this.props.data.appModeData.lectureInfoData.lecture_data;
    
            // Prepare trainings, if they are a part of the task
            if(Array.isArray(lectureData.activity_types)) {
                lectureData.activity_types.map(a => {
                    if(a.time == "") return;
                    if(a.length == "") return;

                    let trainingExists = false;
                    Object.keys(this.props.data.trainings).map(t => {
                        const training = this.props.data.trainings[t];
                        if(training.start === a.time) {
                            trainingExists = true;
                        }
                    });

                    if(!trainingExists) {
                        let t = a.time.split(':');
                        const params = {
                            start: t[0] + ':' + t[1],
                            length: a.length,
                            description: a.title,
                            profiles: [Util.getActiveProfileId()]
                        };
                        this.props.addTraining(params);
                    }
                });
            }

            this.setState({
                loadingPlan: false
            });
        });            
    }

    pad(number, length) { 
        var str = String(number);
        return (str.length >= length) ? str : ( new Array(length - str.length + 1).join('0') ) + str;
    }

    handleDateChange(date) {
        if(typeof date === 'string') {
            let ds = date.split('-');
            date = ds[0] + '-' + this.pad(ds[1], 2) + '-' + this.pad(ds[2], 2);
    
            let y = date.substr(0,4);
            let m = date.substr(5,2) - 1;
            let d = date.substr(8,2);
            date = new Date(y,m,d);
        } 

        const props = this.props;
        const dayFmt = '' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        const currYM = '' + this.state.currentDate.getFullYear() + '-' + (this.state.currentDate.getMonth() + 1);
        const nextYM = '' + date.getFullYear() + '-' + (date.getMonth() + 1);
        
        let t = date.getTime() - (date.getTimezoneOffset() * 60000 );
        var dateString = new Date(t)
                    .toISOString()
                    .split("T")[0];

        if(this.props.match.params.action) {
            props.history.push('/plan/' + dateString + '/' + this.props.match.params.action);
        } else {
            props.history.push('/plan/' + dateString);
        }

        props.setPlannerDate(date);

        this.setState({
            currentDate: date,
            loadingPlan: true
        }, () => {

            if(currYM == nextYM) {  // jumping to same month
                api('load/dayplan?date=' + dayFmt + '&clientId=' + this.props.data.profiClientId).then(result => {
                    result = result.data;
                    result.callback = () => {
                        this.updateEvaluation();
                        this.setState({
                            loadingPlan: false
                        });
                    };
                    props.loadDayPlanCallback(result);
                    this.setState({
                        loadingPlan: false
                    });
                });            
            } else {        // jumping to different month
                const m = date.getMonth() + 1;
                const y = date.getFullYear();

                api('load/month-data?m=' + m + '&y=' + y + '&clientId=' + this.props.data.profiClientId).then(result => {
                    result = result.data;
                    this.handleDateChange(this.state.currentDate);
                    this.setState({
                        monthData: result,
                        monthDataKey: y + '-' + m,
                    }, () => {
                        api('load/dayplan?date=' + dayFmt).then(result => {
                            result = result.data;
                            result.callback = () => {
                                this.updateEvaluation();
                                this.setState({
                                    loadingPlan: false
                                });
                            };
                            props.loadDayPlanCallback(result);
                        });                   
                    });
                });
            }
        });
    }

    saveDayPlan() {
        let saveData = {};
        saveData.titles = JSON.stringify(this.props.data.titles);
        saveData.meals = JSON.stringify(this.props.data.meals);
        saveData.foods = JSON.stringify(this.props.data.foods);
        saveData.groups = JSON.stringify(this.props.data.foodGroups);
        saveData.modifications = JSON.stringify(this.props.data.modifications);
        saveData.activities = JSON.stringify(this.props.data.activities);
        saveData.trainings = JSON.stringify(this.props.data.trainings);
        saveData.title = this.state.saveTitle;
        saveData.dayTimestamp = Math.floor(this.state.currentDate.getTime() / 1000);
        saveData.energyOutputSettings = JSON.stringify(this.props.data.energyOutputSettings);
        saveData = JSON.stringify(saveData);

        api('save/dayplan', { post: true, data: saveData }).then(result => {
            result = result.data;

            const m = this.state.currentDate.getMonth() + 1;
            const y = this.state.currentDate.getFullYear();
            api('load/month-data?m=' + m + '&y=' + y).then(result => {
                result = result.data;
            //    this.handleDateChange(this.state.currentDate);
                this.setState({
                    monthData: result,
                    monthDataKey: y + '-' + m,
                });
            });
        });
    }

    openPanelEnergyOutput(e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;
        this.props.closeModal();
        this.props.openModal({
            'title': 'Výdej energie', 
            'content': (
                <PanelEnergyOutput onDone={this.saveEnergyOutputSettings} />
            ),
            'onClose': this.saveEnergyOutputSettings
        });
    }

    saveEnergyOutputSettings() {
        this.saveDayPlan();
        this.props.closeModal();
    }

    openPanelSport(e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;
        this.props.closeModal();
        this.props.openModal({
            'title': 'Sportovní aktivity v průběhu dne', 
            'content': (
                <PanelSportSettings onDone={this.saveSportSettings} />
            ),
            'onClose': this.saveSportSettings
        });
    }

    saveSportSettings() {
        this.saveDayPlan();
        this.props.closeModal();
    }

    openGenerator() {
        this.props.closeModal();
        this.props.openModal({
            'title': 'Generátor jídelníčku', 
            'class': 'dialog-flexible',
            'content': (
                <DialogGenerator onGenerate={this.generate} clients={this.state.clients} />
            )
        });
    }

    generate(data) {
        if(!data) data = this.state.generatorInputData;

        this.props.closeModal();
        this.setState({
            loading: true
        }, () => {

            const profileId = data.get('profile_id');
            if(typeof this.props.data.energyOutputSettings[profileId] !== 'undefined') {
                const eo = this.props.data.energyOutputSettings[profileId];
                data.set('eo_coefficient_id', eo.coefficientId);
                data.set('eo_amount', eo.amount);
                data.set('eo_unit', eo.unit);
            }

            this.setState({
                loadingGenerator: true,
                generatorFormData: data
            }, () => {
                api('generator', { post: true, data: data }).then(result => {
                    result = result.data;
                    this.setState({
                        loading: false,
                        loadingGenerator: false,
                        generatorInputData: data,
                        generatorData: result
                    }, () => {
                        this.openGeneratorResults();
                    });
                });
            });
        
        });
    }
    
    generateOneMeal(mealId) {
        let data = this.state.generatorInputData;
        data.append('regenerateFromHash', this.state.generatorData.hash);
        data.append('regenerateMealId', mealId);

        const profileId = data.get('profile_id');
        if(typeof this.props.data.energyOutputSettings[profileId] !== 'undefined') {
            const eo = this.props.data.energyOutputSettings[profileId];
            data.set('eo_coefficient_id', eo.coefficientId);
            data.set('eo_amount', eo.amount);
            data.set('eo_unit', eo.unit);
        }
        this.setState({
            loadingGenerator: true,
            generatorFormData: data
        }, () => {
            api('generator', { post: true, data: data }).then(result => {
                result = result.data;
                this.setState({
                    loading: false,
                    loadingGenerator: false,
                    generatorInputData: data,
                    generatorData: result
                }, () => {
                    this.openGeneratorResults();
                });
            });
        });       
    }
        
    generateOnePlate(mealId, recipeId) {
        let data = this.state.generatorInputData;
        data.append('regenerateFromHash', this.state.generatorData.hash);
        data.append('regenerateRecipeId', recipeId);
        data.append('regenerateRecipeMealId', mealId);

        const profileId = data.get('profile_id');
        if(typeof this.props.data.energyOutputSettings[profileId] !== 'undefined') {
            const eo = this.props.data.energyOutputSettings[profileId];
            data.set('eo_coefficient_id', eo.coefficientId);
            data.set('eo_amount', eo.amount);
            data.set('eo_unit', eo.unit);
        }
        this.setState({
            loadingGenerator: true,
            generatorFormData: data
        }, () => {
            api('generator', { post: true, data: data }).then(result => {
                result = result.data;
                this.setState({
                    loading: false,
                    loadingGenerator: false,
                    generatorInputData: data,
                    generatorData: result
                }, () => {
                    this.openGeneratorResults();
                });
            });
        });       
    }
            
    openGeneratorResults(e, data) {
        let menu = this.state.generatorData;
        let dialogTitle = 'Vygenerovaný jídelníček';
        if(typeof menu.info.regimes === 'string') {
            let energRegime = 'Vyrovnaný';
            if(menu.info.energetic_regime == '1') energRegime = 'Hubnoucí';
            dialogTitle = `Jídelníček pro: ${menu.info.name}`;
        }
        this.props.closeModal();
        this.props.openModal({
            'title': dialogTitle, 
            'class': 'dialog-big',
            'content': (
                <DialogGeneratorResults 
                    regenerate={this.generate} 
                    regenerateOneMeal={this.generateOneMeal} 
                    regenerateOnePlate={this.generateOnePlate} 
                    monthData={this.state.monthData} 
                    menu={menu} 
                    onImport={this.importGeneratedMenu} 
                    onClientDateClick={this.setGeneratorClientInsertDate} 
                    />
            )
        });
    }

    setGeneratorClientInsertDate(e, date) {
        let dateFmt = Util.yyyymmdd(date);
        this.setState({
            generatorClientInsertToDate: dateFmt
        });
    }

    importGeneratedMenu(mealsToAdd) {
        let data = this.state.generatorFormData;
        data.append('mealsToAdd', mealsToAdd);
        data.append('hash', this.state.generatorData.hash);

        if(Util.userHasPurchased('ZOF_PROFI')) {
            data.append('clientId', this.state.generatorData.client_id);
            data.append('clientInsertToDate', this.state.generatorClientInsertToDate);
        }

        api('generator/insert', { post: true, data: data }).then(result => {
            this.props.closeModal();

            this.props.showMessage({
                show: true,
                type: 'success',
                message: 'Jídelníček byl vložen.'
            });               

            this.reloadDayPlan();
        });
    }
    
    openShoppingList() {
        this.props.closeModal();
        this.props.openModal({
            'title': 'Nákupní seznam', 
            'class': 'dialog-flexible',
            'content': (
                <DialogShoppingList onGenerate={this.generate} />
            )
        });
    }

    openDayNotes() {
        this.props.closeModal();
        this.props.openModal({
            'title': 'Poznámky ke dni', 
            'class': 'dialog-flexible',
            'content': (
                <DialogDayNotes date={this.state.currentDate} onSaved={this.dayNotesSaved} />
            )
        });
    }

    dayNotesSaved() {
        this.props.showMessage({
            show: true,
            type: 'success',
            message: 'Poznámky byly uloženy.'
        });               
        this.props.closeModal();
    }

    exportToPDF() {
        const currentDate = Math.floor(this.state.currentDate.getTime() / 1000);
        this.props.closeModal();
        this.props.openModal({
            'title': 'Export jídelníčku do PDF', 
            'class': 'dialog-flexible',
            'content': (
                <DialogExportToPDF currentDate={currentDate} onGenerate={this.generate} />
            )
        });
    }

    openSaveMenuPanel() {
        this.props.closeModal();
        this.props.openModal({
            'title': 'Uložit aktuální den jako jídelníček', 
            'class': 'dialog-flexible',
            'content': (
                <DialogSaveMenu />
            )
        });
    }

    openLongTermEvaluation() {
        this.props.closeModal();
        this.props.openModal({
            'title': __('Dlouhodobé vyhodnocení'), 
            'class': 'dialog-big',
            'content': (
                <DialogLongTermEvaluation gotoDate={this.handleDateChange} />
            )
        });
    }

    openSeminarWorkLongtermEvaluationPanel() {
        this.props.closeModal();
        this.props.openModal({
            'title': __('Dlouhodobé vyhodnocení'), 
            'class': 'dialog-big',
            'content': (
                <SeminarWorkLongtermEvaluation />
            )
        });
    }

    onRecommendedFoodAdded() {
        this.handleDateChange(this.state.currentDate);
    }

    
    loadClients() {
        api('clients/list', { post: true, data: { filter: {} }}).then((res) =>{
            this.setState({
                clients: res.data.clients
            });
        });        
    }

    switchToClient(e) {
        this.props.profiSetClientIdCallback({
            id: e.target.value,
            callback: () => {

                this.setState({
                    loadingPlan: true
                }, () => {
        
                    const props = this.props;
                    let date = this.state.currentDate;

                    const dayFmt = '' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

                    const m = date.getMonth() + 1;
                    const y = date.getFullYear();
    
                    api('load/month-data?m=' + m + '&y=' + y + '&clientId=' + props.data.profiClientId).then(result => {
                        result = result.data;
                        this.handleDateChange(this.state.currentDate);
                        this.setState({
                            monthData: result,
                            monthDataKey: y + '-' + m,
                        }, () => {
                            api('load/dayplan?date=' + dayFmt + '&clientId=' + props.data.profiClientId).then(result => {
                                result = result.data;
                                result.callback = () => {
                                    this.updateEvaluation();
                                    this.setState({
                                        loadingPlan: false
                                    });
                                };
                                props.loadDayPlanCallback(result);
                            });
                        });
                    });

                });

            }
        });
    }

    updateEvaluation() {
        Util.doLoadDayEvaluation();        
    }

    showLectureTask() {
        const lectureId = this.props.data.appModeData.lectureId;
        api('lecturetask/info/' + lectureId).then(result => {

            this.props.openModal({
                'title': __('Zadání úkolu'), 
                'class': 'dialog-flexible',
                'content': (
                    <>
                    <div dangerouslySetInnerHTML={{__html: result.data.task_text}}></div>
                    </>
                ),
            });
            });
    }

    openSaveLectureTaskPanel() {
        this.props.closeModal();
        this.props.openModal({
            'title': __('Uložit úkol'), 
            'class': 'dialog-flexible',
            'content': (
                <DialogSaveLectureTask defaultTitle={this.state.lectureInfo.title} />
            )
        });
    }

    openLoadLectureTaskPanel() {
        this.props.closeModal();
        this.props.openModal({
            'title': __('Načíst úkol'), 
            'class': 'dialog-big',
            'content': (
                <DialogLoadLectureTask onLoaded={this.onLectureTaskLoaded} />
            )
        });
    }

    openAssignmentInfo() {
        this.props.closeModal();
        this.props.openModal({
            'title': __('Zadání úkolu'), 
            'class': 'dialog-flexible',
            'content': (
                <DialogAssignmentInfo assignmentText={this.state.lectureInfo.lecture_data.intro_text_new} />
            )
        });
    }

    openProfileInfo() {
        this.props.closeModal();
        this.props.openModal({
            'title': __('Informace o profilu'), 
            'class': 'dialog-flexible',
            'content': (
                <DialogProfileInfo />
            )
        });
    }

    openLectureTaskSampleText() {
        this.props.closeModal();
        this.props.openModal({
            'title': __('Komentář k vzorovému jídelníčku'), 
            'class': 'dialog-flexible',
            'content': (
                <DialogLectureTaskSampleText text={this.state.lectureInfo.lecture_data.demo_lecture_text} />
            )
        });
    }


    onLectureTaskLoaded() {

        // Check if task completed
        // if it is, set appropriate var in state

        this.props.closeModal();
        this.reloadTask();
    }

    lectureTaskAssistantDDD(e, attr, lower) {
        e.preventDefault();
        // if (e.target != e.currentTarget) return;
        this.props.closeModal();

        if(typeof lower === 'undefined') {
            this.props.openModal({
            title:
                "Potraviny vhodné k doplnění: " +
                this.props.data.globals.attributes[attr],
            closeButton: __('Zavřít'),
            content: (
                <LectureTaskAssistantDDD
                attr={attr}
                onFoodAdded={() => {
                    this.props.closeModal();
                    this.reloadTask();
                }}
                />
            ),
            });
        } else {
            this.props.openModal({
            title:
                "Potraviny s nejvyšším obsahem: " +
                this.props.data.globals.attributes[attr],
            closeButton: __('Zavřít'),
            content: (
                <LectureTaskAssistantDDDLower
                attr={attr}
                onFoodAdded={() => {
                    this.props.closeModal();
                    this.reloadTask();
                }}
                />
            ),
            });
        }
    }
    
    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = data;

        let content = <Loading />;
        let plan = <Loading />;

        if(this.state.loading) return content;

        let activeProfileId = data.profileId;
        if(activeProfileId == 0) {
            activeProfileId = Object.keys(globals.profiles)[0];
        }

        plan = <DayPlan date={this.state.currentDate} onUpdateEvaluation={this.updateEvaluation} onChange={this.saveDayPlan} />;

        let copyPasteButton = <a onClick={this.copyDay}><i className="far fa-copy"></i> Kopírovat den</a>;
        if(this.state.copyDaySourceDate !== false) {
            const copySourceDateFmt = format(this.state.copyDaySourceDate, 'yyyy-MM-dd');
            const todayDateFmt = format(this.props.data.plannerDate, 'yyyy-MM-dd');
            if(copySourceDateFmt != todayDateFmt) {
                copyPasteButton = (
                    <React.Fragment>
                        <a onClick={this.pasteDay}><i className="fas fa-paste"></i> Vložit den</a>
                        <a onClick={this.abortCopyDay}><i className="fas fa-times"></i> Zrušit kopírování dne</a>
                    </React.Fragment>
                );
            } else {
                copyPasteButton = (
                    <React.Fragment>
                        <a onClick={this.copyDay}><i className="far fa-copy"></i> Vyberte cílový den</a>
                        <a onClick={this.abortCopyDay}><i className="fas fa-times"></i> Zrušit kopírování dne</a>
                    </React.Fragment>
                );
            }
        }
        
        let viewTypeSwitchTitle = state.showFullPreview ? 'Hodinový přehled' : 'Celkový přehled';
        let viewTypeSwitch = <a className="btn-plain" onClick={this.showFullPreview}>{viewTypeSwitchTitle}</a>;

        let compactSwitchIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.058 216.666" width="100%" height="100%"><path d="M46.593 110.218H67.06c3.378 0 6.14 2.763 6.14 6.14v37.357h34.285c5.475 0 8.187 6.6 4.349 10.49l-50.658 50.658a6.146 6.146 0 01-8.699 0L1.82 164.205c-3.889-3.89-1.126-10.49 4.35-10.49h34.283v-37.357c0-3.377 2.763-6.14 6.14-6.14zM67.465 106.449H46.998c-3.378 0-6.14-2.763-6.14-6.14V62.951H6.573c-5.476 0-8.188-6.601-4.35-10.49L52.882 1.804a6.146 6.146 0 018.699 0l50.658 50.658c3.889 3.889 1.126 10.49-4.35 10.49H73.607v37.356c0 3.378-2.763 6.14-6.14 6.14z"/></svg>;
        if(data.plannerViewMode == 0) {
            compactSwitchIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.058 218.231" width="100%" height="100%"><path d="M46.593 0H67.06c3.378 0 6.14 2.763 6.14 6.14v37.357h34.285c5.475 0 8.187 6.601 4.349 10.49l-50.658 50.658a6.146 6.146 0 01-8.699 0L1.82 53.987c-3.889-3.889-1.126-10.49 4.35-10.49h34.283V6.14c0-3.377 2.763-6.14 6.14-6.14zM67.465 218.231H46.998c-3.378 0-6.14-2.763-6.14-6.14v-37.357H6.573c-5.476 0-8.188-6.601-4.35-10.49l50.658-50.658a6.146 6.146 0 018.699 0l50.658 50.658c3.889 3.889 1.126 10.49-4.35 10.49H73.607v37.357c0 3.377-2.763 6.14-6.14 6.14z"/></svg>;
        }

        let compactSwitch = (
            <>
            <input type="checkbox" id="dayplanner_viewmode_toggle" onChange={() => this.togglePlannerViewMode(0)} defaultChecked={data.plannerViewMode == 0} />
            <label className="btn-plain" htmlFor="dayplanner_viewmode_toggle">
                {compactSwitchIcon}
            </label>
            </>
        );

        if(state.showFullPreview) compactSwitch = false;



        let viewTypeSwitchText = __('Hodinový');
        if(this.props.data.plannerViewMode === 1) viewTypeSwitchText = __('Kompaktní');

        let toggleViewPanel = false;
        if(this.state.toggleViewModePanelOpened) {
            toggleViewPanel = (
                <div className="options">
                    <a onClick={() => this.togglePlannerViewMode(0)}>{__('Hodinový')}</a>
                    <a onClick={() => this.togglePlannerViewMode(1)}>{__('Kompaktní')}</a>
                </div>
            );
        }

        let viewTypeSwitch2 = (
            <div className="planner-switch">
                <label for="cb-planner-switch" onClick={() => this.toggleViewModePanel()}>{__('Přehled')}: <b>{viewTypeSwitchText}</b> <i className="fa fa-angle-down"></i></label>
                {toggleViewPanel}
            </div>
        );






        if(state.showFullPreview) plan = <DayPlanOverview />;
        if(this.state.saving == true) plan = <Loading />;

        let energyOutputAmount = 0;
        if(typeof data.energyOutputSettings[activeProfileId] != 'undefined') {
            energyOutputAmount = data.energyOutputSettings[activeProfileId].amount;
        }

        let navbar = (
            <>
            <label className="page-header-nav-label" htmlFor="cb-page-header-nav"><i className="fa fa-angle-down"></i></label>
            <input type="checkbox" id="cb-page-header-nav" />
            </>
        );

        let sidebar = (
            <>
            <SimpleCalendar initialDate={state.currentDate} onDateChange={this.handleDateChange} monthData={this.state.monthData} />
            <div className="panel-sidebar">
                <a onClick={this.openPanelEnergyOutput}><i className="fa fa-heartbeat"></i> Výdej energie</a>
                {copyPasteButton}
                <a onClick={this.openDayNotes}><i className="fa fa-marker"></i> Poznámky ke dni</a>
                <a onClick={this.openSaveMenuPanel}><i className="fa fa-save"></i> Uložit do „Jídelníčky“</a>
                <a onClick={this.openShoppingList}><i className="far fa-list-alt"></i> Nákupní seznam</a>
                <a onClick={this.exportToPDF}><i className="far fa-list-alt"></i> Export jídelníčku do PDF</a>
                <a onClick={this.openLongTermEvaluation}><i className="fa fa-chart-line"></i> Dlouhodobé vyhodnocení</a>
            </div>
            </>
        );

        let plannerHeadingText = format(state.currentDate, 'EEEE d. M. yyyy', {locale: localeCs});

        // "Default" app mode Plan content

        content = (
            <div className="page page-bg page-plan">
                <div className="page-header" style={{padding:'.5em 0'}}>
                    <div className="container">
                        <div className="page-header-row">
                            <h1>{plannerHeadingText}</h1>
                            <aside>
                                {navbar}
                            </aside>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container" style={{paddingTop:'1em',paddingBottom:'1em'}}>
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-side" />
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-main" defaultChecked={true} />
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-evaluation" />
                        <div className="plan-mobile-nav">
                            <label htmlFor="cb-plan-side"><i className="fa fa-angle-down"></i> Kalendář</label>
                            <label htmlFor="cb-plan-main"><i className="fa fa-angle-down"></i> Rozpis</label>
                            <label htmlFor="cb-plan-evaluation"><i className="fa fa-angle-down"></i> Vyhodnocení</label>
                        </div>
                        <div className="plan">
                            <div className="plan-row">
                                <input className="radio-plan-mobile-nav" type="checkbox" id="cb-panel-evaluation" />
                                <div className="plan-main">
                                    <div className="planner-hours">
                                        <div className="planner-top-nav">
                                            {viewTypeSwitch}
                                            {compactSwitch}
                                        </div>
                                        {plan}
                                    </div>
                                </div>
                                <div className="plan-side">
                                    {sidebar}
                                </div>
                                <EvaluationPanel 
                                    wholeDay={true} 
                                    quickResults={true}
                                    evaluationCollapsedAtStart={true}
                                    onRecommendedFoodAdded={this.onRecommendedFoodAdded} 
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        // Modifications for LECTURE app mode

        if(data.appMode === 'LECTURE') {

            const lectureTaskPlan = <LectureTaskDayPlan 
                                        date={this.state.currentDate} 
                                        onUpdateEvaluation={this.updateEvaluation} 
                                        onChange={this.saveDayPlan} />;

            content = <LectureTaskPlan
                            lectureInfo={state.lectureInfo}
                            viewTypeSwitch2={viewTypeSwitch2}
                            viewTypeSwitch={viewTypeSwitch}
                            compactSwitch={compactSwitch}
                            plan={lectureTaskPlan}
                            openAssignmentInfo={this.openAssignmentInfo}
                            openProfileInfo={this.openProfileInfo}
                            openPanelEnergyOutput={this.openPanelEnergyOutput}
                            openSaveLectureTaskPanel={this.openSaveLectureTaskPanel}
                            openLoadLectureTaskPanel={this.openLoadLectureTaskPanel}
                            openLectureTaskSampleText={this.openLectureTaskSampleText}
                            openSeminarWorkLongtermEvaluationPanel={this.openSeminarWorkLongtermEvaluationPanel}
                        />;
        }

        return content;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
