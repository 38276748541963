import React, { Component } from 'react';
import { connect } from 'react-redux';
import Util from 'utility';
import api from 'api';

import {
    addFoodModification,
    removeFoodModification
} from 'actions/index';

 const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        addFoodModification: data => dispatch(addFoodModification(data)),
        removeFoodModification: data => dispatch(removeFoodModification(data)),
    };
};

class LectureTaskPanelFoodModifications extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            modifications: this.props.data.modifications,
            selectedModificationHelpItem: '',
        };

        this.showModificationHelp = this.showModificationHelp.bind(this);
        this.foodModificationToggle = this.foodModificationToggle.bind(this);

    }

    foodModificationToggle(e, foodId) {
        if(e.target.checked) {
            this.props.addFoodModification({
                foodId: foodId,
                modificationId: e.target.value
            });
        } else {
            this.props.removeFoodModification({
                foodId: foodId,
                modificationId: e.target.value
            });
        }

        this.setState({
            actualFoodModifications: this.props.data.modifications
        });

        Util.doLoadDayEvaluation();
    }

    showModificationHelp(prepId) {
        api('modification-help/' + prepId).then(result => {
            result = result.data;
            if(result.error) return false;
            this.setState({
                selectedModificationHelpItem: result
            });
        });
    }
    render() {
        const { state } = this;
        const { globals } = this.props.data;
        const f = this.props.food;

        if(state.selectedModificationHelpItem !== '') {
            return (
                <>
                <p style={{margin:'0',fontSize:'.9em'}}><a style={{color:'#4eb32d',textDecoration:'underline'}} onClick={(e) => this.setState({selectedModificationHelpItem:''})}>Zpět</a></p>
                <h2>{state.selectedModificationHelpItem.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: state.selectedModificationHelpItem.description }}></div>
                </>
            );
        }


        let modList = [];

        let disableUnchecked = false;
        if (typeof this.state.modifications[f.id] !== "undefined") {
            if(this.state.modifications[f.id].length >= 999) {
                disableUnchecked = true;
            }
        }

        for (let prepKey in globals.preparation_type_ids_sorted) {
            let prepId = globals.preparation_type_ids_sorted[prepKey];
            if(prepId == 0) continue;
            let checked = false;
            if (typeof this.state.modifications[f.id] !== 'undefined') {
                if (this.state.modifications[f.id].indexOf(prepId) > -1) {
                    checked = true;
                }
            }

            let icon = <div className="icon"><img src={`https://www.zofapp.cz/data/zof/modifications/${prepId}.svg`} alt="" /></div>;

            let cbName = `cb_${f.id}_[]`;
            let prepUniqId = `prep_${f.id}_${prepId}`;

            if(disableUnchecked) {
                if(checked) {
                    modList.push(
                        <div className="mod">
                            <input type="checkbox" name={cbName} id={prepUniqId} value={prepId} checked={checked} onChange={(e) => this.foodModificationToggle(e, f.id)} />
                            <label htmlFor={prepUniqId}>
                                {icon}
                                <span>{this.props.data.globals.preparation_types[prepId]}</span>
                            </label>
                        </div>
                    );
                } else {
                    modList.push(
                        <div className="mod">
                            <input type="checkbox" name={cbName} id={prepUniqId} value={prepId} checked={checked} onChange={(e) => this.foodModificationToggle(e, f.id)} />
                            <label htmlFor={prepUniqId} style={{ opacity: .4 }}>
                                {icon}
                                <span>{this.props.data.globals.preparation_types[prepId]}</span>
                            </label>
                        </div>
                    );
                }
            } else {
                modList.push(
                    <div className="mod">
                        <input type="checkbox" name={cbName} id={prepUniqId} value={prepId} checked={checked} onChange={(e) => this.foodModificationToggle(e, f.id)} />
                        <label htmlFor={prepUniqId}>
                            {icon}
                            <span>{this.props.data.globals.preparation_types[prepId]}</span>
                        </label>
                        <a className="link-help" onClick={() => this.showModificationHelp(prepId)}>
                        </a>
                    </div>
                );
            }
        }

        return <div className="food-modifications-list">{modList}</div>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LectureTaskPanelFoodModifications);
