import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import api from 'api';

import EvaluationPanelContents from 'components/EvaluationPanel/EvaluationPanelContents';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class EvaluationPanelSimple extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            recipe: props.recipe,
            selectedGlossaryItem: {},
            glossaryModalIsOpen: false
        };    
        this.openGlossaryModal = this.openGlossaryModal.bind(this);
        this.closeGlossaryModal = this.closeGlossaryModal.bind(this);
        this.showEvaluationGlossary = this.showEvaluationGlossary.bind(this);
    }

    openGlossaryModal() {
        this.setState({ glossaryModalIsOpen: true });
    }

    closeGlossaryModal() {
        this.setState({ glossaryModalIsOpen: false });
    }    

    showEvaluationGlossary(id) {
        api('glossary/item/' + id).then(result => {
            result = result.data;
            if(result.error) return false;
            this.setState({
                selectedGlossaryItem: result
            });
            this.openGlossaryModal();
        });
    }

    render() {
        const { state } = this;

        const results = state.recipe.nutrition_data; //data.evaluationResults.results;

        let descFinal = (
            <React.Fragment>
                <div dangerouslySetInnerHTML={{ __html: this.state.selectedGlossaryItem.description }}></div>
                <p style={{ paddingTop: '1em', marginTop: '1em', borderTop: '1px solid #ddd' }}>Více se o nutriční kvalitě výživy dozvíte na <a style={{ color: '#4eb32d' }} href="https://www.kurzyatac.cz">www.kurzyatac.cz</a></p>
            </React.Fragment>
        );

        let glossaryDialog = false;
        if (state.glossaryModalIsOpen) {
            glossaryDialog = (
                <div className="dialog dialog-flexible" onClick={this.closeSaveRecipeDialog}>
                    <div className="dialog-body">
                        <h2>{this.state.selectedGlossaryItem.title} <a><i onClick={this.closeGlossaryModal} className="fa fa-times"></i></a></h2>
                        <div className="dialog-content">
                            <div style={{ height: '250px' }}>
                            <Scrollbars heightrelativetoparent="1">
                                <div style={{ flex: '1', overflow: 'hidden', padding: '0 14px 0 0' }}>
                                    {descFinal}
                                </div>
                            </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return <EvaluationPanelContents wholeDay={false} results={results} quickresults={false} opened={true} style={this.props.style} />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationPanelSimple);

