import React, { Component } from 'react';
import { connect } from "react-redux";
import Util from 'utility';

import {
    setFoodGroupTitle, 
    changeFoodGroupType, 
    openFoodGroup, 
    closeFoodGroup, 
    moveFoodGroup,
    removeFoodGroup, 
    modifyFoodGroupAmounts, 
    openModal, 
    closeModal, 
    setModalContent
 } from 'actions/index';

import FoodRow from 'components/Recipe/RecipeEditor/FoodRow';
import PanelAddFood from 'components/Recipe/RecipeEditor/MenuPanel/PanelAddFood';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        setFoodGroupTitle: data => dispatch(setFoodGroupTitle(data)),
        changeFoodGroupType: data => dispatch(changeFoodGroupType(data)),
        openFoodGroup: data => dispatch(openFoodGroup(data)),
        closeFoodGroup: data => dispatch(closeFoodGroup(data)),
        moveFoodGroup: data => dispatch(moveFoodGroup(data)),
        removeFoodGroup: data => dispatch(removeFoodGroup(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        setModalContent: data => dispatch(setModalContent(data)),
        modifyFoodGroupAmounts: data => dispatch(modifyFoodGroupAmounts(data)),
    };
};

class FoodGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amountChangeCoefficient: 100.0,
        };

        this.changeTitle = this.changeTitle.bind(this);
        this.toggle = this.toggle.bind(this);
        this.moveUp = this.moveUp.bind(this);
        this.moveDown = this.moveDown.bind(this);
        this.removeGroup = this.removeGroup.bind(this);

        this.openPanelAddFood = this.openPanelAddFood.bind(this);        

        this.updateAmountChangeCoefficient = this.updateAmountChangeCoefficient.bind(this);
        this.doUpdateAmount = this.doUpdateAmount.bind(this);
    }

    changeTitle(e) {
        if (this.props.data.globals.accessType == 'demo') {
            alert("Měnit název talíře je možné pouze v plnohodnotné verzi aplikace ZOF.");
            return;
        }

        this.props.setFoodGroupTitle({
            id: this.props.group.id,
            title: e.target.value
        });
    }

    toggle() {
        let mealHash = this.props.data.activeMealHash;
        if(this.props.data.foodGroups[mealHash][this.props.group.id].opened === true) {
            this.props.closeFoodGroup({
                id: this.props.group.id
            });
        } else {
            this.props.openFoodGroup({
                id: this.props.group.id
            });
        }
    }

    moveUp() {
        this.props.moveFoodGroup({
            id: this.props.group.id,
            direction: -1,
        })
    }

    moveDown() {
        this.props.moveFoodGroup({
            id: this.props.group.id,
            direction: 1,
        })
    }

    removeGroup() {
        if (this.props.data.globals.accessType == 'demo') {
            alert("Měnit název talíře je možné pouze v plnohodnotné verzi aplikace ZOF.");
            return;
        }

        this.props.removeFoodGroup({
            id: this.props.group.id
        });
        Util.doLoadEvaluation();
    }

    changeGroupType(e) {
        if (this.props.data.globals.accessType == 'demo') {
            alert("Měnit typ talíře je možné pouze v plnohodnotné verzi aplikace ZOF.");
            return;
        }

        let id = e.target.attributes.getNamedItem('data-id').value;
        this.props.changeFoodGroupType({
            id: id,
            type: e.target.value
        })
    }

    updateAmountChangeCoefficient(e) {
        let coef = e.target.value;
        coef = coef.replace(',', '.') * 1.0;
        this.setState({
            amountChangeCoefficient: coef
        });
    }

    doUpdateAmount() {
        this.props.modifyFoodGroupAmounts({
            group_id: this.props.group.id,
            coefficient: this.state.amountChangeCoefficient
        });
        Util.doLoadEvaluation();
    }

    openPanelAddFood(e) {
        if(e) {
            e.preventDefault();
            if (e.target != e.currentTarget) return;
        }
        this.props.closeModal();
        this.props.openModal({
            'title': 'Přidat potravinu do: ' + this.props.group.title, 
            'class': 'dialog-big',
            'content': (
                <PanelAddFood onFoodAdded={this.props.closeModal} groupId={this.props.group.id} />
            )
        });
    }


    render() {
        let group = this.props.group;
        let mealHash = this.props.data.activeMealHash;
        let foods = this.props.data.foods[mealHash];

        let groupContents = [];
        let foodsNumberInThisGroup = 0;
        let totalFoodWeight = 0;
        if(foods) {
            foods.map(food => {
                if(food.group_id == group.id) {
                    foodsNumberInThisGroup++;
                    groupContents.push(
                        <FoodRow action={this.props.action} food={food} key={food.id} onModificationsOpen={this.props.onModificationsOpen} />
                    );
                    totalFoodWeight += food.weight * 1;
                }
            })
        }

        let toggleButton = <a className="toggle" onClick={this.toggle}><i className="far fa-minus-square"></i></a>;
        if(group.opened === false) {
            toggleButton = <a className="toggle" onClick={this.toggle}><i className="far fa-plus-square"></i></a>;
        }

        let groupBody = (
            <div className="zof-food-group-body">
                {groupContents}
            </div>            
        );

        if(group.opened === false) {
            groupBody = false;
        }


        let addFoodButton = <a onClick={this.openPanelAddFood} className="zof-food-group-add-btn"><span><i className="fa fa-plus"></i></span> Přidat potravinu</a>;



        let typeSelectOpts = [];
        for(let type in this.props.data.globals.foodGroupTypes) {
            typeSelectOpts.push(<option value={type} key={type}>{this.props.data.globals.foodGroupTypes[type]}</option>);
        }
        let typeSelect = <select name="group-select" data-id={group.id} defaultValue={group.type} onChange={this.changeGroupType.bind(this)}>{typeSelectOpts}</select>;

        let headerContents;
        if (Util.isReadOnly()) {
            typeSelect = this.props.data.globals.foodGroupTypes[group.type];
            headerContents = <React.Fragment>
                {toggleButton}{'\u00A0'}{'\u00A0'}
                {typeSelect}<span>:</span>{'\u00A0'}
                <strong>{group.title}</strong>
            </React.Fragment>;
        } else {

            let groupTitle = group.title;
            let groupPlaceholder = 'Zde napište název chodu';
            if(this.props.action == 'product-create' || this.props.action == 'product-edit') groupPlaceholder = 'Název produktu...';

            if(groupTitle == '- beze jména -') {
                groupTitle = '';
                groupPlaceholder = group.title;
            }

            let foodGroupTitle = <input type="text" className="title" placeholder={groupPlaceholder} value={groupTitle} onChange={this.changeTitle} />;

            let amountChange = (
                <div className="amount-change">
                    <label htmlFor={`cb_${group.id}`}>
                        <i className="fa fa-balance-scale"></i> <span>{Math.round(totalFoodWeight*10)/10} g/ml</span>
                    </label>
                    <input type="checkbox" id={`cb_${group.id}`} />
                    <div className="amount-change-inner">
                        <input type="text" value={this.state.amountChangeCoefficient} onChange={this.updateAmountChangeCoefficient} /><span>%</span>
                        <button onClick={this.doUpdateAmount}><i className="fa fa-sync"></i></button>
                    </div>
                </div>
            );
            
            
            // If current planner edit mode is gastro daily menu, restrict interactive elements to read-only elements only.

            if(this.props.data.plannerEditMode == 'GASTRO_DAILY_MENU') {
                typeSelect = false;
                amountChange = false;
                let gastroInfo = false;
                if(typeof group.gastro_data == 'object') {
                    let gastroInfoArray = [];
                    if(group.gastro_data.grammage !== null && group.gastro_data.grammage != '' && group.gastro_data.grammage != 'null') gastroInfoArray.push(group.gastro_data.grammage);
                    if(group.gastro_data.price !== null && group.gastro_data.price != '' && group.gastro_data.price != 'null') gastroInfoArray.push(group.gastro_data.price + ' Kč');
                    if(gastroInfoArray.length == 0) {
                        gastroInfo = <span style={{ color: 'red' }}>Gastro údaje nezadány.</span>;
                    } else {
                        gastroInfo = <span>{gastroInfoArray.join(', ')}</span>;
                    }
                }
                if(foodsNumberInThisGroup > 0) {
                    gastroInfo = <p style={{ fontSize: '11px', fontWeight: 'bold', margin: '0 1em 0 0' }}>{gastroInfo}</p>
                } else {
                    gastroInfo = false;
                }
                foodGroupTitle = (
                    <React.Fragment>
                        <input type="text" readOnly={true} style={{ background: 'none', border: 'none' }} value={groupTitle} />
                        {gastroInfo}
                    </React.Fragment>
                );                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
            }

            let buttons = (
                <React.Fragment>
                    {amountChange}
                    <a onClick={this.moveUp}><i className="fa fa-angle-up"></i></a>
                    <a onClick={this.moveDown}><i className="fa fa-angle-down"></i></a>
                    <a className="btn-delete" onClick={this.removeGroup}><i className="fa fa-times"></i></a>
                </React.Fragment>
            );


            if(this.props.action == 'product-create' || this.props.action == 'product-edit') {
                typeSelect = false;
                buttons = false;
            }

            headerContents = <>
                {toggleButton}
                {typeSelect}
                {foodGroupTitle}
                <aside>
                    {buttons}
                </aside>
            </>;

            if(this.props.data.appMode === 'RECIPE_EDITOR') {
                headerContents = false; 
                addFoodButton = false;
            }

        }

        if(headerContents !== false) {
            headerContents = <div className="food-group-header">{headerContents}</div>;
        }

        return (
            <div className={group.opened ? 'food-group food-group-opened' : 'food-group'}>
                {headerContents}
                {addFoodButton}
                {groupBody}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodGroup);
