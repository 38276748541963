import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { getQueriesForElement } from '@testing-library/react';
import { findDOMNode } from 'react-dom';
import shortid from 'shortid';
import store from 'store/index';
import { format } from "date-fns";
import api from 'api';
import UnitConvert from 'UnitConverter';
import Util from 'utility';
import { Locale, __ } from 'locale';

import { 
    clearEvaluationResults, 
    openModal, 
    closeModal, 
    showMessage,
    setData,
    setFoodAmount,
} from "../../actions/index";

import OutsideAlerter from 'components/OutsideAlerter';
import LectureTaskMealDescription from './LectureTaskMealDescription';
import LectureTaskPanelAddFood from './LectureTaskPanelAddFood';
import LectureTaskPanelAddRecipe from './LectureTaskPanelAddRecipe';
import LectureTaskPanelSave from './LectureTaskPanelSave';
import EvaluationPanelContents from 'components/EvaluationPanel/EvaluationPanelContents';
import TaskEvaluator from 'components/Plan/LectureTask/TaskEvaluator';
import LectureTaskPanelFoodModifications from './LectureTaskPanelFoodModifications';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        clearEvaluationResults: data => dispatch(clearEvaluationResults(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
        setData: data => dispatch(setData(data)),
        setFoodAmount: data => dispatch(setFoodAmount(data)),
    };
};

class MenuEditorDayPlan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: this.props.date,
            showMealTypeModal: false,
            selectedMealId: 0,
            modalX: 0,
            modalY: 0,

            mealSettingsHash: '',
            mealAddHash: '',
            addFoodOrRecipeHash: '',
            foodGroupSettingsHash: '',
            foodGroupTypeSettingsHash: '',
            foodGroupVisibility: {},
            adjustAmountId: '',

            foodModificationsPanelId: '',
            actualFoodModifications: {}, // must be in state because panel is initialized only once

            foodGroupAmountChangePercent: 100,
            
            focusedAmountInputId: 0,
            focusedAmountInputValue: '',

            currentMouseOverHour: -1,
            currentMouseOverMinute: 0,
            selectedMinute: 0,
        };

        this.openFoodModificationsPanel = this.openFoodModificationsPanel.bind(this);
        this.loadFoodDescription = this.loadFoodDescription.bind(this);
        this.showAlergenInfo = this.showAlergenInfo.bind(this);
        this.fmtEvalValue = this.fmtEvalValue.bind(this);
    }

    fmtEvalValue(v, precision) {
        if(precision === undefined) precision = 2;
        v = v * 1;
        return v.toFixed(precision);
    }

    showAlergenInfo(e, alerg) {
        this.props.openModal({
            'title': 'Alergen', 
            'content': (
                <>
                <p>{alerg}</p>
                </>
            )
        });
    }

    onHourMouseMove(e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;

        let hour = e.target.attributes.getNamedItem('data-hour').value * 1;
        let height = this[`refHour${hour}`].clientHeight;
        let clickY = e.nativeEvent.offsetY;
        let minute = Math.round((clickY / height) * 60);
        minute = Math.round(minute / 5) * 5;
        if (minute < 0) minute = 0;
        if (minute > 55) minute = 55;

        this.setState({
            currentMouseOverHour: hour,
            currentMouseOverMinute: minute
        });
    }

    onHourMouseLeave(e) {
        this.setState({
            currentMouseOverHour: -1,
            currentMouseOverMinute: 0
        });
    }
    handleFoodAmountInputFocus(e, food) {
        e.target.focus();
        e.target.select();
    
        this.setState({
            focusedAmountInputId: food.id,
            focusedAmountInputValue: parseFloat(food.weight) + ''
        });
    }

    handleFoodAmountInputBlur(e, mealHash, food) {
        let weight = e.target.value.replace(',', '.') * 1;
        let item = this.props.data.globals.itemData[food.food_id];

        if(isNaN(weight) || weight < item.weight_min || weight > item.weight_max) {
            alert("Neplatné množství potraviny - zadejte prosím množství od " + item.weight_min + " do " + item.weight_max + " " + item.weight_unit);
            weight = item.weight;
        }

        this.setState({
            foodWeight: weight,
            invalidWeight: false,
            focusedAmountInputId: 0,
            focusedAmountInputValue: '',
        }, () => {
            this.props.setFoodAmount({ 
                mealHash: mealHash,
                foodId: food.id, 
                weight: weight
            });
            this.saveAndUpdateEvaluation();
        });
    }

    handleFoodAmountChange(e) {
        this.setState({
            focusedAmountInputValue: e.target.value,
        });
    }

    openFoodModificationsPanel(e, m, f) {
        e.preventDefault();

        const data = this.props.data;
        const globals = data.globals;

        this.setState({
            actualFoodModifications: data.modifications
        });


        this.props.closeModal();
        this.props.openModal({
            'title': 'Úpravy potraviny ' + f.title,
            'class': 'dialog-flexible',
            'content': <LectureTaskPanelFoodModifications food={f} />
        });
    }

    loadFoodDescription(foodId, amount) {
        const amt = Math.round(amount * 1000);
        api('food/description-lt/' + foodId + '/' + amt + '/' + this.props.data.appModeData.lectureId).then(result => {
            result = result.data;
            if (result.error) return false;
            this.props.closeModal();
            this.props.openModal({
                'title': result.title, 
                'class': 'dialog dialog-flexible',
                'content': (
                    <div className="zof-food-description">
                        <div dangerouslySetInnerHTML={{ __html: result.description }} />
                    </div>
                )
            });
        });
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = data;

        let mealsAreEditable = false;

        let dayPlanHours = [];
        for (let hour = 0; hour < 24; hour++) {
            let hourItems = [];
            let hourItemsResult = [];
            for (let m in this.props.data.meals) {
                if (this.props.data.meals[m].startHour == hour) {
                    this.props.data.meals[m].mealHash = m;
                    hourItems.push({
                        'type': 'meal',
                        'data': this.props.data.meals[m],
                        'key': m
                    });
                }
            }


            // ATTN: MAXIMUM TRAININGS NUMBER!!!!!
            // MAX NUMBER OF TRAININGS - MAKE CFGABLE

            let trainingId;
            for(trainingId=1; trainingId<=10; trainingId++) {
                if(typeof this.props.data.trainings[trainingId] == 'undefined') continue;
                let training = this.props.data.trainings[trainingId];
                if(training.length == 0) continue;
                if(training.start == '') continue;
                training.id = trainingId;
                let x = training.start.split(':');
                training.startHour = x[0] * 1;
                training.startMinute = x[1] * 1;

                if(training.startHour == hour) {
                    hourItems.push({
                        'type': 'training',
                        'data': training,
                        'key': trainingId
                    });
                }
            }

            hourItems.sort((i1, i2) => {
                let minute1, minute2;
                if(i1.type == 'meal') {
                    minute1 = this.props.data.meals[i1.data.mealHash].startMinute;                    
                } else {
                    minute1 = i1.startMinute;
                }
                if(i2.type == 'meal') {
                    minute2 = this.props.data.meals[i2.data.mealHash].startMinute;                    
                } else {
                    minute2 = i2.startMinute;
                }
                return minute1 - minute2;
            });

            hourItems.map(i => {
                if(i.type == 'meal') {
                    let m = i.data.mealHash;
                    let mealId = this.props.data.meals[m].mealId;
                    let mealProfiles = [];

                    this.props.data.meals[m].profiles.map(profileId => {
                        let profileName = '';
                        if(typeof globals.profiles[profileId] !== 'undefined') {
                            profileName = globals.profiles[profileId].name;
                        }
                        if(profileName && profileName != '') {
                            mealProfiles.push(<span>{profileName}</span>);
                        }
                    });

                    let sh = this.props.data.meals[m].startHour;
                    let sm = this.props.data.meals[m].startMinute;
                    if (sh < 10) sh = '0' + sh;
                    if (sm < 10) sm = '0' + sm;

                    if(typeof this.props.data.globals.mealTypes[mealId] == 'undefined') return false;

                    let hourItemButtons = [];
                    hourItemButtons.push(
                        <>
                            <a onClick={(e) => this.handleEditMeal(m, e)}><i className="fa fa-pencil-alt"></i></a>
                            <a onClick={(e) => this.handleDeleteMeal(m, e)}><i className="fa fa-times"></i></a>
                        </>
                    );

                    let currentCommentButton = false;
                    if(typeof this.props.data.meals[m].profi_comment === 'string' && 
                        this.props.data.meals[m].profi_comment != '') {
                            currentCommentButton = <a onClick={(e) => this.handleMealShowProfiComment(m, e)}><i className="fa fa-comment-dots"></i></a>;

                        hourItemButtons.push(
                            <a onClick={(e) => this.handleMealShowProfiComment(m, e)}><i className="fa fa-comment-dots"></i></a>
                        );

                     }

                    let classes = ['meal-item-new'];
                    let thisItemMealId = m.split('|')[0];
                    if(globals.mealTypes[thisItemMealId].is_drink_regime) {
                        classes.push('meal-item-new-drink-regime');    
                    }

                    // Zrusit editacni tlacitka pouze, pokud neni 
                    // aktualni chod pitny rezim
                    if(!mealsAreEditable &&
                        !globals.mealTypes[thisItemMealId].is_drink_regime) {
                        hourItemButtons = false;
                    }

                    if(!mealsAreEditable) hourItemButtons = false;
                    // Pokud se nemaji zobrazovat editButtons, a typ
                    // chodu je pitny rezim, zobrazit jen Xko na smazani

                    if(!mealsAreEditable &&
                        globals.mealTypes[thisItemMealId].is_drink_regime) {
                            hourItemButtons = <a onClick={(e) => this.handleDeleteMeal(m, e)}><i className="fa fa-times"></i></a>;
                    }



                    let foodGroupTitles = [];
                    Object.keys(this.props.data.foodGroups[m]).map(key => {
                        const fg = this.props.data.foodGroups[m][key];
                        let bgUrl = `https://www.zofapp.cz/data/zof/recipe/t/${Math.floor(fg['from_recipe_id'] / 1000)}/${fg['from_recipe_id']}.jpg`;
                        foodGroupTitles.push(
                            <div className="title-row">
                                {fg.title}
                            </div>
                        )
                    });



                    let ev = false;
                    if(typeof data.mealsEvaluationResults[m] === 'object') {
                        ev = data.mealsEvaluationResults[m];
                    }


                    let sortedFoodGroupKeys = [];
                    for(let g in this.props.data.foodGroups[m]) {
                        sortedFoodGroupKeys.push({
                            id: g,
                            item_order: this.props.data.foodGroups[m][g].item_order
                        });
                    }

            
                    sortedFoodGroupKeys.sort(function(a, b) { 
                        return a.item_order - b.item_order;
                    });

                    let groups = [];
                    sortedFoodGroupKeys.map(k => {
                        const fg = this.props.data.foodGroups[m][k.id];
                        
                        let fgVisible = true;
                        let fgVisIcon = <i className="fa fa-angle-up"></i>;
                        if(this.state.foodGroupVisibility[fg.id] === 0) {
                            fgVisIcon = <i className="fa fa-angle-down"></i>;
                            fgVisible = false;
                        }


                        let foods = [];
                        data.foods[m].map(f => {
                            if(f.group_id !== fg.id) return;    

                            let adjustAmount = false;
                            if(state.adjustAmountId === f.id) {
                                adjustAmount = <div className="meal-item-adjust-amount">
                                    <p>
                                        <input type="text" name="amount" value={f.weight} />
                                        <span>{globals.itemData[f.food_id].weight_unit}</span>
                                        <button>OK</button>
                                    </p>
                                </div>;
                            }

                            let foodItem = {
                                id: f.food_id,
                                selectedWeight: f.weight,
                                weight: this.props.data.globals.itemData[f.food_id].weight,
                                norm_unit_amount: this.props.data.globals.itemData[f.food_id].norm_unit_amount * 1.0,
                                norm_unit_unit: this.props.data.globals.itemData[f.food_id].norm_unit_unit,
                                norm_unit2_amount: this.props.data.globals.itemData[f.food_id].norm_unit2_amount * 1.0,
                                norm_unit2_unit: this.props.data.globals.itemData[f.food_id].norm_unit2_unit
                            };

                            let uc = new UnitConvert;
                            let normalizedAmountInfo = uc.convert(foodItem);
                            if(normalizedAmountInfo !== null && normalizedAmountInfo != '' && normalizedAmountInfo != ' null') {
                                normalizedAmountInfo = <span>({normalizedAmountInfo})</span>;
                            } else {
                                normalizedAmountInfo = false;
                            }

                            let inputValue = parseFloat(f.weight + '');
                            if(this.state.focusedAmountInputId === f.id) {
                                inputValue = this.state.focusedAmountInputValue;
                            }

                            const foodItemData = this.props.data.globals.itemData[f.food_id];
                            let drinkModeSelection = false;
                            if(foodItemData.is_drink == 1) {
                                let cbName = 'is_drink_' + f.food_id;
                                drinkModeSelection = (
                                    <p><input type="checkbox" name={cbName} onChange={(e) => this.handleDrinkChange(e, m, f.id)} checked={f.is_drink == 1}/> pitný režim</p>
                                );
                            }

                            // Alergen info

                            let watchAlergens = [];
                            if(typeof this.props.data.appModeData.watchAlergens === 'object') {
                                watchAlergens = this.props.data.appModeData.watchAlergens;
                            }
                            watchAlergens = watchAlergens.map(a => +a);

                            let alergenList = [];
                            if(typeof this.props.data.globals.foodAlergens[f.food_id] === 'object') {
                                let alSorted = this.props.data.globals.foodAlergens[f.food_id].sort();
                                alSorted.map(id => {
                                    let alergenName = this.props.data.globals.itemAlergens[id];
                                    let alerg = alergenName;
                                    alergenName = alergenName.split('-')[0].trim();

                                    if(watchAlergens.includes(id*1)) {
                                        alergenList.push(<span onClick={(e) => this.showAlergenInfo(e, alerg)} style={{cursor:'pointer',borderBottom:'1px dotted #f00',color:'#f00'}}>{alergenName}</span>);
                                        alergenList.push(<span>, </span>);
                                    } else {
                                        alergenList.push(<span onClick={(e) => this.showAlergenInfo(e, alerg)} style={{cursor:'pointer',borderBottom:'1px dotted #bbb'}}>{alergenName}</span>);
                                        alergenList.push(<span>, </span>);
                                    }
                                });
                            }

                            // Undesirable food info

                            let undesirableInfo = false;
                            if(typeof this.props.data.appModeData.undesirableFoods !== 'undefined') {
                                if(this.props.data.appModeData.undesirableFoods.includes(f.food_id)) {
                                    for(let profileId in this.props.data.globals.undesirableFoods) {
                                        profileId = profileId * 1;
                                        undesirableInfo = <p className="item-undesirable"><span>Nevhodná potravina</span></p>;
                                    }
                                }
                            }

                            if(alergenList.length > 1) {
                                if(undesirableInfo) {
                                    alergenList.pop();
                                    undesirableInfo = <p className="item-undesirable"><span>Nevhodná potravina: <span style={{fontWeight:'normal',color:'#000'}}>{alergenList}</span></span></p>;
                                } else {
                                    alergenList.pop();
                                    undesirableInfo = <p className="item-undesirable" style={{color:'#000'}}>
                                                        <span>Alergeny: 
                                                            <span style={{fontWeight:'normal',color:'#000'}}>{alergenList}</span>
                                                        </span>
                                                      </p>;
                                }
                            }
                            
                            // Food modifications

                            let modList = [];
                            if(typeof this.props.data.modifications[f.id] !== "undefined") {
                                let mods = this.props.data.modifications[f.id];
                                mods.forEach((key, val) => {
                                    modList.push(this.props.data.globals.preparation_types[key]);
                                });
                            }
                            modList = modList.length > 0 ? modList.join(', ') : 'Bez úprav';

                            let modifications = (
                                <div className="item-modifications">
                                    <a data-food-id={f.id} onClick={(e) => this.openFoodModificationsPanel(e, m, f)}>
                                        <span>{modList}</span>
                                    </a>
                                </div>
                            );
    
                            foods.push(
                                <div className="meal-item-food">
                                    <div className="icon" style={{backgroundImage: `url(${f.image})`}}>
                                    </div>
                                    <div className="food-title">
                                        <h4>
                                            {f.title}
                                        </h4>
                                        <div className="amount">
                                            <div className="amount-input">
                                                {inputValue}
                                                {'\u00A0'}
                                                <span>{globals.itemData[f.food_id].weight_unit}</span>
                                            </div>
                                            {normalizedAmountInfo}
                                        </div>
                                        {modifications}                                        
                                    </div>
                                    <div className="options">
                                    </div>
                                </div>
                            );
                        });

                        let totalFoodWeight = 0;
                        let foodsNumberInThisGroup = 0;

                        data.foods[m].map(fd => {
                            if(fd.group_id == fg.id) {
                                foodsNumberInThisGroup++;
                                totalFoodWeight += fd.weight * 1;
                            }
                        });
                
                        if(!fgVisible) foods = false;

                        // Food group is visible - show a full version

                        if(fgVisible) {
                            groups.push(
                                <div className="meal-item-group">
                                    <div className="meal-item-group-header">
                                        <div>
                                            {globals.foodGroupTypes[fg.type]}
                                            <input type="text" placeholder={__('Název')} value={fg.title} />
                                        </div>
                                        <aside>
                                        </aside>
                                    </div>
                                    <div className="meal-item-group-foods">
                                        {foods}
                                    </div>
                                </div>
                            );
                        }

                        // Food group is not visible - show a minimalistic version

                        if(!fgVisible) {
                            groups.push(
                                <div className="meal-item-group-minimalistic"><div className="inner">
                                    <p>
                                        {globals.foodGroupTypes[fg.type]}:
                                        <b>{fg.title}</b>
                                    </p>
                                    <aside>
                                        <a onClick={() => this.toggleFoodGroupVisibility(fg.id)}>{fgVisIcon}</a>
                                    </aside>
                                </div></div>
                            );
                        }
                    });

                    const thisMealIsDrinkRegime = this.props.data.globals.mealTypes[mealId].is_drink_regime;

                    let addFoodGroupButton = (
                        <div className="meal-item-group-add-food">
                            <a onClick={(e) => this.openPanelAddFoodGroup(e, m)}>
                                <span><i className="fa fa-plus"></i></span>
                                {__('Přidat talíř')}
                            </a>
                        </div>
                    );

                    if(thisMealIsDrinkRegime) addFoodGroupButton = false;

                    hourItemsResult.push(
                        <div className={classes.join(' ')} key={m}>
                            <div className="title">
                                <p>
                                    <span className="time">
                                        <i className="fa fa-clock"></i> {sh}:{sm}
                                    </span>
                                    <em>&mdash;</em>
                                    <b>{this.props.data.globals.mealTypes[mealId].title}</b>
                                </p>
                                <aside>
                                    <div>
                                    </div>
                                    <div>
                                    </div>
                                </aside>
                                <div className="eval">
                                    <div className="col">
                                        {__('Bílkoviny')}: <b>{this.fmtEvalValue(ev.proteins, 1)} g</b>
                                    </div>
                                    <div className="col">
                                    {__('Tuky')}: <b>{this.fmtEvalValue(ev.fats, 1)} g</b>
                                    </div>
                                    <div className="col">
                                        {__('Sacharidy')}: <b>{this.fmtEvalValue(ev.carbohydrates, 1)} g</b>
                                    </div>
                                    <div className="col">
                                        {__('Vláknina')}: <b>{this.fmtEvalValue(ev.fiber, 1)} g</b>
                                    </div>
                                </div>
                            </div>
                            {groups}
                            {addFoodGroupButton}
                        </div>
                    );
                }

                if(i.type == 'training') {
                    let sh = i.data.startHour;
                    let sm = i.data.startMinute;
                    if (sh < 10) sh = '0' + sh;
                    if (sm < 10) sm = '0' + sm;

                    let mealItemClass = 'meal-item meal-item-training';
                    let m = i.data.id;

                    let hourItemButtons = false;

                    if(data.profiClientId > 0) {
                        hourItemButtons = false;
                    }

                    if(!mealsAreEditable) hourItemButtons = false;

                    hourItemsResult.push(
                        <div className="meal-item-new meal-item-new-training" key={m}>
                            <div className="title">
                                <p>
                                    <span className="time">
                                        <i className="fa fa-clock"></i> {sh}:{sm}
                                    </span>
                                    <em>&mdash;</em>
                                    <b>{__('Trénink')}</b>
                                </p>
                                <aside>
                                    <p className="meal-item-profiles">
                                        <b style={{color:'#000'}}>{i.data.description}</b><br />
                                        {__('Délka')}: {i.data.length} {__('minut')}
                                    </p>
                                </aside>
                            </div>
                        </div>
                    );
                }                


            });

            let hourFmt = hour >= 10 ? hour + ':00' : '0' + hour + ':00';
            if (this.state.currentMouseOverHour == hour) {
                let spanH = '0' + hour;
                if (hour >= 10) spanH = hour;
                let spanM = '0' + this.state.currentMouseOverMinute;
                if (this.state.currentMouseOverMinute >= 10) spanM = this.state.currentMouseOverMinute;
                hourFmt = spanH + ':' + spanM;
            }

            let addCurrentHour = true;
            if(hourItemsResult.length == 0) addCurrentHour = false;

            if(addCurrentHour) {
                dayPlanHours.push(
                    <div ref={div => { this[`refHour${hour}`] = div }} data-hour={hour} className="hour" key={hour}>
                        <span>{hourFmt}</span>
                        {hourItemsResult}
                    </div>
                );
            }
        }

        return dayPlanHours;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuEditorDayPlan));
