import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from "date-fns";
import api from 'api';
import {  withRouter } from 'react-router-dom';
import { Locale, __ } from 'locale';

import PanelAddFood from './PanelAddFood';
import PanelAddRecipe from './PanelAddRecipe';
import PanelAddFoodGroup from './PanelAddFoodGroup';
import PanelRecipeSettings from './PanelRecipeSettings';
import PanelSave from './PanelSave';
import PanelSaveProduct from './PanelSaveProduct';
import PanelProductDescription from './PanelProductDescription';

import { 
    openModal, 
    closeModal, 
    setRecipeTitle,
    setPlannerEditMode,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        setRecipeTitle: data => dispatch(setRecipeTitle(data)),
        setPlannerEditMode: data => dispatch(setPlannerEditMode(data)),
    };
};

class MenuPanelHorizontal extends Component { 
    constructor(props) {
        super(props);

        let action = '';
        if(this.props.match.path == '/recipe/open/:id') action = 'recipe-edit';

        // Is this a drink regime meal type?
        let isDrinkRegime = false;
        let hash = this.props.parameters.hash;
        if(hash && typeof hash === 'string') {
            hash = hash.split('_')[0];
            isDrinkRegime = this.props.data.globals.mealTypes[hash].is_drink_regime;
        }

        // Initial state

        this.state = {
            action: action,
            title: '',
            description: '',
            isDrinkRegime: isDrinkRegime
        };    
        this.onChange = this.onChange.bind(this);
        this.openPanelAddFood = this.openPanelAddFood.bind(this);        
        this.openPanelAddRecipe = this.openPanelAddRecipe.bind(this);        
        this.openPanelAddFoodGroup = this.openPanelAddFoodGroup.bind(this);
        this.openPanelRecipeSettings = this.openPanelRecipeSettings.bind(this);        
        this.openPanelSave = this.openPanelSave.bind(this);        
        this.saveAndBackToPlanner = this.saveAndBackToPlanner.bind(this);
        this.openPanelSaveProduct = this.openPanelSaveProduct.bind(this);        
        this.openPanelProductDescription = this.openPanelProductDescription.bind(this);        
    }

    componentDidMount() {
        const that = this;
        window.globalBroadcaster.subscribe('openAddFoodPanel', function(){
            that.openPanelAddFood();
        });
        window.globalBroadcaster.subscribe('openAddRecipePanel', function(){
            that.openPanelAddRecipe();
        });
    }

    componentWillUnmount() {
        window.globalBroadcaster.unsubscribe('openAddFoodPanel');
        window.globalBroadcaster.unsubscribe('openAddRecipePanel');
    }

    openPanelAddFood(e) {
        if(e) {
            e.preventDefault();
            if (e.target != e.currentTarget) return;
        }
        this.props.closeModal();
        this.props.openModal({
            'title': 'Přidat potravinu', 
            'class': 'dialog-big',
            'content': (
                <PanelAddFood onFoodAdded={this.props.closeModal} isDrinkRegime={this.state.isDrinkRegime} />
            )
        });
    }

    openPanelAddRecipe(e) {
        if(e) {
            e.preventDefault();
            if (e.target != e.currentTarget) return;
        }

        const data = this.props.data;

        let isGastroDailyMenu = false;
        if(typeof data.meals[data.activeMealHash] == 'object') {
            const mealId = data.meals[data.activeMealHash].mealId;
            isGastroDailyMenu = data.globals.mealTypes[mealId].gastro_only;
        }

        this.props.closeModal();
        this.props.openModal({
            'title': __('Přidat recept'), 
            'class': 'dialog-big',
            'content': (
                <PanelAddRecipe onRecipeAdded={this.props.closeModal} isGastroDailyMenu={isGastroDailyMenu} isDrinkRegime={this.state.isDrinkRegime} />
            )
        });
    }

    openPanelAddFoodGroup(e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;
        this.props.closeModal();
        this.props.openModal({
            'title': 'Přidat talíř', 
            'content': (
                <PanelAddFoodGroup onFoodGroupAdded={this.props.closeModal} />
            )
        });
    }

    openPanelRecipeSettings(e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;
        this.props.closeModal();
        this.props.openModal({
            'title': __('Postup přípravy receptu'), 
            'class': 'dialog-flexible',
            'closeButton': __('Uložit a zavřít'),
            'content': (
                <PanelRecipeSettings />
            )
        });
    }

    openPanelSave(e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;

        let currentRecipeId = 0;
        if(this.props.match.params.id !== 'undefined') {
            currentRecipeId = this.props.match.params.id;
        }

        this.props.closeModal();
        this.props.openModal({
            'title': __('Uložit recept'), 
            'class': 'dialog-big',
            'content': (
                <PanelSave currentRecipeId={currentRecipeId} onSaved={this.props.closeModal} />
            )
        });
    }

    saveAndBackToPlanner() {
        const { data } = this.props;

        this.setState({
            saving: true
        });

        if(data.titles[data.activeMealHash] == '') {
            let firstFoodGroupTitle = '';
            for(let g in data.foodGroups[data.activeMealHash]) {
                if(firstFoodGroupTitle == '') {
                    firstFoodGroupTitle = data.foodGroups[data.activeMealHash][g].title;
                }
            }
            data.titles[data.activeMealHash] = firstFoodGroupTitle;
        }

        let dateParsed = this.props.data.plannerDate;

        if(this.props.data.appMode === 'LECTURE') {
            dateParsed = new Date('2000-01-01');
        }

        let dateUTC = new Date(Date.UTC(
            dateParsed.getFullYear(),
            dateParsed.getMonth(),
            dateParsed.getDate(),
            dateParsed.getHours(),
            dateParsed.getMinutes(),
            dateParsed.getSeconds()
            ));

        let dayTimestamp = Math.floor(dateUTC.getTime() / 1000);

        let saveData = {};
        saveData.meals = JSON.stringify(data.meals);
        saveData.groups = JSON.stringify(data.foodGroups);
        saveData.foods = JSON.stringify(data.foods);
        saveData.modifications = JSON.stringify(data.modifications);
        saveData.titles = JSON.stringify(data.titles);
        saveData.desciptions = JSON.stringify(data.desciptions);
        saveData.activities = JSON.stringify(data.activities);
        saveData.trainings = JSON.stringify(data.trainings);
        saveData.dayTimestamp = JSON.stringify(dayTimestamp);
        saveData.energyOutputSettings = JSON.stringify(data.energyOutputSettings);
        saveData = JSON.stringify(saveData);

        api('save/dayplan', {post:true, data: saveData}).then(result => {
            result = result.data;
            this.setState({
                saving: false
            }, () => {
                if(this.props.data.appMode == 'LECTURE') {
                    this.props.history.push('/plan');
                } else {
                    this.props.history.push('/plan/' + format(data.plannerDate, 'yyyy-MM-dd'));
                }
            });
        });
    }

    
    openPanelSaveProduct(e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;
        this.props.closeModal();
        this.props.openModal({
            'title': 'Uložit produkt', 
            'content': (
                <PanelSaveProduct onSaved={this.props.closeModal} />
            )
        });
    }

    openPanelProductDescription(e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;
        this.props.closeModal();
        this.props.openModal({
            'title': 'Popis produktu', 
            'closeButton': __('Uložit a zavřít'),
            'content': (
                <PanelProductDescription />
            )
        });
    }
    onChange(e) {
        if(e.target.name == 'recipe_name') {
            this.props.setRecipeTitle({
                mealHash: this.props.data.activeMealHash, 
                title: e.target.value
            });
        }
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const mh = data.activeMealHash;

        let recipeName = '';
        if(typeof data.titles[mh] !== 'undefined') {
            recipeName = data.titles[mh];
        }

        let saveButtonStyle = {};
        if(this.props.data.unsavedRecipe === true) {
            saveButtonStyle = {background:'#f00',color:'#fff'};
        }

        let headingContent = false;

//                 <a onClick={this.openPanelAddFood}><i className="fa fa-plus"></i> Potravina</a>

        let baseButtons = (
            <>
                <a onClick={this.openPanelAddRecipe}><i className="fa fa-plus"></i> Recept</a>
                <a onClick={this.openPanelAddFoodGroup}><i className="fa fa-plus"></i> Talíř</a>
                <a onClick={this.openPanelRecipeSettings}><i className="fa fa-book"></i> Postup přípravy</a>
            </>
        );

        if(this.props.data.appMode === 'LECTURE') {
            baseButtons = (
                <>
                    <a onClick={this.openPanelAddRecipe}><i className="fa fa-plus"></i> Recept</a>
                    <a onClick={this.openPanelAddFoodGroup}><i className="fa fa-plus"></i> Talíř</a>
                    <a onClick={this.openPanelRecipeSettings}><i className="fa fa-book"></i> Postup přípravy</a>
                </>
            );
            }


        // Content based on this.props.action
        let dayInfo = false;
        let dynamicButtons = false;
        switch(this.props.action) {
            case undefined:
            case 'recipe-new':
                dynamicButtons = (
                    <a style={saveButtonStyle} onClick={this.openPanelSave}><i className="fa fa-save"></i> Uložit</a>
                );
                break;
            case 'recipe-edit':
                dynamicButtons = (
                    <a onClick={this.openPanelSave}><i className="fa fa-save"></i> Uložit</a>
                );
                break;
            case 'meal-edit':
                if(typeof data.meals[mh] == 'undefined') {
                    const today = format(new Date(), 'yyyy-MM-dd');
                    this.props.history.push('/plan/' + today);
                } else {
                    let sh = data.meals[mh].startHour;
                    let sm = data.meals[mh].startMinute;
                    if (sh < 10) sh = '0' + sh;
                    if (sm < 10) sm = '0' + sm;
                    dayInfo = (
                        <div className="day-info">
                            <h2>{data.globals.mealTypes[data.meals[mh].mealId].title}</h2>
                            <p className="date">{format(data.plannerDate, 'dd.MM.yyyy')}, {sh}:{sm}    </p>
                        </div>
                    );
                    headingContent = (
                        <>
                        {data.globals.mealTypes[data.meals[mh].mealId].title}
                        <small>{format(data.plannerDate, 'dd.MM.yyyy')}, {sh}:{sm}</small>
                        </>
                    );
                    dynamicButtons = (
                        <>
                        <a onClick={this.openPanelSave}><i className="fa fa-save"></i> Uložit do mých receptů</a>
                        <a onClick={this.saveAndBackToPlanner}><i className="fa fa-save"></i> Uložit a zpět do Plánovače</a>
                        </>
                    );
                }
                break;
            case 'cookbook-edit':
                dynamicButtons = (
                    <a onClick={this.openPanelSave}><i className="fa fa-save"></i> Uložit do mých receptů</a>
                );
                break;
            case 'lecture-task':
                if(typeof data.meals[mh] == 'undefined') {
                    this.props.history.push('/plan');
                } else {
                    let sh = data.meals[mh].startHour;
                    let sm = data.meals[mh].startMinute;
                    if (sh < 10) sh = '0' + sh;
                    if (sm < 10) sm = '0' + sm;
                    dayInfo = (
                        <div className="day-info">
                            <h2>{data.globals.mealTypes[data.meals[mh].mealId].title}</h2>
                            <p className="date">Abcd {sh}:{sm}    </p>
                        </div>
                    );
                    headingContent = (
                        <>
                        {data.globals.mealTypes[data.meals[mh].mealId].title}
                        <small>{sh}:{sm}</small>
                        </>
                    );
                    dynamicButtons = (
                        <>
                        <a onClick={this.openPanelSave}><i className="fa fa-save"></i> Uložit do mých receptů</a>
                        <a style={{background:'#e40'}} onClick={this.saveAndBackToPlanner}><i className="fa fa-save"></i> Uložit a zpět na přehled</a>
                        </>
                    );
                }
                break;
            case 'recipe-editor':
                baseButtons = false;
                dynamicButtons = false;
                break;
        }


        let buttons = (
            <>
                {baseButtons}
                {dynamicButtons}
            </>
        );


       
        // GASTRO: Editing daily menu? Different buttons

        if(state.action == 'meal-edit' && typeof data.meals[mh] == 'object') {
            if(this.props.data.globals.mealTypes[data.meals[mh].mealId].gastro_only === true) {
                buttons = (
                    <React.Fragment>
                        <a onClick={this.openPanelAddRecipe}><i className="fa fa-plus"></i> {__('Přidat recept')}</a>
                        <a onClick={this.saveAndBackToPlanner}><i className="fa fa-save"></i> Uložit a zpět do Plánovače</a>
                    </React.Fragment>
                );
            }
        }

        // Creating a new product? Different buttons.

        if(state.action == 'product-create' || state.action == 'product-edit') {
            buttons = (
                <React.Fragment>
                <a onClick={this.openPanelAddFood}><i className="fa fa-plus"></i> Přidat potravinu</a>
                <a onClick={this.openPanelProductDescription}><i className="fa fa-book"></i> Popis produktu</a>
                <a onClick={this.openPanelSaveProduct}><i className="fa fa-save"></i> Uložit produkt</a>
                </React.Fragment>
            );
        }

        buttons = (        
            <>
            <label class="page-header-nav-label" htmlFor="cb-page-header-nav"><i className="fa fa-angle-down"></i></label>
            <input type="checkbox" id="cb-page-header-nav" />
            <div className="page-header-nav">
                {buttons}
            </div>
            </>
        );

        if(!baseButtons && !dynamicButtons) return false;        
        
        let content = (
            <div className="page-header" style={{padding:'.5em 0'}}>
                <div className="container">
                    <div className="page-header-row">
                        <h1>{headingContent}</h1>
                        <aside>
                            {buttons}
                        </aside>
                    </div>
                </div>
            </div>
        );

        return content;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuPanelHorizontal));
