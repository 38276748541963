import React, { Component } from 'react';

export default class ClientDataHelp {

    static date() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Zvolte datum, ke kterému se zapisované údaje vztahují.</p>
            </div>
        );
    }

    static weight() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Tělesná hmotnost uživatele, vyplňujte v kilogramech (kg).</p>
            </div>
        );
    }

    static height() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Tělesná výška uživatele, vyplňujte v centimetrech (cm).</p>
            </div>
        );
    }

    static diameter_sides() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Obvod boků je hodnota důležitá pro výpočet WHR (tento index poukazuje na způsob, jakým je na těle rozložen tuk - pomocí něj je možné určit, k jakému typu postavy se řadíte a jaká to sebou nese zdravotní rizika z pohledu hrozící obezity).</p>
                <p>Obvod boků se měří v nejširším místě pánve, v oblasti pánevních kostí, hodnotu vyplňujte v centimetrech (cm).</p>
            </div>
        );
    }

    static diameter_waist() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Obvod pasu je hodnota důležitá pro výpočet WHR (tento index poukazuje na způsob, jakým je na těle rozložen tuk - pomocí něj je možné určit, k jakému typu postavy se řadíte a jaká to sebou nese zdravotní rizika z pohledu hrozící obezity).</p>
                <p>Obvod pasu se měří uprostřed vzdálenosti mezi dolním okrajem žeber a horním okrajem lopaty kosti kyčelní, tj. cca v úrovni pupku, případně cca 2 cm pod pupkem.</p>
            </div>
        );
    }

    static bmi() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>BMI (Body Mass Index = index tělesné hmotnosti) je indexem, který hodnotí hmotnost člověka ve srovnání s průměrným zbytkem populace. Pro jeho výpočet je třeba znát výšku a hmotnost. Tento index slouží jako základní zhodnocení, v jakém rozmezí se nachází tělesná hmotnost jedince a jaká to sebou nese zdravotní rizika.</p>
                <p>Tento údaj nezapisujte, aplikace jej spočítá sama</p>
            </div>
        );
    }

    static whr() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>WHR index (Waist to Hip Ratio) pomáhá určit, k jakému typu postavy se řadíte a jaká to sebou nese zdravotní rizika z pohledu hrozící obezity.</p>
                <p>Tento údaj nezapisujte, aplikace jej spočítá sama na základě vámi vložených hodnot obvodu pasu a boků.</p>
            </div>
        );
    }

    static fat_percent() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>procento tělesného tuku je množství tělesného tuku v poměru k tělesné hmotnosti. Tento údaj získáte např. bioimpedanční analýzou tělesné kompozice. Vyplňujte v procentech (%).</p>
            </div>
        );
    }

    static fat_amount() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Hmotnost tělesného tuku je množství tělesného tuku v poměru k tělesné hmotnosti. Tento údaj získáte např. bioimpedanční analýzou tělesné kompozice. Vyplňujte v kilogramech (kg).</p>
            </div>
        );
    }

    static visceral_fat() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Viscerální (útrobní) tuk se nachází v břišní dutině, obklopuje vnitřní orgány.</p>
                <p>Vyplňujte v jednotkách, které vám byly poskytnuty analýzou tělesné kompozice, nejčastěji se používají cm2.</p>
            </div>
        );
    }

    static water_content() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Vyjadřuje podíl všech tekutin v těle v procentech tělesné váhy.</p>
            </div>
        );
    }

    static water_content_kg() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Vyjadřuje podíl všech tekutin v těle v kilogramech / litrech.</p>
            </div>
        );
    }

    static water_extracellular() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Vyjadřuje podíl mimobuněčné (extracelulární) tekutiny v těle v procentech tělesné váhy.</p>
            </div>
        );
    }

    static water_intracellular() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Vyjadřuje podíl vnitrobuněčné (intracelulární) tekutiny v těle v procentech tělesné váhy.</p>
            </div>
        );
    }

    static bone_mineralization() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Označuje hmotnost kostních minerálů. Tento údaj získáte např. bioimpedanční analýzou tělesné kompozice. Vyplňujte v kilogramech (kg).</p>
            </div>
        );
    }

    static metabolic_age() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Metabolický věk porovnává váš bazální metabolismus s průměrným bazálním metabolismem u stejné věkové skupiny.</p>
                <p>Tento údaj získáte specifickým výpočtem, případně bioimpedanční analýzou tělesné kompozice. Vyplňujte v letech.</p>
            </div>
        );
    }

    static bmr_kcal() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>BMR (Basal Metabolic Rate = Bazální metabolismus) určuje energetickou spotřebu člověka nutnou pro zachování základních životních funkcí.</p>
                <p>Tento údaj získáte specifickým výpočtem (aplikace jej počítá automaticky - viz údaj vedle pole), případně bioimpedanční analýzou tělesné kompozice. Vyplňujte v kilokaloriích (kcal).</p>
            </div>
        );
    }

    static bmr_kj() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>BMR (Basal Metabolic Rate = Bazální metabolismus) určuje energetickou spotřebu člověka nutnou pro zachování základních životních funkcí.</p>
                <p>Tento údaj získáte specifickým výpočtem (aplikace jej počítá automaticky - viz údaj vedle pole), případně bioimpedanční analýzou tělesné kompozice. Vyplňujte v kilojoulech (kJ).</p>
            </div>
        );
    }

    static muscle_amount() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Ukazuje hmotnost příčně pruhovaných i hladkých svalů, včetně vody v nich obsažené.</p>
                <p>Tento údaj získáte bioimpedanční analýzou tělesné kompozice. Vyplňujte v kilogramech (kg).</p>
            </div>
        );
    }

    static physical_condition() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Údaj porovnávající tělesný tuk a svalovou hmotu. Hodnotu získáte bioimpedanční analýzou tělesné kompozice.</p>
            </div>
        );
    }

    static active_body_weight() {
        return (
            <div style={{float:'right',fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                <p>Tělesná hmotnost uživatele bez tukové hmoty, vyplňujte v kilogramech (kg).</p>
            </div>
        );
    }

}
