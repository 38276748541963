import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import api from 'api';
import Loading from 'components/Loading';

import {
    showMessage,
 } from 'actions/index';

 const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        showMessage: data => dispatch(showMessage(data)),
    };
};

class PanelSaveProduct extends Component { 
    constructor(props) {
        super(props);
        this.productNameInput = React.createRef();

        const { data } = this.props;
        let firstFGKey = Object.keys(data.foodGroups[data.activeMealHash])[0];
        const firstFoodGroupTitle = data.foodGroups[data.activeMealHash][firstFGKey].title;


        this.state = {
            targetCategoryId: 0,
            productName: firstFoodGroupTitle,
            savedProducts: {},
            overwriteProductId: -1,
            categories: {},
            loading: true,
            saving: false,
        };
        this.onChange = this.onChange.bind(this);
        this.doSaveProduct = this.doSaveProduct.bind(this);
        this.doOverwriteProduct = this.doOverwriteProduct.bind(this);
    }

    componentDidMount() {
        api('product/list?categories-tree').then(result => {
            this.setState({
                savedProducts: result.data.products,
                categories: result.data.categories,
                loading: false,
            });
        });
    }

    onChange(e) {
        if(e.target.name == 'product_name') {
            this.setState({
                productName: e.target.value
            });
        }
    }

    doSaveProduct(e) {
        const { state } = this;
        const { data } = this.props;
        
        let productName = state.productName;

        let id = 0;
        if(this.state.overwriteProductId < 0) {
            // NOT overwriting a recipe
            if(productName.trim() == '') {
                this.productNameInput.focus();
                this.props.showMessage({
                    show: true,
                    type: 'error',
                    message: 'Zadejte název produktu.'
                });
                return false;
            }
            id = e.target.attributes.getNamedItem('data-id').value * 1;
        } else {
            id = this.state.overwriteProductId;
        }

        let saveData = {};
        saveData.activeMealHash = JSON.stringify(data.activeMealHash);
        saveData.foods = JSON.stringify(data.foods);
        saveData.description = data.productDescription;
        saveData.productName = productName;
        saveData.category = state.targetCategoryId;
        saveData.id = id;
        saveData = JSON.stringify(saveData);

        api('save/product', { post: true, data: saveData }).then(result => {
            result = result.data;
            if(result.ok == 1) {
                this.props.showMessage({
                    show: true,
                    type: 'success',
                    message: 'Produkt byl uložen.'
                });        
                this.props.onSaved();       
            }
        });
    }

    doOverwriteProduct(e, id) {
        this.setState({
            overwriteProductId: id
        }, () => {
            this.doSaveProduct(e);
        });
    }

    render() {
        const { state } = this;

        let categories = [];
        categories.push(<option value={-1} key={-1}>Zařadit do kategorie...</option>);
        for (let i in state.categories) {
            categories.push(<option value={state.categories[i].id} key={i}>{state.categories[i].title}</option>);
        }

        let productName = this.state.productName;
        let savedProductsList = [];

        let content = <Loading />;
        if(!this.state.loading) {
            for(let p in this.state.savedProducts) {
                savedProductsList.push(
                    <div className="recipe-overwrite-item" key={p}>
                        <span>{this.state.savedProducts[p].title}</span>
                        <button onClick={(e) => this.doOverwriteProduct(e, this.state.savedProducts[p].id)}><i className="fa fa-save"></i></button>
                    </div>
                )
            }

            content = (
                <React.Fragment>
                <div className="dialog-content">
                    <div className="zof-input-row">
                        <span><i className="fa fa-pencil-alt"></i></span>
                        <input ref={(input) => { this.productNameInput = input; }} onChange={this.onChange} value={productName} autoComplete="off" type="text" name="product_name" placeholder="Název ukládaného produktu..." />
                    </div>
                    <div className="zof-input-row">
                        <span><i className="fa fa-folder-open"></i></span>
                        <select name="category" defaultValue={this.state.targetCategoryId} onChange={(e) => this.setState({ targetCategoryId: e.target.value })}>
                            {categories}
                        </select>
                    </div>
                    <input className="full btn-primary" data-id="0" type="button" onClick={(e) => this.doSaveProduct(e)} value="Uložit produkt" />
                    <p style={{ marginTop: '1em' }} className="divider"><span>Nebo přepsat jiný produkt:</span></p>
                    <div style={{ height: '250px' }}>
                    <Scrollbars heightrelativetoparent="1">
                        <div style={{ flex: '1', overflow: 'hidden', padding: '0 14px 0 0' }}>
                            {savedProductsList}
                        </div>
                    </Scrollbars>
                    </div>
                </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelSaveProduct);
