import React, { Component } from 'react';
import { connect } from "react-redux";
import Util from 'utility';
import { Locale, __ } from 'locale';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class DialogNewMeal extends Component { 

    constructor(props) {
        super(props);


        // LectureTask settings - are meals editable? 
        // If not, permit only adding drink regime meal
        let mealsAreEditable = true;
        let taskData = {};
        if(typeof this.props.data.lectureTaskData === 'object') {
            taskData = this.props.data.lectureTaskData;
            if(typeof taskData !== 'undefined' && typeof taskData.lecture_data !== 'undefined') {
                let mealsAreEmpty = true;
                taskData.lecture_data.meal_types.map(mt => {
                    if(mt.type !== "0") mealsAreEmpty = false;
                });
                if(!mealsAreEmpty) {
//                if(Array.isArray(taskData.lecture_data.meal_types)) {
                    mealsAreEditable = false;
                }
            }
        }

        let initialType = 'meal';
        if(!mealsAreEditable) initialType = 'drink_regime';

        this.state = {
            type: initialType,
            mealId: 1,
            profiles: [],
            hour: this.props.selectedHour,
            minute: this.props.selectedMinute,
            activityTitle: '',
            activityLength: 0,
            mealsAreEditable: mealsAreEditable
        };
        this.switchType = this.switchType.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    switchType(e, type) {
        this.setState({
            type: type
        });
    }

    onChange(e) {
        const name = e.target.name;
        let value = e.target.value;
        let state = this.state;
        switch(name) {
            case 'profiles':
                let profiles = this.state.profiles;
                profiles = profiles.filter(id => id !== value);
                if(e.target.checked) profiles.push(value);
                state.profiles = profiles;
                break;
            default:
                state[name] = value;
                break;
        }
        this.setState(state);
    }

    render() {
        const { data } = this.props;
        const { globals } = data;

        let hours = [...Array(24).keys()];
        let minutes = [0,5,10,15,20,25,30,35,40,45,50,55];

        // Build a mealType array based on if user has purchased ZOF_GASTRO
        // if not, hide 'gastro_only' meal types

        const hasGastro = Util.userHasPurchased('ZOF_GASTRO');
        let filteredMealTypes = Object.keys(globals.mealTypes).filter(id => {
            let type = globals.mealTypes[id];
            if(!hasGastro && type.gastro_only === true) return false;
            return true;
        });

        let mealTypes = [];
        filteredMealTypes.map(id => {
            const type = globals.mealTypes[id];
            if(type.is_drink_regime === true) return;
            mealTypes.push(<option value={id} key={id}>{globals.mealTypes[id].title}</option>);
        });

        let options = (
            <tr>
                <th>Chod:</th>
                <td>
                    <select name="mealId" onChange={this.onChange}>
                        {mealTypes}
                    </select>
                </td>
            </tr>
        );

        let submitTitle = 'Přidat chod';
        if(Util.getLang() == 'sk') submitTitle = 'Pridať chod';
        
        if(this.state.type == 'drink_regime') {
            options = (
                <>
                </>
            );
            submitTitle = 'Přidat pitný režim';
            if(Util.getLang() == 'sk') submitTitle = 'Pridať pitný režim';
        }

        if(this.state.type == 'activity') {
            options = (
                <>
                <tr>
                    <th>Popis:</th>
                    <td>
                        <input type="text" name="activityTitle" onChange={this.onChange} value={this.state.activityTitle} />
                    </td>
                </tr>
                <tr>
                    <th>Délka:</th>
                    <td>
                        <input type="number" name="activityLength" onChange={this.onChange} value={this.state.activityLength} style={{display:'inline',width:'6em'}} /> minut
                    </td>
                </tr>
                </>
            );
            submitTitle = 'Přidat aktivitu';
            if(Util.getLang() == 'sk') submitTitle = 'Pridať aktivitu';
        }


        let tabs = (
            <>
            <a className={'' + this.state.type == 'meal' ? 'active' : ''} onClick={(e) => this.switchType(e, 'meal')}>Chod</a>
            <a className={'' + this.state.type == 'drink_regime' ? 'active' : ''} onClick={(e) => this.switchType(e, 'drink_regime')}>Pitný režim</a>
            <a className={'' + this.state.type == 'activity' ? 'active' : ''} onClick={(e) => this.switchType(e, 'activity')}>Aktivitu</a>
            </>
        );

        if(!this.state.mealsAreEditable) {
            tabs = (
                <>
                <a className={'' + this.state.type == 'drink_regime' ? 'active' : ''} onClick={(e) => this.switchType(e, 'drink_regime')}>Pitný režim</a>
                </>
            );
            options = (
                <>
                </>
            );
            submitTitle = 'Přidat pitný režim';
            if(Util.getLang() == 'sk') submitTitle = 'Pridať pitný režim';
        }
        


        let content = (
            <>
            <table className="generic-table">
                <tbody>
                <tr>
                        <th>{__('Přidat')}:</th>
                        <td className="add-meal-option">
                            {tabs}
                        </td>
                    </tr>
                    <tr>
                        <th>Čas:</th>
                        <td>
                            <select name="hour" defaultValue={this.state.hour} onChange={this.onChange}>
                                {hours.map(n => {
                                    return <option value={n} key={n}>{n}</option>;
                                })};
                            </select>
                            {'\u00A0'}:{'\u00A0'}
                            <select name="minute" defaultValue={this.state.minute} onChange={this.onChange}>
                                {minutes.map(n => {
                                    return <option value={n} key={n}>{n}</option>;
                                })};
                            </select>
                        </td>
                    </tr>
                    {options}
                    <tr>
                        <th></th>
                        <td><button className="btn-primary" onClick={() => this.props.onAddMeal(this.state)}>{submitTitle}</button></td>
                    </tr>
                </tbody>
            </table>
            </>
        );

        return content;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogNewMeal);
