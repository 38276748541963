import React, { Component } from 'react';
import { connect } from "react-redux";
import { format } from "date-fns";
import Util from 'utility';

var locales = {
    cs: require('date-fns/locale/cs'),
}

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class DialogGenerator extends Component { 
    constructor(props) {
        super(props);

        let profileId = Util.getFirstProfileId();
        let profile = this.props.data.globals.profiles[profileId];

        let initialProfileRegimes = [];
        profile.regimes.map(r => {
            initialProfileRegimes.push(Util.nutritionRegimeIdToTagId(r));
        });


        let allowedNutritionRegimeCombinations = {
            103: [103, 92],
            93: [93, 91],
            95: [95, 91],
            94: [94, 91],
            96: [96, 91],
            91: [91, 103, 93, 95, 94, 96, 92],
            92: [92, 103, 95, 96, 91]
        };

        let nutritionRegimeExcludesList = {
            103: [93, 95, 94, 96],
            93: [95, 94, 96, 103, 92],
            95: [93, 94, 96, 103],
            94: [93, 95, 96, 103, 92],
            96: [93, 95, 94, 103, 92],
            91: [],
            92: [93, 94]
        };

        this.state = {
            courses: -1,
            regime: -1,
            regimes: initialProfileRegimes,
            clientId: 0,
            mealSelection: [],   // ZOFapp Profi only
            mealSelectionRatio: {},     // ZOFapp Profi only - % radio of energy value of individual selected meals
            eatingRegimes: [103, 105, 91, 92, 93, 94, 95, 96, 98, 101, 102, 100],
            allowedNutritionRegimes: [103, 105, 91, 92, 93, 94, 95, 96, 98, 101, 102, 100],
            allowedNutritionCombinations: allowedNutritionRegimeCombinations,
            nutritionRegimeExcludes: nutritionRegimeExcludesList,
            vegeRegimesWarning: false,
            energeticRegime: 0,
            drinkRegime: "1",
            desserts: "1"
        };
        this.changeClient = this.changeClient.bind(this);
        this.restrictRegimes = this.restrictRegimes.bind(this);
        this.onChange = this.onChange.bind(this);
        this.doGenerate = this.doGenerate.bind(this);
    }

    componentDidMount() {
        let reg = this.restrictRegimes(this.state.regimes);
        this.setState({
            regimes: reg
        });
    }

    changeClient(e) {
        const clientId = e.target.value;
        let regimeTagIds = [];

        if(clientId == 0) {
            // My profile

            let profileId = Util.getFirstProfileId();
            let profile = this.props.data.globals.profiles[profileId];
    
            profile.regimes.map(r => {
                regimeTagIds.push(Util.nutritionRegimeIdToTagId(r));
            });

            regimeTagIds = this.restrictRegimes(regimeTagIds);

            this.setState({
                clientId: 0,
                regimes: regimeTagIds
            });

            
        } else {
            // Client profile
            
            let client = false;
            Object.keys(this.props.clients).map(c => {
                if(this.props.clients[c].id == clientId) {
                    client = this.props.clients[c];
                }
            });

            let regimes = [];
            if(client && client.profile && client.profile.regimes) {
                regimes = client.profile.regimes;
            }

            regimes.map(r => {
                regimeTagIds.push(Util.nutritionRegimeIdToTagId(r));
            });

            regimeTagIds = this.restrictRegimes(regimeTagIds);
    
            if(client) {
                this.setState({
                    clientId: clientId,
                    regimes: regimeTagIds
                });
            }  
        }
    }

    restrictRegimes(reg) {
        let state = this.state;

        // Disable non-valid regime combinations
        let allowed = this.state.eatingRegimes; //Object.keys(this.props.data.globals.eatingRegimes);
        reg.map(r => {
            r = r * 1;
            if(typeof this.state.nutritionRegimeExcludes[r] == 'undefined') return;
            this.state.nutritionRegimeExcludes[r].map(excl => {
                allowed = allowed.filter(a => a != excl);
            });
        });

        // Filter currently selected eating regimes based on what is excluded
        reg = reg.filter(r => allowed.includes(r*1));

        // If nothing allowed, reset everything
        if(allowed.length == 0) {
            reg = [];
            allowed = this.state.eatingRegimes; //Object.keys(this.props.data.globals.eatingRegimes);
        }

        state.allowedNutritionRegimes = allowed.map(r => r*1);

        // Warning, if more than one vegetarian regime is selected

        const vegetarianRegimes = [10, 20, 30];
        let numVegeRegimes = 0;
        reg.map(regime => {
            if(vegetarianRegimes.includes(regime)) numVegeRegimes++;
        });

        if(numVegeRegimes > 1) {
            this.setState({
                vegeRegimesWarning: true
            });
        } else {
            this.setState({
                vegeRegimesWarning: false
            });
        }

        return reg;
    }

    onChange(e) {
        let state = this.state;

        switch(e.target.name) {
            case 'meal_energy_ratio[]':
                const id = e.target.dataset.id;
                state.mealSelectionRatio[id] = e.target.value;
                break;
            case 'regimes[]':
                let reg = state.regimes;
                let regimeTagId =  e.target.value * 1;
                reg = reg.filter(i => i !== regimeTagId);
                if(e.target.checked) {
                    reg.push(regimeTagId);
                }

                state.regimes = this.restrictRegimes(reg);
                break;
            case 'meal_selection[]':
                let ms = state.mealSelection;
                let msr = state.mealSelectionRatio;
                ms = ms.filter(i => i !== e.target.value);
                if(e.target.checked) {
                    ms.push(e.target.value);
                } else {
                    // If meal is unchecked, remove energy ratio aswell
                    msr[e.target.value] = 0;
                }
                state.mealSelection = ms;
                state.mealSelectionRatio = msr;
                break;
            default:
                state[e.target.name] = e.target.value;
        }
        this.setState(state);
    }

    doGenerate(e) {
        e.preventDefault();

        let activeProfileId = this.props.data.profileId;
        if(activeProfileId == 0) {
            activeProfileId = Object.keys(this.props.data.globals.profiles)[0];
        }
        
        if(Util.userHasPurchased('ZOF_PROFI')) {
            // ZOFapp Profi meal selection
            if(this.state.mealSelection.length == 0 || this.state.regimes.length == 0) {
                alert("Vyberte prosím chod(y) a stravovací režim.");
                return;
            }
        } else {
            // ZOFapp Personal meal selection (just a number of meals)
            if(this.state.courses < 0 || this.state.regime < 0) {
                alert("Vyberte prosím počet chodů a stravovací režim.");
                return;
            }
        }



        const fd = new FormData(e.target);       
        fd.append('profile_id', activeProfileId);

        if(Util.userHasPurchased('ZOF_PROFI')) {
            fd.append('client_id', this.state.clientId);
        }

        this.props.onGenerate(fd);
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = data;

        let activeProfileId = data.profileId;
        if(activeProfileId == 0) {
            activeProfileId = Object.keys(globals.profiles)[0];
        }

        let nutritionRegimeOptions = [];
        Object.keys(globals.tagsToGroups[11]).map(t => {
            const tagId = globals.tagsToGroups[11][t];
            if([98, 99, 100, 102, 104, 105].includes(tagId)) return;
            nutritionRegimeOptions.push(
                <label><input type="radio" name="regime" value={tagId} onChange={this.onChange} /><span></span> {globals.tags[tagId]}</label>
            );
        })

        let clientSelection = false;
        let mealSelection = false;
        let content = false;

        let energeticRegime = false;


        let submitButton = <button type="submit"><i className="fa fa-check"></i> Vygenerovat jídelníček</button>;

        if(Util.userHasPurchased('ZOF_PROFI')) {

            // Nutrition regime options - option to select more when ZOFapp Profi

            let allowed = state.allowedNutritionRegimes.map(r => r * 1);

            nutritionRegimeOptions = [];
            Object.keys(globals.tagsToGroups[11]).map(t => {
                const tagId = globals.tagsToGroups[11][t];
                if([98, 99, 100, 101, 102, 104, 105].includes(tagId)) return;
                if (!allowed.includes(tagId)) {
                    nutritionRegimeOptions.push(
                        <label style={{opacity:'.3'}}><input disabled={true} type="checkbox" name="regimes[]" value={tagId} onChange={this.onChange} checked={state.regimes.includes(tagId)} /><span></span> {globals.tags[tagId]}</label>
                    );
                } else {
                    nutritionRegimeOptions.push(
                        <label><input type="checkbox" name="regimes[]" value={tagId} onChange={this.onChange} checked={state.regimes.includes(tagId)} /><span></span> {globals.tags[tagId]}</label>
                    );
                }
            })            

            // Client selection (who to generate for)
            
            let clientOptions = [];
            clientOptions.push(<option value={0}>- Můj profil -</option>);
            Object.keys(this.props.clients).map(c => {
                const client = this.props.clients[c];
                clientOptions.push(<option value={client.id}>{client.name}</option>);
            });

            clientSelection = (
                <>
                <p className="sub-hd">Vygenerovat pro:</p>
                <select name="client_id" onChange={this.changeClient}>
                    {clientOptions}
                </select>
                </>
            );
        
            // Selection of individual meal types to generate meals to

            let filteredMealTypes = Object.keys(globals.mealTypes).filter(id => {
                let type = globals.mealTypes[id];
                if(type.gastro_only === true) return false;
                return true;
            });

            let energyRatioOptions = [];
            for(let e=0; e<=100; e+=5) {
                energyRatioOptions.push(<option value={e}>{e} %</option>);
            }

            let mealTypes = [];
            filteredMealTypes.map(id => {
                const type = globals.mealTypes[id];
                if(type.is_drink_regime === true) return;

                let energyRatioSelect = false;
                if(this.state.mealSelection.includes(id)) {
                    energyRatioSelect = <select onChange={this.onChange} name="meal_energy_ratio[]" data-id={id}>{energyRatioOptions}</select>;
                }

                mealTypes.push(
                    <label>
                        <input type="checkbox" name="meal_selection[]" onChange={this.onChange} value={id} /><span></span> {type.title}
                        {energyRatioSelect}
                    </label>
                );
            });

            // Control if energy ratios sum is 100
            let erSum = 0, erSumWarning = false;
            Object.keys(this.state.mealSelectionRatio).map(e => {
                erSum += this.state.mealSelectionRatio[e] * 1;
            });

            let generatorSubmitAllowed = true;

            if(this.state.mealSelection.length > 0 && erSum != 100) {
                generatorSubmitAllowed = false;
            erSumWarning = <p style={{textAlign:'left',fontSize:'.9em',margin:'1em 0',color:'#f00'}}><i className="fa fa-exclamation-triangle"></i> Součet procent energetických poměrů je {erSum} (musí být 100).</p>;
                submitButton = false;
            }

            mealSelection = (
                <>
                <p className="sub-hd">Vyberte chody:</p>
                <div className="options-row options-row-2">
                    {mealTypes}
                </div>
                {erSumWarning}
                </>
            );

            energeticRegime = (
                <>
                <p className="sub-hd">Energetický režim:</p>
                <div className="options-row options-row-2">
                    <label>
                        <input type="checkbox" name="energeticRegime" onChange={this.onChange} value={0} checked={this.state.energeticRegime == 0} /><span></span> vyrovnaný
                    </label>
                    <label>
                        <input type="checkbox" name="energeticRegime" onChange={this.onChange} value={1} checked={this.state.energeticRegime == 1} /><span></span> hubnoucí
                    </label>
                </div>
                </>
            );

            let drinkRegime = (
                <>
                <p className="sub-hd">Pitný režim:</p>
                <div className="options-row options-row-2">
                    <label>
                        <input type="checkbox" name="drinkRegime" onChange={this.onChange} value={1} checked={this.state.drinkRegime == "1"} /><span></span> ano
                    </label>
                    <label>
                        <input type="checkbox" name="drinkRegime" onChange={this.onChange} value={0} checked={this.state.drinkRegime == "0"} /><span></span> ne
                    </label>
                </div>
                </>
            );

            let desserts = (
                <>
                <p className="sub-hd">Dezerty:</p>
                <div className="options-row options-row-2">
                    <label>
                        <input type="checkbox" name="desserts" onChange={this.onChange} value={1} checked={this.state.desserts == "1"} /><span></span> ano
                    </label>
                    <label>
                        <input type="checkbox" name="desserts" onChange={this.onChange} value={0} checked={this.state.desserts == "0"} /><span></span> ne
                    </label>
                </div>
                </>
            );

            energeticRegime = (
                <>
                {energeticRegime}
                {drinkRegime}
                {desserts}
                </>
            );


        } else {
            mealSelection = (
                <>
                <p class="sub-hd">Počet chodů:</p>
                <div className="options-row options-row-4">
                    <label><input type="radio" name="courses" value="3" onChange={this.onChange} /><span></span> 3</label>{'\u00A0'}{'\u00A0'}
                    <label><input type="radio" name="courses" value="4" onChange={this.onChange} /><span></span> 4</label>{'\u00A0'}{'\u00A0'}
                    <label><input type="radio" name="courses" value="5" onChange={this.onChange} /><span></span> 5</label>{'\u00A0'}{'\u00A0'}
                    <label><input type="radio" name="courses" value="6" onChange={this.onChange} /><span></span> 6</label>
                </div>
                </>
            );
        }

        content = (
                <React.Fragment>
                    <div className="plan-generator">
                        <form className="generator-form" id="generator-form" onSubmit={this.doGenerate}>
                            {clientSelection}
                            {mealSelection}
                            <p class="sub-hd">Stravovací režim:</p>
                            <div className="options-row">
                                {nutritionRegimeOptions}
                            </div>
                            {energeticRegime}
                            <div className="submit">
                                <input type="hidden" name="date" value={format(this.props.data.plannerDate, 'yyyy-MM-dd')} />
                                {submitButton}
                            </div>
                        </form>
                    </div>
                </React.Fragment>
            );

        return content;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogGenerator);
