import React, { Component } from 'react';
import { connect } from 'react-redux';
import Util from 'utility';
import { __ } from 'locale';

import { 
    setFoodGroupDescription,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setFoodGroupDescription: data => dispatch(setFoodGroupDescription(data)),
    };
};

class LectureTaskMealDescription extends Component { 
    constructor(props) {
        super(props);

        const mealHash = props.mealHash;
        const foodGroupId = props.foodGroupId;

        let firstFoodGroupId = false;
        if(typeof foodGroupId !== 'undefined') firstFoodGroupId = foodGroupId;

        let firstFoodGroupDescription = '';
        for(let g in props.data.foodGroups[mealHash]) {
            if(!firstFoodGroupId) {
                firstFoodGroupId = g;
                firstFoodGroupDescription = props.data.foodGroups[mealHash][g].description;
            }
        }

        this.state = {
            mealHash: mealHash,
            selectedFoodGroupId: firstFoodGroupId,
        };    
        this.selectFoodGroup = this.selectFoodGroup.bind(this);
        this.updateFoodGroupDescription = this.updateFoodGroupDescription.bind(this);
    }

    selectFoodGroup(e) {
        const fgId = e.target.value;
        this.setState({
            selectedFoodGroupId: fgId
        });
    }

    updateFoodGroupDescription(e) {
        this.props.setFoodGroupDescription({
            mealHash: this.state.mealHash,
            id: this.state.selectedFoodGroupId,
            description: e.target.value
        });
        Util.saveCurrentRecipeLocal();
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = this.props.data;

        let foodGroupsOptions = [];
        for(let g in data.foodGroups[state.mealHash]) {
            const group = data.foodGroups[state.mealHash][g];
            if(group.title == '') group.title = '- beze jména -';
            foodGroupsOptions.push(
                <option value={g} key={g}>{globals.foodGroupTypes[group.type]}: {group.title}</option>
            );
        }

        let currentFoodGroupDescription = '';
        if(typeof data.foodGroups[state.mealHash] !== 'undefined') {
            if(typeof data.foodGroups[state.mealHash][state.selectedFoodGroupId] !== 'undefined') {
                currentFoodGroupDescription = data.foodGroups[state.mealHash][state.selectedFoodGroupId].description;
            }
        }

        return (
            <div className="row">
                <div className="col">
                <div className="input-row">
                        <span style={{flexBasis:'10em'}}>{__('Vyberte chod')}:</span>
                        <div className="inputs">
                            <select onChange={this.selectFoodGroup} defaultValue={this.state.selectedFoodGroupId}>{foodGroupsOptions}</select>
                        </div>
                    </div>
                    <div className="input-row">
                        <span style={{flexBasis:'10em'}}>{__('Postup přípravy')}:</span>
                        <div className="inputs">
                            <textarea onChange={this.updateFoodGroupDescription} style={{ height: '9em' }} value={currentFoodGroupDescription}></textarea>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LectureTaskMealDescription);
