import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { withRouter } from 'react-router-dom';
import shortid from 'shortid';
import api from 'api';
import Util from 'utility';
import UnitConvert from 'UnitConverter';
import Loading from 'components/Loading/index';
import { __ } from 'locale';

import RecipeSearch from 'components/RecipeSearch';

import {
    setData,
    addFoodGroup,
    setFoodGroupDescription,
    addFood,
    addFoodModification,
    deleteEmptyFoodGroups,
    setRecipeTitle,
 } from 'actions/index';

 const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setData: data => dispatch(setData(data)),
        addFoodGroup: data => dispatch(addFoodGroup(data)),
        setFoodGroupDescription: data => dispatch(setFoodGroupDescription(data)),
        addFood: data => dispatch(addFood(data)),
        addFoodModification: data => dispatch(addFoodModification(data)),
        deleteEmptyFoodGroups: data => dispatch(deleteEmptyFoodGroups(data)),
        setRecipeTitle: data => dispatch(setRecipeTitle(data)),
    };
};

class PanelAddRecipe extends Component { 
    constructor(props) {
        super(props);

        let initialFilter = {
            q: '',
            groupId: 0,
            categoryId: 0,
        };

        this.state = {
            recipes: [],
            searchKeyword: initialFilter.q,
            searchGroupId: initialFilter.groupId,
            searchCategoryId: initialFilter.categoryId,
            sortBy: '',
            lastAction: '',

            recipeLoading: false,
            currentRecipe: false,

            selectedModalRecipe: {},            
            selectedRecipeId: 0,
            selectedRecipe: {},
            modalX: 0,
            modalY: 0,
            showModal: false,
        };

        this.showRecipeDetail = this.showRecipeDetail.bind(this);
        this.closeRecipeDetail = this.closeRecipeDetail.bind(this);

        this.addToRecipe = this.addToRecipe.bind(this);
    }

    showRecipeDetail(id, e) {
        this.setState({
            recipeLoading: true
        }, () => {
            api('recipes/detail/' + id).then(result => {
                setTimeout(() => {
                    this.setState({
                        recipeLoading: false,
                        currentRecipe: result.data
                    });
                }, 100);
            });
        });
    }

    closeRecipeDetail() {
        this.setState({
            recipeLoading: false,
            currentRecipe: false
        });
    }

    addToRecipe(recipeId) {
        const { data } = this.props;

        api('recipes/add/' + recipeId).then(result => {
            result = result.data;
            this.props.deleteEmptyFoodGroups();

            Object.keys(result.groups).map(g => {
                const group = result.groups[g];
                this.props.addFoodGroup({
                    id: group.group_id,
                    title: group.title,
                    type: group.type,
                    opened: true
                });    

                this.props.setFoodGroupDescription({
                    id: group.group_id,
                    description: group.description
                });                
            }); 

            Object.keys(result.foods).map(f => {
                const food = result.foods[f];
                const newFoodId = shortid();

                this.props.addFood({
                    id: newFoodId,
                    food_id: food.food_id,
                    group_id: food.group_id,
                    is_drink: food.is_drink,
                    weight: food.amount,
                    image: "https://www.zofapp.cz/data/zof/item/" + food.food_id + ".svg",
                    title: this.props.data.globals.itemData[food.food_id]['title'],
                });

                Object.keys(food.modifications).map(m => {
                    const modificationId = food.modifications[m];
                    this.props.addFoodModification({
                        foodId: newFoodId,
                        modificationId: modificationId
                    });
                });
            });

            this.setState({
                selectedRecipeId: 0,
                currentRecipe: false,
                recipeLoading: false,
                showModal: false
            });

            const currentRecipeTitle = this.props.data.titles[this.props.data.activeMealHash];
            if(currentRecipeTitle == '') {
                this.props.setRecipeTitle({
                    mealHash: this.props.data.activeMealHash,
                    title: result.title
                });
            }

            this.props.setData({
                lastOpenedCookBookRecipe: recipeId,
                lastAddedRecipeTitle: result.title
            });   
            Util.doLoadEvaluation();
            this.props.onRecipeAdded();
        });
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = this.props.data;

        let selectedRecipeCategory = state.searchCategoryId;
        let selectRecipesCategoryElement = false;

        let selectedRecipeGroup = state.searchGroupId;
        let selectRecipesGroupElement = false;

        let categoryOptions = [<option value="-1" key="-1">Vyberte kategorii receptů...</option>];
        for(let c in data.globals.recipeCategories) {
            let cat = data.globals.recipeCategories[c];
            categoryOptions.push(
                <option value={cat.id} key={cat.id}>{cat.title}</option>
            );
        }

        selectRecipesCategoryElement = (
            <select name="select-category" className="zof-category-select-element" value={selectedRecipeCategory} onChange={this.handleCategorySelect}>
                {categoryOptions}
            </select>
        );

        selectRecipesGroupElement = (
            <select name="select-group" className="zof-category-select-element" value={selectedRecipeGroup} onChange={this.handleGroupSelect}>
                <option value="-1" key="-1">Recepty vhodné pro...</option>
                {data.globals.recipeGroups.map(o => {
                    return <option value={o.id} key={o.id}>{o.title}</option>;
                })};
            </select>
        );

        let recipeList = [];
        let recipes = this.state.recipes;

        if(Object.prototype.toString.call(recipes) == '[object Array]' && 
            typeof recipes.error == 'undefined'
        ) {
            recipes.map((recipe) => {
                let img_src = 'https://www.kurzyatac.cz/data/szv/recept/th/' + recipe.id + '.jpg';
                recipeList.push(
                    <div className="zof-search-result" key={recipe.id}>
                        <div className="item" onClick={() => this.showRecipeDetail(recipe.id)}>
                            <div className="item-wrap">
                                <div className="image">
                                    <img src={img_src} alt="" />
                                </div>
                                <div className="details">
                                    <h4>{recipe.title}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }

        let content;

        if(this.state.recipeLoading) {
            content = <Loading />
        } else {
            content = <RecipeSearch onClick={this.showRecipeDetail} isEmbedded={true} isDrinkRegime={this.props.isDrinkRegime} />;
        }

        const recipe = state.currentRecipe;
        if(recipe) {
    
            let ingredients = [];
            for(let i in recipe.ingredients) {
                let ingr = recipe.ingredients[i];
    
                // Check if this ingredient if undesirable for any profile
    
                let undesirableFoodForProfiles = [];
                for(let profileId in this.props.data.globals.undesirableFoods) {
                    profileId = profileId * 1;
                    let arr = this.props.data.globals.undesirableFoods[profileId];
                    if(arr.includes(ingr.food_id)) {
                        undesirableFoodForProfiles.push(profileId);
                    }
                }

                // Is this food undesirable for lecture task profile?
                if(typeof this.props.data.appModeData !== 'undefined') {
                    undesirableFoodForProfiles = [];
                    if(typeof this.props.data.appModeData.undesirableFoods !== 'undefined') {
                        if(this.props.data.appModeData.undesirableFoods.includes(ingr.food_id)) {
                            if(typeof this.props.data.appModeData.lectureId === 'number') {
                                undesirableFoodForProfiles.push(this.props.data.appModeData.profileId);
                            }
                        }
                    }
                }


        
                let itemRowClass = `ingredient`;
                if(undesirableFoodForProfiles.length > 0) {
                    itemRowClass = `ingredient ingredient-undesirable`;
                }
        
                let undesirableFoodWarning = [];
                undesirableFoodForProfiles.map(profileId => {
                    let profile = this.props.data.globals.profiles[profileId];
                    let profileColor = profile.profile_color;
                    if (profileColor == '' || !profileColor) profileColor = '999999';
                    let profileStyle = {
                        backgroundColor: '#' + profileColor,
                        color: '#fff'
                    };
                    undesirableFoodWarning.push(
                        <span style={profileStyle} className="undesirable-food-warning-profile">{this.props.data.globals.profiles[profileId].name}</span>
                    );
                });
    
                //
                let ingredientAmount = false;
                if(Util.userHasPurchased('ZOF_COOKBOOK')) {
                    ingredientAmount = <p>{parseFloat(ingr['amount'] || 0)} {ingr['unit']} ({ingr['normalized_amount']})</p>;

                    let foodItem = {
                        id: ingr['item_id'],
                        selectedWeight: ingr['amount'],
                        weight: this.props.data.globals.itemData[ingr['item_id']].weight,
                        norm_unit_amount: this.props.data.globals.itemData[ingr['item_id']].norm_unit_amount * 1.0,
                        norm_unit_unit: this.props.data.globals.itemData[ingr['item_id']].norm_unit_unit,
                        norm_unit2_amount: this.props.data.globals.itemData[ingr['item_id']].norm_unit2_amount * 1.0,
                        norm_unit2_unit: this.props.data.globals.itemData[ingr['item_id']].norm_unit2_unit
                    };
            
                    let uc = new UnitConvert;
                    let normalizedUnitInfo = uc.convert(foodItem);
                    let normalizedUnitInfoRaw = normalizedUnitInfo;
                    if(normalizedUnitInfo !== null && normalizedUnitInfo != '' && normalizedUnitInfo != ' null') {
                        normalizedUnitInfo = <span>{normalizedUnitInfo}</span>;
                    } else {
                        normalizedUnitInfo = false;
                    }

                    ingredientAmount = normalizedUnitInfo;
                }

                ingredients.push(
                    <div className="food-row">
                        <div className="icon">
                            <img src={`https://www.zofapp.cz/data/hra/icon/png/${ingr['icon_url']}`} alt="" />
                        </div>
                        <div className="title">
                            <h2>{ingr['title']}</h2>
                            {ingredientAmount}
                            {undesirableFoodWarning}
                        </div>
                    </div>
                );
            }

            let recipeLocked = false;
            if(recipe.user_id !== this.props.data.user.id) {
                if(!Util.userHasPurchased('ZOF_COOKBOOK')) {
                    recipeLocked = true;
                }
            }

            let preparationInfo = false;
            let addToRecipeButton = false;
            if(!recipeLocked) {
                let description = [];
                let numberOfGroups = Object.keys(recipe.groups).length;
                Object.keys(recipe.groups).map(g => {
                    const group = recipe.groups[g];
                    if(numberOfGroups > 1) {
                        description.push(group.title + "\n\n" + group.description + "\n\n");
                    } else {
                        description.push(group.description);
                    }
                })
                description = description.join("\n");        

                preparationInfo = (
                    <div className="recipe-detail-description">
                        <div>{Util.parseRecipeDescription(description)}</div>
                    </div>
                );

                addToRecipeButton = <button onClick={() => this.addToRecipe(recipe.id)}><i className="fa fa-plus"></i> {__('Přidat do receptu')}</button>;
            } else {
                preparationInfo = (
                    <>
                        <h3>Příprava receptu</h3>
                        <div>Příprava receptu se zobrazí po zakoupení ZOF kuchařky.</div>
                    </>
                )
            }

            let cookbookNotBoughtAlert = false;
            if(recipeLocked) {
                cookbookNotBoughtAlert = (
                    <div className="alert alert-info">
                        <i className="fa fa-info-circle"></i>
                        <p>Nemáte přístup k receptům ZOF kuchařky. Pro aktivaci této funkce je nutné <a style={{ fontWeight: 'bold', textDecoration: 'underline' }} onClick={() => this.props.history.push('/cookbook/buy')}>kuchařku objednat</a>.</p>
                    </div>
                );
            }

            content = (
                <React.Fragment>
                <div className="zof-food-detail">
                    <h2>
                        <button onClick={this.closeRecipeDetail}><i className="fa fa-angle-left"></i></button>
                        {recipe.lang.title}
                    </h2>
                    <div className="zof-food-detail-add">
                        {addToRecipeButton}
                    </div>
                </div>
                <div className="recipe-detail">
                    {cookbookNotBoughtAlert}
                    <div className="recipe-detail-contents">
                        <div style={{flex:1}}>
                            <img style={{width:'100%',height:'auto',display:'blok'}} src={`https://www.zofapp.cz/data/zof/recipe/${Math.floor(recipe.id / 1000)}/${recipe.id}.jpg`} alt="" />
                            <h2>{__('Ingredience')} ({parseFloat(recipe.nutrition_data.weight)} g)</h2>
                            <div className="recipe-foods">
                            {ingredients}
                            </div>
                        </div>
                        <div style={{flexBasis:'270px'}}>
                            {preparationInfo}
                        </div>
                    </div>
                </div>                
                </React.Fragment>
            );
        }   


        return (
            <div className="zof-items-selection">
            {content}
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PanelAddRecipe));
