import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import Loading from 'components/Loading/index';
import Saving from 'components/Saving/index';
import api from 'api';
import Util from 'utility';

import { loadGlobals } from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        loadGlobals: data => dispatch(loadGlobals(data)),
    };
};

class MyFoodDelete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            loading: true,
            food: []
        };
        this.doDelete = this.doDelete.bind(this);
    }

    componentDidMount() {
        Util.setPageTitle('Smazat moji potravinu');
        let foodId = this.props.match.params.id;
        api('food/get/' + foodId).then((res) =>{
            this.setState({
                id: foodId,
                food: res.data,
                loading: false
            });
        });        
    }

    doDelete() {
        api('myfoods/delete/' + this.state.id, { post: true }).then(result => {
            api('load/startup-data').then((result) => {
                this.props.loadGlobals(result.data);
                this.props.history.push('/food/me');
            });
        });
    }

    render() {
        const { state } = this;
        const { food } = state;

        if(state.loading) return false;

        const random = Math.random() * 10000000;

        let img_src = 'https://www.zofapp.cz/data/zof/item/' + food.id + '.svg?r=' + random;

        return ( 
            <React.Fragment>
            <div className="panel panel-padded mt-1 mb-1">
                <div style={{textAlign:'center'}}>
                    <p className="food-icon">
                        <img src={img_src} alt="" />
                    </p>
                    <h2>{food.title}</h2>
                    <p>Opravdu chcete smazat tuto potravinu?<br /><br /><span style={{ color: 'red' }}><b>Upozornění:</b><br />potravina bude vymazána ze všech receptů, jejíchž je součástí!</span></p>
                    <p><button onClick={this.doDelete}>Ano, smazat potravinu</button></p>
                    <p><Link to="/food/me">nechci potravinu smazat</Link></p>
                </div>
            </div>



            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyFoodDelete);
