import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import store from 'store/index';
import Loading from 'components/Loading/index';
import Saving from 'components/Saving/index';
import api from 'api';
import Util from 'utility';
import { fstat } from 'fs';
import BuildMetadata from 'metadata.json';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class Support extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            formMessage: '',
        };    
        this.onMessageChange = this.onMessageChange.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }

    componentDidMount() {
        Util.setPageTitle('Podpora');
    }

    onMessageChange(e) {
        this.setState({
            formMessage: e.target.value
        });
    }

    sendMessage(e) {
        e.preventDefault();
        const msg = this.state.formMessage;
        if(msg == '') {
            alert("Napište nejprve text zprávy.");
            return;
        }

        api('account/send-message', { post: true, data: { message: msg }}).then((res) =>{
            this.setState({
                formMessage: '',
            }, () => {
                alert("Zpráva odeslána, děkujeme.");
            });
        });
    }

    render() {
        const { state } = this;

        // TODO: these texts should NOT be in globals!
        // Load it dynamically on component mount!


        let texts = [], n = 1;

        let versions = ['personal', 'profi']; //, 'gastro'];
        versions.map(version => {
            const versionHeading = version.charAt(0).toUpperCase() + version.slice(1);
            texts.push(<h2>ZOFapp {versionHeading}</h2>);
            this.props.data.globals.zofManualTexts.map(text => {
                if(!text.version || text.version == '') text.version = 'personal';
                if(text.version !== version) return;
                texts.push(
                    <div className="question">
                        <input type="checkbox" id={`q${n}`} />
                        <label htmlFor={`q${n}`}>
                            <span><i className="far fa-question-circle"></i></span>
                            <h3>{text.title}</h3>
                        </label>
                        <div className="answer">
                            <div dangerouslySetInnerHTML={{ __html: text.body }} />
                        </div>
                    </div>
                );
                n++;
            });
        });


        return (
            <div className="page">
                <div className="page-header">
                    <h1>Nápověda</h1>
                </div>
                <div className="page-content">
                    <div className="container" style={{paddingTop:'1em'}}>
                        <div className="faq-row">
                            <div className="faq-questions">
                                {texts}
                            </div>
                            <div className="faq-form">
                                <h2>Dejte nám vědět!</h2>
                                <p style={{fontSize:'.9em',color:'#444',margin:'-.5em 0 1em 0'}}>Máte námět na zlepšení nebo chcete nahlásit chybu? Napište nám:</p>
                                <textarea name="message" onChange={this.onMessageChange}>{this.state.formMessage}</textarea>
                                <p>
                                    <button onClick={this.sendMessage}>Odeslat</button>
                                </p>
                            </div>
                            <p style={{margin:'1em 0 0',fontWeight:'bold'}}>Verze aplikace: {BuildMetadata.buildNumber}.{BuildMetadata.buildDate}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Support);
