import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading/index';
import Saving from 'components/Saving/index';
import api from 'api';
import Util from 'utility';

import { 
    loadGlobals
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        loadGlobals: data => dispatch(loadGlobals(data)),
    };
};

class GastroProfilePanel extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            saving: false,
            profileData: {},
            initialProfileUrl: '',
            originalLogoFilename: '',
            originalCoverFilename: '',
            tags: [],
            errors: [],
            saved: false,
            changeLoginData: false,
        };    
        this.onChange = this.onChange.bind(this);
        this.sortTag = this.sortTag.bind(this);
        this.saveProfileData = this.saveProfileData.bind(this);
    }

    componentDidMount() {
        Util.setPageTitle('Účet');
        api('account/gastro/get').then(result => {
            result = result.data;
            this.setState({
                profileData: result,
                initialProfileUrl: result.profile_url,
                originalLogoFilename: result.logo,
                originalCoverFilename: result.cover,
                tags: result.tags,
                loading: false
            });
        });
    }

    sortTag(id, dir) {
        let tags = this.state.tags;
        let thisTag = false, swapWithTag = false;

        tags.map(t => {
            if(t.id == id) {
                thisTag = t;
            }
        });
        tags.map(t => {
            if(t.order == (thisTag.order + dir)) {
                swapWithTag = t;
            }
        });

        if(thisTag && swapWithTag) {
            let tmp = thisTag.order;
            thisTag.order = swapWithTag.order;
            swapWithTag.order = tmp;

            let resTags = [];
            tags.map(t => {
                if(t.id == thisTag.id) {
                    resTags.push(swapWithTag);
                } else if(t.id == swapWithTag.id) {
                    resTags.push(thisTag);
                } else {
                    resTags.push(t);
                }
            });
            this.setState({
                tags: resTags
            });
        }
    }

    onChange(e) {
        const n = e.target.name;
        let v = e.target.value;

        if(n == 'logo') {
            v = e.target.files[0];
        }
        if(n == 'delete_logo') {
            v = e.target.checked ? 1 : 0;
        }
        if(n == 'cover') {
            v = e.target.files[0];
        }
        if(n == 'delete_cover' || n == 'section_recipes_show' || n == 'section_dailymenu_show' || n == 'section_products_show') {
            v = e.target.checked ? 1 : 0;
        }
        let profileData = this.state.profileData;
        profileData[n] = v;

        this.setState({
            profileData: profileData
        }); 
    }

    saveProfileData() {
        const { profileData } = this.state;
        this.setState({
            saving: true,
            errors: []
        }, () => {

            let fd = new FormData;
            for(let i in profileData) {
                fd.append(i, profileData[i]);
            }

            fd.append('tags', JSON.stringify(this.state.tags));

            api('account/gastro/update', {post: true, data: fd}).then(result => {
                result = result.data;

                if(result.status == 0) {
                    this.setState({
                        saving: false,
                        errors: result.errors
                    });
                } else {
                    api('load/startup-data').then((result) => {
                        this.props.loadGlobals(result.data);
                        let profileData = this.state.profileData;
                        profileData.delete_avatar = 0;
                        setTimeout(() => {
                            this.setState({
                                profileData: profileData,
                                saving: false,
                                saved: true
                            });
                        }, 300);
                    });
                }
            });
        });
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;
        const { profileData } = state;

        if(state.loading) return <Loading />;

        if(profileData.profile_url == null) profileData.profile_url = '';

        let saving = false;
        if(state.saving) {
            saving = <Saving />;
        }

        let errors = false;
        if(state.errors.length > 0) {
            errors = <div className="errors">{state.errors}</div>;
        }

        // Form data

        let publicProfileInput = (
            <React.Fragment>
                <div className="inputs" style={{ flexBasis: '100%' }}>
                    <div style={{ display: 'flex', flex: '1', alignItems: 'center' }}>
                        <span style={{ width: 'auto', flexBasis: 'auto', paddingRight: '.5em' }}>zofapp.cz/g/</span><input type="text" name="profile_url" onChange={this.onChange} value={profileData.profile_url} maxLength="40" />
                    </div>
                </div>
                <p style={{ margin: '.5em 0', fontSize: '11px', color: '#444' }}>Upozornění: po zadání adresy již nebude možné ji změnit.</p>
            </React.Fragment>
        );
        if(state.initialProfileUrl !== null && state.initialProfileUrl != '') {
            publicProfileInput = (
                <React.Fragment>
                    <div className="inputs" style={{ flexBasis: '100%' }}>
                        <div style={{ display: 'flex', flex: '1', alignItems: 'center' }}>
                            <span style={{ width: 'auto', flexBasis: 'auto', paddingRight: '.5em' }}>zofapp.cz/g/</span><input style={{ background: '#f1f1f1', color: '#777', fontWeight: 'bold' }} readOnly={true} type="text" name="profile_url" value={state.initialProfileUrl} maxLength="40" />
                        </div>
                    </div>
                    <p style={{ margin: '.5em 0', fontSize: '11px', color: '#444' }}>Chcete změnit adresu Vašeho profilu? <a target="_blank" href="https://www.zofapp.cz/kontakt">Napište nám</a>.</p>
                </React.Fragment>
            );
        }


        // Poradi kategorii jidelniho listku

        let tags = state.tags;
        tags.sort((a, b) => {
            return a.order - b.order;
        });

        let tagsHtml = [];
        tags.map(t => {
            let tagTitle = t.title;
            if(t.id in globals.tagMultipleTitles) {
                tagTitle = globals.tagMultipleTitles[t.id];    
            }
            tagsHtml.push(
                <div className="menu-sort-item">
                    <p>{tagTitle}</p>
                    <aside>
                        <a onClick={(e) => this.sortTag(t.id, -1)}><i className="fa fa-angle-up"></i></a>
                        <a onClick={(e) => this.sortTag(t.id, 1)}><i className="fa fa-angle-down"></i></a>
                    </aside>
                </div>
            );
        });
        if(tagsHtml.length > 0) {
            tagsHtml = <div className="menu-sort">{tagsHtml}</div>;
        }

        //

        const random = Math.random() * 10000000;

        let publicProfileLink = false;
        if(state.initialProfileUrl !== null && state.initialProfileUrl != '') {
            publicProfileLink = <a className="public-profile-link" target="_blank" href={`https://www.zofapp.cz/g/${state.initialProfileUrl}`}><i className="fa fa-link"></i> Zobrazit profil</a>;
        }

        let content = (
            <React.Fragment>
                <div className="panel panel-padded mt-1 mb-1">
                    <h1 style={{ fontSize:'1.7em', display:'flex',flexWrap:'wrap',alignItems:'center',margin:'0 0 1.5em 0'}}>
                        <span>Upravit veřejný profil</span>
                        {publicProfileLink}
                    </h1>
                    {errors}
                    <div className="row">
                        <div className="col">
                            <h3>Váš podnik</h3>
                            <div className="input-row">
                                <span>Název:</span>
                                <div className="inputs">
                                    <input type="text" name="company_name" onChange={this.onChange} value={profileData.company_name} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Stručný popis:<br /><small>(max. 15O znaků)</small></span>
                                <div className="inputs">
                                    <textarea style={{ height: '6em' }} name="short_description" onChange={this.onChange} value={profileData.short_description}></textarea>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Podrobnější popis:</span>
                                <div className="inputs">
                                    <textarea style={{ height: '12em' }} name="description" onChange={this.onChange} value={profileData.description}></textarea>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>IČO:</span>
                                <div className="inputs">
                                    <input type="text" name="business_id" onChange={this.onChange} value={profileData.business_id} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>DIČ:</span>
                                <div className="inputs">
                                    <input type="text" name="tax_id" onChange={this.onChange} value={profileData.tax_id} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>IČ DPH:</span>
                                <div className="inputs">
                                    <input type="text" name="vat_id" onChange={this.onChange} value={profileData.vat_id} />
                                </div>
                            </div>

                            <h3>Adresa</h3>
                            <div className="input-row">
                                <span>Ulice:</span>
                                <div className="inputs">
                                   <input type="text" name="address_street" onChange={this.onChange} value={profileData.address_street}/>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Město:</span>
                                <div className="inputs">
                                <input type="text" name="address_city" onChange={this.onChange} value={profileData.address_city}/>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>PSČ:</span>
                                <div className="inputs">
                                    <input type="text" name="address_zip" onChange={this.onChange} value={profileData.address_zip}/>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Země:</span>
                                <div className="inputs">
                                    <input type="text" name="address_country" onChange={this.onChange} value={profileData.address_country}/>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>GPS souřadnice:</span>
                                <div className="inputs">
                                    <input type="text" name="gps_coordinates" onChange={this.onChange} value={profileData.gps_coordinates} />
                                </div>
                            </div>

                            <h3>Kontaktní informace</h3>
                            <div className="input-row">
                                <span>Telefon:</span>
                                <div className="inputs">
                                    <input type="text" name="phone" onChange={this.onChange} value={profileData.phone} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>E-mail:</span>
                                <div className="inputs">
                                    <input type="text" name="email" onChange={this.onChange} value={profileData.email} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Web:</span>
                                <div className="inputs">
                                    <input type="text" name="link_web" onChange={this.onChange} value={profileData.link_web} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Facebook:</span>
                                <div className="inputs">
                                    <input type="text" name="link_facebook" onChange={this.onChange} value={profileData.link_facebook} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Instagram:</span>
                                <div className="inputs">
                                    <input type="text" name="link_instagram" onChange={this.onChange} value={profileData.link_instagram} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Twitter:</span>
                                <div className="inputs">
                                    <input type="text" name="link_twitter" onChange={this.onChange} value={profileData.link_twitter} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>YouTube:</span>
                                <div className="inputs">
                                    <input type="text" name="link_youtube" onChange={this.onChange} value={profileData.link_youtube} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>LinkedIn:</span>
                                <div className="inputs">
                                    <input type="text" name="link_linkedin" onChange={this.onChange} value={profileData.link_linkedin} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Pinterest:</span>
                                <div className="inputs">
                                    <input type="text" name="link_pinterest" onChange={this.onChange} value={profileData.link_pinterest} />
                                </div>
                            </div>
                            <h3>Provozní informace</h3>
                            <div className="input-row">
                                <span>Otevírací doba:</span>
                                <div className="inputs">
                                    <textarea name="opening_hours" onChange={this.onChange} value={profileData.opening_hours}></textarea>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Zaměření kuchyně:</span>
                                <div className="inputs">
                                    <input type="text" name="cuisine" onChange={this.onChange} value={profileData.cuisine} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Šéfkuchař:</span>
                                <div className="inputs">
                                    <input type="text" name="chef" onChange={this.onChange} value={profileData.chef} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Dodavatelé:</span>
                                <div className="inputs">
                                    <textarea name="suppliers" onChange={this.onChange} value={profileData.suppliers}></textarea>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Datum založení:</span>
                                <div className="inputs">
                                    <input type="text" name="established_in" onChange={this.onChange} value={profileData.established_in} />
                                </div>
                            </div>
                            <h3>Branding</h3>
                            <div className="input-row">
                                <span>Nahrát logo:</span>
                                <div className="inputs">
                                    <input type="file" name="logo" accept="image/*" onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Stávající logo:</span>
                                <div className="inputs">
                                    <img src={`https://www.zofapp.cz/${state.originalLogoFilename}?r=${random}`} style={{ width: '90px', height: 'auto' }} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span></span>
                                <div className="inputs">
                                    <label style={{ padding: 0 }}>
                                        <input type="checkbox" name="delete_logo" checked={profileData.delete_logo === 1} onChange={this.onChange} style={{ verticalAlign: 'middle' }} /> odstranit logo
                                    </label>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Nahrát cover foto:</span>
                                <div className="inputs">
                                    <input type="file" name="cover" accept="image/*" onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Stávající cover foto:</span>
                                <div className="inputs">
                                    <img src={`https://www.zofapp.cz/${state.originalCoverFilename}?r=${random}`} style={{ width: '320px', height: 'auto' }} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span></span>
                                <div className="inputs">
                                    <label style={{ padding: 0 }}>
                                        <input type="checkbox" name="delete_avatar" checked={profileData.delete_avatar === 1} onChange={this.onChange} style={{ verticalAlign: 'middle' }} /> odstranit cover foto
                                    </label>
                                </div>
                            </div>
                            <h3>Adresa veřejného profilu</h3>
                            <div className="input-row">
                                {publicProfileInput}
                            </div>
                            <h3>Informace na veřejném profilu</h3>
                            <div className="input-row">
                                <span>Recepty - zobrazovat?</span>
                                <div className="inputs">
                                    <label style={{ padding: 0 }}>
                                        <input type="checkbox" name="section_recipes_show" checked={profileData.section_recipes_show === 1} onChange={this.onChange} style={{ verticalAlign: 'middle' }} />
                                    </label>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Recepty - název sekce:</span>
                                <div className="inputs">
                                    <input type="text" name="section_recipes_title" onChange={this.onChange} value={profileData.section_recipes_title} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Denní menu - zobrazovat?</span>
                                <div className="inputs">
                                    <label style={{ padding: 0 }}>
                                        <input type="checkbox" name="section_dailymenu_show" checked={profileData.section_dailymenu_show === 1} onChange={this.onChange} style={{ verticalAlign: 'middle' }} />
                                    </label>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Denní menu - název sekce:</span>
                                <div className="inputs">
                                    <input type="text" name="section_dailymenu_title" onChange={this.onChange} value={profileData.section_dailymenu_title} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Produkty - zobrazovat?</span>
                                <div className="inputs">
                                    <label style={{ padding: 0 }}>
                                        <input type="checkbox" name="section_products_show" checked={profileData.section_products_show === 1} onChange={this.onChange} style={{ verticalAlign: 'middle' }} />
                                    </label>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Produkty - název sekce:</span>
                                <div className="inputs">
                                    <input type="text" name="section_products_title" onChange={this.onChange} value={profileData.section_products_title} />
                                </div>
                            </div>

                            <h3>Pořadí kategorií jídelního lístku</h3>
                            {tagsHtml}
                        </div>
                    </div>
                    <div className="form-submit">
                        <button onClick={this.saveProfileData}>Uložit nastavení</button>
                        <p style={{ marginTop: '1.5em' }}><Link to="/">zpět na úvod</Link></p>
                    </div>
                </div>
            {saving}
            </React.Fragment>
        );

        if(state.saved) {
            content = (
                <React.Fragment>
                    <div style={{ textAlign: 'center', padding: '2em 0', maxWidth: '480px', margin: '1em auto' }}>
                        <h2 style={{ color: '#4eb32d' }}>Údaje byly uloženy</h2>
                        <p>Zadané údaje k Vašemu účtu byly uloženy.</p>
                        <p>Kam dále?</p>
                        <p style={{ margin: '2em 0' }}>
                            <a className="button-primary" onClick={() => this.setState({saved:false})}>Zpět na úpravu údajů</a>
                        </p>
                        <p style={{ margin: '2em 0' }}>
                            <a className="button-primary" onClick={() => this.props.history.push('/')}>Přejít na úvodní stranu</a>
                        </p>
                    </div>
                </React.Fragment>
            );
        }   



        return content;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GastroProfilePanel);
