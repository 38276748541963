import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { debounce } from 'throttle-debounce';

import Loading from 'components/Loading/index';
import Saving from 'components/Saving/index';
import api from 'api';
import Util from 'utility';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class MyFoodEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            loading: true,
            saving: false,
            food: [],
            lastActiveAttribute: false,
            validationErrors: [],
            attributes: ['proteins', 'carbohydrates', 'carbohydrates_total', 'fructose', 'glucose', 'lactose', 'polyols', 
                'fats', 'fats_saturated', 'fats_monounsaturated', 'fats_polyunsaturated', 'fats_omega3', 'fats_omega3_ala',
                'fiber', 'fiber_soluble', 'fiber_insoluble',
                'alcohol', 'purines', 'salt', 'potassium', 'sodium', 'calcium', 'magnesium', 'iodine', 'selenium', 'zinc', 'iron', 'iron_hem', 'phosphorus', 'copper',
                'vitamin_c', 'vitamin_e', 'vitamin_d2', 'vitamin_d3', 'vitamin_a', 'vitamin_b1', 'vitamin_b2', 'vitamin_b3', 'vitamin_b5', 'vitamin_b6', 'vitamin_b9', 'vitamin_b12', 'vitamin_k',
                'histamine', 'antioxidants', 'gi', 'phytates', 'caffeine'
            ],

            attributes_basic: [
                'proteins', 'proteins_plant', 'proteins_animal', 'carbohydrates', 'fats', 'fiber', 'salt'
            ],

            showAdvancedAttributes: false,        
        };
        this.validate = this.validate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentDidMount() {
        Util.setPageTitle('Upravit vlastní potravinu');
        let foodId = this.props.match.params.id;
        api('food/get/' + foodId).then((res) =>{
            this.setState({
                id: foodId,
                food: res.data,
                loading: false
            });
        });        
    }

    saveChanges() {
        this.setState({
            saving: true
        }, () => {
            let fd = new FormData;
            for(let i in this.state.food) {
                fd.append(i, this.state.food[i]);
            }
            api('myfoods/update/' + this.state.id, { post: true, data: fd }).then(result => {
                this.props.history.push('/food/me');
            });
        });
    }

    validate(f) {
        const attrUnits = this.props.data.globals.attribute_units;
        const weight = f.weight * 1;
        let attributesTotal = 0;

        let validationErrors = [];
        let totalMacro = f['proteins'] * 1 + f['carbohydrates'] * 1 + f['fats'] * 1 + f['fiber'] * 1 + f['salt'] * 1;
        if(totalMacro > weight) {
            validationErrors.push('Součet základních složek (bílkoviny, sacharidy, vláknina, sůl) je vyšší než celkové množství potraviny');
        }

        if(f['proteins'] * 1 > 0 && (f['proteins_animal'] * 1 == 0 && f['proteins_plant'] * 1 == 0)) {
            validationErrors.push('Zadejte hodnotu buď u rostlinných, nebo živočišných bílkovin.');
        }

/*
        const ignoreForTotal = ['carbohydrates_total', 'fructose', 'lactose', 'glucose', 'polyols', 
                                'fats_unsaturated', 'fats_monounsaturated', 'fats_polyunsaturated', 
                                'fats_omega_3', 'fats_omega3_ala', 'fiber_soluble', 'fiber_insoluble'];
        for(let attr in f) {
            if(typeof attrUnits[attr] == 'undefined') continue;
            if(!this.state.attributes.includes(attr)) continue;
            if(ignoreForTotal.includes(attr)) continue;
            let unit = attrUnits[attr];
            let amount = f[attr] * 1;
            if(unit == 'mg') amount /= 1000;
            if(unit == 'mcg') amount /= 1000000;
            attributesTotal += amount;
        }

        if(attributesTotal > weight) {
            validationErrors.push("Součet položek je vyšší než celkové množství potraviny.");
        }

        let fatsTotal = f['fats'] * 1;
        let fatsSubTotal = 0;
        fatsSubTotal += f['fats_saturated'] * 1;
        fatsSubTotal += f['fats_monounsaturated'] * 1;
        fatsSubTotal += f['fats_polyunsaturated'] * 1;
        if(fatsSubTotal > fatsTotal) {
            validationErrors.push("Součet jednotlivých podskupin tuků je vyšší než celkové množství tuků.");
        }

        let carbsTotal = f['carbohydrates'] * 1;
        let carbsSubTotal = 0;
        carbsSubTotal += f['carbohydrates_total'] * 1;
        carbsSubTotal += f['polyols'] * 1;
        if(carbsSubTotal > carbsTotal) {
            validationErrors.push("Součet jednotlivých podskupin sacharidů je vyšší než celkové množství sacharidů.");
        }

        carbsTotal = f['carbohydrates_total'] * 1;
        carbsSubTotal = 0;
        carbsSubTotal += f['fructose'] * 1;
        carbsSubTotal += f['glucose'] * 1;
        carbsSubTotal += f['lactose'] * 1;
        if(carbsSubTotal > carbsTotal) {
            validationErrors.push("Součet jednotlivých podskupin cukrů je vyšší než celkové množství cukrů.");
        }

        let fiberTotal = f['fiber'] * 1;
        let fiberSubTotal = 0;
        fiberSubTotal += f['fiber_soluble'] * 1;
        fiberSubTotal += f['fiber_insoluble'] * 1;
        fiberSubTotal = +fiberSubTotal.toFixed(12);
        if(fiberSubTotal !== fiberTotal) {
            validationErrors.push("Součet rozpustné a nerozpustné vlákniny musí být stejný jako celkové množství vlákniny.");
        }

        let omega3 = f['fats_omega3'] * 1;
        let omega3ala = f['fats_omega3_ala'] * 1;
        if(omega3ala > omega3) {
            validationErrors.push("Množství Omega 3 ALA nesmí být vyšší než množství Omega 3.");
        }

        let fatsPoly = f['fats_polyunsaturated'] * 1;
        if(omega3 > fatsPoly) {
            validationErrors.push("Množství Omega 3 nesmí být vyšší než množství tuků polynenasycených.");
        }
*/
        this.setState({
            validationErrors: validationErrors,
        });
    }

    onChange(e) {
        let n = e.target.name;
        let v = e.target.value;
        let saa = this.state.showAdvancedAttributes;

        let valueNumeric = v * 1;
        if(v == valueNumeric.toString()) {
            v = valueNumeric;
        }

        if(n == 'photo') {
            v = e.target.files[0];
        }

        if(n == 'show_advanced') {
            if(e.target.checked) {
                saa = true;
            } else {
                saa = false;
            }
        }

        let f = this.state.food;
        f[n] = v;

        this.validate(f);

        if(this.state.validationErrors.length == 0) {
            this.setState({
                food: f,
                showAdvancedAttributes: saa
            });
        }
    }

    onFocus(e) {
        this.setState({
            lastActiveAttribute: e.target.name
        });
    }

    render() {
        const { state } = this;
        const { food } = state;
        const data = this.props.data;
        const { globals } = data;


        if(state.loading) return false;

        let formFieldsBasic = [];
        this.state.attributes_basic.map(attr => {
            let style = { textAlign: 'right', width: '8em' };
            let error = false;
            if(attr == state.lastActiveAttribute && state.validationErrors.length > 0) {
                let errors = [];
                state.validationErrors.map(err => {
                    errors.push(<span>{err}<br /></span>);
                })
                style = { textAlign: 'right', width: '8em', background: '#fdd', border: '1px solid red' };
                error = <p style={{ fontSize: '11px', margin: '6px 0', color: 'red' }}>{errors}</p>;
            } 

            let name = data.globals.attributes[attr];
            let additionalRowClass = '';

            if(name == 'Bílkoviny rostlinné') name = '- z toho rostlinné';
            if(name == 'Bílkoviny živočišné') name = '- z toho živočišné';


            formFieldsBasic.push(
                <div className={`input-row ${additionalRowClass}`} key={attr}>
                    <span>{name}:</span>
                    <div className="inputs">
                        <input style={style} type="text" ref={`input${attr}`} name={`${attr}`} value={food[attr]} onFocus={this.onFocus} onChange={this.onChange} />
                        <span style={{ fontSize: '.9em' }}>{data.globals.attribute_units[attr]}</span>
                    </div>
                    {error}
                </div>
            );
        });

        let formFieldsAdvanced = [];
        this.state.attributes.map(attr => {
            if(this.state.attributes_basic.includes(attr)) return;
            let style = { textAlign: 'right', width: '8em' };
            let error = false;
            if(attr == state.lastActiveAttribute && state.validationErrors.length > 0) {
                let errors = [];
                state.validationErrors.map(err => {
                    errors.push(<span>{err}<br /></span>);
                })
                style = { textAlign: 'right', width: '8em', background: '#fdd', border: '1px solid red' };
                error = <p style={{ fontSize: '11px', margin: '6px 0', color: 'red' }}>{errors}</p>;
            } 
            formFieldsAdvanced.push(
                <div className="input-row" key={attr}>
                    <span>{data.globals.attributes[attr]}:</span>
                    <div className="inputs">
                        <input style={style} type="text" ref={`input${attr}`} name={`${attr}`} value={food[attr]} onFocus={this.onFocus} onChange={this.onChange} />
                        <span style={{ fontSize: '.9em' }}>{data.globals.attribute_units[attr]}</span>
                    </div>
                    {error}
                </div>
            );
        });

        // if(!this.state.showAdvancedAttributes) formFieldsAdvanced = false;



        let saving = false;
        if(this.state.saving) saving = <Saving />;

        const random = Math.random() * 10000000;

        let valErrors = false;
        let saveButton = false;
        if(state.validationErrors.length == 0) {
            saveButton = <button onClick={this.saveChanges}>Uložit změny</button>
        } else {
            let err = state.validationErrors.map(e => {
                return <p style={{margin:'3px 0',fontSize:'.85em'}}>{e}</p>
            });

            valErrors = <div className="food-validation-errors">{err}</div>
            saveButton = <button style={{ background: '#ddd', color: '#aaa' }}>Uložit změny</button>
        }


        //

        let showAdvancedFieldsButton = (
            <div className="input-row myfood-edit-advanced-row">
                <span></span>
                <div className="inputs">
                    <label><input type="checkbox" name="show_advanced" onClick={this.onChange} checked={this.state.showAdvancedAttributes} /> pokročilé</label>
                </div>
            </div>
        );

        showAdvancedFieldsButton = false;

        let form = [];
        for (let group in globals.evaluation_groups) {
            let fields = [];

            if(group == 'Energie' || group == 'Množství' || group == 'Makroživiny') continue;

            for (let subgroup in globals.evaluation_groups[group]) {
                const attr = globals.evaluation_groups[group][subgroup];
                const name = globals.attributes[attr];

                if(attr.indexOf('ratio') >= 0) continue;

                let style = { textAlign: 'right', width: '8em' };
                let error = false;
                if(attr == state.lastActiveAttribute && state.validationErrors.length > 0) {
                    let errors = [];
                    state.validationErrors.map(err => {
                        errors.push(<span>{err}<br /></span>);
                    })
                    style = { textAlign: 'right', width: '8em', background: '#fdd', border: '1px solid red' };
                    error = <p style={{ fontSize: '11px', margin: '6px 0', color: 'red' }}>{errors}</p>;
                } 

                
                fields.push(
                    <div className={`input-row`} key={attr}>
                        <span>{name}:</span>
                        <div className="inputs">
                            <input style={style} type="text" ref={`input${attr}`} name={`${attr}`} value={food[attr]} onFocus={this.onFocus} onChange={this.onChange} />
                            <span style={{ fontSize: '.9em' }}>{data.globals.attribute_units[attr]}</span>
                        </div>
                        {error}
                    </div>  
                );
            }
            form.push(
                <>
                <h3>{group}</h3>
                {fields}
                </>

            );
        }


        return ( 
            <div className="page">
                <div className="page-header">
                    <h1>Upravit potravinu</h1>
                </div>
                <div className="page-content">
                    <div className="container" style={{paddingTop:'1em'}}>
                        <div className="alert alert-info">
                            <i className="fa fa-info-circle"></i>
                            <p>Zvolte název nové potraviny a upravte její nutriční profil. Upravujte pouze ty údaje, které naleznete na obalu či etiketě příslušné potraviny, případně na jiných dostupných a ověřených zdrojích. Pokud tyto údaje nebudou dostupné, doporučujeme ponechat stávající - budete-li měnit hmotnost potraviny, nezapomeňte poměrově snížit i množství zastoupených živin.</p>
                        </div>
                        {valErrors}
                        <div className="input-row">
                                <span><strong>Název:</strong></span>
                                <div className="inputs">
                                    <input type="text" name="title" value={food.title} onChange={this.onChange} style={{ fontWeight: 'bold' }} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Množství / jednotka:</span>
                                <div className="inputs">
                                    <input type="text" name="weight" value={food.weight} onChange={this.onChange} style={{ width: '8em' }} />
                                    <input type="text" name="weight_unit" value={food.weight_unit} onChange={this.onChange} style={{ width: '5em' }} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Normo množství / jednotka:</span>
                                <div className="inputs">
                                    <input type="text" name="norm_unit_amount" value={food.norm_unit_amount} onChange={this.onChange} style={{ width: '8em' }} />
                                    <select name="norm_unit_unit" value={food.norm_unit_unit} style={{ width: '12em' }} onChange={this.onChange}>
                                        {Object.keys(data.globals.normoUnits).map(unit => {
                                            return <option value={unit} key={unit}>{data.globals.normoUnits[unit]}</option>
                                        })};
                                    </select>
                                </div>
                            </div>
                            <br />        

                            {form}

                            {showAdvancedFieldsButton}

                            {formFieldsAdvanced}
                            <br />
                            <div className="input-row">
                                <span>Foto:</span>
                                <div className="inputs">
                                    <input type="file" name="photo" accept="image/*" onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Stávající foto:</span>
                                <div className="inputs">
                                    <img src={`https://www.kurzyatac.cz/data/hra/icon/png/${food.id}.png?r=${random}`} style={{ width: '90px', height: 'auto' }} alt="" />
                                </div>
                            </div>
                            <div className="input-row">
                                <span></span>
                                <div className="inputs">
                                    <label style={{ padding: 0 }}>
                                        <input type="checkbox" name="delete_photo" defaultChecked={food.delete_photo === true} onChange={this.onChange} style={{ verticalAlign: 'middle' }} /> obnovit výchozí ikonu potraviny
                                    </label>
                                </div>
                            </div>
                            {valErrors}
                            <div className="form-submit">
                                {saveButton}
                                <p style={{ marginTop: '1.5em' }}><Link to="/food/me">zpět</Link></p>
                            </div>                
                            {saving}




                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyFoodEdit);
