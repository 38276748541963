import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import api from 'api';
import Loading from 'components/Loading/index';
import Util from 'utility';

import ClientDataHelp from 'components/Help/ClientDataHelp';

import { 
    openModal, 
    closeModal, 
    showMessage,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
    };
};

class ClientData extends Component {
    constructor(props) {
        super(props);

        let d = new Date();
        const today = Util.yyyymmdd(d);

        this.state = {
            userId: this.props.match.params.id,
            loading: true,
            newData: {
                'target_date': today,
                'weight': '',
                'height': '',
                'diameter_sides': '',
                'diameter_waist': '',
                'whr': '',
                'bmi': '',
                'bmr_kcal': '',
                'bmr_kj': '',
                'fat_percent': '',
                'fat_amount': '',
                'visceral_fat': '',
                'water_content': '',
                'water_extracellular': '',
                'water_intracellular': '',
                'bone_mineralization': '',
                'metabolic_age': '',
                'muscle_amount': '',
                'physical_condition': '',
                'active_body_weight': '',
            },
            data: []
        }
        this.loadData = this.loadData.bind(this);
        this.onChange = this.onChange.bind(this);
        this.editItem = this.editItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.openDeleteItem = this.openDeleteItem.bind(this);
        this.submitData = this.submitData.bind(this);
        this.openHelp = this.openHelp.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }
    

    openHelp(e, help) {

        let helpTitles = {
            'date': 'Datum zápisu dat',
            'weight': 'Váha',
            'height': 'Výška',
            'diameter_sides': 'Obvod boků',
            'diameter_waist': 'Obvod pasu',
            'bmi': 'BMI',
            'whr': 'WHR',
            'fat_percent': 'Procento tuku v těle',
            'fat_amount': 'Hmotnost tuku v těle',
            'visceral_fat': 'Viscerální tuk',
            'water_content': 'Objem vody v těle',
            'water_extracellular': 'Voda mimobuněčná',
            'water_intracellular': 'Voda vnitrobuněčná',
            'bone_mineralization': 'Kostní hmota',
            'metabolic_age': 'Metabolický věk',
            'bmr_kcal': 'BMR',
            'bmr_kj': 'BMR',
            'muscle_amount': 'Svalová hmota',
            'physical_condition': 'Fyzická kondice',
            'active_body_weight': 'Aktivní tělesná hmotnost',
        };


        this.props.closeModal();
        this.props.openModal({
            'title': helpTitles[help] + ' - nápověda', 
            'class': 'dialog-flexible',
            'content': ClientDataHelp[help]()
        });
    }


    loadData() {
        api('profi/clientdata/' + this.state.userId).then((res) =>{
            this.setState({
                data: res.data.data,
                loading: false
            });
        });
    }

    editItem(e, id) {
        api('profi/clientdata/load/' + this.state.userId + '/' + id).then((res) =>{
            this.setState({
                newData: res.data,
                loading: false
            });
        });
    }

    openDeleteItem(e, id) {
        let modalContent = (
            <div style={{textAlign:'center'}}>
                <p><b>Opravdu smazat data klienta?</b></p>
                <p>{Util.getFormattedDate(new Date(this.state.data[id].target_date))}</p>
                <p style={{margin:'2em 0 1.5em 0'}}><a style={{background:'#4eb32d',color:'#fff',padding:'.5em 1.25em',textDecoration:'none',borderRadius:'3px'}} onClick={(e) => this.deleteItem(e, id)}>Ano, smazat</a></p>
                <p><a onClick={this.props.closeModal}>Ne, zpět</a></p>
            </div>
        );
        this.props.openModal({
            'title': 'Smazat data klienta', 
            'class': 'dialog',
            'content': modalContent
        });
    }

    deleteItem(e, date) {
        api('profi/clientdata/delete', {post:true, data:{client_id:this.state.userId, date:date}}).then((res) =>{
            this.setState({
                data: res.data,
                loading: false
            }, () => {
                this.props.closeModal();
            });
        });
    }

    onChange(e) {
        const n = e.target.name;
        let v = e.target.value;
        let data = this.state.newData;

        if(n == 'weight' || n == 'height' || n == 'diameter_waist' || n == 'diameter_sides') {
            v = parseInt(v);
            if(isNaN(v)) v = 0;
        }

        if(n == 'bmr_kcal') {
            v = parseInt(v);
            if(isNaN(v)) v = 0;
            data['bmr_kj'] = Math.round(v * 4.18);
        }

        if(n == 'bmr_kj') {
            v = parseInt(v);
            if(isNaN(v)) v = 0;
            data['bmr_kcal'] = Math.round(v / 4.18);
        }

        data[n] = v;

        // Calculate BMI

        let bmi = '---';
        let weight = data['weight'] * 1;
        let height = data['height'] * 1;
        let heightMetres = height / 100;
        let denom = heightMetres * heightMetres;
        if(denom > 0) {
            bmi = Math.round((weight / (heightMetres * heightMetres) * 10)) / 10;
        }
        data.bmi = bmi;

        // Calculate WHR

        let whr = '---';
        let ds = data['diameter_sides'] * 1;
        let dw = data['diameter_waist'] * 1;
        if(ds > 0 && dw > 0) {
            whr = Math.round((dw / ds) * 100) / 100;
        }
        data.whr = whr;

        // Save state

        this.setState({
            newData: data
        }); 
    }

    submitData() {
        const { newData } = this.state;
        this.setState({
            loading: true,
        }, () => {   
            let fd = new FormData;
            for(let i in newData) {
                fd.append(i, newData[i]);
            }
            fd.append('client_id', this.state.userId);
            api('profi/clientdata', {post: true, data: fd}).then(result => {
                result = result.data;
                this.setState({
                    newData: {
                        'weight': '',
                        'height': '',
                        'diameter_sides': '',
                        'diameter_waist': '',
                        'whr': '',
                        'bmi': '',
                        'bmr_kcal': '',
                        'bmr_kj': '',
                        'fat_percent': '',
                        'fat_amount': '',
                        'visceral_fat': '',
                        'water_content': '',
                        'water_extracellular': '',
                        'water_intracellular': '',
                        'bone_mineralization': '',
                        'metabolic_age': '',
                        'bmr': '',
                        'muscle_amount': '',
                        'physical_condition': '',
                        'active_body_weight': '',
                    },                    
                }, () => {
                    this.loadData();
                });                
            });
        });
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;
        const { newData } = state;

        if(state.loading) return <Loading />;

        let savedInfo = false;
        if(state.saved) {
            savedInfo = (
                <div className="message message-success">
                    <i className="fa fa-check"></i>
                    Hodnoty byly uloženy.
                </div>
                );
        }

        let overwriteWarning = false;
        Object.keys(state.data).map(i => {
            if(i == state.newData.target_date) {
                overwriteWarning = <p style={{fontSize:'11px',color:'#f00'}}>Pro vybrané datum už existuje záznam.<br />Odesláním formuláře se starý záznam přepíše.</p>;
            }
        });
                
        let dataList = [];
        Object.keys(state.data).map(i => {
            let dataItem = state.data[i];

            Object.keys(dataItem).map(key => {
                if(!dataItem[key] || dataItem[key] == '') dataItem[key] = '---';
            });

            let fmtDate = Util.getFormattedDate(new Date(dataItem.target_date));

            dataList.push(
                <div className="client-data-item">
                    <p><b>{fmtDate}</b></p>
                    <div className="row">
                        <p><span>Váha:</span> <b>{dataItem.weight} kg</b></p>
                        <p><span>Výška:</span> <b>{dataItem.height} cm</b></p>
                        <p><span>Obvod pasu:</span> <b>{dataItem.diameter_waist} cm</b></p>
                        <p><span>Obvod boků:</span> <b>{dataItem.diameter_sides} cm</b></p>
                        <p><span>Procento tuku:</span> <b>{dataItem.fat_percent} %</b></p>
                        <p><span>Hmotnost tuku:</span> <b>{dataItem.fat_amount} kg</b></p>
                        <p><span>Viscerální tuk:</span> <b>{dataItem.visceral_fat}</b></p>
                        <p><span>Objem vody v těle:</span> <b>{dataItem.water_content} %</b></p>
                        <p><span>Voda mimobuněčná:</span> <b>{dataItem.water_extracellular} %</b></p>
                        <p><span>Voda vnitrobuněčná:</span> <b>{dataItem.water_content} %</b></p>
                        <p><span>Kostní hmota:</span> <b>{dataItem.bone_mineralization} kg</b></p>
                        <p><span>Metabolický věk:</span> <b>{dataItem.metabolic_age} let</b></p>
                        <p><span>BMR kcal:</span> <b>{dataItem.bmr_kcal} kcal</b></p>
                        <p><span>BMR kJ:</span> <b>{dataItem.bmr_kj} kJ</b></p>
                        <p><span>BMI:</span> <b>{dataItem.bmi}</b></p>
                        <p><span>WHR:</span> <b>{dataItem.whr}</b></p>
                        <p><span>Svalová hmota:</span> <b>{dataItem.muscle_amount} kg</b></p>
                        <p><span>Fyzická kondice:</span> <b>{dataItem.physical_condition}</b></p>
                        <p><span>Aktivní tělesná hmotnost:</span> <b>{dataItem.active_body_weight} kg</b></p>
                    </div>
                    <div className="options">
                        <a onClick={(e) => this.editItem(e, i)}>Upravit</a>
                        {'\u00A0'}
                        <a style={{background:'#ccc',color:'#555'}} onClick={(e) => this.openDeleteItem(e, i)}>Smazat</a>
                    </div>
                </div>
            );
        });

        if(dataList.length > 0) {
            dataList = (
                <>
                {dataList}
                </>
            );

        } else {
            dataList = <p>V historii nejsou uloženy žádné údaje.</p>
        }

        // BMR

        let profileId = Util.getFirstProfileId();
        let profile = this.props.data.globals.profiles[profileId];
        let birth = new Date(profile.birthday);
        let cur = new Date();
        let diff = cur - birth;
        profile.age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
        let bmrCalculatedKcal = Math.round(Util.getBMR(profile));
        let bmrCalculatedKj = Math.round(bmrCalculatedKcal * 4.18)

        // BMI

        let bmiInfo = (
            <>
            <span>BMI:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'bmi')}>?</a></span>
            <div className="inputs">
                <input style={{border:'none',boxShadow:'none',background:'none',display:'inline',width:'6em'}} readonly={true}  type="text" name="bmi" value={newData.bmi} />
            </div>
            </>
        );

        // WHR

        let whrInfo = (
            <>
            <span>WHR:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'whr')}>?</a></span>
            <div className="inputs">
                <input style={{border:'none',boxShadow:'none',background:'none',display:'inline',width:'6em'}} readonly={true}  type="text" name="whr" value={newData.whr} />
            </div>
            </>
        );

        //

        return (
            <React.Fragment>
            <div className="page">
                <div className="page-header">
                    <h1>Data uživatele</h1>
                </div>
                <div className="page-content">
                    <div className="container" style={{paddingTop:'1em',paddingBottom:'3em'}}>
                        <h2>Zapsat nové údaje</h2>
                        <div className="input-row">
                            <span>Zapsat k: <a className="btn-help" onClick={(e) => this.openHelp(e, 'date')}>?</a></span>
                            <div className="inputs">
                                <input type="date" name="target_date" style={{display:'inline',width:'12em'}} onChange={this.onChange} value={newData.target_date} />
                            </div>
                        </div>

                        <div className="input-row-double">
                            <div className="input-row">
                                <span><b>Váha:</b> <a className="btn-help" onClick={(e) => this.openHelp(e, 'weight')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="weight" onChange={this.onChange} value={newData.weight} />
                                    kg
                                </div>
                            </div>
                            <div className="input-row">
                                <span><b>Výška:</b> <a className="btn-help" onClick={(e) => this.openHelp(e, 'height')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="height" onChange={this.onChange} value={newData.height} />
                                    cm
                                </div>
                            </div>
                        </div>
                        <div className="input-row-double">
                            <div className="input-row">
                                <span>Obvod boků: <a className="btn-help" onClick={(e) => this.openHelp(e, 'diameter_sides')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="diameter_sides" onChange={this.onChange} value={newData.diameter_sides} />
                                    cm
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Obvod pasu: <a className="btn-help" onClick={(e) => this.openHelp(e, 'diameter_waist')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="diameter_waist" onChange={this.onChange} value={newData.diameter_waist} />
                                    cm
                                </div>
                            </div>
                        </div>
                        <div className="input-row-double">
                            <div className="input-row">
                                {bmiInfo}
                            </div>
                            <div className="input-row">
                                {whrInfo}
                            </div>
                        </div>
                        <div className="input-row-double">
                            <div className="input-row">
                                <span>Procento tuku v těle:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'fat_percent')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="fat_percent" onChange={this.onChange} value={newData.fat_percent} />
                                    %
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Hmotnost tuku v těle:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'fat_amount')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="fat_amount" onChange={this.onChange} value={newData.fat_amount} />
                                    kg
                                </div>
                            </div>
                        </div>
                        <div className="input-row-double">
                            <div className="input-row">
                                <span>Viscerální tuk:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'visceral_fat')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="visceral_fat" onChange={this.onChange} value={newData.visceral_fat} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Objem vody v těle:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'water_content')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="water_content" onChange={this.onChange} value={newData.water_content} />
                                    %
                                </div>
                            </div>
                        </div>
                        <div className="input-row-double">
                            <div className="input-row">
                                <span>Voda mimobuněčná:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'water_extracellular')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="water_extracellular" onChange={this.onChange} value={newData.water_extracellular} />
                                    %
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Voda vnitrobuněčná: <a className="btn-help" onClick={(e) => this.openHelp(e, 'water_intracellular')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="water_intracellular" onChange={this.onChange} value={newData.water_intracellular} />
                                    %
                                </div>
                            </div>
                        </div>
                        <div className="input-row-double">
                            <div className="input-row">
                                <span>Aktivní tělesná hmotnost:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'active_body_weight')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="active_body_weight" onChange={this.onChange} value={newData.active_body_weight} />
                                    kg
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Kostní hmota:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'bone_mineralization')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="bone_mineralization" onChange={this.onChange} value={newData.bone_mineralization} />
                                    kg
                                </div>
                            </div>
                            </div>
                        <div className="input-row-double">
                            <div className="input-row">
                                <span>Metabolický věk:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'metabolic_age')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="metabolic_age" onChange={this.onChange} value={newData.metabolic_age} />
                                    let
                                </div>
                            </div>
                            <div className="input-row">
                                <span>BMR - kcal:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'bmr_kcal')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="bmr_kcal" onChange={this.onChange} value={newData.bmr_kcal} />
                                    kcal (výpočet: {bmrCalculatedKcal} kcal)
                                </div>
                            </div>
                        </div>
                        <div className="input-row-double">
                            <div className="input-row">
                                <span>BMR - kJ:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'bmr_kj')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="bmr_kj" onChange={this.onChange} value={newData.bmr_kj} />
                                    kJ (výpočet: {bmrCalculatedKj} kJ)
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Svalová hmota:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'muscle_amount')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="muscle_amount" onChange={this.onChange} value={newData.muscle_amount} />
                                    kg
                                </div>
                            </div>
                        </div>
                        <div className="input-row-double">
                            <div className="input-row">
                                <span>Fyzická kondice:  <a className="btn-help" onClick={(e) => this.openHelp(e, 'physical_condition')}>?</a></span>
                                <div className="inputs">
                                    <input style={{display:'inline',width:'6em'}} type="text" name="physical_condition" onChange={this.onChange} value={newData.physical_condition} />
                                </div>
                            </div>
                        </div>
                        <div className="input-row-double">
                            <div className="input-row" style={{flexDirection:'column'}}>
                                {overwriteWarning}
                                <button style={{background:'#4eb32d',color:'#fff',border:'none',padding:'6px 18px',borderRadius:'3px'}} onClick={this.submitData}>Zapsat hodnoty</button>
                            </div>
                        </div>


                        <div className="client-data-list">
                            <h2>Historie údajů uživatele</h2>
                            {dataList}
                        </div>

                    </div>
                </div>
            </div>
            {savedInfo}
            </React.Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientData));
