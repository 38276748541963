import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loading from 'components/Loading/index';
import Util from 'utility';
import api from 'api';

import { 
    closeModal,
    showMessage,
    loadGlobals,
    loginUser,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
        loadGlobals: data => dispatch(loadGlobals(data)),
        loginUser: data => dispatch(loginUser(data)),
    };
};


class PanelNewClient extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            client_email: '',
            client_zof_pin: '',
        };    

        this.update = this.update.bind(this);
        this.invite = this.invite.bind(this);
    }

    invite() {
        api('clients/invite', { post: true, data: { client_email: this.state.client_email, client_zof_pin: this.state.client_zof_pin }}).then((res) =>{
            if(typeof res.data.error !== 'undefined') {
                this.props.showMessage({
                    show: true,
                    type: 'error',
                    message: 'Neplatný e-mail nebo PIN.'
                });               
            } else {
                this.props.closeModal();
                this.props.showMessage({
                    show: true,
                    type: 'success',
                    message: 'Pozvánka byla odeslána.'
                });               
            }
        });
    }

    update(e) {
        let state = this.state;
        switch(e.target.name) {
            case 'client_email':
                state.client_email = e.target.value;
                break;
            case 'client_zof_pin':
                state.client_zof_pin = e.target.value;
                break;
            }
        this.setState(state);
    }

    render() {
        const { client } = this.state;
        const { globals } = this.props.data;

        return (
            <React.Fragment>
            <div className="filters">
                <p><input type="text" name="client_email" onChange={this.update} value={this.state.client_email} placeholder="Zadejte e-mail účtu uživatele" /></p>
                <p><input type="text" name="client_zof_pin" onChange={this.update} value={this.state.client_zof_pin} placeholder="Zadejte ZOF PIN uživatele" /></p>
                <p><button onClick={this.invite}>Pozvat uživatele</button></p>
            </div>
            </React.Fragment>            
        );            
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PanelNewClient));
