import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { debounce } from "throttle-debounce";   
import api from 'api';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class MenuInsert extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const d = this.props.match.params.date;
        api("menus/insert/" + id + "/" + d, { post: true }).then((res) => {
            res = res.data;
            this.props.history.push('/plan/' + d);
        });
    }

    render() {
        return [];
    }  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuInsert));
