import React, { Component } from 'react';

class Loading extends Component { 

    render() {
        return (
            <div className="loader-wrap">
                <div className="loader"></div>
            </div>
        );
    }
}

export default Loading;
