import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { debounce } from "throttle-debounce";   
import api from 'api';

import {
    showMessage
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        showMessage: data => dispatch(showMessage(data)),
    };
};

class RecipeShare extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;                  // id of recipe to share
        const profile = this.props.match.params.profile;        // profile type ('personal' or 'gastro')
        api("recipes/share/" + id + "/" + profile, { post: true, data: {description: localStorage.getItem('shareMenuDescription') }}).then((res) => {
            this.props.showMessage({
                show: true,
                type: 'success',
                message: 'Recept byl sdílen na Váš profil.'
            });               
            this.props.history.push('/recipe/me');
        });
    }



    render() {
        return [];
    }  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecipeShare));
