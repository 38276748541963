import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import Loading from 'components/Loading/index';
import Saving from 'components/Saving/index';
import api from 'api';
import Util from 'utility';

import { loadGlobals } from 'actions/index';
import { stat } from 'fs';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        loadGlobals: data => dispatch(loadGlobals(data)),
    };
};

class MyFoodCopy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            loading: true,
            saving: false,
            food: []
        };
        this.doCopy = this.doCopy.bind(this);
    }

    componentDidMount() {
        Util.setPageTitle('Vytvořit novou potravinu');
        let foodId = this.props.match.params.id;
        api('food/get/' + foodId).then((res) =>{
            this.setState({
                id: foodId,
                food: res.data,
                loading: false
            });
        });        
    }

    doCopy() {
        this.setState({
            saving: true
        }, () => {
            api('myfood/copy/' + this.state.id, { post: true }).then(result => {
                const newId = result.data.id * 1;
                api('load/startup-data').then((result) => {
                    this.setState({
                        saving: false
                    });
                    this.props.loadGlobals(result.data);
                    this.props.history.push('/myfood/edit/' + newId);
                });
            });
        });
    }

    render() {
        const { state } = this;
        const { food } = state;

        if(state.loading) return false;

        let img_src = 'https://www.zofapp.cz/data/zof/item/' + food.id + '.svg';

        let options = (
            <React.Fragment>
                <p>Tuto potravinu použijete jako vzor při vytvoření nové.<br />Můžete upravit název, nutriční hodnoty a nahrát obrázek potraviny.</p>
            <p style={{ marginTop: '2.5em', marginBottom: '2.5em' }}><button onClick={this.doCopy}>Ano, vytvořit kopii</button></p>
            <p><a style={{ color: '#555', textDecoration: 'underline' }} onClick={() => this.props.history.goBack()}>Zpět</a></p>
            </React.Fragment>
        );

        if(state.saving) options = <Saving />;

        return ( 
            <React.Fragment>
            <div className="panel foods-copy-detail">
                <div class="cookbook-header">
                    <h1>Vytváříte novou potravinu</h1>
                </div>
                <p className="food-icon">
                    <img src={img_src} alt="" />
                </p>
                <h2>{food.title}</h2>
                {options}
            </div>



            </React.Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyFoodCopy));
