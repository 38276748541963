import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from 'components/Loading';
import Util from 'utility';
import api from 'api';
import { Locale, __ } from 'locale';

import {
    addFood,
    setTags,
    showMessage,
 } from 'actions/index';

 const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        addFood: data => dispatch(addFood(data)),
        setTags: data => dispatch(setTags(data)),
        showMessage: data => dispatch(showMessage(data)),
    };
};

class LectureTaskPanelSave extends Component { 
    constructor(props) {
        super(props);

        let initialRecipeName = this.props.data.titles['1|0'];
        if(typeof initialRecipeName !== 'string') initialRecipeName = '';

        let initialTags = [];
        if(typeof this.props.data.tags[this.props.mealHash] !== 'undefined') {
            initialTags = this.props.data.tags[this.props.mealHash];
        }

        this.state = {
            recipeName: initialRecipeName,
            savedRecipes: {},
            overwriteRecipeId: -1,
            loading: false,
            saving: false,
            tags: initialTags,
            useInGenerator: false,
            saveRecipeDialogOpened: false,
        };
        this.onChange = this.onChange.bind(this);
        this.doSaveRecipe = this.doSaveRecipe.bind(this);
        this.doOverwriteRecipe = this.doOverwriteRecipe.bind(this);
    }

    componentDidMount() {
        api('recipes/list-saved').then(result => {
            this.setState({
                savedRecipes: result.data,
                loading: false,
            });
        });
    }

    onChange(e) {
        if(e.target.name == 'recipe_name') {
            this.setState({
                recipeName: e.target.value
            });
        }
        if(e.target.name == 't') {
            let tags = this.state.tags;
            const v = parseInt(e.target.value);
            if(tags.includes(v)) {
                tags = tags.filter(item => item !== v);                    
            } else {
                tags.push(v);
            }
            this.setState({
                tags: tags
            });
            this.props.setTags(tags);
        }
        if(e.target.name == 'use_in_generator') {
            this.setState({
                useInGenerator: e.target.checked ? 1 : 0
            });
        }

    }

    findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    }

    doSaveRecipe(e) {
        const { state } = this;
        const { data } = this.props;
        
        let recipeName = state.recipeName;

        let id = 0;
        if(this.state.overwriteRecipeId < 0) {
            // NOT overwriting a recipe
            if(recipeName.trim() == '') {
                this.recipeNameInput.focus();
                this.props.showMessage({
                    show: true,
                    type: 'error',
                    message: 'Zadejte název receptu.'
                });
                return false;
            }
            id = e.target.attributes.getNamedItem('data-id').value * 1;
        } else {
            id = this.state.overwriteRecipeId;
        }

        let gastroData = {
            'ingredients_origin': '',
            'grammage': '',
            'price': '',
        };
        if(typeof data.gastro == 'object') {
            gastroData = data.gastro;
        }

        let saveData = {};

        // Old
        saveData.activeMealHash = JSON.stringify(this.props.mealHash);
        saveData.titles = JSON.stringify(data.titles);

        //
        saveData.recipeName = JSON.stringify(state.recipeName);
        saveData.foods = JSON.stringify(data.foods);
        saveData.groups = JSON.stringify(data.foodGroups);
        saveData.modifications = JSON.stringify(data.modifications);
        saveData.descriptions = JSON.stringify(data.descriptions);
        saveData.recipeName = recipeName;
        saveData.tags = JSON.stringify(state.tags);
        saveData.useInGenerator = state.useInGenerator;
        saveData.cookBookRecipeId = data.lastOpenedCookBookRecipe;
        saveData.gastro = JSON.stringify(gastroData);
        saveData.id = id;

        saveData = JSON.stringify(saveData);

        api('save/recipe2', { post: true, data: saveData }).then(result => {
            result = result.data;
            if(result.ok == 1) {
                this.setState({
                    saveRecipeDialogOpened: false
                }, () => {    

                    // Delete local saved recipe
                    localStorage.removeItem('zof_unsaved_recipe');

                    //
                    this.props.showMessage({
                        show: true,
                        type: 'success',
                        message: 'Recept byl uložen.'
                    });        
                    this.props.onSaved();       
                }); 
            }
        });
    }

    doOverwriteRecipe(e, id) {
        this.setState({
            overwriteRecipeId: id
        }, () => {
            this.doSaveRecipe(e);
        });
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;

        let tags = [];
        Object.values(globals.tagGroupsOrder).map(g => {
            const tg = globals.tagGroups[g];
            let groupContents = [];
            Object.keys(globals.tagsToGroups[g]).map(io => {
                const tagId = globals.tagsToGroups[g][io];
                // if(tagId == 1) return;      // Pevne jidlo nepouzivat
                groupContents.push(
                    <>
                    <input id={`rtag_${g}_${io}`} type="checkbox" defaultChecked={state.tags.includes(tagId)} onChange={this.onChange} name="t" value={tagId} data-id={tagId} />
                    <label htmlFor={`rtag_${g}_${io}`}>
                        <span><i className="fa fa-check"></i></span>
                        {globals.tags[tagId]}
                    </label>
                    </>
                );
            });
             tags.push(<h4>{tg}</h4>);
            tags.push(<div className="recipe-tags-group">{groupContents}</div>);    
        });

        

        let useRecipeInGeneratorOption = false;
        if(Util.userHasPurchased('ZOF_PROFI')) {
            useRecipeInGeneratorOption = (
                <div style={{padding:'2em 0 1em 0'}}>
                    <p><b>Použít tento recept v generátoru</b><br />
                    Před zařazením receptu do generátoru je důležité zaškrtnout všechny relevantní a správné štítky.</p>
                    <label><input type="checkbox" name="use_in_generator" onChange={this.onChange} checked={state.useInGenerator == 1} /> Použít recept v generátoru jídelníčků</label>
                </div>
            );
        }



        let recipeName = this.state.recipeName;
        let savedRecipesList = [];

        // Put the actual opened recipe first (if any)
        for(let r in this.state.savedRecipes) {
            if(r == this.props.currentRecipeId) {
                savedRecipesList.push(
                    <div className="recipe-save-row" key={r}>
                        <span>{this.state.savedRecipes[r]}</span>
                        <button onClick={(e) => this.doOverwriteRecipe(e, r)}>Přepsat stávající recept</button>
                    </div>
                )
            }
        }

        for(let r in this.state.savedRecipes) {
            if(r == this.props.currentRecipeId) continue;
            savedRecipesList.push(
                <div className="recipe-save-row" key={r}>
                    <span>{this.state.savedRecipes[r]}</span>
                    <button onClick={(e) => this.doOverwriteRecipe(e, r)}>{__('Přepsat recept')}</button>
                </div>
            )
        }

        let savedRecipeList = <Loading />;
        if(!this.state.loading) {
            savedRecipeList = (
                <React.Fragment>
                <div className="dialog-content">
                    <div className="recipe-save-layout">
                        <div className="recipe-save-col">
                            <Scrollbars heightrelativetoparent="1">
                                <div className="recipe-tags">
                                {tags}
                                </div>
                                {useRecipeInGeneratorOption}
                            </Scrollbars>
                        </div>
                        <div className="recipe-save-col">
                            <div className="recipe-save-rows" style={{flex:1}}>
                            <Scrollbars heightrelativetoparent="1">
                                <div className="recipe-save-row">
                                    <input ref={(input) => { this.recipeNameInput = input; }} onChange={this.onChange} value={recipeName} autoComplete="off" type="text" name="recipe_name" placeholder={__('Název ukládaného receptu')} />
                                    <button data-id="0" type="button" onClick={(e) => this.doSaveRecipe(e)}>{__('Uložit nový recept')}</button>
                                </div>
                                {savedRecipesList}
                            </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {savedRecipeList}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LectureTaskPanelSave);
