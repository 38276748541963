import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser } from 'actions/index';

const mapStateToProps = state => {
    return { loading: state.loading };
}

const mapDispatchToProps = dispatch => {
    return {
        logoutUser: data => dispatch(logoutUser(data)),
    };
};

class Logout extends Component {
    componentDidMount() {
        this.props.logoutUser({
            callback: () => {
                localStorage.removeItem('jwt_token');
                this.props.history.push('/login');
            }
        });
    }

    render() {
        return false;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
