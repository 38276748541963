import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from "date-fns";
import Loading from 'components/Loading/index';
import Util from 'utility';
import api from 'api';
import { Editor, EditorState, RichUtils } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';

import EvaluationPanelSimple from 'components/EvaluationPanel/EvaluationPanelSimple';
import SimpleCalendar from 'components/Plan/SimpleCalendar';

import { 
    closeModal,
    showMessage,
    setFoodGroupDescription,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
        setFoodGroupDescription: data => dispatch(setFoodGroupDescription(data)),
        showMessage: data => dispatch(showMessage(data)),
    };
};


class PanelMenuDetail extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            menu: {},
            clients: {},
            targetPdfClientId: -1,
            monthData: false,
            targetDate: new Date(),
            tab: 'contents',
            loading: true,
            confirmDelete: false,
            shareEditorState: EditorState.createEmpty(),
            shareMenuClientId: 0,
            exportPdfLink: false,
        };    

        this.switchTab = this.switchTab.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.shareEditorChange = this.shareEditorChange.bind(this);
        this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.onEditChange = this.onEditChange.bind(this);
        this.shareMenuToClient = this.shareMenuToClient.bind(this);
        this.exportPDF = this.exportPDF.bind(this);

    }

    componentDidMount() {
        api('menus/load/' + this.props.id).then((res) =>{
            if(typeof res.data.error === 'string') {
                this.props.showMessage({
                    show: true,
                    type: 'error',
                    message: 'Jídelníček nenalezen.'
                });               
                this.props.closeModal();
            } else {
                this.setState({
                    loading: false,
                    menu: res.data.menu,
                    clients: res.data.clients,
                    monthData: res.data.monthData,
                });
            }
        });
    }

    switchTab(tab) {
        this.setState({
            tab: tab
        });
    }

    handleDateChange(date) {
        this.setState({
            targetDate: date
        });
    }

    
    showDeleteConfirm() {
        this.setState({
            confirmDelete: true
        });
    }

    cancelDelete() {
        this.setState({
            confirmDelete: false
        });
    }

    doDelete() {
        this.props.beginReload();
        api('menus/delete', {post: true, data: {id: this.state.menu.id}}).then((result) => {
            this.props.closeModal();
            this.props.showMenus();
        });
    }


    shareEditorChange(editorState) {
        this.setState({
            shareEditorState: editorState
        }, () => {
            localStorage.setItem('shareMenuDescription', stateToHTML(this.state.shareEditorState.getCurrentContent()))
        });
    }
    
    toggleInlineStyle (event) {
        event.preventDefault();
        let style = event.currentTarget.getAttribute('data-style');
        this.setState({
          shareEditorState: RichUtils.toggleInlineStyle(this.state.shareEditorState, style)
        });
    }


    onEditChange(e) {
        let n = e.target.name;
        let v = e.target.value;
        let shareMenuClientId = this.state.shareMenuClientId;
        switch(n) {
            case 'clientshare_client_id':
                shareMenuClientId = v;
                break;
        }

        this.setState({
            shareMenuClientId: shareMenuClientId
        });
    }

    shareMenuToClient() {
        let clientId = this.state.shareMenuClientId * 1;
        if(clientId == 0) {
            alert("Vyberte uživatele, kterému chcete jídelníček nasdílet.");
            return;
        }
        api('clients/share-menu-to', {post: true, data: {clientId: clientId, menuId: this.state.menu.id}}).then(res => {
            this.props.closeModal();
            this.props.showMessage({
                show: true,
                type: 'success',
                message: 'Jídelníček byl sdílen uživateli.'
            });     
        });
    }

        
    exportPDF() {
        const clientId = this.state.targetPdfClientId * 1;
        if(clientId == -1 || clientId == "-1") {
            alert("Vyberte prosím, pro koho chcete PDF vytvořit.");
            return;
        }

        this.setState({
            exportPdfLink: false,
        }, () => {
            api('menus/export-pdf', {post: true, data: {id: this.state.menu.id, clientId: clientId}}).then(result => {
                this.setState({
                    exportPdfLink: 'https://www.zofapp.cz/data/zofapp/menu-pdf/' + result.data.filename + '.pdf'
                });
            });
        });
    }

    render() {
        if(this.state.loading === true) return <Loading />;
        const { menu } = this.state;
        const { globals } = this.props.data;

        //                     <Link className="btn-secondary" to={`/menus/favorite/${menu.id}`}>Oblíbené</Link>


        let navbarAside = false;
        if(!this.state.confirmDelete) {
            navbarAside = (
                <aside>
                    <a onClick={() => this.switchTab('open')}>Otevřít</a>
                    <a onClick={() => this.switchTab('share')}>Sdílet</a>
                    <a onClick={this.showDeleteConfirm}><i className="fa fa-trash"></i> Smazat</a>
                </aside>
            );
        } else {
            navbarAside = (
                <aside>
                    <a onClick={this.doDelete} className="btn-warning"><i className="fa fa-exclamation-triangle"></i> Potvrdit smazání</a>
                    <a onClick={this.cancelDelete} className="btn-cancel"><i className="fa fa-undo"></i> Nemazat</a>
                </aside>
            );
        }


        let shareToClientButton = false;
        if(menu.user_has_clients > 0 && Util.userHasPurchased('ZOF_PROFI')) {
            shareToClientButton = <a className={'' + this.state.tab == 'clientshare' ? 'active': ''} style={{marginRight:'.5em'}} onClick={() => this.switchTab('clientshare')}>Sdílet uživateli</a>
        }


        let pdfExportButton = (
            <a className={'' + this.state.tab == 'exportpdf' ? 'active': ''} style={{marginRight:'.5em'}} onClick={() => this.switchTab('exportpdf')}>Export PDF</a>
        );



        let navBar = (
            <div className="recipe-detail-navbar">
                <div className="tabs" style={{flex:1,display:'flex',alignItems:'center'}}>
                    <a className={'' + this.state.tab == 'contents' ? 'active' : ''} onClick={() => this.switchTab('contents')}>Přehled</a>
                    {shareToClientButton}
                    {pdfExportButton}
                </div>
                {navbarAside}
            </div>
        );

        let tabContent = [];
            if(menu.comment.trim() != '') {
                tabContent.push(<div style={{marginBottom:'1em'}}>{Util.nl2br(menu.comment)}</div>);
            }

        for(var m in menu.contents) {
            let meal = menu.contents[m];

            let foodGroupTitles = [];
            meal.groups.map(group => {
                foodGroupTitles.push(<p style={{fontSize:'1em'}}>{group.title}</p>);
            });

            tabContent.push(
                <div className="meal-item meal-item-standalone" key={m}>
                <div className="meal-item-body">
                    <div className="meal-item-title">
                        <p>{globals.mealTypes[meal.id].title}</p>
                        <span className="time">
                            <i className="fa fa-clock"></i> {meal.time}
                        </span>
                    </div>
                    <div className="meal-item-details">
                        <div className="meal-item-details-title" style={{ fontWeight: 'normal' }}>
                            {foodGroupTitles}
                        </div>
                    </div>
                </div>
                <div className="meal-item-buttons">
                </div>
            </div>
            );
        }

        tabContent = (
            <div className="recipe-detail-contents">
                <input type="checkbox" id="cb-panel-evaluation" />
                <div className="recipe-foods" style={{flexGrow:1}}>
                    {tabContent}
                </div>
                <EvaluationPanelSimple recipe={menu} opened={true} />
            </div>
        );


        if(this.state.tab == 'open') {
            const dateString = format(this.state.targetDate, 'yyyy-MM-dd');
            const dateStringFmt = format(this.state.targetDate, 'd. M. yyyy');
            tabContent = <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <p><b>Vyberte den, do kterého chcete jídelníček vložit:</b></p>
                    <p style={{fontSize:'.9em',color:'#555',margin:'0 0 1.5em 0'}}>(Upozornění: aktuální obsah cílového dne bude vymazán!)</p>
                    <div style={{width:'100%',maxWidth:'440px',margin:'0 auto',background:'#f1f1f1',padding:'1em'}}>
                    <SimpleCalendar initialDate={this.state.targetDate} onDateChange={this.handleDateChange} monthData={this.state.monthData} />
                    <p style={{margin:'1.5em 0',textAlign:'center'}}><Link className="button-default" to={`/menu/insert/${this.state.menu.id}/${dateString}`}>Vložit jídelníček do {dateStringFmt}</Link></p>
                    </div>
                </div>;
        }

        if(this.state.tab == 'share') {
            const dateString = format(this.state.targetDate, 'yyyy-MM-dd');
            const dateStringFmt = format(this.state.targetDate, 'd. M. yyyy');
            tabContent = <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <p>Chcete sdílet tento jídelníček na svůj profil?</p>
                    <p><b>Napište komentář:</b></p>
                    <div style={{width:'100%',maxWidth:'640px'}}>
                        <p style={{margin:'0 0 2px 0',fontSize:'.9e'}}>
                            <input type="button" value="B" data-style="BOLD" onMouseDown={this.toggleInlineStyle} />
                            <input type="button" value="I" data-style="ITALIC" onMouseDown={this.toggleInlineStyle} style={{fontStyle:'italic'}} />
                        </p>
                        <div style={{border:'1px solid #ccc',padding:'4px',maxHeight:'300px',overflowY:'scroll'}}>
                            <Editor editorState={this.state.shareEditorState} onChange={this.shareEditorChange} />
                        </div>
                    </div>
                    <p style={{margin:'1.5em 0',textAlign:'center'}}><Link className="button-default" to={`/menu/share/${this.state.menu.id}`}>Sdílet jídelníček</Link></p>
                    </div>;
        }

        if(this.state.tab == 'clientshare') {
            let clientShareOptions = [];
            clientShareOptions.push(<option value={0}>- Vyberte -</option>);
            this.state.clients.map(client => {
            //this.props.data.globals.clients.map(client => {
                clientShareOptions.push(<option value={client.id}>{client.name}</option>);
            });
            tabContent = (
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <div className="form-generic">
                        <h3>
                            Sdílet jídelníček uživateli
                        </h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Vyberte uživatele:</td>
                                    <td><select name="clientshare_client_id" onChange={this.onEditChange} >{clientShareOptions}</select></td>
                                </tr>                
                                <tr>
                                    <td></td>
                                    <td><button onClick={() => this.shareMenuToClient()}>Poslat</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        }

        if(this.state.tab == 'exportpdf') {
            let pdfDownloadLink = false;
            if(this.state.exportPdfLink) {
                pdfDownloadLink = <p style={{padding:'0 0 2em 0',margin:'0',fontWeight:'bold'}}><a style={{color:'#4ebe55',textDecoration:'none',padding:'.35em 1em',border:'1px solid #4ebe55',borderRadius:'3px'}} target="_blank" href={this.state.exportPdfLink}><i className="fa fa-file-pdf"></i> Stáhnout PDF</a></p>
            }

            let clientOptions = [];
            clientOptions.push(<option value={-1}>Vyberte...</option>);
            clientOptions.push(<option value={0}>- Pro sebe -</option>);
            Object.keys(this.state.clients).map(c => {
                const client = this.state.clients[c];
                clientOptions.push(<option value={client.id}>{client.name}</option>);
            });

            tabContent = (
                <>
                {pdfDownloadLink}
                <p>Vytvořit pro: <select onChange={(e) => this.setState({targetPdfClientId:e.target.value})} name="client_id">{clientOptions}</select></p>
                <p><button className="btn-primary" onClick={this.exportPDF}>Vytvořit PDF</button></p>
                </>
            );
        }


        return (
            <React.Fragment>
            <div className="recipe-detail">
                <h1>{menu.title}</h1>
                {navBar}
                {tabContent}
            </div>
            </React.Fragment>            
        );            
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelMenuDetail);
