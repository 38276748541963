import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import Util from 'utility';
import { Locale, __ } from 'locale';

import { 
    showMessage, 
    openModal,
    closeModal,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        showMessage: data => dispatch(showMessage(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
    };
};

class DialogProfileInfo extends Component { 

    constructor(props) {
        super(props);
    }

    render() {
        const { data } = this.props;
        const { globals } = data;

        let fpid = Util.getFirstProfileId();
        if(typeof data.appModeData.profileId === 'number') {
            fpid = data.appModeData.profileId;
        }

        let profile = globals.profiles[fpid];

        if(typeof data.appModeData.profileOverride === 'object') {
           profile = data.appModeData.profileOverride;
        } 

        const bmr = Util.getBMR(profile);
        let energCoef = this.props.data.globals.energyOutputCoefficients[profile.energy_output_coefficient];

        const targetProfileId = data.appModeData.lectureInfoData.lecture_data.target_profile_id;

        // Brat koeficient ze zadani ukolu pouze, pokud neni zadan uzivateluv vlastni profil
        if(targetProfileId > 0) {
            if(typeof data.appModeData.lectureInfoData.lecture_data.energy_coefficient_id === 'number') {
                const newCoefId = data.appModeData.lectureInfoData.lecture_data.energy_coefficient_id;
                if(newCoefId > 0) {
                    energCoef = this.props.data.globals.energyOutputCoefficients[newCoefId];
                }
            }
        }

        const energyExpTotal = bmr * energCoef.coefficient;

        let healthInfo = false;
        if(typeof data.appModeData.lectureInfoData.lecture_data.health_condition_info === 'string' &&
        data.appModeData.lectureInfoData.lecture_data.health_condition_info != '') {
            healthInfo = (
                <>
                <div dangerouslySetInnerHTML={{ __html: data.appModeData.lectureInfoData.lecture_data.health_condition_info }}></div>
                </>
            );
        }

        return (
            <div className="lecturetask-profile-dialog">
                <p><b>{profile.name}</b></p>
                <table>
                    <tbody>
                        <tr>
                            <td>{__('Výška')}:</td>
                            <td>{profile.height} cm</td>
                        </tr>
                        <tr>
                            <td>{__('Váha')}:</td>
                            <td>{profile.weight} kg</td>
                        </tr>
                        <tr>
                            <td>{__('Obvod pasu')}:</td>
                            <td>{profile.diameter_waist} cm</td>
                        </tr>
                        <tr>
                            <td>{__('Obvod boků')}:</td>
                            <td>{profile.diameter_sides} cm</td>
                        </tr>           
                        <tr>
                            <td>{__('BMR')}:</td>
                            <td>{bmr} kcal / {Math.round(bmr * 4.18)} kJ</td>
                        </tr>
                        <tr>
                            <td>{__('Výdej energie')}:</td>
                            <td>{Math.round(energyExpTotal)} kcal / {Math.round(energyExpTotal * 4.18)} kJ</td>
                        </tr>
                    </tbody>
                </table>
                {healthInfo}
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogProfileInfo));
